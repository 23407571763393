import React from "react";
import styled from "styled-components";
import * as Popover from "@radix-ui/react-popover";
import { PopoverArrow, PopoverClose } from "../header/Balances";

// Assets
import { ReactComponent as Arrow } from "../../assets/v2/icons/arrow.svg";
import { ReactComponent as Document } from "../../assets/v2/icons/document.svg";
const OfficialLinks = () => {
  return <Popover.Root>
            <Popover.Trigger asChild>
                <OfficialLinksContainer>
                    <Document />
                    <div>Official Links</div>
                    <StyledArrow />
                </OfficialLinksContainer>
            </Popover.Trigger>
            <Popover.Portal>
                <PopoverContent>
                    <PopoverClose aria-label="Close" />
                    <PopoverArrow />
                    <LinkContainer>
                        <Link href="https://sweetgg.gitbook.io/sweet-whitepaper" target="_blank" rel="noopener noreferrer">
                            Whitepaper
                        </Link>
                        <Link href="https://www.dextools.io/app/en/ether/pair-explorer/0x6f807ca2e3074c6ba8bfb4c9c319c938d56dd71c" target="_blank" rel="noopener noreferrer">
                            Chart
                        </Link>
                        <Link href="https://hub.sweet.gg/changelog" target="_blank" rel="noopener noreferrer">
                            Changelog
                        </Link>
                        <Link href="https://hub.sweet.gg" target="_blank" rel="noopener noreferrer">
                            Feedback
                        </Link>
                    </LinkContainer>
                </PopoverContent>
            </Popover.Portal>
        </Popover.Root>;
};
export default OfficialLinks;
const OfficialLinksContainer = styled.div`
    padding: 8px 12px;
    margin: 0 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 8px;
    border: 1px solid ${props => props.theme.border.primary};
    color: ${props => props.theme.colors.primary};
    background: ${props => props.theme.background.tertiary};
    cursor: pointer;
    font-weight: 500;
`;
const StyledArrow = styled(Arrow)`
    margin-left: auto;
`;
const PopoverContent = styled(Popover.Content)`
    border-radius: 10px;
    padding: 5px;
    width: 210px;
    border: 1px solid ${props => props.theme.border.primary};
    background: ${props => props.theme.background.primary};
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
`;
const LinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
`;
const Link = styled.a`
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    color: ${props => props.theme.colors.primary};
    background: ${props => props.theme.background.secondary};
    text-decoration: none;
    width: 100%;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
        opacity: 0.9;
    }
`;