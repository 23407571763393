const cutDecimalPoints = (num) => {
    return num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
};

// Parse commas into thousands  // TODO: Use this everywhere for consistency
const parseCommasToThousands = (value, noCommas = false) => {
    if (!value) {
        return "0";
    }
    if (!noCommas && typeof value === "number") {
        value = value.toFixed(2);
    }

    if (noCommas) {
        value = cutDecimalPoints(value);
    }

    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default parseCommasToThousands;
