import React from "react";
import styled from "styled-components";
import { TransitionGroup } from "react-transition-group";
import PropTypes from "prop-types";
import BREAKPOINTS from "../../constants/breakpoints";
import { getLevelBadge } from "../../utils/vip";

// MUI Components
import { Collapse } from "@material-ui/core";

// Assets
import coin from "../../assets/coin.svg";
const Bets = ({
  players,
  loading
}) => {
  return <Root>
            <TotalBets>
                ALL BETS
                <Total>
                    {loading ? "Loading..." : players.map(player => parseFloat(player.betAmount)).reduce((a, b) => a + b, 0).toFixed(2)}
                </Total>
                <img src={coin} alt="Sweet Coin" style={{
        marginLeft: "5px"
      }} />
            </TotalBets>
            <BetsContainer>
                <TransitionGroup>
                    {players.sort((a, b) => b.betAmount - a.betAmount).map((player, index) => <Collapse key={index}>
                                <Bet key={index}>
                                    <LevelBadge alt="Level Badge" src={getLevelBadge(player.vip_rank)} />
                                    <Username>{player.username}</Username>
                                    <StoppedAt>
                                        {player.stoppedAt && `${player.stoppedAt / 100}x`}
                                    </StoppedAt>
                                    {player.winningAmount ? <WinningAmount>
                                            +{player.winningAmount.toFixed(2)}
                                        </WinningAmount> : <BetAmount>
                                            {player.betAmount.toFixed(2)}
                                        </BetAmount>}
                                    <img src={coin} alt="Sweet Coin" style={{
              marginLeft: "5px"
            }} />
                                </Bet>
                            </Collapse>)}
                </TransitionGroup>
            </BetsContainer>
        </Root>;
};
const Root = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid ${props => props.theme.border.primary};
    background: ${props => props.theme.background.primary};
    backdrop-filter: blur(7.5px);
    padding: 0.5rem;
    width: 40%;

    @media (max-width: ${BREAKPOINTS.sm}) {
        width: 100%;
        height: 70vh;
        margin-top: 20px;
    }
`;
const Total = styled.div`
    color: ${({
  theme
}) => theme.colors.tertiary};
    margin-left: auto;
`;
const TotalBets = styled.div`
    width: 100%;
    height: 2.1rem;
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    color: ${({
  theme
}) => theme.colors.secondary};
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.1em;
`;
const BetsContainer = styled.div`
    display: flex;
    color: white;
    height: 100%;
    flex-direction: column;
    overflow-y: auto;
    padding: 0.5rem;
`;
const Bet = styled.div`
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 5px;
    width: 100%;
    padding: 10px;
    color: ${({
  theme
}) => theme.colors.primary};

    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.1em;
    background: ${({
  theme
}) => theme.background.secondary};
    position: relative;
`;
const WinningAmount = styled.div`
    color: #6afe43;
    filter: drop-shadow(0px 0px 15px green) invert(10%);
    margin-left: auto;
`;
const LevelBadge = styled.img`
    height: auto;
    width: 25px;
`;
const Username = styled.div`
    margin-left: 5px;
    color: ${({
  theme
}) => theme.colors.primary};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100px;
`;
const BetAmount = styled.div`
    margin-left: auto;
`;
const StoppedAt = styled.div`
    width: 10%;
    margin-right: 5px;
`;
Bets.propTypes = {
  players: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
};
export default Bets;