import {
    USER_LOADED,
    RELOADING_USER,
    WALLET_CHANGE,
    EVENT_KEYS_CHANGE,
    AUTH_ERROR,
} from "../actions/types";

const initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: null,
    isLoading: true,
    user: null,
};

const auth = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user: payload,
            };
        case RELOADING_USER:
            return {
                ...state,
                isAuthenticated: false,
                isLoading: true,
            };
        case WALLET_CHANGE:
            let wallet = state.user.wallets[payload.currency_id];
            return {
                ...state,
                user: {
                    ...state.user,
                    wallets: {
                        ...state.user.wallets,
                        [payload.currency_id]: {
                            ...wallet,
                            [payload.type]: {
                                ...wallet[payload.type],
                                amount: payload.amount,
                            },
                        },
                    },
                },
            };
        case EVENT_KEYS_CHANGE:
            return {
                ...state,
                user: {
                    ...state.user,
                    eventKeys: state.user.eventKeys + payload,
                },
            };
        case AUTH_ERROR:
            localStorage.removeItem("token");
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                isLoading: false,
            };
        default:
            return state;
    }
};

export default auth;
