import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React from "react";

// Assets // TODO: Use CDN for these
import Angy from "../../assets/emojis/angy.webp";
import Blush from "../../assets/emojis/blush.webp";
import Borpahmmm from "../../assets/emojis/borpahmmm.webp";
import Bruh from "../../assets/emojis/bruh.webp";
import Catno from "../../assets/emojis/catno.gif";
import Catnod from "../../assets/emojis/catnod.gif";
import Chimken from "../../assets/emojis/chimken.gif";
import Corpa from "../../assets/emojis/corpa.webp";
import CSGOGreen from "../../assets/emojis/csgogreen.gif";
import Derp from "../../assets/emojis/derp.webp";
import DerpBinoculars from "../../assets/emojis/derpbinoculars.gif";
import Derpy from "../../assets/emojis/derpy.webp";
import EggplantHullo from "../../assets/emojis/eggplanthullo.webp";
import FatCredit from "../../assets/emojis/fatcredit.gif";
import FrogeHappy from "../../assets/emojis/frogehappy.gif";
import Haroldkek from "../../assets/emojis/haroldkek.gif";
import Hi from "../../assets/emojis/hi.webp";
import HmmBongos from "../../assets/emojis/hmmbongos.gif";
import HmmHonk from "../../assets/emojis/hmmhonk.gif";
import HmmNotes from "../../assets/emojis/hmmnotes.gif";
import Huh from "../../assets/emojis/huh.webp";
import HuhExit from "../../assets/emojis/huhexit.gif";
import HyperGamba from "../../assets/emojis/hypergamba.gif";
import KekHmm from "../../assets/emojis/kekhmm.webp";
import KekShrug from "../../assets/emojis/kekshrug.webp";
import KekWBoom from "../../assets/emojis/kekwboom.gif";
import KekSweat from "../../assets/emojis/kekwsweat.webp";
import Lurk from "../../assets/emojis/lurk.gif";
import Mad from "../../assets/emojis/mad.gif";
import Ok from "../../assets/emojis/ok.webp";
import Orly from "../../assets/emojis/orly.webp";
import Owo from "../../assets/emojis/owo.webp";
import PeepoBusiness from "../../assets/emojis/peepobusiness.webp";
import PeKek from "../../assets/emojis/pekek.webp";
import PepeBabyBottle from "../../assets/emojis/pepebabybottle.gif";
import PepeBooba from "../../assets/emojis/pepebooba.webp";
import PepeHmmm from "../../assets/emojis/pepehmmm.webp";
import PepeRip from "../../assets/emojis/peperip.webp";
import PoggSpin from "../../assets/emojis/poggspin.gif";
import Ree from "../../assets/emojis/ree.webp";
import SadShitFace from "../../assets/emojis/sadshitface.webp";
import SaltClownKek from "../../assets/emojis/saltclownkek.webp";
import Salute from "../../assets/emojis/salute.webp";
import Scrajj from "../../assets/emojis/scrajj.gif";
import Shitface from "../../assets/emojis/shitface.gif";
import Slowjam from "../../assets/emojis/slowjam.gif";
import Stare from "../../assets/emojis/stare.webp";
import Swipe from "../../assets/emojis/swipe.gif";
import Vibe from "../../assets/emojis/vibe.gif";
import Walter from "../../assets/emojis/walter.gif";
import WhyGodWhyMe from "../../assets/emojis/whygodwhyme.gif";
import Wicked from "../../assets/emojis/wicked.gif";
const EMOJIS = [{
  word: ":angy:",
  src: Angy
}, {
  word: ":blush:",
  src: Blush
}, {
  word: ":borpahmmm:",
  src: Borpahmmm
}, {
  word: ":bruh:",
  src: Bruh
}, {
  word: ":catno:",
  src: Catno
}, {
  word: ":catnod:",
  src: Catnod
}, {
  word: ":chimken:",
  src: Chimken
}, {
  word: ":corpa:",
  src: Corpa
}, {
  word: ":csgogreen:",
  src: CSGOGreen
}, {
  word: ":derp:",
  src: Derp
}, {
  word: ":derpbinoculars:",
  src: DerpBinoculars
}, {
  word: ":derpy:",
  src: Derpy
}, {
  word: ":eggplanthullo:",
  src: EggplantHullo
}, {
  word: ":fatcredit:",
  src: FatCredit
}, {
  word: ":frogehappy:",
  src: FrogeHappy
}, {
  word: ":haroldkek:",
  src: Haroldkek
}, {
  word: ":hi:",
  src: Hi
}, {
  word: ":hmmbongos:",
  src: HmmBongos
}, {
  word: ":hmmhonk:",
  src: HmmHonk
}, {
  word: ":hmmnotes:",
  src: HmmNotes
}, {
  word: ":huh:",
  src: Huh
}, {
  word: ":huhexit:",
  src: HuhExit
}, {
  word: ":hypergamba:",
  src: HyperGamba
}, {
  word: ":kekhmm:",
  src: KekHmm
}, {
  word: ":kekshrug:",
  src: KekShrug
}, {
  word: ":kekwboom:",
  src: KekWBoom
}, {
  word: ":keksweat:",
  src: KekSweat
}, {
  word: ":lurk:",
  src: Lurk
}, {
  word: ":mad:",
  src: Mad
}, {
  word: ":ok:",
  src: Ok
}, {
  word: ":orly:",
  src: Orly
}, {
  word: ":owo:",
  src: Owo
}, {
  word: ":peepobusiness:",
  src: PeepoBusiness
}, {
  word: ":pekek:",
  src: PeKek
}, {
  word: ":pepebabybottle:",
  src: PepeBabyBottle
}, {
  word: ":pepebooba:",
  src: PepeBooba
}, {
  word: ":pepehmmm:",
  src: PepeHmmm
}, {
  word: ":peperip:",
  src: PepeRip
}, {
  word: ":poggspin:",
  src: PoggSpin
}, {
  word: ":ree:",
  src: Ree
}, {
  word: ":sadshitface:",
  src: SadShitFace
}, {
  word: ":saltclownkek:",
  src: SaltClownKek
}, {
  word: ":salute:",
  src: Salute
}, {
  word: ":scrajj:",
  src: Scrajj
}, {
  word: ":shitface:",
  src: Shitface
}, {
  word: ":slowjam:",
  src: Slowjam
}, {
  word: ":stare:",
  src: Stare
}, {
  word: ":swipe:",
  src: Swipe
}, {
  word: ":vibe:",
  src: Vibe
}, {
  word: ":walter:",
  src: Walter
}, {
  word: ":whygodwhyme:",
  src: WhyGodWhyMe
}, {
  word: ":wicked:",
  src: Wicked
}];
export { EMOJIS };
const Emoji_1 = _compiledBlock(_props => <img style={_props.v0} src={_props.v1} alt={_props.v2} title={_props.v3} />, {
  name: "Emoji_1"
});
const Emoji = ({
  src,
  alt,
  title,
  size = "30px"
}) => {
  return /*@million jsx-skip*/<Emoji_1 v0={{
    width: size,
    margin: "0 5px"
  }} v1={src} v2={alt} v3={title} />;
};
export default Emoji;