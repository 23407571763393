import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { toast } from "react-hot-toast";
import Moment from "react-moment";
import { getUserTransactions } from "../services/api.service";

// MUI Components
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

// Components
import Skeleton from "../components/Skeleton";
import PageContainer from "../components/PageContainer";
import CircularLoader from "../components/CircularLoader";
import BREAKPOINTS from "../constants/breakpoints";
import { getCurrencyById } from "../constants/currencies";
import Price from "../components/Price";

// TODO: add tx id for withdraws and link to copy on click
const UserTransactions = ({
  isLoading,
  isAuthenticated
}) => {
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("deposits"); // "deposits", "withdrawals", "bets", "affiliate", "bonus", "refunds", "other"
  const [transactions, setTransactions] = useState(null);
  const limit = 12;
  const [offset, setOffset] = useState(0);
  const [pagination, setPagination] = useState({
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    nextPage: null,
    prevPage: null
  });
  const handleTypeChange = newType => {
    setType(newType);
    setOffset(0);
    setPagination({
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      nextPage: null,
      prevPage: null
    });
  };
  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    const fetchData = async () => {
      setLoading(true);
      try {
        const resp = await getUserTransactions(type, limit, offset);
        setTransactions(resp.data);
        setPagination({
          totalItems: resp.pagination.total_items,
          totalPages: resp.pagination.total_pages,
          currentPage: resp.pagination.current_page,
          nextPage: resp.pagination.next_page,
          prevPage: resp.pagination.prev_page
        });
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.error(error.message);
      }
    };
    fetchData();
  }, [isAuthenticated, limit, offset, type]);

  // If user is not logged in
  if (!isLoading && !isAuthenticated) {
    return <Redirect to="/" />;
  }
  return isLoading ? <Loader>
            <CircularLoader />
        </Loader> : <PageContainer maxWidth="lg">
            <PageTitle>Transactions</PageTitle>
            <TypeSelectionWrapper>
                <TypeSelection $isActive={type === "deposits"} onClick={() => handleTypeChange("deposits")}>
                    Deposits
                </TypeSelection>
                <TypeSelection $isActive={type === "withdrawals"} onClick={() => handleTypeChange("withdrawals")}>
                    Withdrawals
                </TypeSelection>
                <TypeSelection $isActive={type === "bets"} onClick={() => handleTypeChange("bets")}>
                    Bets
                </TypeSelection>
                <TypeSelection $isActive={type === "affiliate"} onClick={() => handleTypeChange("affiliate")}>
                    Affiliate
                </TypeSelection>
                <TypeSelection $isActive={type === "bonus"} onClick={() => handleTypeChange("bonus")}>
                    Bonus
                </TypeSelection>
                <TypeSelection $isActive={type === "refunds"} onClick={() => handleTypeChange("refunds")}>
                    Refunds
                </TypeSelection>
                <TypeSelection $isActive={type === "other"} onClick={() => handleTypeChange("other")}>
                    Other
                </TypeSelection>
            </TypeSelectionWrapper>
            <Tran>
                {loading ? <LoadingTable /> : transactions.length >= 1 ? <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Time</TableCell>
                                <TableCell>Action</TableCell>
                                <TableCell>Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transactions.map(transaction => <TableRow key={transaction.id}>
                                    <TableCell>
                                        <Moment format="YYYY/MM/DD HH:mm:ss">
                                            {transaction.created_at}
                                        </Moment>
                                    </TableCell>
                                    <TableCell>{transaction.type}</TableCell>
                                    <TableCell>
                                        <AmountInnerContainer>
                                            <Price amount={transaction.amount} currency={getCurrencyById(transaction.currency_id)} />
                                        </AmountInnerContainer>
                                    </TableCell>
                                </TableRow>)}
                        </TableBody>
                    </Table> : <NoTransactions>NO TRANSACTIONS</NoTransactions>}
            </Tran>
            <OffsetButtonsRow>
                <OffsetButton clickable={pagination.prevPage != null} onClick={() => {
        if (pagination.prevPage != null) {
          setOffset((pagination.currentPage - 2) * limit);
        }
      }}>
                    Previous
                </OffsetButton>
                <OffsetButton clickable={pagination.nextPage != null} onClick={() => {
        if (pagination.nextPage != null) {
          setOffset(pagination.currentPage * limit);
        }
      }}>
                    Next
                </OffsetButton>
            </OffsetButtonsRow>
        </PageContainer>;
};
const LoadingTable = () => {
  return <Table>
            <TableHead>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Action</TableCell>
                    <TableCell>Amount</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Array(5).fill().map((element, index) => <TableLoader key={index} />)}
            </TableBody>
        </Table>;
};
const TableLoader = () => {
  return <TableRow>
            <TableCell>
                <Skeleton height="25px" width="250px" />
            </TableCell>
            <TableCell>
                <Skeleton height="25px" width="50px" />
            </TableCell>
            <TableCell>
                <Skeleton height="25px" width="100px" />
            </TableCell>
            <TableCell>
                <Skeleton height="25px" width="50px" />
            </TableCell>
        </TableRow>;
};
UserTransactions.propTypes = {
  isAuthenticated: PropTypes.bool,
  isLoading: PropTypes.bool
};
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading
});
export default connect(mapStateToProps)(UserTransactions);
const NoTransactions = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 35%;
    color: ${props => props.theme.colors.secondary};
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1em;

    @media (max-width: ${BREAKPOINTS.sm}) {
        margin-top: 50%;
    }
`;
const Loader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36rem;
`;
const PageTitle = styled.h1`
    color: ${({
  theme
}) => theme.colors.primary};
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.1em;
    margin: 0;
    margin-top: 1rem;
`;
const Tran = styled.div`
    margin-top: 2rem;
    border-radius: 10px;
    background: ${props => props.theme.background.primary};
    border: 1px solid ${props => props.theme.border.primary};
    backdrop-filter: blur(7.5px);
    padding: 1.5rem;
    padding-top: 1rem;
    overflow-y: auto;
    min-height: 70vh;

    @media (max-width: ${BREAKPOINTS.sm}) {
        padding: 1rem;
    }

    & th {
        border-bottom: none;
        color: ${props => props.theme.colors.primary};
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        padding: 0;
    }

    & td {
        border-bottom: 1px solid ${props => props.theme.colors.secondary};
        color: ${props => props.theme.colors.secondary};

        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.05em;
        padding-left: 0;

        &:nth-child(n + 1):nth-child(-n + 3) {
            color: ${props => props.theme.colors.secondary};

            font-size: 13px;
            font-weight: 500;
            letter-spacing: 0.05em;
        }
    }
`;
const AmountInnerContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;
const OffsetButtonsRow = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 1rem;
`;
const OffsetButton = styled.div`
    color: ${({
  clickable,
  theme
}) => clickable ? theme.colors.primary : theme.colors.secondary};
    cursor: ${({
  clickable
}) => clickable ? "pointer" : "forbidden"};
    font-size: 14px;
`;
const TypeSelectionWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
    background: ${props => props.theme.background.primary};
    border-radius: 10px;
    padding: 5px;
    width: fit-content;
`;
const TypeSelection = styled.div`
    color: ${props => props.$isActive ? props.theme.colors.primary : props.theme.colors.secondary};
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.1em;
    padding: 0.5rem 1rem;
    background: ${props => props.$isActive ? props.theme.background.secondary : props.theme.background.primary};
    border-radius: 8px;

    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
        color: ${props => props.theme.colors.primary};
    }
`;