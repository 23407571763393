export const USER_LOADED = "USER_LOADED";
export const RELOADING_USER = "RELOADING_USER";
export const WALLET_CHANGE = "WALLET_CHANGE";
export const EVENT_KEYS_CHANGE = "EVENT_KEYS_CHANGE";
export const AUTH_ERROR = "AUTH_ERROR";
export const SWEET_TOKEN_DATA_UPDATE = "SWEET_TOKEN_DATA_UPDATE";
export const SELECT_BET = "SELECT_BET";
export const DESELECT_BET = "DESELECT_BET";
export const CLEAR_BETS = "CLEAR_BETS";
export const OPEN_BETS = "OPEN_BETS";
export const UPDATE_CURRENCY = "UPDATE_CURRENCY";
export const UPDATE_THEME = "UPDATE_THEME";
export const TOGGLE_NAVBAR = "TOGGLE_NAVBAR";
export const TOGGLE_CHAT = "TOGGLE_CHAT";
export const TOGGLE_BETSLIP = "TOGGLE_BETSLIP";
export const SET_USERS_ONLINE = "SET_USERS_ONLINE";
export const UPDATE_EXCHANGE_RATES = "UPDATE_EXCHANGE_RATES";
export const TOGGLE_SHOW_IN_FIAT = "TOGGLE_SHOW_IN_FIAT";
export const SET_IN_PLAY = "SET_IN_PLAY";
export const REDIRECT_TO_BANNED_COUNTRY_PAGE =
    "REDIRECT_TO_BANNED_COUNTRY_PAGE";
