import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Assets
import red from "../../assets/coinflip/red_coin.webp";
import blue from "../../assets/coinflip/blue_coin.webp";
const Color = ({
  value,
  onChange
}) => {
  return <ColorOptionsContainer>
            <Label>SIDE</Label>
            <ColorOptions>
                <ColorOption checked={value === "red"} onClick={() => onChange("red")}>
                    <img src={red} alt="Red" style={{
          height: "100%"
        }} />
                </ColorOption>
                <ColorOption checked={value === "blue"} onClick={() => onChange("blue")}>
                    <img src={blue} alt="Blue" style={{
          height: "100%"
        }} />
                </ColorOption>
            </ColorOptions>
        </ColorOptionsContainer>;
};
const Label = styled.div`
    color: ${({
  theme
}) => theme.colors.secondary};
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-transform: uppercase;
`;
const ColorOptionsContainer = styled.div`
    margin-bottom: 20px;
`;
const ColorOptions = styled.div`
    margin-top: 5px;
    display: flex;
    gap: 10px;
`;
const ColorOption = styled.div`
    height: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border: 2px solid transparent;
    border-radius: 100%;
    ${({
  checked,
  theme
}) => checked ? `border-color: ${theme.colors.tertiary};` : "opacity: 0.5;"}
    cursor: pointer;
`;
Color.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
export default Color;