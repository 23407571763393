import { WebSocketManager } from "./websocket";

const V2_SOCKET_URL = process.env.REACT_APP_V2_API_URL.replace("https", "wss")
    .replace("http", "ws")
    .replace(new URL(process.env.REACT_APP_V2_API_URL).pathname, "/socket");

export const v2Socket = new WebSocketManager(V2_SOCKET_URL);

export const authSocket = v2Socket.getNamespace("auth");
export const chatSocket = v2Socket.getNamespace("chat");
export const walletSocket = v2Socket.getNamespace("wallet");
export const exchangeSocket = v2Socket.getNamespace("exchange");
export const dataSocket = v2Socket.getNamespace("data");
export const notificationsSocket = v2Socket.getNamespace("notifications");

export const coinflipSocket = v2Socket.getNamespace("coinflip");
export const rouletteSocket = v2Socket.getNamespace("roulette");
export const crashSocket = v2Socket.getNamespace("crash");
export const diceSocket = v2Socket.getNamespace("dice");
export const casesSocket = v2Socket.getNamespace("crates");
export const caseBattleSocket = v2Socket.getNamespace("casebattle");
export const blackjackSocket = v2Socket.getNamespace("blackjack");

export const sockets = [
    chatSocket,
    coinflipSocket,
    rouletteSocket,
    crashSocket,
    diceSocket,
    casesSocket,
    caseBattleSocket,
    blackjackSocket,
];
