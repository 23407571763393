import styled, { keyframes } from "styled-components";
const spin = keyframes`
    0% { 
        transform: rotate(0deg);
   
    }
    100% { 
        transform: rotate(360deg); 
       
    }
`;
const CircularLoader = styled.div`
    border: 3px solid transparent;
    border-top: 3px solid ${({
  theme
}) => theme.colors.tertiary};
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: ${spin} 0.8s linear infinite;
    user-select: none;
`;
export default CircularLoader;