import sweet from "../assets/coin.svg";
import eth from "../assets/v2/currency-icons/eth.webp";

export const SWEET_CURRENCY = {
    id: "ethereum-token-sweet-mainnet-0x2df7d0e4903029717c949cad204075a3d75c8806",
    name: "SWEET",
    image: sweet,
    type: "TOKEN",
    textColor: "#eb2584",
    decimalPlaces: 9,
};

export const ETH_CURRENCY = {
    id: "ethereum-coin-ethereum-mainnet",
    name: "ETH",
    image: eth,
    type: "COIN",
    textColor: "#5e7ae1",
    decimalPlaces: 18,
};

export const USD_CURRENCY = {
    id: "usd",
    name: "USD",
    image: "",
    type: "FIAT",
    textColor: "#00ff00",
    decimalPlaces: 2,
};

// TODO: should be fetched from API
export const CURRENCIES = {
    SWEET: SWEET_CURRENCY,
    ETH: ETH_CURRENCY,
    USD: USD_CURRENCY,
};

export function isFiat(currency) {
    switch (currency.id) {
        case CURRENCIES.USD.id:
            return true;
        default:
            return false;
    }
}

export function getCurrencyById(id) {
    return Object.values(CURRENCIES).find((currency) => currency.id === id);
}

export const getExchangeRate = (exchangeRates, from, to) => {
    if (!exchangeRates) {
        return NaN;
    }

    switch (from) {
        case CURRENCIES.SWEET.id:
            return exchangeRates.token.ethereum[
                "0x2df7d0e4903029717c949cad204075a3d75c8806"
            ][to];
        case CURRENCIES.ETH.id:
            return exchangeRates.coin.ethereum[to];
        default:
            return NaN;
    }
};
