import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { updateTheme } from "../store/actions/theme";
import BREAKPOINTS from "../constants/breakpoints";

// Components
import PageContainer from "../components/PageContainer";
import Button, { COLOURS } from "../components/Button";

// Assets
import themeBannerImage from "../assets/v2/theme-banner.webp";
import defaultThemePreview from "../assets/v2/theme-previews/default-theme.svg";
import midnightThemePreview from "../assets/v2/theme-previews/midnight-theme.svg";
import ogThemePreview from "../assets/v2/theme-previews/og-theme.svg";
import blastThemePreview from "../assets/v2/theme-previews/blast-theme.svg";
const AvailableThemes = [{
  title: "Light Theme",
  theme: "light",
  preview: defaultThemePreview,
  requirementText: "",
  isUnlocked: _ => {
    return true;
  }
}, {
  title: "Midnight Theme",
  theme: "midnight",
  preview: midnightThemePreview,
  requirementText: "",
  isUnlocked: _ => {
    return true;
  }
}, {
  title: "OG Theme",
  theme: "og",
  preview: ogThemePreview,
  requirementText: "",
  isUnlocked: _ => {
    return true;
  }
}, {
  title: "Blast Theme",
  theme: "blast",
  preview: blastThemePreview,
  requirementText: "",
  isUnlocked: _ => {
    return true;
  }
}];
const Themes = ({
  user,
  activeTheme
}) => {
  const dispatch = useDispatch();
  const handleThemeSelect = theme => {
    dispatch(updateTheme(theme));
  };
  return <PageContainer maxWidth="lg">
            <ThemesBanner src={themeBannerImage} alt="Themes Banner" />
            <PageTitle>Themes Store</PageTitle>
            <Subtitle>
                Unlock themes by completing tasks and challenges. This can
                require playing games, participating in events, or even just
                being a member of the community for a certain amount of time.
            </Subtitle>
            <ThemesContainer>
                {AvailableThemes.map(themeData => {
        return <ThemeCard key={themeData.theme} active={activeTheme === themeData.theme} user={user} themeData={themeData} handleThemeSelect={handleThemeSelect} />;
      })}
            </ThemesContainer>
        </PageContainer>;
};
Themes.propTypes = {
  isAuthenticated: PropTypes.bool,
  isLoading: PropTypes.bool,
  user: PropTypes.object
};
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading,
  user: state.auth.user,
  activeTheme: state.theme.themeType
});
export default connect(mapStateToProps)(Themes);
const PageTitle = styled.h1`
    color: ${({
  theme
}) => theme.colors.primary};
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.1em;
    margin: 0;
    margin-top: 20px;
`;
const Subtitle = styled.div`
    color: ${({
  theme
}) => theme.colors.secondary};
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 15px;
`;
const ThemesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 2rem;

    @media (max-width: ${BREAKPOINTS.md}) {
        justify-content: center;
    }
`;
const ThemeCard = ({
  user,
  themeData,
  handleThemeSelect,
  active
}) => {
  const {
    title,
    theme,
    preview,
    requirementText,
    isUnlocked
  } = themeData;
  const _isUnlocked = isUnlocked(user);
  return <CardContainer>
            <PreviewImage src={preview} />
            <ThemeName>{title}</ThemeName>
            {!_isUnlocked && <RequirementText>{requirementText}</RequirementText>}
            {active ? <Button colour={COLOURS.secondary} style={{
      marginTop: "auto",
      padding: "10px"
    }} disabled>
                    ACTIVE
                </Button> : <Button colour={_isUnlocked ? COLOURS.primary : COLOURS.secondary} disabled={!_isUnlocked} style={{
      marginTop: "auto",
      padding: "10px"
    }} onClick={() => handleThemeSelect(theme)}>
                    {_isUnlocked ? "SELECT" : "LOCKED"}
                </Button>}
        </CardContainer>;
};
const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${props => props.theme.background.primary};
    border: 1px solid ${props => props.theme.border.primary};
    border-radius: 10px;
    text-align: center;
    padding: 15px;
    width: 220px;
    height: 240px;
`;
const PreviewImage = styled.img`
    height: 100px;
    width: auto;
`;
const ThemeName = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 15px;
`;
const RequirementText = styled.div`
    color: ${props => props.theme.colors.secondary};
    font-size: 11px;
`;
const ThemesBanner = styled.img`
    border-radius: 10px;
    border: 3px solid rgba(255, 255, 255, 0.2);
    width: 100%;
    height: auto;
`;