import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import toast from "react-hot-toast";
import BREAKPOINTS from "../../constants/breakpoints";
import useMediaQuery from "../../hooks/useMediaQuery";
import { blackjackSocket } from "../../services/websocket.service";

// Components
import Hand from "./Hand";
import Controls from "./Controls";
import CircularLoader from "../CircularLoader";

// Assets
import blackjackBackground from "../../assets/v2/blackjack/background.png";
import blackJackCardsImg from "../../assets/v2/blackjack/cards.png";
const RESET_ROUND_TIME = 3000;
const Blackjack = forwardRef(({
  sweet,
  loading
}, ref) => {
  const [activeHand, setActiveHand] = useState("");
  const [state, setState] = useState({
    gameId: null,
    playerHands: [],
    dealerHand: {
      total: 0,
      cards: []
    },
    inProgress: false
  });
  useImperativeHandle(ref, () => ({
    setData(data) {
      setState(data);
    }
  }));

  // Set active hand to first hand when player hands are updated
  useEffect(() => {
    if (state.playerHands.length > 0) {
      const firstHand = state.playerHands[0];
      setActiveHand(firstHand.id);
    }
  }, [state.playerHands]);
  const onError = error => {
    toast.error(error);
  };
  const onDeal = game => {
    console.log(game);
    setState({
      gameId: game.id,
      playerHands: game.table.player,
      dealerHand: {
        ...game.table.dealer,
        cards: [...game.table.dealer.cards, {
          value: "dummy",
          suit: ""
        }]
      },
      inProgress: true
    });
    setActiveHand(game.table.player[0].id);
  };
  const onGameActionResult = table => {
    setState(prevState => ({
      ...prevState,
      playerHands: table.player,
      dealerHand: {
        ...table.dealer,
        cards: [...table.dealer.cards, {
          value: "dummy",
          suit: ""
        }]
      }
    }));
  };
  const onGameResult = data => {
    console.log(data);

    // Reset active hand
    setActiveHand("");

    // Set state to show results
    setState(prevState => ({
      ...prevState,
      playerHands: data.player,
      dealerHand: data.dealer
    }));
    setTimeout(() => {
      resetRound();
    }, RESET_ROUND_TIME);
  };
  const resetRound = () => {
    setState({
      isWin: undefined,
      inProgress: false,
      playerHands: [],
      dealerHand: {
        total: 0,
        cards: []
      }
    });
  };
  useEffect(() => {
    blackjackSocket.on("game-creation-error", onError);
    blackjackSocket.on("game-creation-success", onDeal);
    blackjackSocket.on("game-action-error", onError);
    blackjackSocket.on("game-action-success", onGameActionResult);
    blackjackSocket.on("game-result", onGameResult);
    return () => {
      blackjackSocket.off("game-creation-error", onError);
      blackjackSocket.off("game-creation-success", onDeal);
      blackjackSocket.off("game-action-error", onError);
      blackjackSocket.off("game-action-success", onGameActionResult);
      blackjackSocket.off("game-result", onGameResult);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check if current active hand is done and set next active hand
  useEffect(() => {
    if (!state.playerHands || state.playerHands.length === 0) {
      return;
    }

    // Check if current active hand is done
    const _activeHand = state.playerHands.find(hand => hand.id === activeHand);
    if (_activeHand?.done) {
      const nextHand = state.playerHands.find(hand => !hand.done);
      if (!nextHand) {
        return;
      }
      setActiveHand(nextHand.id);
    }
  }, [state, activeHand]);
  const isMobile = useMediaQuery(BREAKPOINTS.sm);
  const _checkIfCanSplit = () => {
    if (!state.playerHands || state.playerHands.length === 0) {
      return false;
    }
    return state.playerHands.length < 2 && state.playerHands[0].cards.length === 2 && state.playerHands[0].cards[0].value === state.playerHands[0].cards[1].value;
  };
  return loading ? <CircularLoader style={{
    marginTop: "30vh"
  }} /> : <>
            {isMobile ? <>
                    {state.gameId && <ContentContainer>
                            <Hand hand={state.dealerHand} inProgress={state.inProgress} owner="dealer" />
                            <PlayerHandsContainer>
                                {state.playerHands.map((hand, index) => <Hand key={index} hand={hand} isActive={activeHand === hand.id} setActiveHand={() => {
            if (!hand.done) {
              setActiveHand(hand.id);
            }
          }} inProgress={state.inProgress} owner="player" />)}
                            </PlayerHandsContainer>
                        </ContentContainer>}
                    <Controls sweet={sweet} gameId={state.gameId} activeHandId={activeHand} inProgress={state.inProgress} canSplit={_checkIfCanSplit()} />
                </> : <FlexContainer>
                    <BlackjackCards src={blackJackCardsImg} alt="Blackjack Cards" />
                    <Controls sweet={sweet} gameId={state.gameId} activeHandId={activeHand} inProgress={state.inProgress} canSplit={_checkIfCanSplit()} />
                    <ContentContainer>
                        {state.gameId && <>
                                <Hand hand={state.dealerHand} inProgress={state.inProgress} owner="dealer" />
                                <PlayerHandsContainer>
                                    {state.playerHands?.map((hand, index) => <Hand key={index} hand={hand} isActive={activeHand === hand.id} setActiveHand={() => {
              if (!hand.done) {
                setActiveHand(hand.id);
              }
            }} inProgress={state.inProgress} owner="player" />)}
                                </PlayerHandsContainer>
                            </>}
                    </ContentContainer>
                </FlexContainer>}
        </>;
});
Blackjack.propTypes = {
  sweet: PropTypes.object.isRequired,
  gameId: PropTypes.string,
  playerHands: PropTypes.array,
  dealerHand: PropTypes.object,
  inProgress: PropTypes.bool
};
export default Blackjack;
const FlexContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
const PlayerHandsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
`;
const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    margin-left: 35px;
    background-image: url(${blackjackBackground});
    background-repeat: no-repeat;
    background-position: center;

    @media (max-width: ${BREAKPOINTS.sm}) {
        margin-left: 0;
    }
`;
const BlackjackCards = styled.img`
    position: absolute;
    top: 0;
    right: 5%;
    height: 5rem;
    width: auto;
`;