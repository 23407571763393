import React from "react";
import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";
import capitalize from "../../utils/capatlize";
export const StyledNavItem = styled(Link)`
    color: ${props => props.theme.colors.secondary};
    pointer-events: ${props => props.disabled ? "none" : "auto"};
    width: 100%;
    padding: ${props => props.isWide ? "0 15px" : "0 10px"};
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: ${props => props.isWide ? "inherit" : "center"};
    gap: 10px;
    text-decoration: none;
    font-size: 12.5px;
    font-weight: 500;
    letter-spacing: 0.05em;
    outline: none;
    border: 1px solid transparent;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
    opacity: ${props => props.disabled ? "0.5" : "1"};

    &:hover {
        border: 1px solid ${props => props.theme.border.primary};
        background: ${props => props.theme.background._activeNavigation};
    }

    &.active {
        color: ${props => props.theme.colors.tertiary};
        border: 1px solid ${props => props.theme.border.primary};
        background: ${props => props.theme.background._activeNavigation};
    }
`;
export const Label = styled.span`
    width: 50%;
    text-align: left;
    text-transform: uppercase;
`;

// navigation item for links such as faq, terms of service etc
const Item = ({
  root,
  label,
  icon: Icon,
  children,
  isWide
}) => {
  return <StyledNavItem exact to={root} activeClassName="active" isWide={isWide} data-tooltip-content={isWide ? "" : capitalize(label)} data-tooltip-id="default">
            <Icon style={{
      height: "20px",
      width: "20px"
    }} />
            {isWide && <>
                    <Label>{label}</Label>
                    {children}
                </>}
        </StyledNavItem>;
};
export default Item;