import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as Popover from "@radix-ui/react-popover";
import { useHistory } from "react-router-dom";

// Components
import PageContainer from "./PageContainer";
import GameHeader from "./GameHeader";
import { PopoverArrow, PopoverClose } from "./header/Balances";

// Assets
import { ReactComponent as SearchIcon } from "../assets/v2/icons/search.svg";
import { getGameProviders, getProviderGames } from "../services/api.service";
import Skeleton from "./Skeleton";
import Button from "./Button";
import BREAKPOINTS from "../constants/breakpoints";
const ProviderGamesPage = ({
  type
}) => {
  const history = useHistory();
  const [selectedProvider, setSelectedProvider] = useState("ALL");
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("popularity");
  const [isLoading, setIsLoading] = useState(true);
  const [games, setGames] = useState([]);
  const [providers, setProviders] = useState([]);
  const [offset, setOffset] = useState(0);
  const [canLoadMore, setCanLoadMore] = useState(false);
  useEffect(() => {
    const fetchProviders = async () => {
      const resp = await getGameProviders(type);
      setProviders(resp.data);
    };
    fetchProviders();
  }, []);
  useEffect(() => {
    setIsLoading(true);
    try {
      let provider = selectedProvider === "ALL" ? "" : selectedProvider;
      const fetchGames = async () => {
        const resp = await getProviderGames(type, provider, 35, offset, search, sort);
        if (resp.data.length < 35) {
          setCanLoadMore(false);
        } else {
          setCanLoadMore(true);
        }
        if (offset === 0) {
          setGames(resp.data);
        } else {
          setGames([...games, ...resp.data]);
        }
      };
      fetchGames();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [selectedProvider, search, offset, sort]);
  const handleProviderChange = provider => {
    setOffset(0);
    setSelectedProvider(provider);
  };
  const handleSortingChange = sorting => {
    setOffset(0);
    setSort(sorting);
  };
  const handleSearchChange = e => {
    if (offset !== 0) {
      setOffset(0);
    }
    setSearch(e.target.value);
  };
  return <PageContainer maxWidth="lg" header={<GameHeader game={type} />}>
            <TopBar>
                <SearchBar>
                    <SearchIcon />
                    <SearchInput placeholder="Search" value={search} onChange={handleSearchChange} />
                </SearchBar>
                <SortingWrapper>
                    <SortingSelector selectedSorting={sort} handleSortingChange={handleSortingChange} />
                    <ProviderSelector selectedProvider={selectedProvider} providers={providers} handleProviderChange={handleProviderChange} />
                </SortingWrapper>
            </TopBar>
            <GamesGrid>
                {isLoading ? new Array(35).fill(0).map((_, index) => <Skeleton width="140px" height="200px" style={{
        borderRadius: "10px"
      }} />) : games.map((game, index) => {
        return <GameCard key={index} type={type} game={game} history={history} />;
      })}
            </GamesGrid>
            {canLoadMore && <Button colour="secondary" style={{
      margin: "auto",
      width: "150px"
    }} onClick={() => setOffset(offset + 35)}>
                    Load More
                </Button>}
        </PageContainer>;
};
const TopBar = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    margin: 20px 0;

    @media (max-width: ${BREAKPOINTS.md}) {
        flex-direction: column;
        gap: 10px;
    }
`;
const SortingWrapper = styled.div`
    display: flex;
    gap: 15px;
    margin-left: auto;

    @media (max-width: ${BREAKPOINTS.md}) {
        width: 100%;
        margin: 0;
    }
`;
const SearchBar = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    color: ${({
  theme
}) => theme.colors.secondary};
    background: ${({
  theme
}) => theme.background.primary};
    padding: 8px 10px;
    border-radius: 8px;
    border: 1px solid ${({
  theme
}) => theme.border.primary};
    width: 50%;
    max-width: 400px;
    min-width: 250px;
    margin-right: auto;

    @media (max-width: ${BREAKPOINTS.sm}) {
        width: 100%;
    }
`;
const SearchInput = styled.input`
    border: none;
    outline: none;
    background: transparent;
    font-weight: 500;
    font-size: 14px;

    color: ${({
  theme
}) => theme.colors.primary};
    &::placeholder {
        color: ${({
  theme
}) => theme.colors.secondary};
    }
`;
const ProviderContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    color: ${({
  theme
}) => theme.colors.secondary};
    background: ${({
  theme
}) => theme.background.primary};
    padding: 8px 10px;
    border-radius: 8px;
    border: 1px solid ${({
  theme
}) => theme.border.primary};
    width: 190px;

    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
        opacity: 0.9;
    }
`;
const Provider = styled.div`
    color: ${({
  theme
}) => theme.colors.tertiary};
    font-weight: 500;
`;
const SelectableOption = styled(ProviderContainer)`
    background: transparent;
    width: 100%;
    color: ${({
  theme
}) => theme.colors.primary};

    transition: 0.2s ease-in-out;
    &:hover {
        background: ${({
  theme
}) => theme.background.secondary};
    }
`;
const GameAmount = styled.span`
    color: ${({
  theme
}) => theme.colors.tertiary};
`;
const ProviderSelector = ({
  selectedProvider,
  providers,
  handleProviderChange
}) => {
  return <Popover.Root>
            <Popover.Trigger asChild>
                <ProviderContainer>
                    Provider:
                    <Provider>{selectedProvider}</Provider>
                </ProviderContainer>
            </Popover.Trigger>
            <Popover.Portal>
                <PopoverContent>
                    <PopoverClose aria-label="Close" />
                    <PopoverArrow />
                    <SelectableOption onClick={() => handleProviderChange("ALL")}>
                        All
                    </SelectableOption>
                    {Object.keys(providers).map((provider, keyIndex) => {
          let gameAmount = providers[provider];
          return <SelectableOption key={keyIndex} onClick={() => handleProviderChange(provider)}>
                                {provider} <GameAmount>{gameAmount}</GameAmount>
                            </SelectableOption>;
        })}
                </PopoverContent>
            </Popover.Portal>
        </Popover.Root>;
};
const PopoverContent = styled(Popover.Content)`
    border-radius: 10px;
    padding: 5px;
    width: 200px;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid ${props => props.theme.border.primary};
    background: ${props => props.theme.background.primary};
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;
const GamesGrid = styled.div`
    display: grid;
    height: fit-content;
    grid-template-columns: repeat(auto-fill, minmax(140px, 0fr));
    grid-gap: 20px;
    padding: 20px 0;
    justify-content: center;
`;
const GameCardContainer = styled.div`
    background-color: ${({
  theme
}) => theme.background.secondary};
    background-image: url(${props => props.$image});
    background-size: cover;
    background-position: center;
    width: 140px;
    height: 200px;
    border-radius: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    transition: 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
        opacity: 0.9;
        transform: scale(1.05);
        drop-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
`;
const GameCard = ({
  type,
  game,
  history
}) => {
  const handleGameClick = () => {
    history.push(`/${type}/${game.id}`);
  };
  return <GameCardContainer onClick={handleGameClick} $image={game.card_image !== "" ? game.card_image : game.image} />;
};
const SortingSelector = ({
  selectedSorting,
  handleSortingChange
}) => {
  return <Popover.Root>
            <Popover.Trigger asChild>
                <ProviderContainer>
                    Sort By:
                    <Provider style={{
          textTransform: "capitalize"
        }}>
                        {selectedSorting}
                    </Provider>
                </ProviderContainer>
            </Popover.Trigger>
            <Popover.Portal>
                <PopoverContent>
                    <PopoverClose aria-label="Close" />
                    <PopoverArrow />
                    <SelectableOption onClick={() => handleSortingChange("popularity")}>
                        Popularity
                    </SelectableOption>
                    <SelectableOption onClick={() => handleSortingChange("newest")}>
                        Newest
                    </SelectableOption>
                    <SelectableOption onClick={() => handleSortingChange("a-z")}>
                        Name (A-Z)
                    </SelectableOption>
                    <SelectableOption onClick={() => handleSortingChange("z-a")}>
                        Name (Z-A)
                    </SelectableOption>
                </PopoverContent>
            </Popover.Portal>
        </Popover.Root>;
};
export default ProviderGamesPage;