import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-hot-toast";
import { TransitionGroup } from "react-transition-group";
import { getActiveCratesBattles, getCratesSchema, getCratesBattlesHistory } from "../services/api.service";
import { caseBattleSocket } from "../services/websocket.service";

// Components
import Game from "../components/cratesbattles/GameList";
import ListItemsSkeleton from "../components/ListItemsSkeleton";
import GameHeader from "../components/GameHeader";
import Button from "../components/Button";
import CreateBattle from "../components/modals/CreateBattleModal";
import GrowAnimation from "../components/GrowAnimation";
import PageContainer from "../components/PageContainer";

// Assets
import error from "../assets/error.wav";
const errorAudio = new Audio(error);
const playSound = audioFile => {
  audioFile.play();
};
const NoBattles = styled.div`
    display: flex;
    flex-direction: column;
    height: 40rem;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.colors.secondary};

    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1em;
`;
const Battles = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 20px;
    overflow-y: auto;
    margin-top: 25px;
`;
const CrateBattles = ({
  isAuthenticated,
  user,
  history,
  sweet
}) => {
  const [loading, setLoading] = useState(true);
  const [creating, setCreating] = useState(false);
  const [battles, setBattles] = useState([]);
  const [pastBattles, setPastBattles] = useState([]);
  const [cases, setCases] = useState([]);

  // Add new game
  const addGame = game => {
    setCreating(false);
    setBattles(state => state ? [game, ...state] : null);
  };

  // Handle player join event
  const battleJoined = data => {
    const {
      _id,
      newPlayer
    } = data;

    // Update State
    setBattles(state => state.map(game => game._id === _id ? {
      ...game,
      players: [...game.players, newPlayer]
    } : game));
  };

  // Handle game end event
  const gameRolled = newData => {
    setBattles(state => state.map(game => game._id === newData._id ? {
      ...game,
      ...newData,
      status: 3
    } : game));
  };

  // Handle game rolling event
  const gameRolling = gameId => {
    //console.log("[Coinflip] Game", gameId, "rolling...");
    // Update State
    setBattles(state => state.map(game => game._id === gameId ? {
      ...game,
      status: 2
    } : game));
  };

  // Handle game creation success event
  const successRedirect = gameId => {
    history.push(`/crate-battles/${gameId}`);
  };

  // Handle game creation error event
  const creationError = msg => {
    setCreating(false);
    toast.error(msg);
    playSound(errorAudio);
  };
  const joinError = msg => {
    toast.error(msg);
    playSound(errorAudio);
  };
  useEffect(() => {
    // Fetch active battles from the API
    const fetchData = async () => {
      setLoading(true);
      try {
        const battles = await getActiveCratesBattles();
        setBattles(battles);
        const history = await getCratesBattlesHistory();
        setPastBattles(history);
        const data = await getCratesSchema();
        setCases(data.cases);
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.error("There was an error while loading battles data, please try again later!");
      }
    };

    // Fetch battles when component mounts
    fetchData();
    caseBattleSocket.on("battle-creation-error", creationError);
    caseBattleSocket.on("battle-creation-success", successRedirect);
    caseBattleSocket.on("new-crates-battle", addGame);
    caseBattleSocket.on("battle-joined", battleJoined);
    caseBattleSocket.on("battle-finished", gameRolled);
    caseBattleSocket.on("battle-started", gameRolling);
    caseBattleSocket.on("battle-join-error", joinError);
    caseBattleSocket.on("battle-join-success", successRedirect);
    return () => {
      caseBattleSocket.off("battle-creation-error", creationError);
      caseBattleSocket.off("battle-creation-success", successRedirect);
      caseBattleSocket.off("new-crates-battle", addGame);
      caseBattleSocket.off("battle-joined", battleJoined);
      caseBattleSocket.off("battle-finished", gameRolled);
      caseBattleSocket.off("battle-started", gameRolling);
      caseBattleSocket.off("battle-join-error", joinError);
      caseBattleSocket.off("battle-join-success", successRedirect);
    };
    // eslint-disable-next-line
  }, []);
  const [battleCreationOpen, setBattleCreationOpen] = useState(false);
  return <PageContainer maxWidth="xl" header={<GameHeader game="battles">
                    <Button disabled={!isAuthenticated} onClick={() => setBattleCreationOpen(true)} style={{
      marginLeft: "auto"
    }}>
                        START NEW BATTLE
                    </Button>
                </GameHeader>}>
            <CreateBattle history={history} cases={cases} sweet={sweet} open={battleCreationOpen} handleClose={() => setBattleCreationOpen(false)} creating={creating} setCreating={setCreating} />
            <GrowAnimation duration="820ms">
                <Battles>
                    {loading ? <ListItemsSkeleton length={10} height="6rem" /> : [...battles, ...pastBattles].length > 0 ? <TransitionGroup>
                            {battles.map((game, index) => <Game key={game._id} game={game} user={user} sweet={sweet} />)}
                            {pastBattles.map((game, index) => <Game key={game._id} game={game} user={user} sweet={sweet} />)}
                        </TransitionGroup> : <NoBattles>NO AVAILABLE BATTLES</NoBattles>}
                </Battles>
            </GrowAnimation>
        </PageContainer>;
};
CrateBattles.propTypes = {
  user: PropTypes.object,
  isAuthenticated: PropTypes.bool
};
const mapStateToProps = state => ({
  user: state.auth.user,
  sweet: state.site.sweet,
  isAuthenticated: state.auth.isAuthenticated
});
export default connect(mapStateToProps)(CrateBattles);