import React from "react";
import styled from "styled-components";

// Icons
import { AiFillRobot } from "react-icons/ai";
const BotAvatar = ({
  size = "35px"
}) => {
  return <AvatarBackground size={size}>
            <AiFillRobot style={{
      fontSize: `calc(${size} - 18px)`
    }} />
        </AvatarBackground>;
};
const AvatarBackground = styled.div`
    height: ${props => props.size};
    width: ${props => props.size};
    background: ${props => props.theme.background.secondary};
    color: ${props => props.theme.colors.secondary};
    padding: 9px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export default BotAvatar;