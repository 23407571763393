import React, { useState, useEffect, useRef, createRef } from "react";
import styled, { useTheme } from "styled-components";
import { getCratesBattle } from "../services/api.service";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-hot-toast";
import RecaptchaV3 from "react-google-recaptcha3";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import CountUp from "react-countup";
import { caseBattleSocket } from "../services/websocket.service";
import parseCommasToThousands from "../utils/parseCommasToThousands";
import BREAKPOINTS from "../constants/breakpoints";

// Assets
import coin from "../assets/coin.svg";
import { ReactComponent as PvPIcon } from "../assets/cases/pvp.svg";
import { ReactComponent as RefreshIcon } from "../assets/v2/icons/refresh.svg";

// Components
import GameHeader from "../components/GameHeader";
import PlayerAvatar from "../components/PlayerAvatar";
import { JoinButton } from "../components/cratesbattles/JoinButton";
import { Wheel, _generateWheelItems, _getRarityColor } from "../components/cases/Wheel";
import BotAvatar from "../components/BotAvatar";
import GrowAnimation from "../components/GrowAnimation";
import CircularLoader from "../components/CircularLoader";
import PageContainer from "../components/PageContainer";
const Crates = styled.div`
    width: 100%;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const WaitingForStatus = styled.div`
    color: ${({
  theme
}) => theme.colors.primary};
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    &:after {
        content: " .";
        animation: dots 1s steps(5, end) infinite;
    }
`;
const Separator = styled.div`
    width: 100%;
    height: 1px;
    background: ${({
  theme
}) => theme.border.primary};
    margin: 25px 0;
`;
const Players = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 20px;

    @media (max-width: ${BREAKPOINTS.sm}) {
        flex-direction: column;
        gap: 15px;
    }
`;
const Player = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid ${props => props.theme.border.primary};
    background: ${props => props.theme.background.primary};
    backdrop-filter: blur(7.5px);
    padding: 0.7rem;
    width: 100%;
    max-width: 300px;
`;
const PlayerDrawnItems = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 10px;
    font-weight: 500;
    width: 100%;
    @media (max-width: ${BREAKPOINTS.sm}) {
        flex-direction: row;
        flex-wrap: wrap;
    }
`;
const CrateHeader = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: ${({
  theme
}) => theme.colors.primary};
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
`;
const PlayerDrawnItem = styled.div`
    width: 100%;
    color: ${({
  theme
}) => theme.colors.primary};
    text-align: center;
    background: ${({
  theme
}) => theme.background.secondary};
    padding: 10px 20px;
    border-radius: 5px;

    @media (max-width: ${BREAKPOINTS.sm}) {
        width: calc(50% - 10px);
    }
`;
const Wheeels = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
const renderTime = ({
  remainingTime
}) => {
  return <Time>{remainingTime}</Time>;
};
const Time = styled.div`
    font-weight: 500;
    letter-spacing: 0.1em;
    font-size: 60px;
    color: ${({
  theme
}) => theme.colors.primary};
`;
const _getCurrentDrawnTotal = drawnItems => {
  let total = 0;
  if (drawnItems) {
    drawnItems.forEach(item => {
      total += item.fiatPrice;
    });
  }
  return total;
};
const RewatchBattle = styled(RefreshIcon)`
    color: ${({
  theme
}) => theme.colors.secondary};
    height: 18px;
    width: auto;

    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
        filter: brightness(1.1);
    }
`;
const TotalUnboxed = styled.div`
    margin-top: 25px;
    color: ${({
  theme
}) => theme.colors.secondary};
    font-weight: 500;
`;
const WheelUsername = styled.div`
    color: ${({
  theme
}) => theme.colors.secondary};
    font-weight: 500;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
`;
const DrawSubTitle = styled.div`
    color: ${({
  theme
}) => theme.colors.secondary};
    font-size: 14px;
    margin: 5px 0;
`;
const StyledPvPIcon = styled(PvPIcon)`
    height: 20px;
    width: auto;
    color: ${({
  theme
}) => theme.colors.secondary};
`;
const CratesBattle = ({
  user,
  match,
  history,
  sweet
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [game, setGame] = useState(null);
  const [joining, setJoining] = useState(false);
  const [newGame, setNewGame] = useState(false);
  const [running, setRunning] = useState(false);
  const [caseNumber, setCaseNumber] = useState(0);
  const [currentCase, setCurrentCase] = useState(null);
  const wheelItemsRef = useRef({});
  const [resultItems, setResultItems] = useState({});
  const wheelRefs = useRef({});
  const [showBattle, setShowBattle] = useState(false);
  const [showCountdown, setShowCountdown] = useState(false);
  useEffect(() => {
    if (game) {
      if (game.status !== 3) {
        return;
      }
      if (!newGame) {
        let _drawnItems = {};
        game.players.forEach(player => {
          _drawnItems[player._id] = [];
          player.caseGames.sort((a, b) => {
            if (a.case < b.case) return -1;
            if (a.case > b.case) return 1;
            return 0;
          }).forEach(caseGame => {
            _drawnItems[player._id].push(caseGame.drawnItem);
          });
        });
        setResultItems(_drawnItems);
      }
      game.players.forEach(player => {
        wheelRefs.current[player._id] = createRef();
        wheelItemsRef.current[player._id] = _generateWheelItems(game.cases[0].items);
      });
    }
  }, [game]);
  const runBattle = async game => {
    setShowCountdown(true);
    setRunning(true);
    setResultItems({});

    // TODO: can use this later on to show while the game is drawing
    await new Promise(resolve => setTimeout(resolve, 3_500));
    setShowCountdown(false);
    setShowBattle(true);
    let shownGames = [];

    // Loop through the cases and set the caseitems and the winning item for each player
    for (let index = 0; index < game.cases.sort((a, b) => {
      if (a._id < b._id) return -1;
      if (a._id > b._id) return 1;
      return 0;
    }).length; index++) {
      const caseItem = game.cases[index];
      setCurrentCase(caseItem);
      setCaseNumber(index + 1);

      // Render the wheel items
      game.players.forEach(player => {
        wheelItemsRef.current[player._id] = _generateWheelItems(caseItem.items);
      });
      await new Promise(resolve => setTimeout(resolve, 1_000));

      // Spin the wheel for each player
      await Promise.all(game.players.map(async player => {
        const playerId = player._id;
        const wheelRef = wheelRefs.current[playerId];
        const caseGames = player.caseGames;
        let result = null;
        for (const caseGame of caseGames.sort((a, b) => {
          if (a.case < b.case) return -1;
          if (a.case > b.case) return 1;
          return 0;
        })) {
          if (shownGames.includes(caseGame._id)) {
            continue;
          }
          result = caseGame.drawnItem;
          shownGames.push(caseGame._id);
          break;
        }
        wheelRef.current.spinWheel(result, false);
        await new Promise(resolve => setTimeout(resolve, 8_000));
        setResultItems(resultItems => {
          let drawnItems = resultItems[playerId] || [];
          drawnItems.push(result);
          return {
            ...resultItems,
            [playerId]: drawnItems
          };
        });
        await new Promise(resolve => setTimeout(resolve, 2_000));
        wheelRef.current.resetWheel();
      }));
    }

    // Show the winner
    setShowBattle(false);
    setCurrentCase(null);
    setRunning(false);
    setNewGame(false);
  };
  const joinGame = async teamID => {
    setJoining(true);
    const reCaptcha = await RecaptchaV3.getToken({
      action: "join_crates_battle"
    });
    caseBattleSocket.emit("join-battle", game._id, reCaptcha, teamID);
  };
  const callBot = async teamID => {
    setJoining(true);
    caseBattleSocket.emit("call-bot", game._id, teamID);
  };

  // Joining a game was successfull
  const joinSuccess = () => {
    setJoining(false);
    toast.success("Successfully joined battle!");
  };

  // There was an error while joining a game
  const joinError = msg => {
    setJoining(false);
    toast.error(msg);
  };

  // Handle player join event
  const battleJoined = data => {
    const {
      _id,
      newPlayer,
      teamId
    } = data;

    // Update State
    setGame(game => game._id === _id ? {
      ...game,
      players: [...game.players, newPlayer],
      teams: game.teams.map(team => team._id === teamId ? {
        ...team,
        players: [...game.players.filter(player => player.teamId === teamId), newPlayer]
      } : team)
    } : game);
  };

  // Handle game rolling event
  const battleStarted = gameId => {
    // TODO: figure out how to do it conditionally only on this game
    setNewGame(true);
    setGame(game => game._id === gameId ? {
      ...game,
      status: 2
    } : game);
  };

  // Handle game end event
  const battleFinished = async gameData => {
    setGame(game => game._id === gameData._id ? {
      ...game,
      ...gameData,
      status: 3
    } : game);
    runBattle(gameData);
  };
  useEffect(() => {
    // Fetch private game data from API
    const fetchGameData = async id => {
      setLoading(true);
      try {
        const cratesBattle = await getCratesBattle(id);
        setGame(cratesBattle);
        setLoading(false);
      } catch (error) {
        console.error(error);
        if (error.response && error.response.status === 400) {
          toast.error("Couldn't find any active games with that invite link!");
          history.push("/crate-battles");
        } else {
          toast.error("There was an error while loading that private game data, please try again later!");
          history.push("/crate-battles");
        }
      }
    };

    // If game id was passed in url, fetch that game data
    if (match.params.id) {
      fetchGameData(match.params.id);
    }
    caseBattleSocket.on("battle-joined", battleJoined);
    caseBattleSocket.on("battle-finished", battleFinished);
    caseBattleSocket.on("battle-started", battleStarted);
    caseBattleSocket.on("battle-join-error", joinError);
    caseBattleSocket.on("battle-join-success", joinSuccess);
    return () => {
      caseBattleSocket.off("battle-joined", battleJoined);
      caseBattleSocket.off("battle-finished", battleFinished);
      caseBattleSocket.off("battle-started", battleStarted);
      caseBattleSocket.off("battle-join-error", joinError);
      caseBattleSocket.off("battle-join-success", joinSuccess);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params]);
  return <PageContainer maxWidth="xl" header={<GameHeader game="battles" returnTo={{
    tooltip: "Return to Battles",
    route: "/crate-battles"
  }} children={game && game.status === 3 && !running && <RewatchBattle onClick={() => runBattle(game)} data-tooltip-content="Rewatch Battle" data-tooltip-id="default" />} />} style={{
    minHeight: "100%"
  }}>
            <GrowAnimation duration="620ms">
                {loading ? <CircularLoader style={{
        margin: "auto",
        marginTop: "35vh"
      }} /> : <>
                        <Crates>
                            {game.status === 1 && <WaitingForStatus>
                                    Waiting For Players
                                </WaitingForStatus>}
                            {game.status === 2 && <WaitingForStatus>
                                    Battle Starting
                                </WaitingForStatus>}
                            {game.status === 3 && <>
                                    {showCountdown ? <CountdownCircleTimer isPlaying duration={3} strokeWidth={5} strokeLinecap={"square"} colors={[theme.colors.tertiary]} trailColor={[theme.background.secondary]} onComplete={() => ({
              shouldRepeat: false
            })}>
                                            {renderTime}
                                        </CountdownCircleTimer> : <Wheeels>
                                            {showBattle ? <>
                                                    <CrateHeader>
                                                        {currentCase && <>
                                                                <div style={{
                      color: theme.colors.secondary,
                      marginRight: "10px"
                    }}>
                                                                    Crate #
                                                                    {caseNumber}
                                                                </div>
                                                                <img src={currentCase.imageUrl} alt={currentCase.name} style={{
                      height: "47px"
                    }} />
                                                                {currentCase.name}
                                                            </>}
                                                    </CrateHeader>
                                                    {game.teamSize >= 2 ? <div style={{
                  display: "flex",
                  gap: "20px"
                }}>
                                                            {game.teams.map((team, index) => <div key={team._id} style={{
                    display: "flex",
                    flexDirection: "column",
                    width: `calc(${100 / game.teams.length}% - 10px)`
                  }}>
                                                                        {game.players.map(player => {
                      if (player.teamId === team._id) {
                        return <div key={index}>
                                                                                            <WheelUsername>
                                                                                                {player.username}
                                                                                            </WheelUsername>
                                                                                            <Wheel scale={1 - (game.players.length - 1) * 0.1} margin="10px 0 0 0" ref={wheelRefs.current[player._id]} items={wheelItemsRef.current[player._id]} />
                                                                                        </div>;
                      }
                      return null;
                    })}
                                                                    </div>)}
                                                        </div> : game.players.map(player => {
                  return <div key={player._id}>
                                                                        <WheelUsername>
                                                                            {player.username}
                                                                        </WheelUsername>
                                                                        <Wheel scale={1 - (game.players.length - 1) * 0.1} margin="10px 0 0 0" ref={wheelRefs.current[player._id]} items={wheelItemsRef.current[player._id]} />
                                                                    </div>;
                })}
                                                </> : <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}>
                                                    {game.teams.filter(team => team.winner).length === 1 ? <>
                                                            <div style={{
                    color: "#00e258",
                    fontSize: "18px",
                    fontWeight: "500",
                    textTransform: "uppercase",
                    marginBottom: "10px"
                  }}>
                                                                WINNER
                                                            </div>
                                                            <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "15px"
                  }}>
                                                                {game.players.map(player => {
                      const winningTeam = game.teams.find(team => team.winner);
                      if (player.teamId !== winningTeam._id) {
                        return null;
                      }
                      if (player.isBot) {
                        return <BotAvatar size="45px" />;
                      }
                      return <PlayerAvatar size="45px" address={player.address} levelColor={player.level.levelColor} levelName={player.level.name} />;
                    })}
                                                            </div>
                                                        </> : <>
                                                            <div style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "18px",
                    fontWeight: "500",
                    textTransform: "uppercase"
                  }}>
                                                                DRAW
                                                                <DrawSubTitle>
                                                                    Win has been
                                                                    split
                                                                    between all
                                                                    winners
                                                                </DrawSubTitle>
                                                            </div>
                                                            <div style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "15px"
                  }}>
                                                                {game.players.map(player => {
                      const winningTeams = game.teams.filter(team => team.winner);
                      if (!winningTeams.map(team => team._id).includes(player.teamId)) {
                        return null;
                      }
                      if (player.isBot) {
                        return <BotAvatar size="45px" />;
                      }
                      return <PlayerAvatar size="45px" address={player.address} levelColor={player.level.levelColor} levelName={player.level.name} />;
                    })}
                                                            </div>
                                                        </>}
                                                    <TotalUnboxed>
                                                        TOTAL UNBOXED
                                                    </TotalUnboxed>
                                                    <div style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: 500
                }}>
                                                        <div style={{
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: 500
                  }}>
                                                            {parseCommasToThousands(game.payout)}
                                                        </div>
                                                        <img src={coin} alt="Sweet" style={{
                    height: "17px",
                    width: "17px",
                    marginLeft: "4px"
                  }} />
                                                    </div>
                                                </div>}
                                        </Wheeels>}
                                </>}
                        </Crates>
                        <Separator />
                        <Players>
                            {game.teams.map((team, index) => {
            return <>
                                        {index > 0 && game.teams.length && <StyledPvPIcon />}
                                        {game.players.map(player => {
                if (player.teamId !== team._id) {
                  return null;
                }
                return <Player key={player._id}>
                                                    <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fff"
                  }}>
                                                        {player.isBot ? <BotAvatar /> : <PlayerAvatar size="35px" address={player.address} levelColor={player.level.levelColor} levelName={player.level.name} />}
                                                        <div style={{
                      marginLeft: "10px",
                      fontWeight: "500"
                    }}>
                                                            {player.username}
                                                        </div>
                                                        <div style={{
                      marginLeft: "15px",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "500",
                      color: theme.colors.secondary
                    }} data-tooltip-content="Total Unboxed" data-tooltip-id="default">
                                                            $
                                                            <CountUp delay={0} duration={1} decimals={2} start={0} end={_getCurrentDrawnTotal(resultItems[player._id]) || 0} />
                                                        </div>
                                                    </div>
                                                    {resultItems[player._id] && <PlayerDrawnItems>
                                                            {resultItems[player._id].slice(0, game.cases.length).map((item, index) => <PlayerDrawnItem key={index}>
                                                                            <img src={item.imageUrl} alt={item.name} style={{
                        height: "70px",
                        filter: `drop-shadow(0 .5rem 1rem ${_getRarityColor(item.fiatPrice)})`
                      }} />
                                                                            <div style={{
                        fontSize: "13px"
                      }}>
                                                                                $
                                                                                {item.fiatPrice}
                                                                            </div>
                                                                        </PlayerDrawnItem>)}
                                                        </PlayerDrawnItems>}
                                                </Player>;
              })}
                                        {Array.from({
                length: game.teamSize - game.players.filter(player => player.teamId === team._id).length
              }).map((_, index) => <Player key={index} style={{
                flexDirection: "row"
              }}>
                                                {game._creator !== user?._id ? <JoinButton variant="contained" onClick={() => joinGame(team._id)} disabled={joining ? true : false} style={{
                  marginTop: "0"
                }}>
                                                        {joining ? "Joining..." : `Join Battle`}
                                                    </JoinButton> : <JoinButton variant="contained" onClick={() => callBot(team._id)} disabled={joining ? true : false} style={{
                  marginTop: "0"
                }}>
                                                        {joining ? "Calling..." : `Call A Bot`}
                                                    </JoinButton>}
                                            </Player>)}
                                    </>;
          })}
                        </Players>
                    </>}
            </GrowAnimation>
        </PageContainer>;
};
CratesBattle.propTypes = {
  user: PropTypes.object,
  sweet: PropTypes.object
};
const mapStateToProps = state => ({
  user: state.auth.user,
  sweet: state.site.sweet
});
export default connect(mapStateToProps)(CratesBattle);