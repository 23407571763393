import React, { useState, Fragment } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Components
import ProvablyModal from "../modals/ProvablyModal";
const HistoryEntry = ({
  game
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const onClick = () => {
    setModalVisible(state => !state);
  };
  return <Fragment>
            <ProvablyModal open={modalVisible} handleClose={() => setModalVisible(state => !state)} fields={[{
      label: "Round ID",
      value: game._id
    }, {
      label: "Private Hash",
      value: game.privateHash
    }, {
      label: "Private Seed",
      value: game.privateSeed ? game.privateSeed : "Not Revealed"
    }, {
      label: "Public Seed",
      value: game.publicSeed ? game.publicSeed : "Not Revealed"
    }, {
      label: "Drawn Number",
      value: game.drawnNumber
    }]} />
            <Multiplier style={{
      background: game.won ? "#03df58" : "#BF3932 "
    }} onClick={onClick} data-tooltip-content="Click to view Provably Fair" data-tooltip-id="default" data-tooltip-place="bottom">
                {game.drawnNumber.toFixed(2)}
            </Multiplier>
        </Fragment>;
};
const Multiplier = styled.div`
    border-radius: 4px;
    margin-right: 10px;
    padding: 8px 12px;
    color: ${({
  theme
}) => theme.colors.primary};
    cursor: pointer;
    text-align: center;
`;
HistoryEntry.propTypes = {
  game: PropTypes.object.isRequired
};
export default HistoryEntry;