import React, { Fragment } from "react";
import styled from "styled-components";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import PropTypes from "prop-types";
import { chatSocket } from "../../services/websocket.service";

// Components
import Emoji, { EMOJIS } from "./Emoji";
import { getLevelBadge } from "../../utils/vip";
const MessageBox = styled.div`
    margin-bottom: 10px;
    background: ${({
  theme
}) => theme.background._chatMessage};
    padding: 8px;
    border-radius: 8px;

    & .admin {
        background: #eb3527;
    }

    & .mod {
        background: #43bb48;
    }

    & .dev {
        background: #0cb3a2;
    }

    & .partner {
        background: #791f84;
    }
`;
const Rank = styled.span`
    border-radius: 2.5px;
    font-size: 10px;
    margin-right: 7px;
    padding: 2px 4px;
    color: ${({
  theme
}) => theme.colors.primary};
    font-weight: 500;
    letter-spacing: 0.15em;
`;
const UserMessageContainer = styled.div`
    display: flex;
    align-items: center;
`;
const StyledContextMenu = styled(ContextMenu)`
    background: #212529;
    border: 1px solid #212529;
    color: white;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.1em;
    z-index: 100;
    border-radius: 5px;
`;
const StyledContextMenuItem = styled(MenuItem)`
    cursor: pointer;
    padding: 0.7rem 1.5rem;
    transition: all 0.3s ease;
    &:hover {
        color: #737990;
    }
`;
const Content = styled.div`
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.secondary};
    font-size: 13px;
    font-weight: 500;
    white-space: normal;
    margin-top: 5px;
    margin-left: 20px;
    margin-bottom: 0;
    word-wrap: break-word;
    hyphens: auto;
`;
const Avatar = styled.img`
    width: auto;
    height: 16px;
    margin-right: 5px;
`;
const Username = styled.div`
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 10px;
    color: ${props => props.theme.colors.primary};
`;
const Message = ({
  message,
  user
}) => {
  // MenuItem onClick event handler
  const onContextClick = (event, action, props) => {
    switch (action) {
      case "mute":
        return chatSocket.emit("send_chat_message", `.mute-user ${props.userId}`);
      case "remove-message":
        return chatSocket.emit("send_chat_message", `.remove-message ${props.msgId}`);
      default:
        break;
    }
  };
  return <Fragment>
            {user && user.rank >= 3 && <StyledContextMenu id={message.id}>
                    <StyledContextMenuItem onClick={e => onContextClick(e, "remove-message", {
        msgId: message.id
      })}>
                        DELETE MESSAGE
                    </StyledContextMenuItem>
                    <StyledContextMenuItem onClick={e => onContextClick(e, "mute", {
        userId: message.owner.id
      })}>
                        MUTE USER
                    </StyledContextMenuItem>
                </StyledContextMenu>}
            <ContextMenuTrigger id={message.id}>
                <MessageBox>
                    <UserMessageContainer>
                        <Avatar alt="VIP Badge" src={getLevelBadge(message.owner.vip_rank)} data-tooltip-content={message.owner.vip_rank} data-tooltip-id="default" />
                        <Username>
                            {message.owner?.rank === 5 && <Rank className="admin">ADMIN</Rank>}
                            {message.owner?.rank === 4 && <Rank className="mod">MOD</Rank>}
                            {message.owner?.rank === 3 && <Rank className="dev">DEV</Rank>}
                            {message.owner?.rank === 2 && <Rank className="partner">PARTNER</Rank>}
                            {message.owner.username}
                        </Username>
                    </UserMessageContainer>
                    <Content>
                        {message.data.split(" ").map((word, i) => {
            let emote = EMOJIS.find(emote => emote.word.toLowerCase() === word.toLowerCase());
            if (emote) {
              return <Emoji key={i} src={emote.src} alt={emote.word} title={emote.word} size="25px" />;
            }
            return word + " ";
          })}
                    </Content>
                </MessageBox>
            </ContextMenuTrigger>
        </Fragment>;
};
Message.propTypes = {
  user: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired
};
export default Message;