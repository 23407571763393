/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import { getRouletteSchema } from "../services/api.service";
import { rouletteSocket } from "../services/websocket.service";

// Components
import Header from "../components/roulette/Header";
import History from "../components/roulette/History";
import Game from "../components/roulette/Game";
import Timer from "../components/roulette/Timer";
import BetAmount from "../components/roulette/BetAmount";
import BetSelection from "../components/roulette/BetSelection";
import BetFeeds from "../components/roulette/BetFeeds";
import PageContainer from "../components/PageContainer";
import GrowAnimation from "../components/GrowAnimation";
import { getExchangeRate } from "../constants/currencies";

// Assets
import red from "../assets/roulette/red_icon.svg";
import black from "../assets/roulette/blue_icon.svg";
import green from "../assets/roulette/green_icon.svg";
import placebet from "../assets/placebet.wav";
import error from "../assets/error.wav";
const CARD_WIDTH = 67;
const errorAudio = new Audio(error);
const placebetAudio = new Audio(placebet);
const playSound = audioFile => {
  audioFile.play();
};

// TODO: Make more efficent
// Renderer callback with condition
const rendererR = ({
  total,
  completed
}, waitTime) => {
  if (completed) {
    // Render a completed state
    return "";
  } else {
    // Calculate remaining seconds and milliseconds
    const remainingTime = Math.max(waitTime - Date.now() + 50, 0); // add 50ms buffer
    const remainingSeconds = Math.floor(remainingTime / 1000);
    const displaySeconds = remainingSeconds % 60;
    const displayMilliseconds = (remainingTime % 1000).toString().padStart(3, "0").substring(0, 2);

    // Render a countdown
    return <span style={{
      letterSpacing: "0rem",
      width: "1px"
    }}>
                <span style={{
        fontSize: "1.2em",
        lineHeight: "1.5em",
        fontWeight: "500",
        color: "#ffe063"
      }}>
                    {displaySeconds}.{displayMilliseconds}
                </span>
            </span>;
  }
};

// Same game states as in backend
const GAME_STATES = {
  NotStarted: "Loading...",
  InProgress: "Rolling"
};
const Roulette = ({
  user,
  currency,
  exchangeRates,
  showInFiat
}) => {
  const [gameState, setGameState] = useState("Loading...");
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState([]);
  const [last100, setLast100] = useState([]);
  const [players, setPlayers] = useState([]);
  const [waitTime, setWaitTime] = useState(5000);
  const [betAmount, setBetAmount] = useState("0");
  const [lastBetAmount, setLastBetAmount] = useState("0");
  const [gameId, setGameId] = useState(null);
  const [privateHash, setPrivateHash] = useState(null);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [transitionTimingFunction, setTransitionTimingFunction] = useState(""); // Wheel Transition
  const [transitionDuration, setTransitionDuration] = useState(""); // Wheel Transition Duration
  const [wheelTransform, setWheelTransform] = useState(""); // Wheel Transform
  const [selectorOpacity, setselectorOpacity] = useState("0.8");
  const [redResult, setRedResult] = useState(false);
  const [blackResult, setBlackResult] = useState(false);
  const [greenResult, setGreenResult] = useState(false);
  const [numberResult, setNumberResult] = useState(null);
  function spinWheel(game, AnimationDuration, AnimationDurationTotal) {
    const roll = game.winning_color;
    const order = ["green", "blue", "red", "blue", "red", "blue", "red", "blue", "red", "blue", "red", "blue", "red", "blue", "red"];
    const position = order.indexOf(roll);

    // determine position where to land
    const rows = 6;
    const card = CARD_WIDTH + 15;
    let landingPosition = rows * 15 * card + position * card;
    const randomize = Math.floor(Math.random() * CARD_WIDTH) - CARD_WIDTH / 2;
    landingPosition = landingPosition + randomize;
    const object = {
      x: Math.floor(Math.random() * 50) / 100,
      y: Math.floor(Math.random() * 20) / 100
    };
    setTransitionTimingFunction(`cubic-bezier(0, ${object.x}, ${object.y}, 1)`);
    setTransitionDuration(`${AnimationDuration / 1000}s`);
    setWheelTransform(`translate3d(-${landingPosition}px, 0px, 0px)`);
    setTimeout(() => {
      setTransitionTimingFunction(`ease-in-out`);
      setTransitionDuration("1s");
      if (randomize >= 0 && randomize <= 34) {
        setWheelTransform(`translate3d(-${landingPosition - randomize}px, 0px, 0px)`);
      }
      if (randomize <= 0 && randomize >= -34) {
        setWheelTransform(`translate3d(-${landingPosition - randomize}px, 0px, 0px)`);
      }
    }, AnimationDuration + 1000);
    setTimeout(() => {
      setselectorOpacity("0");
      setNumberResult(roll);
      if (position === 0) {
        setGreenResult(true);
      } else if (position % 2 === 1) {
        setBlackResult(true);
      } else {
        setRedResult(true);
      }
    }, AnimationDuration + 1800);
    setTimeout(() => {
      setNumberResult(null);
      setselectorOpacity("0.8");
      setTransitionTimingFunction("");
      setTransitionDuration("");
      setTimeout(() => {
        setRedResult(false);
        setBlackResult(false);
        setGreenResult(false);
      }, 500);
      let resetTo = -(position * card);
      setWheelTransform(`translate3d(${resetTo}px, 0px, 0px)`);
      setPlayers([]);
      setGameId("");
      setPrivateHash("");
      setWaitTime(0);
      setGameState("PLACE YOUR BETS");
      setTimeout(() => {
        setButtonsDisabled(false);
      }, 1000);
      addGameToHistory(game);
    }, AnimationDurationTotal);
  }

  // Add new player to the current game
  const addNewPlayer = player => {
    setPlayers(state => {
      if (player.currency_id !== currency.id) return state;
      // Check if player already has a cached bet for the same color and if so remove it and replace it with the new one
      // let filteredState = state.filter(
      //     (p) => p.color !== player.color || p._id !== player._id,
      // );

      if (currency.decimalPlaces) {
        player.amount = player.amount / Math.pow(10, currency.decimalPlaces);
      }
      return [...state, player];
    });
  };
  const onJoinGame = color => {
    let amount = parseFloat(betAmount);
    if (currency.decimalPlaces) {
      amount = amount * Math.pow(10, currency.decimalPlaces);
    }
    rouletteSocket.emit("join-game", JSON.stringify({
      color: color,
      currency_id: currency.id,
      amount: amount,
      idempotency_key: uuidv4()
    }));
    setLastBetAmount(betAmount);
  };

  // New round started event handler
  const newRoundStarted = newRound => {
    const playersWithoutPrecision = newRound?.players?.map(player => {
      if (currency.decimalPlaces) {
        player.amount = player.amount / Math.pow(10, currency.decimalPlaces);
      }
      return player;
    });
    setPlayers(playersWithoutPrecision || []);
    setGameId(newRound.id);
    setPrivateHash(newRound.server_seed_hash);
    setWaitTime(Date.now() + newRound.time_left);
    setGameState("PLACE YOUR BETS");
    setTimeout(() => {
      setButtonsDisabled(false);
    }, 1000);
  };

  // Add game to history
  const addGameToHistory = game => {
    // Update history
    setHistory(state => state.length >= 50 ? [...state.slice(1, state.length), game] : [...state, game]);
  };
  const exchangeRate = getExchangeRate(exchangeRates, currency.id, "usd");

  // Update last 100 games on history change
  useEffect(() => {
    const previousWinningColors = history?.slice(0, 100)?.map(game => game.winning_color);
    setLast100(previousWinningColors || []);
  }, [history]);

  // ! Dont add any changing values to the dependency array
  // componentDidMount
  useEffect(() => {
    let unmounted = false;

    // Fetch roulette schema from API
    const fetchData = async () => {
      setLoading(true);
      try {
        const schema = await getRouletteSchema();
        const currGame = schema?.data?.active?.[currency.id];
        if (currGame) {
          setGameId(currGame.id);
          setPrivateHash(currGame.server_seed_hash);
          const playersWithoutPrecision = currGame.players?.map(player => {
            if (currency.decimalPlaces) {
              player.amount = player.amount / Math.pow(10, currency.decimalPlaces);
            }
            return player;
          });
          setPlayers(playersWithoutPrecision || []);
          setGameId(currGame.id);
          setPrivateHash(currGame.server_seed_hash);
          setWaitTime(Date.now() + currGame.time_left);
          setGameState("PLACE YOUR BETS");
        }
        const previousWinningColors = schema?.data?.history?.[currency.id]?.slice(0, 100).map(game => game.winning_color);
        setLast100(previousWinningColors || []);
        setHistory(schema?.data?.history?.[currency.id]?.reverse() || []);
        setLoading(false);
      } catch (error) {
        console.log("There was an error while loading roulette schema:", error);
      }
    };

    // Game has rolled, show animation
    const gameRolled = game => {
      // Update state
      spinWheel(game, 5500, 11500);
      setGameState(GAME_STATES.InProgress);
      setButtonsDisabled(true);
    };
    const gameFailed = game => {
      if (game?.currency_id === currency.id) {
        setPlayers([]);
        setGameId("");
        setPrivateHash("");
        setWaitTime(0);
        setGameState("PLACE YOUR BETS");
        setTimeout(() => {
          setButtonsDisabled(false);
        }, 1000);
        toast.error("Game failed to spin. All transactions have been refunded. Please try again!");
      }
    };

    // Error event handler
    const joinError = msg => {
      toast.error(msg);
      playSound(errorAudio);
    };

    // Success event handler
    const joinSuccess = () => {
      playSound(placebetAudio);
    };
    if (!unmounted) {
      // Initially, fetch data
      fetchData();

      // Listeners
      rouletteSocket.on("new-player", addNewPlayer);
      rouletteSocket.on("game-join-error", joinError);
      rouletteSocket.on("game-join-success", joinSuccess);
      rouletteSocket.on("new-round", newRoundStarted);
      rouletteSocket.on("game-rolled", gameRolled);
      rouletteSocket.on("add-game-to-history", addGameToHistory);
      rouletteSocket.on("game-failed", gameFailed);
    }
    return () => {
      unmounted = true;

      // Remove Listeners
      rouletteSocket.off("new-player", addNewPlayer);
      rouletteSocket.off("game-join-error", joinError);
      rouletteSocket.off("game-join-success", joinSuccess);
      rouletteSocket.off("new-round", newRoundStarted);
      rouletteSocket.off("game-rolled", gameRolled);
      rouletteSocket.off("add-game-to-history", addGameToHistory);
    };
  }, []);
  const getAmountOfPastGames = color => {
    return last100.filter(c => c === color).length;
  };
  const _getNumberStyling = number => {
    return {
      zIndex: numberResult === number ? 2 : 1,
      transition: "all 400ms",
      transform: numberResult === number ? "scale(1.2)" : "scale(1)",
      WebkitTransform: numberResult === number ? "scale(1.2)" : "scale(1)"
    };
  };
  return <PageContainer maxWidth="lg" header={<Header gameId={gameId} privateHash={privateHash} />}>
            <GrowAnimation duration="620ms">
                <History getAmountOfPastGames={getAmountOfPastGames} history={history} />
                <Game transitionTimingFunction={transitionTimingFunction} selectorOpacity={selectorOpacity} wheelTransform={wheelTransform} transitionDuration={transitionDuration} _getNumberStyling={_getNumberStyling} CARD_WIDTH={CARD_WIDTH} />
                <Timer loading={loading} gameState={gameState} GAME_STATES={GAME_STATES} waitTime={waitTime} rendererR={rendererR} setGameState={setGameState} />
                <BetAmount user={user} betAmount={betAmount} lastBetAmount={lastBetAmount} setBetAmount={setBetAmount} currency={currency} exchangeRate={exchangeRate} showInFiat={showInFiat} />
                <BetSelection buttonsDisabled={buttonsDisabled} onJoinGame={onJoinGame} black={black} green={green} red={red} />
                <BetFeeds currency={currency} buttonsDisabled={buttonsDisabled} players={players} blackResult={blackResult} redResult={redResult} greenResult={greenResult} />
            </GrowAnimation>
        </PageContainer>;
};
Roulette.propTypes = {
  user: PropTypes.object,
  sweet: PropTypes.object,
  currency: PropTypes.string
};
const mapStateToProps = state => ({
  user: state.auth.user,
  currency: state.currency.currency,
  exchangeRates: state.site.exchangeRates,
  showInFiat: state.site.showInFiat
});
export default connect(mapStateToProps)(Roulette);