import React from "react";
import styled from "styled-components";
import BREAKPOINTS from "../../constants/breakpoints";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { DynamicConnectButton } from "@dynamic-labs/sdk-react-core";
import { toggleChat, toggleNavbar } from "../../store/actions/site";

// Assets
import logo from "../../assets/v2/sweet-logo.svg";
import User from "./User";
import Balances from "./Balances";
import Help from "../../assets/v2/mobile-header/help";
import Slots from "../../assets/v2/mobile-header/slots";
import Chat from "../../assets/v2/mobile-header/chat";
import Menu from "../../assets/v2/mobile-header/menu";
const MobileHeader = ({
  isAuthenticated
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    show,
    hide,
    isOpen
  } = useIntercom();
  const redirectToHome = () => history.push("/");
  const handleHelp = () => isOpen ? hide() : show();
  const handleMenu = () => dispatch(toggleNavbar());
  const handleToogleChat = () => dispatch(toggleChat());
  const handleRedirect = () => history.push("/slots");
  return <>
            <HeaderContainer>
                <StyledLogo src={logo} alt="Sweet.gg" onClick={redirectToHome} />
                {isAuthenticated ? <>
                        <Balances />
                        <User />
                    </> : <LoginButtonWrapper>
                        <DynamicConnectButton buttonClassName="authButton">
                            LOGIN / REGISTER
                        </DynamicConnectButton>
                    </LoginButtonWrapper>}
            </HeaderContainer>
            <BottomHeader>
                <BottomHeaderItem onClick={handleMenu}>
                    <Menu />
                    <p>Menu</p>
                </BottomHeaderItem>
                <BottomHeaderItem onClick={handleToogleChat}>
                    <Chat />
                    <p>Chat</p>
                </BottomHeaderItem>
                <BottomHeaderItem onClick={handleRedirect}>
                    <Slots />
                    <p>Slots</p>
                </BottomHeaderItem>
                <BottomHeaderItem onClick={handleHelp}>
                    <Help />
                    <p>Help</p>
                </BottomHeaderItem>
            </BottomHeader>
        </>;
};
const HeaderContainer = styled.header`
    background: ${props => props.theme.background.primary};
    border-bottom: 2px solid ${props => props.theme.border.primary};
    display: none;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 70px;
    padding: 0 30px;
    position: fixed;
    z-index: 100;

    @media (max-width: ${BREAKPOINTS.md}) {
        padding: 0 10px;
        display: flex;
    }
`;
const StyledLogo = styled.img`
    height: 40px;
    width: auto;
    cursor: pointer;
`;
const LoginButtonWrapper = styled.div`
    .authButton {
        text-transform: uppercase;
        padding: 10px;
        margin: 15px 0;
        justify-self: center;
        background: ${({
  theme
}) => theme.colors.tertiary};
        border: none;
        outline: none;
        border-radius: 5px;
        color: ${({
  theme
}) => theme.colors.primary};
        letter-spacing: 0.1em;
        line-height: 1;
        font-size: 10px;
        font-weight: 700;
        box-shadow: ${({
  theme
}) => theme.shadow && `0px 0px 15px 0px ${theme.shadow.tertiary}`};

        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover {
            filter: brightness(1.1);
        }
    }

    display: flex;
    justify-content: center;
    margin-left: auto;
`;
const BottomHeader = styled.div`
    height: 70px;
    width: 100vw;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${props => props.theme.background.primary};
    border-top: 2px solid ${props => props.theme.border.primary};
    padding: 0 10px;
    z-index: 100;
`;
const BottomHeaderItem = styled.div`
    color: ${({
  theme
}) => theme.colors.secondary};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    height: 44px;

    p {
        font-size: 12px;
        color: ${({
  theme
}) => theme.colors.secondary};
        margin-top: 5px;
        margin-bottom: 0;
        font-weight: 500;
    }

    svg {
        height: 20px;
        display: flex;
        item-align: center;
    }
`;
export default MobileHeader;