import React, { useState } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

// MUI Components
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";

// Components
import SweetWithdraw from "./withdraw/Sweet";
import EthereumWithdraw from "./withdraw/Ethereum";
import SweetDeposit from "./deposit/Sweet";
import EthereumDeposit from "./deposit/Ethereum";
import { CloseButton, StyledModal } from ".";
import BREAKPOINTS from "../../constants/breakpoints";

// Assets
import sweet from "../../assets/coin.svg";
import solana from "../../assets/v2/currency-icons/sol.webp";
import ethereum from "../../assets/v2/currency-icons/eth.webp";
import usdc from "../../assets/v2/currency-icons/usdc.webp";
import litecoin from "../../assets/v2/currency-icons/litecoin.webp";
import dogecoin from "../../assets/v2/currency-icons/dogecoin.webp";
import Button from "../Button";
import toast from "react-hot-toast";
import { activateDepositBonus, sendTip } from "../../services/api.service";
const OptionLink = ({
  image,
  onClick,
  name,
  disabled
}) => {
  return <Option onClick={() => onClick()} disabled={disabled} style={{
    backgroundImage: `url(${image})`,
    backgroundSize: "40%",
    backgroundPosition: "top 30px center",
    backgroundRepeat: "no-repeat"
  }}>
            {name}
        </Option>;
};
const DepositTab = "deposit";
const WithdrawTab = "withdraw";
const BonusTab = "bonus";
const TipTab = "tip";
const CashierModal = ({
  open,
  handleClose,
  isAuthenticated,
  user,
  activeCurrency
}) => {
  const [crypto, setCrypto] = useState("ethereum");
  const [bonusCode, setBonusCode] = useState("");
  const [username, setUsername] = useState("");
  const [tipAmount, setTipAmount] = useState("");
  const [activeTab, setActiveTab] = useState(DepositTab);
  const [showDepositDetailPage, setShowDepositDetailPage] = useState(false);
  const [showWithdrawDetailPage, setShowWithdrawDetailPage] = useState(false);
  const renderDepositDetailPage = () => {
    switch (crypto) {
      case "sweet":
        return <SweetDeposit />;
      case "ethereum":
        return <EthereumDeposit />;
      default:
        return null;
    }
  };
  const renderWithdrawDetailPage = () => {
    switch (crypto) {
      case "sweet":
        return <SweetWithdraw />;
      case "ethereum":
        return <EthereumWithdraw />;
      default:
        return null;
    }
  };
  const resetContent = () => {
    setShowDepositDetailPage(false);
    setShowWithdrawDetailPage(false);
  };
  const activateBonus = async () => {
    try {
      const idempotencyKey = uuidv4();
      const response = await activateDepositBonus(bonusCode, idempotencyKey);
      if (response.data.success) {
        toast.success("Bonus Activated!");
        return;
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };
  const submitTip = async () => {
    try {
      const usdAmount = parseFloat(tipAmount);
      if (isNaN(usdAmount) || usdAmount <= 0) {
        toast.error("Invalid amount");
        return;
      }
      if (username === "") {
        toast.error("Invalid username");
        return;
      }
      const idempotencyKey = uuidv4();
      const response = await sendTip(idempotencyKey, username, usdAmount, activeCurrency?.id);
      if (response.data.success) {
        toast.success("Tip Sent!");
        return;
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };
  return <StyledModal onClose={handleClose} open={open}>
            <TabContainer>
                <Tab isActive={activeTab === DepositTab} onClick={() => {
        if (activeTab !== DepositTab) {
          setActiveTab(DepositTab);
          resetContent();
        }
      }}>
                    DEPOSIT
                </Tab>
                <Tab isActive={activeTab === WithdrawTab} onClick={() => {
        if (activeTab !== WithdrawTab) {
          setActiveTab(WithdrawTab);
          resetContent();
        }
      }}>
                    WITHDRAW
                </Tab>
                <Tab // ! Activate when bonus is ready
      isActive={activeTab === BonusTab} onClick={() => {
        if (activeTab !== BonusTab) {
          setActiveTab(BonusTab);
          resetContent();
        }
      }}>
                    BONUS
                </Tab>
                <Tab isActive={activeTab === TipTab} onClick={() => {
        if (activeTab !== TipTab) {
          setActiveTab(TipTab);
          resetContent();
        }
      }}>
                    TIP
                </Tab>
            </TabContainer>
            <>
                {activeTab === DepositTab && <>
                        {!showDepositDetailPage ? <>
                                <TabTitle>Deposit Options</TabTitle>
                                <CurrencyOptions>
                                    <OptionLink image={ethereum} name={"Ethereum"} onClick={() => {
              setCrypto("ethereum");
              setShowDepositDetailPage(true);
            }} />
                                    <OptionLink image={solana} name={"Solana"} disabled />
                                    <OptionLink image={litecoin} name={"Litecoin"} disabled />
                                    <OptionLink image={usdc} name={"USDC"} disabled />
                                    <OptionLink image={dogecoin} name={"Dogecoin"} disabled />
                                    <OptionLink image={sweet} name={"Sweet"} disabled />
                                </CurrencyOptions>
                            </> : <>
                                <DetailTitle onClick={() => setShowDepositDetailPage(false)}>
                                    <ArrowBackIcon />
                                    Deposit {crypto}
                                </DetailTitle>
                                <React.Fragment>
                                    {renderDepositDetailPage()}
                                </React.Fragment>
                            </>}
                    </>}
                {activeTab === WithdrawTab && <>
                        {!showWithdrawDetailPage ? <>
                                <TabTitle>Withdraw Options</TabTitle>
                                <CurrencyOptions>
                                    <OptionLink image={ethereum} name={"Ethereum"} onClick={() => {
              setCrypto("ethereum");
              setShowWithdrawDetailPage(true);
            }} />
                                    <OptionLink image={solana} name={"Solana"} disabled />
                                    <OptionLink image={litecoin} name={"Litecoin"} disabled />
                                    <OptionLink image={usdc} name={"USDC"} disabled />
                                    <OptionLink image={dogecoin} name={"Dogecoin"} disabled />
                                    <OptionLink image={sweet} name={"Sweet"} disabled />
                                </CurrencyOptions>
                            </> : <>
                                <DetailTitle onClick={() => setShowWithdrawDetailPage(false)}>
                                    <ArrowBackIcon />
                                    Withdraw {crypto}
                                </DetailTitle>
                                <React.Fragment>
                                    {renderWithdrawDetailPage()}
                                </React.Fragment>
                            </>}
                    </>}
                {activeTab === BonusTab && <CenteredTabContent>
                        <TabTitle>APPLY BONUS CODE</TabTitle>
                        <BonusSubTitle>
                            Redeem a bonus code to activate a deposit bonus of
                            200%. <br /> Terms & Conditions apply.
                        </BonusSubTitle>
                        <Input placeholder="Enter Bonus Code" value={bonusCode} onChange={e => setBonusCode(e.target.value)} />
                        <CodeInputSubtext>
                            No bonus code? Enter "SWEET200"
                        </CodeInputSubtext>
                        <Button disabled={!isAuthenticated} onClick={activateBonus}>
                            ACTIVATE BONUS
                        </Button>
                    </CenteredTabContent>}
                {activeTab === TipTab && <CenteredTabContent>
                        <TabTitle>SEND A TIP</TabTitle>
                        <ActiveCurrency>
                            <CurrencyImage src={activeCurrency?.image} />
                            {activeCurrency?.name}
                        </ActiveCurrency>
                        <Input placeholder="Enter Username" style={{
          marginTop: "25px"
        }} value={username} onChange={e => setUsername(e.target.value)} />
                        <Input placeholder="Enter Amount (USD)" style={{
          marginTop: "10px"
        }} value={tipAmount} onChange={e => setTipAmount(e.target.value)} />
                        <Button disabled={!isAuthenticated} onClick={submitTip} style={{
          marginTop: "20px"
        }}>
                            SEND TIP
                        </Button>
                    </CenteredTabContent>}
            </>
            <CloseButton autoFocus onClick={handleClose} color="primary">
                CLOSE
            </CloseButton>
        </StyledModal>;
};

// TODO: clean code

export default CashierModal;
const TabTitle = styled.div`
    font-size: 17px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    leading-trim: both;
    text-edge: cap;
    text-transform: uppercase;
`;
const CurrencyOptions = styled.div`
    margin-top: 20px;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    @media (max-width: ${BREAKPOINTS.md}) {
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }
`;
const DetailTitle = styled.div`
    color: ${({
  theme
}) => theme.colors.primary};

    font-size: 20px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 12px;
    & svg {
        cursor: pointer;
    }
`;
const TabContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 25px;
`;
const Tab = styled.div`
    padding: 8px 16px;
    color: ${props => props.isActive ? props.theme.colors.primary : props.theme.colors.secondary} !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.border.primary};
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-weight: 500;

    &:hover {
        filter: brightness(1.2);
    }
`;
const Option = styled.div`
    width: 100%;
    height: 100%;
    aspect-ratio: 245 / 240;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    position: relative;
    border-radius: 10px;
    padding: 16px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: ${props => props.theme.colors.primary};
    border: 1px solid ${props => props.theme.border.primary};
    background: ${props => props.theme.background.secondary};
    backdrop-filter: blur(7.5px);
    text-transform: uppercase;
    font-size: 13px;

    &:hover {
        transform: scale(1.05);
    }

    ${props => props.disabled && `
        opacity: 0.4;
        pointer-events: none;
    `}

    @media (max-width: ${BREAKPOINTS.md}) {
        padding: 20px;
    }
`;
const CenteredTabContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin: 25px 0;
`;
const Input = styled.input`
    outline: none;
    border: ${({
  theme
}) => `1px solid ${theme.border.primary}`};
    border-radius: 5px;
    background: ${({
  theme
}) => theme.background.secondary};
    color: ${({
  theme
}) => theme.colors.primary};
    padding: 10px 15px;
    font-size: 16px;
    text-align: center;

    &::placeholder {
        color: ${({
  theme
}) => theme.colors.secondary};
    }
`;
const BonusSubTitle = styled.div`
    font-size: 14px !important;
    color: ${({
  theme
}) => theme.colors.secondary} !important;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 25px;
`;
const CodeInputSubtext = styled.div`
    font-size: 12px !important;
    color: ${({
  theme
}) => theme.colors.secondary} !important;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 20px;
`;
const ActiveCurrency = styled.div`
    background: ${({
  theme
}) => theme.background.secondary};
    border-radius: 10px;
    padding: 5px 10px;
    color: ${({
  theme
}) => theme.colors.primary};
    font-size: 14px !important;
    font-weight: 500;
    text-transform: uppercase;
    border: 1px solid ${({
  theme
}) => theme.border.primary};
    margin: 10px 0;

    display: flex;
    align-items: center;
    justify-content: center;
`;
const CurrencyImage = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;