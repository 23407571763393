import React from "react";
import styled from "styled-components";

// Assets
import logo from "../assets/v2/sweet-textlogo.webp";
const Preloader = () => {
  return <Page>
            <LoadingLogo src={logo} alt="Sweet.gg" />
        </Page>;
};
const Page = styled.div`
    height: 100vh;
    width: 100vw;
    background: ${props => props.theme.background.primary};
    display: flex;
    align-items: center;
    justify-content: center;
`;
const LoadingLogo = styled.img`
    height: 60px;
    width: auto;
    animation: 1.5s ease 0s infinite beat;
`;
export default Preloader;