import styled from "styled-components";
import Bets from "../../components/roulette/Bets";
import BREAKPOINTS from "../../constants/breakpoints";
const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 18px;
    color: ${props => props.theme.colors.secondary};

    margin-top: 20px;

    @media (max-width: ${BREAKPOINTS.md}) {
        display: flex;
        flex-direction: column;
    }
`;
const FeedWrapper = styled.div`
    transition: opacity 0.4s cubic-bezier(0.17, 0.67, 0.34, 1.21);
`;
const BetFeeds = ({
  currency,
  buttonsDisabled,
  players,
  blackResult,
  redResult,
  greenResult
}) => {
  return <Container>
            <FeedWrapper style={{
      opacity: buttonsDisabled && !blackResult ? "0.3" : buttonsDisabled && blackResult ? "1" : "1"
    }}>
                <Bets players={players} won={blackResult} color="blue" currency={currency} multiplier={2} />
            </FeedWrapper>
            <FeedWrapper style={{
      opacity: buttonsDisabled && !greenResult ? "0.3" : buttonsDisabled && greenResult ? "1" : "1"
    }}>
                <Bets currency={currency} players={players} won={greenResult} color="green" multiplier={14} />
            </FeedWrapper>
            <FeedWrapper style={{
      opacity: buttonsDisabled && !redResult ? "0.3" : buttonsDisabled && redResult ? "1" : "1"
    }}>
                <Bets currency={currency} players={players} lost={greenResult || blackResult} won={redResult} color="red" multiplier={2} />
            </FeedWrapper>
        </Container>;
};
export default BetFeeds;