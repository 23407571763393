import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import RecaptchaV3 from "react-google-recaptcha3";
import { coinflipSocket } from "../../services/websocket.service";
import BREAKPOINTS from "../../constants/breakpoints";

// MUI Components
import { Visibility as VisibilityIcon, Replay as ReplayIcon } from "@material-ui/icons";

// Components
import { getPlayerAvatar } from "./Game";
import { JoinButton } from "./JoinButton";

// Assets
import redCoin from "../../assets/coinflip/red_coin.webp";
import blueCoin from "../../assets/coinflip/blue_coin.webp";
import coin from "../../assets/coin.svg";
import defaultAvatar from "../../assets/profile.svg";

// TODO: Remove useless logic
const Game = ({
  game
}) => {
  const history = useHistory();
  const [joining, setJoining] = useState(null);
  const joinGame = async color => {
    setJoining(color);
    const reCaptcha = await RecaptchaV3.getToken({
      action: "join_coinflip_game"
    });
    coinflipSocket.emit("join-game", game._id, color, reCaptcha);
    history.push(game.inviteLink);
  };
  const onClickView = () => {
    history.push(`/coinflip/${game._id}`);
  };
  useEffect(() => {
    // Handle join event
    const joinEvent = () => {
      setJoining(null);
    };
    coinflipSocket.on("game-join-error", joinEvent);
    coinflipSocket.on("game-join-success", joinEvent);
    return () => {
      coinflipSocket.off("game-join-error", joinEvent);
      coinflipSocket.off("game-join-success", joinEvent);
    };
  }, []);
  return <GameWrapper key={game._id} style={game.ended && {
    animation: "blinker 1.5s forwards",
    WebkitAnimation: "blinker 1.5s forwards",
    MozAnimation: "blinker 1.5s forwards"
  }}>
            {game.status === 1 && <ActionWrapper onClick={onClickView}>
                    <VisibilityIcon data-tooltip-content="Spectate" data-tooltip-id="default" />
                </ActionWrapper>}
            {game.status === 2 && <ActionWrapper onClick={onClickView}>
                    <VisibilityIcon data-tooltip-content="Spectate" data-tooltip-id="default" />
                </ActionWrapper>}
            {game.status === 3 && <ActionWrapper onClick={onClickView}>
                    <ReplayIcon data-tooltip-content="Rewatch Game" data-tooltip-id="default" />
                </ActionWrapper>}
            <Value>
                <h1>{(game.betAmount * game.playerAmount).toFixed(2)}</h1>
                <img src={coin} alt="Sweet" style={{
        marginLeft: "5px"
      }} />
            </Value>
            <Players>
                {game.players.find(player => player.color === "red") ? <Player style={game.status === 3 && game.winningSide === "red" ? {
        opacity: 1
      } : game.status === 1 ? {
        opacity: 1
      } : game.status === 2 ? {
        opacity: 1
      } : game.status === 3 && game.winningSide !== "red" ? {
        opacity: 0.15,
        transition: "all 0.3s ease"
      } : {
        opacity: 1
      }}>
                        <img src={redCoin} alt="Red" style={{
          height: "2.3rem"
        }} />
                        <StyledAvatar src={getPlayerAvatar(game.players.find(player => player.color === "red"))} style={{
          border: "2px solid",
          borderColor: game.players.find(player => player.color === "red").level.levelColor
        }} />
                        <UserLevel style={{
          background: game.players.find(player => player.color === "red").level.levelColor
        }}>
                            {game.players.find(player => player.color === "red").level.name}
                        </UserLevel>
                        <span>
                            {game.players.find(player => player.color === "red").username}
                        </span>
                    </Player> : <Player>
                        <img src={redCoin} alt="Red" style={{
          height: "2.3rem"
        }} />
                        <StyledAvatar src={defaultAvatar} style={{
          border: "2px solid #CD4443"
        }} />
                        <JoinButton variant="contained" onClick={() => !joining ? joinGame("red") : null} disabled={joining ? true : false}>
                            {joining === "red" ? "Joining..." : `Join for ${game.betAmount}`}
                        </JoinButton>
                    </Player>}
                {game.players.find(player => player.color === "blue") ? <Player style={game.status === 3 && game.winningSide === "blue" ? {
        opacity: 1
      } : game.status === 1 ? {
        opacity: 1
      } : game.status === 2 ? {
        opacity: 1
      } : game.status === 3 && game.winningSide !== "blue" ? {
        opacity: 0.15,
        transition: "all 0.3s ease"
      } : {
        opacity: 1
      }}>
                        <img src={blueCoin} alt="Blue" style={{
          height: "2.3rem"
        }} />
                        <StyledAvatar src={getPlayerAvatar(game.players.find(player => player.color === "blue"))} style={{
          border: "2px solid",
          borderColor: game.players.find(player => player.color === "blue").level.levelColor
        }} />
                        <UserLevel style={{
          background: game.players.find(player => player.color === "blue").level.levelColor
        }}>
                            {game.players.find(player => player.color === "blue").level.name}
                        </UserLevel>
                        <span>
                            {game.players.find(player => player.color === "blue").username}
                        </span>
                    </Player> : <Player>
                        <img src={blueCoin} alt="Blue" style={{
          height: "2.3rem"
        }} />
                        <StyledAvatar src={defaultAvatar} style={{
          border: "2px solid #2B83D1"
        }} />
                        <JoinButton variant="contained" onClick={() => !joining ? joinGame("blue") : null} disabled={joining ? true : false}>
                            {joining === "blue" ? "Joining..." : `Join for ${game.betAmount}`}
                        </JoinButton>
                    </Player>}
            </Players>
        </GameWrapper>;
};
const GameWrapper = styled.div`
    display: flex;
    height: 7.25rem;
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    background: ${props => props.theme.background.primary};
    border: 1px solid ${props => props.theme.background.primary};
    border-radius: 20px;

    @media (max-width: ${BREAKPOINTS.md}) {
        width: 100%;
        display: grid;
        height: fit-content;
        margin-bottom: 30px;
    }
`;
const UserLevel = styled.span`
    font-size: 10px;
    padding: 3px 6px;
    color: ${({
  theme
}) => theme.colors.primary};
    
    font-weight: 500;
    letter-spacing: 0.15em;
    margin-top: -4px;
    border-radius: 5px;
    marginRight: "-10px",
    marginLeft: "14px",
`;
const ActionWrapper = styled.div`
    color: ${({
  theme
}) => theme.colors.secondary};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 2rem;
    padding: 0 35px;
    border-radius: 1rem 0 0 1rem;

    @media (max-width: ${BREAKPOINTS.md}) {
        height: fit-content;
        margin-bottom: 0.5rem;
        width: 100%;
        border-radius: 1rem 1rem 0 0;
        padding: 15px 0;
    }

    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
        opacity: 0.9;
        background: rgba(255, 255, 255, 0.05);
    }
`;
const Value = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;

    @media (max-width: ${BREAKPOINTS.md}) {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    & h1 {
        color: ${({
  theme
}) => theme.colors.primary};
        font-weight: 500;
        letter-spacing: 0.1em;
        font-size: 18px;
    }

    & h3 {
        margin: 0;
        font-size: 12px;
        color: ${props => props.theme.colors.secondary};
    }
`;
const StyledAvatar = styled.img`
    height: 40px;
    width: 40px;
    margin-left: 1rem;
    background-color: #272b2f;
    border-radius: 100%;
`;
const Player = styled.div`
    display: flex;
    color: ${({
  theme
}) => theme.colors.primary};

    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.1em;
    align-items: center;
    position: relative;

    & > span {
        margin-left: 1rem;
        font-size: 11px;
        text-transform: uppercase;
    }

    @media (max-width: ${BREAKPOINTS.md}) {
        margin-bottom: 25px;
    }
`;
const Players = styled.div`
    width: 100%;
    display: flex;
    padding: 10px 0px 10px 0px;
    margin-right: 1rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;

    @media (max-width: ${BREAKPOINTS.md}) {
        margin-left: 1.5rem;
        max-width: 300px;
    }
`;
Game.propTypes = {
  game: PropTypes.object.isRequired,
  user: PropTypes.object,
  isAuthenticated: PropTypes.bool
};
const mapStateToProps = state => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated
});
export default connect(mapStateToProps)(Game);