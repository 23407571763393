import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as Popover from "@radix-ui/react-popover";
import { connect, useDispatch } from "react-redux";
import Cashier from "./Cashier";
import BREAKPOINTS from "../../constants/breakpoints";
import { WALLET_TYPE_PLAY } from "../../constants/wallets";
import { CURRENCIES } from "../../constants/currencies";
import { updateCurrency } from "../../store/actions/currency";
import { formatFiatNumber, formatCryptoNumber } from "../../utils/formatNumber";

// Assets
import { ReactComponent as Arrow } from "../../assets/v2/icons/arrow.svg";
import { ReactComponent as Checkmark } from "../../assets/v2/icons/checkmark.svg";
const _getExchangeRate = (exchangeRates, from, to) => {
  switch (from.id) {
    case CURRENCIES.SWEET.id:
      return exchangeRates.token.ethereum["0x2df7d0e4903029717c949cad204075a3d75c8806"][to];
    case CURRENCIES.ETH.id:
      return exchangeRates.coin.ethereum[to];
    default:
      return 1;
  }
};
const Balances = ({
  user,
  currency: selectedCurrency,
  exchangeRates,
  inPlay
}) => {
  const dispatch = useDispatch();
  const [usdBalance, setUsdBalance] = useState(0);
  const handleCurrencyChange = currency => {
    dispatch(updateCurrency(currency));
  };
  const getActiveCurrencyAmount = () => {
    const selectedWallet = user?.wallets?.[selectedCurrency.id]?.[WALLET_TYPE_PLAY];
    if (selectedWallet && selectedWallet.amount) {
      const amount = selectedWallet.amount / 10 ** selectedCurrency.decimalPlaces;
      return amount.toFixed(4);
    }
    return 0;
  };
  useEffect(() => {
    if (!user || !exchangeRates || !selectedCurrency) {
      return;
    }
    const rate = _getExchangeRate(exchangeRates, selectedCurrency, "usd");
    const usdValue = parseFloat(getActiveCurrencyAmount()) * parseFloat(rate);
    setUsdBalance(usdValue.toFixed(2));
  }, [selectedCurrency, user, exchangeRates]);
  return <BalanceWrapper>
            <Popover.Root>
                <Popover.Trigger asChild>
                    <BalanceContainer>
                        <BalanceImage src={selectedCurrency.image} />
                        <BalanceContent>
                            <BalanceTitle>{selectedCurrency.name}</BalanceTitle>
                            <BalanceAmount>
                                {inPlay ? "(In Play)" : `$${formatFiatNumber(usdBalance)}`}
                            </BalanceAmount>
                        </BalanceContent>
                        <Arrow />
                    </BalanceContainer>
                </Popover.Trigger>
                <Popover.Portal>
                    <PopoverContent sideOffset={10}>
                        <CurrencyOptions>
                            {Object.values(CURRENCIES).filter(curr => curr.id !== CURRENCIES.USD.id).map(currency => {
              const totalBalance = user?.wallets?.[currency.id]?.[WALLET_TYPE_PLAY]?.amount / 10 ** currency.decimalPlaces;
              const fbal = formatCryptoNumber(totalBalance, 5);
              return <CurrencyOption key={currency.id} onClick={() => handleCurrencyChange(currency)}>
                                            <CurrencyImage src={currency.image} />
                                            <BalanceContent>
                                                <CurrencyOptionTitle>
                                                    {currency.name}
                                                </CurrencyOptionTitle>
                                                <CurrencyOptionBalance>
                                                    {fbal}
                                                </CurrencyOptionBalance>
                                            </BalanceContent>
                                            {selectedCurrency.id === currency.id && <SelectedCurrencyCheckmark />}
                                        </CurrencyOption>;
            })}
                        </CurrencyOptions>
                        <PopoverClose aria-label="Close" />
                        <PopoverArrow />
                    </PopoverContent>
                </Popover.Portal>
            </Popover.Root>
            <Cashier />
        </BalanceWrapper>;
};
const mapStateToProps = state => ({
  isLoading: state.auth.isLoading,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  currency: state.currency.currency,
  exchangeRates: state.site.exchangeRates,
  inPlay: state.site.inPlay
});
export default connect(mapStateToProps)(Balances);
const BalanceWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 10px;
    border-radius: 1rem;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid ${props => props.theme.border.primary};
    gap: 10px;

    @media (max-width: ${BREAKPOINTS.md}) {
        padding: 6px 10px;
    }
`;
const BalanceContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 10px;
`;
const BalanceImage = styled.img`
    height: 20px;
    width: auto;
`;
const BalanceContent = styled.div``;
const BalanceTitle = styled.div`
    font-size: 13px;
    font-weight: 500;
    color: ${props => props.theme.colors.primary};
    line-height: 1;
    margin-bottom: 2px;
`;
const CurrencyOptionTitle = styled(BalanceTitle)`
    font-size: 12px;
`;
const BalanceAmount = styled.div`
    font-size: 12px;
    color: ${props => props.theme.colors.secondary};
    line-height: 1;
`;
const CurrencyOptionBalance = styled(BalanceAmount)`
    font-size: 11px;
    color: ${props => props.selected && props.theme.colors.primary};
`;

// TODO: move to external file
const PopoverContent = styled(Popover.Content)`
    border-radius: 10px;
    padding: 7px;
    width: 180px;
    border: 1px solid ${props => props.theme.border.primary};
    background: ${props => props.theme.background.primary};
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
`;

// TODO: figure out why this is needed
export const PopoverClose = styled(Popover.Close)`
    all: unset;
    position: absolute;
`;
export const PopoverArrow = styled(Popover.Arrow)`
    fill: ${props => props.theme.background.secondary};
    margin-right: 10px;
    height: 10px;
    width: 10px;
`;
const CurrencyOptions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;
const CurrencyOption = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    background: ${props => props.selected ? props.theme.colors.tertiary : "transparent"};
    border-radius: 8px;
    padding: 7px 10px;
    cursor: pointer;
    font-weight: 500;
    color: ${({
  theme
}) => theme.colors.primary};
`;
const CurrencyImage = styled.img`
    height: 20px;
    width: auto;
`;
const SelectedCurrencyCheckmark = styled(Checkmark)`
    margin-left: auto;
`;