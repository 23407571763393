import React from "react";
import styled from "styled-components";
import BREAKPOINTS from "../constants/breakpoints";
const Layout = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
    text-align: center;

    h1 {
        margin: 0;
        color: ${({
  theme
}) => theme.colors.primary};
        font-weight: 500;
        letter-spacing: 0.1em;
        font-size: 50px;

        @media (max-width: ${BREAKPOINTS.sm}) {
            font-size: 36px;
        }
    }

    span {
        color: ${props => props.theme.colors.secondary};
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.005em;
    }
`;
const NotFound = () => {
  return <Layout>
            <h1>404 ERROR</h1>
            <span>We couldn't find what you were looking for.</span>
        </Layout>;
};
export default NotFound;