import { useEffect, useState } from "react";

// TODO: Implement everywhere
// Custom hook for fetching data
const useFetch = (
    method,
    {
        canFetchData = true,
        dependencies = [],
        onSuccess = null,
        onError = null,
        initialData = null,
        reloadOnError = false,
        stopLoadingOnError = false,
    } = {},
) => {
    if (!method) {
        throw new Error("Method is required");
    }

    const [data, setData] = useState(initialData);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!canFetchData) {
            return;
        }

        method()
            .then((res) => {
                setData(res.data);
                if (onSuccess) {
                    onSuccess(res.data);
                }

                setIsLoading(false);
            })
            .catch((error) => {
                setError(error);

                if (reloadOnError) {
                    setTimeout(() => {
                        window.location.reload();
                    }, 5000);
                    return;
                }

                if (stopLoadingOnError) {
                    setIsLoading(false);
                }

                if (onError) {
                    onError(error);
                }
            });
    }, [canFetchData, ...dependencies]);

    return { data, isLoading, error };
};

export default useFetch;
