import React, { Fragment, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { chatSocket } from "../../services/websocket.service";

// Components
import EmojiDrawer from "./EmojiDrawer";
import SugarRush from "./SugarRush";

// Assets
import { ReactComponent as EmotesIcon } from "../../assets/v2/chat-icons/emotes.svg";
import { ReactComponent as SendIcon } from "../../assets/v2/chat-icons/send.svg";
const ChatInputContainer = styled.div`
    display: flex;
    margin: 15px;
    background: ${props => props.theme.background._chatMessageInput};
    border-radius: 5px;
    border: 1px solid ${props => props.theme.border.primary};
    padding: 7px 10px;
`;
const ChatInputWrapper = styled.div`
    width: 100%;
    background: ${props => props.theme.background.primary};
    z-index: 10;
`;
const ChatInput = styled.input`
    background: transparent;
    border: none;
    outline: none;
    width: calc(100% - 70px);
    margin-right: auto;
    color: ${props => props.theme.colors.primary};

    &::placeholder {
        color: ${props => props.theme.colors.secondary};
    }
`;
const EmojiButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 4px;

    ${props => props.disabled ? "opacity: 0.5;" : "cursor: pointer;"}

    & > svg {
        width: 20px;
        height: 20px;
    }
`;
const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
`;
const EmotesButton = styled(EmojiButton)`
    background: ${props => props.theme.background.secondary};
`;
const SendButton = styled(EmojiButton)`
    background: ${props => props.theme.colors.tertiary};
    color: ${props => props.theme.colors._colorOnTeritary ? props.theme.colors._colorOnTeritary : props.theme.colors.primary};
`;
const Controls = ({
  isAuthenticated,
  rain,
  sweet
}) => {
  const [input, setInput] = useState("");
  const [emojiModalVisible, setEmojiModalVisible] = useState(false);

  // TextField onKeyPress event handler
  const onKeyPress = es => {
    if (es.key === "Enter") {
      chatSocket.emit("send_chat_message", input);
      setInput("");
      return false;
    }
  };
  const onSendClick = () => {
    if (isAuthenticated && input === "") {
      return;
    }
    chatSocket.emit("send_chat_message", input);
    setInput("");
  };
  const onEmojiClick = () => {
    if (!isAuthenticated) {
      return;
    }
    setEmojiModalVisible(state => !state);
  };

  // Input onChange event handler
  const onChange = e => {
    setInput(e.target.value);
  };
  return <Fragment>
            {rain && rain.active && <SugarRush rain={rain} sweet={sweet} />}
            <EmojiDrawer open={emojiModalVisible} handleEmojiSelect={emoji => {
      setInput(state => state !== "" ? `${state} ${emoji}` : emoji);
    }} />
            <ChatInputWrapper>
                <ChatInputContainer>
                    <ChatInput placeholder={isAuthenticated ? "Send a message ..." : "Login to chat"} disabled={isAuthenticated ? false : true} onChange={onChange} onKeyPress={onKeyPress} value={input} />
                    <ButtonsContainer>
                        <EmotesButton disabled={isAuthenticated ? false : true} onClick={onEmojiClick}>
                            <EmotesIcon />
                        </EmotesButton>
                        <SendButton disabled={isAuthenticated ? false : true} onClick={onSendClick}>
                            <SendIcon />
                        </SendButton>
                    </ButtonsContainer>
                </ChatInputContainer>
            </ChatInputWrapper>
        </Fragment>;
};
Controls.propTypes = {
  isAuthenticated: PropTypes.bool,
  sweet: PropTypes.object
};
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  sweet: state.site.sweet
});
export default connect(mapStateToProps, {})(Controls);