import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Components
import Message from "./Message";
import RareOpening from "./RareOpening";
import Skeleton from "../Skeleton";
const Messages = ({
  chatMessages,
  loading,
  user
}) => {
  const chatAnker = useRef(null);

  // Scroll chat using ref
  const scrollChat = () => {
    if (chatAnker && chatAnker.current) {
      chatAnker.current.scrollIntoView({
        behavior: "smooth"
      });
    }
  };

  // When chat messages change
  useEffect(() => {
    scrollChat();
  }, [chatMessages]);

  // When messages load the first time
  useEffect(() => {
    const timeout = setTimeout(() => {
      // If messages are loaded
      if (chatMessages) scrollChat();
    }, 500);
    return () => {
      // Save memory and remove timeout
      clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, [loading]);
  return <MessagesContainer>
            {loading ? Array(15).fill().map((_, index) => <Skeleton key={index} height="50px" style={{
      margin: "10px 0"
    }} />) : chatMessages.map(message => message?.type === "rare-opening" ? <RareOpening key={message.id} message={message} /> : <Message key={message.id} user={user} message={message} />)}
            <ChatAnker ref={chatAnker} />
        </MessagesContainer>;
};
const MessagesContainer = styled.div`
    flex: 1 1;
    flex-direction: column;
    overflow-x: hidden;
    padding: 0 15px;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;
const ChatAnker = styled.div`
    clear: both;
`;
Messages.propTypes = {
  chatMessages: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  user: PropTypes.object
};
const mapStateToProps = state => ({
  user: state.auth.user
});
export default connect(mapStateToProps)(Messages);