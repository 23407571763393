import styled, { keyframes } from "styled-components";
const pulse = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
`;
const Skeleton = styled.div`
    width: ${props => props.width};
    height: ${props => props.height};
    background: rgba(255, 255, 255, ${props => props.opacity || 0.06});
    animation: ${pulse} 2s ease-in-out 0.5s infinite;
    ${props => props.variant === "circle" && "border-radius: 50%;"}
    ${props => props.variant === "rounded" && "border-radius: 5px;"}
`;
export default Skeleton;