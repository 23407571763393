import styled from "styled-components";
import arrow from "../../assets/roulette/arrow.svg";
import red from "../../assets/roulette/red_icon.svg";
import black from "../../assets/roulette/blue_icon.svg";
import green from "../../assets/roulette/green_icon.svg";
import CARD_COLORS from "../../constants/cardColors";
const Container = styled.div`
    width: 100%;
    height: 112px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;
const ArrowWrapper = styled.div`
    transform: translateY(-6px);
    height: 112px;
    position: absolute;
    z-index: 2;
    transition: opacity 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;
const RouletteWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100px;
    background: ${props => props.theme.background.secondary};
    margin: 0 auto;
    border-radius: 20px;
    overflow: hidden;
    justify-content: center;

    &::before {
        background: linear-gradient(
            90deg,
            ${props => props.theme.background.primary} 0%,
            transparent 100%
        );
        content: "";
        width: 118px;
        height: 100px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 1;
    }

    &::after {
        background: linear-gradient(
            270deg,
            ${props => props.theme.background.primary} 0%,
            transparent 100%
        );
        content: "";
        width: 118px;
        height: 100px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
`;
const RouletteWheel = styled.div`
    display: flex;
    gap: 15px;
`;
const RouletteItem = styled.div`
    display: flex;
    padding: 15px 0;
    background: rgb(15, 15, 16, 0.08);
    gap: 15px;
`;
const Card = styled.div`
    background: ${props => props.background};
    width: ${props => `${props.width}px`};
    height: 70px;
    border-radius: 10px;
    transition: text-shadow 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.5em;
`;
const Game = ({
  transitionTimingFunction,
  selectorOpacity,
  wheelTransform,
  transitionDuration,
  _getNumberStyling,
  CARD_WIDTH
}) => {
  return <Container>
            <ArrowWrapper style={{
      opacity: selectorOpacity
    }}>
                <img src={arrow} alt="" style={{
        transform: "translateY(-0px)"
      }} />
                <img src={arrow} alt="" style={{
        transform: "translateY(-126px) rotate(180deg)"
      }} />
            </ArrowWrapper>
            <RouletteWrapper>
                <RouletteWheel style={{
        transitionTimingFunction: transitionTimingFunction,
        transitionDuration: transitionDuration,
        transform: wheelTransform
      }}>
                    {[...Array(15)].map((row, i) => <RouletteItem key={i}>
                            <Card style={_getNumberStyling(1)} background={CARD_COLORS.RED} width={CARD_WIDTH}>
                                <img src={red} alt="Red" />
                            </Card>
                            <Card style={_getNumberStyling(14)} background={CARD_COLORS.BLACK} width={CARD_WIDTH}>
                                <img src={black} alt="Blue" />
                            </Card>
                            <Card style={_getNumberStyling(2)} background={CARD_COLORS.RED} width={CARD_WIDTH}>
                                <img src={red} alt="Red" />
                            </Card>
                            <Card style={_getNumberStyling(13)} background={CARD_COLORS.BLACK} width={CARD_WIDTH}>
                                <img src={black} alt="Blue" />
                            </Card>
                            <Card style={_getNumberStyling(3)} background={CARD_COLORS.RED} width={CARD_WIDTH}>
                                <img src={red} alt="Red" />
                            </Card>
                            <Card style={_getNumberStyling(12)} background={CARD_COLORS.BLACK} width={CARD_WIDTH}>
                                <img src={black} alt="Blue" />
                            </Card>
                            <Card style={_getNumberStyling(4)} background={CARD_COLORS.RED} width={CARD_WIDTH}>
                                <img src={red} alt="Red" />
                            </Card>
                            <Card style={_getNumberStyling(0)} background={CARD_COLORS.GREEN} width={CARD_WIDTH}>
                                <img src={green} alt="Green" style={{
              width: "30px",
              height: "30px"
            }} />
                            </Card>
                            <Card style={_getNumberStyling(11)} background={CARD_COLORS.BLACK} width={CARD_WIDTH}>
                                <img src={black} alt="Blue" />
                            </Card>
                            <Card style={_getNumberStyling(5)} background={CARD_COLORS.RED} width={CARD_WIDTH}>
                                <img src={red} alt="Red" />
                            </Card>
                            <Card style={_getNumberStyling(10)} background={CARD_COLORS.BLACK} width={CARD_WIDTH}>
                                <img src={black} alt="Blue" />
                            </Card>
                            <Card style={_getNumberStyling(6)} background={CARD_COLORS.RED} width={CARD_WIDTH}>
                                <img src={red} alt="Red" />
                            </Card>
                            <Card style={_getNumberStyling(9)} background={CARD_COLORS.BLACK} width={CARD_WIDTH}>
                                <img src={black} alt="Blue" />
                            </Card>
                            <Card style={_getNumberStyling(7)} background={CARD_COLORS.RED} width={CARD_WIDTH}>
                                <img src={red} alt="Red" />
                            </Card>
                            <Card style={_getNumberStyling(8)} background={CARD_COLORS.BLACK} width={CARD_WIDTH}>
                                <img src={black} alt="Blue" />
                            </Card>
                        </RouletteItem>)}
                </RouletteWheel>
            </RouletteWrapper>
        </Container>;
};
export default Game;