import React from "react";
import styled from "styled-components";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Balances from "./Balances";
import User from "./User";
import { toggleChat } from "../../store/actions/site";
import { HEADER_HEIGHT } from "../../constants/ui";

// Icons
import { BsFillChatFill } from "react-icons/bs";

// Assets
import logo from "../../assets/v2/sweet-textlogo.webp";
const Header = ({
  showChat,
  isAuthenticated
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const redirectToHome = () => {
    history.push("/");
  };
  const handleToggleChat = () => {
    dispatch(toggleChat());
  };
  return <HeaderContainer isMobile={false}>
            <Logo src={logo} alt="Sweet Logo" onClick={redirectToHome} />
            {isAuthenticated && <Balances />}
            <RightContainer>
                <User />
                {!showChat && <>
                        <IconContainer onClick={handleToggleChat}>
                            <BsFillChatFill size="1.2rem" />
                        </IconContainer>
                    </>}
            </RightContainer>
        </HeaderContainer>;
};
const HeaderContainer = styled.header`
    height: ${HEADER_HEIGHT};
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    ${props => !props.isMobile && `
        position: sticky;
        top: 0;
        z-index: 100;
        background: ${props.theme.background.primary};
        border-bottom: 1px solid ${props.theme.border.primary};
    `}
`;
const Logo = styled.img`
    height: 40px;
    width: auto;
    cursor: pointer;
`;
const RightContainer = styled.div`
    display: flex;
    align-items: center;
`;
const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 25px;
    color: ${props => props.theme.colors.primary};

    cursor: pointer;
    transition: opacity 0.1s ease 0s;
    &:hover {
        opacity: 0.8;
    }
`;
const RaceImage = styled.img`
    height: 40px;
    width: auto;
    margin-right: 20px;

    cursor: pointer;
    transition: opacity 0.1s ease 0s;
    &:hover {
        filter: brightness(1.1);
    }
`;
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  showChat: state.site.showChat
});
export default connect(mapStateToProps)(Header);