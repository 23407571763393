import React from "react";
import Spritesheet from "react-responsive-spritesheet";

// Assets
import blueWin from "../../assets/coinflip/blue_win.png";
import redWin from "../../assets/coinflip/red_win.png";
const Animation = ({
  winner,
  setAnimationFinished
}) => {
  const animation = winner === 1 ? redWin : blueWin;
  return <Spritesheet image={animation} widthFrame={480} heightFrame={480} steps={51} fps={40} style={{
    zIndex: 3,
    marginTop: "60px",
    marginLeft: "15px",
    height: "240px",
    width: "240px"
  }} onPause={() => {
    setAnimationFinished(true);
  }} />;
};
export default Animation;