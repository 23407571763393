import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

// Components
import { SectionHeader, SlotLink } from "../views/Main";
import BREAKPOINTS from "../constants/breakpoints";

// Assets - Slots - Pragmatic
import BladeAndFangs from "../assets/v2/slots-cards/pragmatic/blade-and-fangs.webp";
import CastleOfFire from "../assets/v2/slots-cards/pragmatic/castle-of-fire.webp";
import GatesOfOlympus1000 from "../assets/v2/slots-cards/pragmatic/gates-of-olympus-1000.webp";
import GatesOfOlympus from "../assets/v2/slots-cards/pragmatic/gates-of-olympus.webp";
import SugarRush from "../assets/v2/slots-cards/pragmatic/sugar-rush.webp";
import SweetBonanza from "../assets/v2/slots-cards/pragmatic/sweet-bonanza.webp";
import TheAlterEgo from "../assets/v2/slots-cards/pragmatic/the-alter-ego.webp";
import TheBigDawgs from "../assets/v2/slots-cards/pragmatic/the-big-dawgs.webp";
import ZeusVsHades from "../assets/v2/slots-cards/pragmatic/zeus-vs-hades.webp";
import GreekGods from "../assets/v2/slots-cards/pragmatic/greek-gods.webp";
import TheDogHouseMegaways from "../assets/v2/slots-cards/pragmatic/the-dog-house-megaways.webp";

// Assets - Slots - Hacksaw
import TwoWildTwoDie from "../assets/v2/slots-cards/hacksaw/2-wild-2-die.webp";
import LeBandit from "../assets/v2/slots-cards/hacksaw/le-bandit.webp";
import WantedDeadOrAWild from "../assets/v2/slots-cards/hacksaw/wanted-dead-or-a-wild.webp";
import useMediaQuery from "../hooks/useMediaQuery";
const PopularSlots = () => {
  const history = useHistory();
  const isMobile = useMediaQuery(BREAKPOINTS.md);
  const handleViewAll = () => {
    history.push("/slots");
  };
  const handleSlotClick = slotId => {
    history.push(`/slots/${slotId}`);
  };
  return <>
            <SectionHeader>
                Popular Slots{" "}
                <ViewAll onClick={handleViewAll}>View All</ViewAll>
            </SectionHeader>
            <SlotsContainer>
                <SlotLink image={SweetBonanza} onClick={() => handleSlotClick(isMobile ? "f5470d59bedf4dfca216bb37f8c2e3ff" : "0b6e2e38d76c4a40bf6ab7235f92c5e7")} />
                <SlotLink image={WantedDeadOrAWild} onClick={() => handleSlotClick(isMobile ? "558122a7b7204983b4a4cad07cf4caab" : "858e6979d3ec4acc86048fe1b858c142")} />
                <SlotLink image={GatesOfOlympus} onClick={() => handleSlotClick(isMobile ? "260d4a6e9f99c91940517ac650330683f6f42408" : "5878c98bf96de5c3ecb4fa37ddb45928da161525")} />
                <SlotLink image={SugarRush} onClick={() => handleSlotClick(isMobile ? "a90e2dfc927a7af291051b192c0ab32376cc5c2d" : "d8f118e9bfd01ace4f417a8adf3c72845788a658")} />
                <SlotLink image={LeBandit} onClick={() => handleSlotClick(isMobile ? "1ebdd55f548c47cfad8cc8c6fb23433e" : "32e1d1aa2a824db2b0441dadf610d4d3")} />
                <SlotLink image={ZeusVsHades} onClick={() => handleSlotClick(isMobile ? "39a54043488743e1b2ba7b95cf26a9a1" : "517d2e03971d41f6875781c9d5bd4443")} />
                <SlotLink image={TwoWildTwoDie} onClick={() => handleSlotClick(isMobile ? "ce5a449f352e12d78d961cbc7854a50678c87101" : "8fcfb41100add080297e2ccf315842921dbe3312")} />
                <SlotLink image={TheBigDawgs} onClick={() => handleSlotClick(isMobile ? "baaa822aa29a4bd6891cb52c17949483" : "a51e3ca7c0fd4f2d944580a326e2e9f7")} />
                <SlotLink image={CastleOfFire} onClick={() => handleSlotClick(isMobile ? "b7882b4d66364f6e8562f1f2eae40f02" : "b8818304dfad4bb5ba532f3de4b2c351")} />
                <SlotLink image={TheAlterEgo} onClick={() => handleSlotClick(isMobile ? "d15126fb59b441079f11c761c7f634d9" : "14737dfe24ba4367af30764616a483c0")} />
                <SlotLink image={BladeAndFangs} onClick={() => handleSlotClick(isMobile ? "d7882e9cfe7d4262883d285814006e54" : "935c82ff0a344750b61ef4bd8875fa69")} />
                <SlotLink image={GatesOfOlympus1000} onClick={() => handleSlotClick(isMobile ? "fec310babd42482597380c8933cd761a" : "221101cf569449de93de210b3e0eadfa")} />
                <SlotLink image={GreekGods} onClick={() => handleSlotClick(isMobile ? "dc532c6ddb1e410b84a4d82bb1faa1dd" : "8e3b909218164e2281b744018ac8598f")} />
                <SlotLink image={TheDogHouseMegaways} onClick={() => handleSlotClick(isMobile ? "bc68834c779e4d00b757605f4e83aab9" : "b3a22c28284e4de69efb358940838cb1")} />
            </SlotsContainer>
        </>;
};
const SlotsContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(13%, 1fr));
    margin: 1rem 0;

    @media (max-width: ${BREAKPOINTS.md}) {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
`;
const ViewAll = styled.span`
    color: ${props => props.theme.colors.tertiary} !important;
    font-weight: 500;
    font-size: 12px !important;
    margin-left: 10px;

    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        filter: brightness(1.2);
    }
`;
export default PopularSlots;