import { getExchangeRates } from "../../services/api.service";
import {
    TOGGLE_NAVBAR,
    TOGGLE_CHAT,
    TOGGLE_BETSLIP,
    SWEET_TOKEN_DATA_UPDATE,
    SET_USERS_ONLINE,
    UPDATE_EXCHANGE_RATES,
    TOGGLE_SHOW_IN_FIAT,
    SET_IN_PLAY,
} from "./types";

export const toggleNavbar = () => (dispatch) => {
    dispatch({
        type: TOGGLE_NAVBAR,
    });
};

export const toggleChat = () => (dispatch) => {
    dispatch({
        type: TOGGLE_CHAT,
    });
};

export const toggleBetSlip = () => (dispatch) => {
    dispatch({
        type: TOGGLE_BETSLIP,
    });
};

export const updateSweetTokenData = (data) => (dispatch) => {
    dispatch({
        type: SWEET_TOKEN_DATA_UPDATE,
        payload: data,
    });
};

export const updateUsersOnline = (count) => (dispatch) => {
    dispatch({
        type: SET_USERS_ONLINE,
        payload: count,
    });
};

export const updateExchangeRates = (data) => (dispatch) => {
    dispatch({
        type: UPDATE_EXCHANGE_RATES,
        payload: data,
    });
};

export const loadExchangeRates = () => async (dispatch) => {
    try {
        const response = await getExchangeRates();
        dispatch(updateExchangeRates(response.data));
    } catch (error) {
        console.error("[EXCHANGE RATES ERROR]", error);
    }
};

export const toggleShowInFiat = () => (dispatch) => {
    dispatch({
        type: TOGGLE_SHOW_IN_FIAT,
    });
};

export const setInPlay = (data) => (dispatch) => {
    dispatch({
        type: SET_IN_PLAY,
        payload: data,
    });
};
