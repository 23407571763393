import { getAuthToken } from "@dynamic-labs/sdk-react-core";
import { getUser, getUserWallets } from "../../services/api.service";
import { authSocket, v2Socket } from "../../services/websocket.service";
import setAuthToken from "../../utils/setAuthToken";
import {
    USER_LOADED,
    WALLET_CHANGE,
    EVENT_KEYS_CHANGE,
    AUTH_ERROR,
    RELOADING_USER,
} from "./types";

// Load user from API
export const loadUser = () => async (dispatch) => {
    const dynamicJwtToken = getAuthToken();
    if (!dynamicJwtToken) {
        console.log("[AUTH] No token found");
        return dispatch({
            type: AUTH_ERROR,
        });
    }

    setAuthToken(dynamicJwtToken);
    v2Socket.setAuthToken(dynamicJwtToken);
    authSocket.emit("authenticate", { token: dynamicJwtToken });

    try {
        const [userResp, walletsResp] = await Promise.all([
            getUser(),
            getUserWallets(),
        ]);

        dispatch({
            type: USER_LOADED,
            payload: {
                ...userResp.data,
                wallets: walletsResp.data,
            },
        });
    } catch (error) {
        console.error("[AUTH ERROR]", error);
        dispatch({
            type: AUTH_ERROR,
        });
    }
};

// Reload User, don't authenticate sockets
export const reloadUser = () => async (dispatch) => {
    dispatch({
        type: RELOADING_USER,
    });

    try {
        const [userResp, walletsResp] = await Promise.all([
            getUser(),
            getUserWallets(),
        ]);

        dispatch({
            type: USER_LOADED,
            payload: {
                ...userResp.data,
                wallets: walletsResp.data,
            },
        });
    } catch (error) {
        console.error("[AUTH ERROR]", error);
        dispatch({
            type: AUTH_ERROR,
        });
    }
};

// Change wallet amount
export const changeWallet = (payload) => async (dispatch) => {
    dispatch({
        type: WALLET_CHANGE,
        payload,
    });
};

export const changeEventKeys =
    ({ eventKeys }) =>
    async (dispatch) => {
        dispatch({
            type: EVENT_KEYS_CHANGE,
            payload: eventKeys,
        });
    };
