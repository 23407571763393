import React from "react";
import styled from "styled-components";
import { GameIcon, getGameTitle } from "../GameHeader";
import { StyledNavItem } from "./Item";
import capitalize from "../../utils/capatlize";
const GameName = styled.span`
    width: 50%;
    margin-right: 32px;
    text-align: left;
    text-transform: uppercase;
    line-height: 1;
`;
const GameItem = ({
  root,
  game,
  disabled,
  isWide
}) => {
  const preventNavigation = e => {
    if (disabled) {
      e.preventDefault();
    }
  };
  return <StyledNavItem disabled={disabled} exact to={root} onClick={preventNavigation} activeClassName="active" isWide={isWide} data-tooltip-content={isWide ? "" : capitalize(game)} data-tooltip-id="default">
            <GameIcon game={game} height="20px" width="20px" />
            {isWide && <GameName>{getGameTitle(game)}</GameName>}
        </StyledNavItem>;
};
export default GameItem;