import {
    SWEET_TOKEN_DATA_UPDATE,
    TOGGLE_NAVBAR,
    TOGGLE_CHAT,
    TOGGLE_BETSLIP,
    SET_USERS_ONLINE,
    UPDATE_EXCHANGE_RATES,
    TOGGLE_SHOW_IN_FIAT,
    SET_IN_PLAY,
} from "../actions/types";

// Get persisted state from window local storage
const getPersistedState = (key) => {
    const persistedState = localStorage.getItem(key);
    if (!persistedState) {
        return null;
    }

    return persistedState === "true" ? true : false;
};

// Persist state in window local storage
const persistState = (key, value) => {
    localStorage.setItem(key, value);
};

const initialState = {
    usersOnline: "?",
    inPlay: false,
    showNavbar: getPersistedState("showNavbar") || true,
    showChat: getPersistedState("showChat") || false,
    showBetSlip: getPersistedState("showBetSlip") || false,
    sweet: {
        usdPrice: 0,
        dailyPriceChange: 0,
        marketCap: 0,
        dailyVolume: 0,
    },
    exchangeRates: null,
    showInFiat: getPersistedState("showInFiat") || true,
};

const site = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SWEET_TOKEN_DATA_UPDATE:
            // ? Check if sweet data hasnt changed to prevent rerendering
            if (JSON.stringify(state.sweet) === JSON.stringify(payload)) {
                return state;
            }

            return {
                ...state,
                sweet: payload,
            };
        case TOGGLE_NAVBAR:
            persistState("showNavbar", !state.showNavbar);
            return {
                ...state,
                showNavbar: !state.showNavbar,
            };
        case TOGGLE_CHAT:
            persistState("showChat", !state.showChat);
            return {
                ...state,
                showChat: !state.showChat,
            };
        case TOGGLE_BETSLIP:
            persistState("showBetSlip", !state.showBetSlip);
            return {
                ...state,
                showBetSlip: !state.showBetSlip,
            };
        case SET_USERS_ONLINE:
            return {
                ...state,
                usersOnline: payload,
            };
        case UPDATE_EXCHANGE_RATES:
            return {
                ...state,
                exchangeRates: payload,
            };
        case TOGGLE_SHOW_IN_FIAT:
            persistState("showInFiat", !state.showBetSlip);
            return {
                ...state,
                showInFiat: !state.showInFiat,
            };
        case SET_IN_PLAY:
            return {
                ...state,
                inPlay: payload,
            };
        default:
            return state;
    }
};

export default site;
