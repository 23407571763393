import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAffiliateData, getAffiliatedUsers, updateUserAffiliateCode, claimAffiliateEarnings } from "../services/api.service";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { toast } from "react-hot-toast";
import Moment from "react-moment";
import { v4 as uuidv4 } from "uuid";

// MUI Components
import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";

// Components
import Skeleton from "../components/Skeleton";
import GrowAnimation from "../components/GrowAnimation";
import CircularLoader from "../components/CircularLoader";
import PageContainer from "../components/PageContainer";
import BREAKPOINTS from "../constants/breakpoints";
import Button from "../components/Button";
import { getCurrencyById } from "../constants/currencies";
import Price from "../components/Price";

// Icons
import AffiliateCreate from "../assets/affiliate/create";
import AffiliateFriends from "../assets/affiliate/friends";
import AffilliateStats from "../assets/affiliate/stats";
import AffiliateWallet from "../assets/affiliate/wallet";
import AffiliateEarn from "../assets/affiliate/earn.webp";
const Affiliates = ({
  isAuthenticated,
  isLoading,
  user
}) => {
  const {
    setShowAuthFlow
  } = useDynamicContext();
  const [loading, setLoading] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [saving, setSaving] = useState(false);
  const [claiming, setClaiming] = useState(false);
  const [affiliateData, setAffiliateData] = useState(null);
  const [affiliatedUsers, setAffiliatedUsers] = useState([]);
  const [affiliateCode, setAffiliateCode] = useState("");
  const fetchData = async () => {
    setLoading(true);
    try {
      const resp = await getAffiliateData();
      console.log(resp);
      setAffiliateData(resp.data);
      setAffiliateCode(resp.data.affiliate_code);
      setLoading(false);
    } catch (error) {
      console.log("There was an error while loading user affiliates data:", error);
      toast.error("There was an error while loading user affiliates data. Please try again later!");
    }
  };
  const fetchAffiliatedUsers = async () => {
    setLoadingUsers(true);
    try {
      const resp = await getAffiliatedUsers();
      setAffiliatedUsers(resp.data);
      setLoadingUsers(false);
    } catch (error) {
      console.log(error);
      toast.error("There was an error while loading user affiliates data. Please try again later!");
    }
  };
  const saveAffiliateCode = async () => {
    if (!isAuthenticated) {
      setShowAuthFlow(true);
      return;
    }
    setSaving(true);
    try {
      const resp = await updateUserAffiliateCode(affiliateCode);
      console.log(resp);
      setSaving(false);
      toast.success("Successfully updated your affiliate code!");
    } catch (error) {
      setSaving(false);
      toast.error(error.message);
    }
  };
  const claimEarnings = async () => {
    setClaiming(true);
    try {
      let hasWalletWithBalance = false;
      affiliateData.earnings.forEach(wallet => {
        if (wallet.amount > 0) {
          hasWalletWithBalance = true;
        }
      });
      if (!hasWalletWithBalance) {
        setClaiming(false);
        toast.error("You don't have any earnings to claim");
        return;
      }
      const idempotencyKey = uuidv4();
      const response = await claimAffiliateEarnings(idempotencyKey);
      if (response.data.success === false) {
        setClaiming(false);
        toast.error("There was an error while claiming your earnings");
        return;
      }
      setClaiming(false);
      fetchData();
      toast.success("Successfully claimed affiliate earnings");
    } catch (error) {
      setClaiming(false);
      console.log(error);
      toast.error("There was an error while claiming your affiliate earnings. Please try again later!");
    }
  };
  useEffect(() => {
    if (isAuthenticated) {
      fetchData();
      fetchAffiliatedUsers();
    }
    if (!isAuthenticated) {
      setLoading(false);
    }
  }, [isAuthenticated]);
  return isLoading ? <Loader>
            <CircularLoader />
        </Loader> : <GrowAnimation duration="420ms">
            <PageContainer maxWidth="lg" style={{
      color: "#fff"
    }}>
                <EarnAsAffiliate>
                    <BannerTitle>
                        <span>EARN</span>AS AN AFFILIATE
                    </BannerTitle>
                    <div>
                        <BannerDescription>
                            Create your Affiliate Code and share with friends.
                        </BannerDescription>
                        <BannerDescription>
                            Receive 5% of the house edge from each user you
                            refer.
                        </BannerDescription>
                    </div>
                    <BannerButtonWrapper>
                        <Button onClick={() => {}}>LEARN MORE</Button>
                    </BannerButtonWrapper>
                </EarnAsAffiliate>
                <HowItWorksTitle>How it works?</HowItWorksTitle>
                <HowItWorksWrap>
                    <HowItWorksElement>
                        <Step>Step 1</Step>
                        <AffiliateCreate />
                        <HowItWorksDesc>Create your unique code</HowItWorksDesc>
                    </HowItWorksElement>
                    <HowItWorksElement>
                        <Step>Step 2</Step>
                        <AffiliateFriends />
                        <HowItWorksDesc>Invite friends to Sweet</HowItWorksDesc>
                    </HowItWorksElement>
                    <HowItWorksElement>
                        <Step>Step 3</Step>
                        <AffiliateWallet />
                        <HowItWorksDesc>
                            New user register and deposit
                        </HowItWorksDesc>
                    </HowItWorksElement>
                    <HowItWorksElement>
                        <Step>Step 4</Step>
                        <AffilliateStats />
                        <HowItWorksDesc>
                            Your earn from each user bet
                        </HowItWorksDesc>
                    </HowItWorksElement>
                </HowItWorksWrap>
                <SectionTitle>Affiliate Program</SectionTitle>
                <UserWrap>
                    <InputRows>
                        <TextField variant="outlined" fullWidth={true} label="YOUR AFFILIATE LINK" disabled={loading} value={loading ? "LOADING..." : affiliateCode !== "" ? `${window.location.origin}/a/${affiliateCode}` : "SET YOUR CODE TO GET YOUR LINK"} onFocus={e => {
            e.target.select();
            if (!isAuthenticated) {
              setShowAuthFlow(true);
              return;
            }
            if (affiliateCode === "") {
              return;
            }

            // Copy to clipboard
            navigator.clipboard.writeText(`${window.location.origin}/a/${affiliateCode}`);
            toast.success("Copied to clipboard!");
          }} />
                        <TextField variant="outlined" label="SET YOUR CODE" fullWidth={true} disabled={loading} value={affiliateCode} onChange={e => setAffiliateCode(e.target.value)} />
                        <Button disabled={saving || loading} onClick={saveAffiliateCode}>
                            {saving ? "SAVING..." : "SAVE"}
                        </Button>
                    </InputRows>
                </UserWrap>
                {isAuthenticated && <>
                        <StatsWrap>
                            <div className="stats">
                                TOTAL PAID OUT
                                <h1>
                                    {loading ? <Skeleton height="40px" width="150px" /> : affiliateData.paid_out.map(wallet => {
                return <Price amount={wallet.amount} currency={getCurrencyById(wallet.currency_id)} />;
              })}
                                </h1>
                            </div>
                            <div>
                                AFFILIATE USERS
                                <h1>
                                    {loading ? <Skeleton height="40px" width="150px" /> : affiliateData.total_affiliated_users}
                                </h1>
                            </div>
                            <div className="earnings">
                                <div style={{
              wordWrap: "break-word"
            }}>
                                    AVAILABLE EARNINGS
                                    <EarningsContainer>
                                        {loading ? <Skeleton height="40px" width="150px" /> : affiliateData.earnings.map(wallet => {
                  return <Price amount={wallet.amount} currency={getCurrencyById(wallet.currency_id)} />;
                })}
                                    </EarningsContainer>
                                </div>
                                <Button onClick={claimEarnings} disabled={claiming || loading}>
                                    {claiming ? "CLAIMING..." : "CLAIM"}
                                </Button>
                            </div>
                        </StatsWrap>
                        <SectionTitle>Affiliate Users</SectionTitle>
                        <Tran>
                            {loadingUsers ? <LoadingTable /> : affiliatedUsers.length > 0 ? <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Username</TableCell>
                                            <TableCell>Referred On</TableCell>
                                            <TableCell>
                                                Total Wager (USD)
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {affiliatedUsers.map(user => {
                return <TableRow key={user.username}>
                                                    <TableCell>
                                                        {user.username}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Moment format="DD/MM/YYYY HH:mm">
                                                            {user.referred_at}
                                                        </Moment>
                                                    </TableCell>
                                                    <TableCell>
                                                        {user.total_usd_wagered}
                                                    </TableCell>
                                                </TableRow>;
              })}
                                    </TableBody>
                                </Table> : <NoAffiliates>
                                    No one used your code yet. Share it to start
                                    earning!
                                </NoAffiliates>}
                        </Tran>
                    </>}
            </PageContainer>
        </GrowAnimation>;
};
const LoadingTable = () => {
  return <Table>
            <TableHead>
                <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell>Total Wager</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Array(5).fill().map((_, index) => <TableLoader key={index} />)}
            </TableBody>
        </Table>;
};
const TableLoader = () => {
  return <TableRow>
            <TableCell>
                <Skeleton height="25px" width="50px" />
            </TableCell>
            <TableCell>
                <Skeleton height="25px" width="50px" />
            </TableCell>
            <TableCell>
                <Skeleton height="25px" width="50px" />
            </TableCell>
            <TableCell>
                <Skeleton height="25px" width="100px" />
            </TableCell>
        </TableRow>;
};
Affiliates.propTypes = {
  isAuthenticated: PropTypes.bool,
  isLoading: PropTypes.bool
};
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading
});
export default connect(mapStateToProps)(Affiliates);
const Loader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45rem;
`;
const SectionTitle = styled.h1`
    color: ${({
  theme
}) => theme.colors.primary};
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.1em;
    margin: 0;
    margin-left: 5px;
    margin-bottom: 1rem;
`;
const HowItWorksTitle = styled.div`
    color: ${({
  theme
}) => theme.colors.primary};
    font-size: 18px;
    font-weight: 500;
`;
const NoAffiliates = styled.div`
    width: 100%;
    text-align: center;
    padding: 1rem 0;
    color: ${props => props.theme.colors.secondary};

    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.05em;
`;
const InputRows = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    @media (max-width: ${BREAKPOINTS.md}) {
        width: 100% !important;
        flex-direction: column;
    }
`;
const UserWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.07);
    background: ${props => props.theme.background.primary};
    backdrop-filter: blur(7.5px);
    border-radius: 10px;
    padding: 20px;
    height: fit-content;

    & input {
        color: ${({
  theme
}) => theme.colors.primary};
        font-family: "Rubik", sans-serif;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.05em;
    }

    & label {
        color: ${props => props.theme.colors.secondary};
        font-family: "Rubik", sans-serif;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.1em;
    }

    & label.Mui-focused {
        color: ${props => props.theme.colors.secondary};
    }

    & .MuiInput-underline:after {
        border: 1px solid ${props => props.theme.colors.secondary};
    }

    & .MuiOutlinedInput-root {
        & fieldset {
            border: 1px solid ${props => props.theme.colors.secondary};
        }
        &:hover fieldset {
            border: 1px solid ${props => props.theme.colors.secondary};
        }
        &.Mui-focused fieldset {
            border: 1px solid ${props => props.theme.colors.secondary};
        }
    }

    & > div {
        width: 100%;
    }

    @media (max-width: ${BREAKPOINTS.sm}) {
        flex-direction: column;

        & > div {
            width: 100%;
            margin-bottom: 20px;
        }
    }
`;
const StatsWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background: url(${AffiliateEarn}) center center / cover no-repeat
        rgb(26, 1, 45);
    padding: 50px;
    border-radius: 10px;
    gap: 15px;
    margin: 20px 0;

    @media (max-width: ${BREAKPOINTS.md}) {
        flex-direction: column;
    }

    & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 10px;
        border: 1px solid ${props => props.theme.border.primary};
        background: ${props => props.theme.background.primary};
        height: 7rem;
        padding: 0 2rem;
        color: ${props => props.theme.colors.secondary};
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.05em;

        @media (max-width: ${BREAKPOINTS.md}) {
            width: 100%;
        }

        & h1 {
            margin: 0;
            margin-top: 10px;
            color: ${({
  theme
}) => theme.colors.primary};
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0.05em;
        }

        &.earnings {
            flex-direction: row;
            align-items: center;
            gap: 1rem;
        }
    }
`;
const Tran = styled.div`
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.07);
    background: ${props => props.theme.background.primary};
    backdrop-filter: blur(7.5px);
    padding: 2rem;

    @media (max-width: ${BREAKPOINTS.sm}) {
        padding: 1rem;
    }

    & th {
        border-bottom: none;
        color: ${({
  theme
}) => theme.colors.primary};
        font-family: "Rubik", sans-serif;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        padding-left: 0;
    }

    & td {
        border-bottom: 1px solid ${props => props.theme.colors.secondary};
        color: ${props => props.theme.colors.secondary};
        font-family: "Rubik", sans-serif;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.05em;
        padding-left: 0;

        &:nth-child(1) {
            padding-left: 1rem;
        }
    }
`;
const EarningsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: ${({
  theme
}) => theme.colors.primary};
    margin-top: 10px;
`;

/* How it works */
const BannerTitle = styled.h1`
    color: ${({
  theme
}) => theme.colors.primary};
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 0px;

    span {
        color: ${({
  theme
}) => theme.colors.tertiary};
        margin-right: 8px;
    }
`;
const BannerDescription = styled.p`
    color: ${({
  theme
}) => theme.colors.secondary};
    font-size: 16px;
    font-weight: 500;
    margin: 0;
`;
const BannerButtonWrapper = styled.a`
    width: fit-content;
    margin-top: 20px;
`;
const HowItWorksWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 15px;
    margin-top: 20px;
    margin-bottom: 35px;
    width: 100%;

    @media (max-width: ${BREAKPOINTS.md}) {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
`;
const HowItWorksElement = styled.div`
    padding: 15px;
    border: 1px solid rgba(255, 255, 255, 0.07);
    background: ${props => props.theme.background.primary};
    border-radius: 10px;
    position: relative;
    width: 100%;
    text-align: center;
    text-weight: 700;
`;
const Step = styled.p`
    color: ${({
  theme
}) => theme.colors.secondary};
    position: absolute;
    transform: rotate(-90deg);
    top: 30px;
    left: 0px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.25em;
`;
const HowItWorksDesc = styled.p`
    color: ${({
  theme
}) => theme.colors.primary};
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.05em;
    margin: 0;
    text-align: center;
`;
const EarnAsAffiliate = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 30px 50px;
    gap: 5px;
    border-radius: 10px;
    background: url(${AffiliateEarn}) center center / cover no-repeat
        rgb(26, 1, 45);
    color: #fff;
    margin-bottom: 20px;
`;