import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const initialState = {};

const middleware = [thunk];

let store;

if (process.env.NODE_ENV === "production") {
    store = createStore(
        rootReducer,
        initialState,
        applyMiddleware(...middleware),
    );
} else {
    const { composeWithDevTools } = require("redux-devtools-extension");
    store = createStore(
        rootReducer,
        initialState,
        composeWithDevTools(applyMiddleware(...middleware)),
    );
}

export default store;
