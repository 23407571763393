import React from "react";
import styled from "styled-components";

// Components
import PageContainer from "../components/PageContainer";
const Banned = () => {
  return <PageContainer maxWidth="lg">
            <Content>
                <h1>YOU HAVE BEEN BANNED</h1>
                <span>
                    If you think this was an error, you can appeal via our Live
                    Chat.
                </span>
            </Content>
        </PageContainer>;
};
const Content = styled.div`
    height: 45rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.colors.secondary};

    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.005em;

    & h1 {
        color: ${({
  theme
}) => theme.colors.primary};
        font-size: 27px;
        font-weight: 500;
        letter-spacing: 0.1em;
    }
`;
export default Banned;