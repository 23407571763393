import { API, V2API } from "../services/api.service";

const setAuthToken = (token) => {
    if (token) {
        API.defaults.headers.common["x-auth-token"] = token;
        V2API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
        delete API.defaults.headers.common["x-auth-token"];
        delete V2API.defaults.headers.common["Authorization"];
    }
};

export default setAuthToken;
