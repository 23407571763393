import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { toast } from "react-hot-toast";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { getCrateBySlug, openCrate } from "../services/api.service";
import { Wheel, _generateWheelItems, _getRarityColor, _shuffleItems } from "../components/cases/Wheel";
import ItemFeed from "../components/cases/ItemFeed";
import useFetch from "../hooks/useFetch";

// MUI Components
import { Checkbox, FormControlLabel } from "@material-ui/core";

// Components
import Skeleton from "../components/Skeleton";
import GrowAnimation from "../components/GrowAnimation";
import PageContainer from "../components/PageContainer";
import OurButton, { COLOURS } from "../components/Button";
import { CURRENCIES } from "../constants/currencies";

// Assets
import error from "../assets/error.wav";
import Price from "../components/Price";
import eventKeys from "../assets/event-key.png";
import { ReactComponent as ExitIcon } from "../assets/v2/icons/exit.svg";
const errorAudio = new Audio(error);
const playSound = audioFile => {
  audioFile.play();
};
const _sortItemsByPrice = items => {
  return items.sort((a, b) => b.item.priceUSD - a.item.priceUSD);
};
const _cleanNumber = number => {
  let precision = 10; // Start with 10 decimal places
  return Number(number.toFixed(precision));
};
const getRandomWeightedItem = items => {
  const totalWeight = items.reduce((acc, item) => acc + item.weight, 0);
  let randomNum = Math.random() * totalWeight;
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (randomNum < item.weight) {
      return item;
    }
    randomNum -= item.weight;
  }
  return null;
};

// TODO: rename "game" to "case"
const Case = ({
  isAuthenticated,
  match,
  history,
  currency
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [game, setGame] = useState(null);
  const [bestOpenings, setBestOpenings] = useState([]);
  const [recentOpenings, setRecentOpenings] = useState([]);
  const [isOpening, setIsOpening] = useState(false);
  const [caseAmount, setCaseAmount] = useState(1);
  const fastOpeningRef = useRef(false);
  const [fastOpening, setFastOpening] = useState(false);
  const hasSpinnedRef = useRef(false);
  const childRef = useRef();
  const {
    isLoading: isLoadingCrate
  } = useFetch(() => getCrateBySlug(match.params.slug), {
    onSuccess: data => {
      setGame(data);
    }
  });
  const updateOpenings = item => {
    setRecentOpenings(prevItems => [item, ...prevItems]);
    if (item.priceUSD > 100) {
      setBestOpenings(prevItems => [item, ...prevItems]);
    }
  };

  // TODO: Logic for multi cases
  const gameResult = result => {
    const drawnItem = result[0].drawnItem.item;

    // If the wheel was spon before, reset it
    if (hasSpinnedRef.current) {
      // shuffle items again
      wheelItemsRef.current = _shuffleItems(wheelItemsRef.current);

      // reset wheel
      childRef.current.resetWheel();
      setTimeout(() => {
        childRef.current.spinWheel(drawnItem, fastOpeningRef.current);
      }, 1000);
    } else {
      hasSpinnedRef.current = true;
      childRef.current.spinWheel(drawnItem, fastOpeningRef.current);
    }
  };
  const gameError = msg => {
    setIsOpening(false);
    toast.error(msg);
    playSound(errorAudio);
  };
  const onClickBack = () => {
    history.push("/crates");
  };
  const handleFastSpinChange = e => {
    setFastOpening(e.target.checked);
    fastOpeningRef.current = e.target.checked;
  };
  const openCase = async () => {
    try {
      if (!isAuthenticated) {
        toast.error("You need to be logged in to open a case!");
        return;
      }
      setIsOpening(true);
      const idempotencyKey = uuidv4();
      const resp = await openCrate(game.slug, currency.id, caseAmount, idempotencyKey);
      console.log(resp.data);
      setIsOpening(false);
      for (const item of resp.data.items) {
        gameResult([{
          drawnItem: item
        }]); // Pass item to gameResult
      }
    } catch (error) {
      console.error(error);
      gameError("There was an error while opening the case, please try again later!");
      setIsOpening(false);
    }
  };
  console.log(game);
  const demoSpin = () => {
    if (!isOpening) {
      setIsOpening(true);
      const randomItem = getRandomWeightedItem(game.items);
      gameResult([{
        drawnItem: randomItem.item
      }]);
    }
  };
  const wheelItemsRef = useRef([]);

  // TODO: update that to use slug

  useEffect(() => {
    if (isLoadingCrate) {
      return;
    }
    wheelItemsRef.current = _generateWheelItems(game.items);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCrate]);
  return <PageContainer maxWidth="lg" header={<Header>
                    <ReturnToCrates onClick={onClickBack} data-tooltip-content="Return to Crates" data-tooltip-id="default" />
                    {!game ? <Skeleton width="150px" height="40px" /> : <>
                            <CaseImage src={game.imageUrl} alt="Case Image" />
                            <CaseName>{game.name}</CaseName>
                        </>}
                </Header>}>
            {!isLoading ? <GrowAnimation duration="620ms">
                    <Wheel margin="20px 0 0 0" ref={childRef} items={wheelItemsRef.current} setIsOpening={setIsOpening} updateOpenings={updateOpenings} />
                    <ActionsWrapper>
                        <ActionsInnerContainer>
                            {/*  <Counter
                                    count={caseAmount}
                                    setCount={setCaseAmount}
                                />  */}
                            <OpenCase // TODO: clean this up
          disabled={!isAuthenticated || isOpening} onClick={openCase} colour={COLOURS.primary}>
                                <InnerOpenCaseContainer>
                                    OPEN FOR
                                    {game.eventCrate ? <>
                                            <div>1</div>
                                            <img src={eventKeys} alt="Event Key" style={{
                  height: "20px",
                  width: "auto"
                }} />
                                        </> : <Price amount={game.priceUSD} currency={CURRENCIES.USD} textSize="13px" imageSize="17px" textWeight={600} />}
                                </InnerOpenCaseContainer>
                            </OpenCase>
                            <DemoSpin onClick={demoSpin} colour={COLOURS.secondary}>
                                Demo Spin
                            </DemoSpin>
                            <StyledFormControlLabel control={<Checkbox checked={fastOpening} onChange={handleFastSpinChange} name="fastSpin" />} label="Fast Opening" />
                        </ActionsInnerContainer>
                    </ActionsWrapper>
                    <ItemFeed bestOpenings={bestOpenings} recentOpenings={recentOpenings} />
                    <ItemsTitle>Items In This Crate</ItemsTitle>
                    <CaseItemsContainer>
                        {_sortItemsByPrice(game.items).map((item, i) => <CaseItem key={i}>
                                <RarityHighlight style={{
            background: _getRarityColor(item.item.priceUSD)
          }} />
                                <ItemChance>
                                    {_cleanNumber(item.weight * 100)}%
                                </ItemChance>
                                <ItemImage src={item.item.imageUrl} alt={item.item.name} />
                                <ItemName>{item.item.name}</ItemName>
                                <ItemPrice>
                                    {item.item.isEventCrateKey ? <>Unlocks Event Crate</> : <Price amount={item.item.priceUSD} currency={CURRENCIES.USD} textSize="14px" />}
                                </ItemPrice>
                            </CaseItem>)}
                    </CaseItemsContainer>
                    <DisclaimerText>
                        Disclaimer: All items are virtual and get paid out to
                        your account balance.
                    </DisclaimerText>
                </GrowAnimation> : <>
                    <Skeleton height="150px" width="100%" style={{
        marginTop: "5%"
      }} />
                    <Skeleton height="400px" width="100%" style={{
        marginTop: "30px"
      }} />
                </>}
        </PageContainer>;
};
Case.propTypes = {
  isAuthenticated: PropTypes.bool
};
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  currency: state.currency.currency
});
export default connect(mapStateToProps)(Case);
const ActionsWrapper = styled.div`
    margin: 30px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
const ActionsInnerContainer = styled.div`
    display: flex;
    padding: 0.8rem 1rem;
    border: 1px solid rgba(255, 255, 255, 0.07);
    background: ${props => props.theme.background.primary};
    backdrop-filter: blur(7.5px);
    border-radius: 10px;
    gap: 10px;
`;
const ItemsTitle = styled.div`
    color: white;

    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 25px;
    padding-bottom: 10px;
    border-bottom: 2px solid ${props => props.theme.border.primary};
`;
const OpenCase = styled(OurButton)`
    margin-right: 10px;
`;
const InnerOpenCaseContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;
const DemoSpin = styled(OurButton)`
    margin-left: 10px;
`;
const CaseItemsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 40px;
`;
const CaseItem = styled.div`
    width: 190px;
    color: ${({
  theme
}) => theme.colors.primary};
    font-size: 14px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.07);
    background: ${props => props.theme.background.primary};
    backdrop-filter: blur(7.5px);
    border-radius: 10px;
    padding: 15px;
    margin: 0 15px 15px 0;
`;
const RarityHighlight = styled.div`
    position: absolute;
    top: 0;
    border-radius: 0px 0px 5px 5px;
    height: 5px;
    width: 60%;
`;
const ItemChance = styled.p`
    color: ${props => props.theme.colors.secondary};
    font-size: 12px;
    font-weight: 600;
    margin-right: auto;
`;
const ItemImage = styled.img`
    width: 90px;
    height: auto;
`;
const ItemName = styled.p`
    width: 100%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
`;
const ItemPrice = styled.div`
    background: ${props => props.theme.background.secondary};
    padding: 7px 10px;
    border-radius: 5px;
    width: 100%;
    text-align: center;
    font-size: 12px;
    text-wrap: nowrap;
`;
const Header = styled.div`
    padding-bottom: 10px;
    border-bottom: 1px solid ${({
  theme
}) => theme.border.primary};
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
`;
const CaseImage = styled.img`
    height: 55px;
    width: auto;
`;
const CaseName = styled.div`
    color: ${({
  theme
}) => theme.colors.primary};
    font-size: 18px;
    font-weight: 500;

    text-transform: uppercase;
    margin-left: -5px;
`;
const StyledFormControlLabel = styled(FormControlLabel)`
    margin-left: 5px;

    & .MuiSvgIcon-root {
        color: ${({
  theme
}) => theme.colors.secondary};
    }

    & .MuiFormControlLabel-label {
        color: ${({
  theme
}) => theme.colors.secondary};
        font-weight: 700;
        letter-spacing: 0.1em;
        font-size: 12px;
    }

    & .MuiCheckbox-colorSecondary:hover {
        background-color: transparent;
        color: ${({
  theme
}) => theme.colors.secondary};
    }

    & .MuiCheckbox-colorSecondary.Mui-checked {
        background-color: transparent;
        color: ${({
  theme
}) => theme.colors.secondary};
    }

    & .MuiCheckbox-colorSecondary.Mui-checked:hover {
        background-color: transparent;
        color: ${({
  theme
}) => theme.colors.secondary};
    }
`;
const DisclaimerText = styled.div`
    margin-top: 10px;
    text-align: center;
    color: ${({
  theme
}) => theme.colors.secondary};
`;
const ReturnToCrates = styled(ExitIcon)`
    color: ${({
  theme
}) => theme.colors.secondary};
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
        filter: brightness(1.1);
    }
`;