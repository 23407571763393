import React, { useState } from "react";
import styled from "styled-components";
import { toast } from "react-hot-toast";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withdrawCrypto } from "../../../services/api.service";
import BREAKPOINTS from "../../../constants/breakpoints";
import Button from "../../Button";

// MUI Components
import { TextField } from "@material-ui/core";
import error from "../../../assets/error.wav";
import { CURRENCIES } from "../../../constants/currencies";
const errorAudio = new Audio(error);
const playSound = audioFile => {
  audioFile.play();
};
const Sweet = ({
  user
}) => {
  const [withdrawing, setWithdrawing] = useState(false);
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState("");

  // TextField onChange event handler
  const addressOnChange = e => {
    setAddress(e.target.value);
  };

  // TextField onChange event handler
  const amountOnChange = e => {
    setAmount(e.target.value);
  };
  const onClick = async () => {
    setWithdrawing(true);
    try {
      const response = await withdrawCrypto("sweet", address, parseFloat(amount));

      // Update state
      setWithdrawing(false);

      // Check transaction status
      if (response.state === 1) {
        toast.success(`Successfully withdrawed ${parseFloat(amount)} Sweet! Your withdrawal should manual confirm within a few minutes!`);
      } else {
        toast.success(`Successfully withdrawed ${parseFloat(amount)} Sweet! Your withdrawal should manual confirm within a few minutes!`);
      }
    } catch (error) {
      setWithdrawing(false);
      toast.error(error.message);
      playSound(errorAudio);
    }
  };
  const onMaxClick = () => {
    let ethWallet = user.wallets[CURRENCIES.SWEET.id]["play"];
    setAmount(ethWallet.amount / 10 ** CURRENCIES.SWEET.decimalPlaces);
  };
  return <ContentWrapper>
            <Text>
                Withdraw your desired amount of Sweet ($SWEET).
                <br />
                Minimum withdraw amount is 1000 SWEET
            </Text>
            <Inputs>
                <TextField label="YOUR ETHEREUM ADDRESS" variant="outlined" value={address} onChange={addressOnChange} />
                <Value>
                    <TextField label="MIN. 1000" variant="outlined" value={amount} onChange={amountOnChange} />
                    <Button style={{
          marginRight: "10px"
        }} onClick={onMaxClick}>
                        MAX
                    </Button>
                </Value>
            </Inputs>
            <Button style={{
      marginTop: "1rem"
    }} onClick={onClick} disabled={withdrawing}>
                {withdrawing ? "Withdrawing..." : "Request Withdrawal"}
            </Button>
        </ContentWrapper>;
};
Sweet.propTypes = {
  user: PropTypes.object
};
const mapStateToProps = state => ({
  user: state.auth.user,
  sweet: state.site.sweet
});
export default connect(mapStateToProps)(Sweet);
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 15px;

    & > div {
        & label {
            color: ${({
  theme
}) => theme.colors.primary};

            font-size: 15px;
            font-weight: 300;
        }

        & label.Mui-focused {
            color: ${({
  theme
}) => theme.colors.primary};
        }

        & .MuiInput-underline:after {
            border-radius: 6px;
            border-color: ${props => props.theme.border.primary};
        }

        & .MuiOutlinedInput-root {
            & fieldset {
                border-radius: 6px;
                border-color: ${props => props.theme.border.primary};
            }

            &:hover fieldset {
                border-radius: 6px;
                border-color: ${props => props.theme.border.primary};
            }

            &.Mui-focused fieldset {
                border-radius: 6px;
                border-color: ${props => props.theme.border.primary};
            }
        }
    }
`;
const Inputs = styled.div`
    position: relative;
    margin-top: 15px;

    & > div {
        width: 100%;

        & > div > input {
            width: 70%;
            font-weight: 500;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: ${({
  theme
}) => theme.colors.primary};
        }
    }

    & button {
        position: absolute;
        right: 0;
        top: 0.5rem;
        width: 6rem;
    }
`;
const Text = styled.div`
    color: ${({
  theme
}) => theme.colors.secondary} !important;
`;
const Value = styled.div`
    position: relative;
    width: 100%;
    color: #cfcfd0;
    margin-top: 15px;

    @media (max-width: ${BREAKPOINTS.sm}) {
        width: 100% !important;
        color: #cfcfd0 !important;
    }

    & > div {
        width: 100%;
        color: #cfcfd0;

        & > div {
            color: #cfcfd0;

            & > input {
                color: #cfcfd0;
                width: 70%;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
`;