import styled from "styled-components";
const Stats = () => <SVGWrapper>
    <svg viewBox="0 0 2000 2000" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M696.55 1061.57C660.02 1061.57 630.4 1091.19 630.4 1127.72V1163.65C630.4 1171.92 637.1 1178.62 645.37 1178.62H747.72C755.99 1178.62 762.69 1171.92 762.69 1163.65V1127.72C762.7 1091.19 733.09 1061.57 696.55 1061.57Z" fill="currentColor" />
        <path d="M872.85 839.09C836.32 839.09 806.7 868.71 806.7 905.24V1156.6C806.7 1168.76 816.56 1178.62 828.72 1178.62H916.97C929.13 1178.62 938.99 1168.76 938.99 1156.6V905.24C939 868.71 909.39 839.09 872.85 839.09Z" fill="currentColor" />
        <path d="M1053.31 839.09C1016.78 839.09 987.16 868.71 987.16 905.24V1156.6C987.16 1168.76 997.02 1178.62 1009.18 1178.62H1097.43C1109.59 1178.62 1119.45 1168.76 1119.45 1156.6V905.24C1119.46 868.71 1089.85 839.09 1053.31 839.09Z" fill="currentColor" />
        <path d="M1231.41 686.89C1195.75 686.89 1166.84 715.8 1166.84 751.46V1152.12C1166.84 1166.76 1178.71 1178.62 1193.34 1178.62H1272.63C1287.27 1178.62 1299.13 1166.75 1299.13 1152.12V754.62C1299.14 717.21 1268.82 686.89 1231.41 686.89Z" fill="currentColor" />
    </svg>
</SVGWrapper>;
export default Stats;
const SVGWrapper = styled.div`
    width: 100%;
    color: ${({
  theme
}) => theme.colors.tertiary};
`;