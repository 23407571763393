import React from "react";
import styled, { keyframes } from "styled-components";
const GrowAnimation = ({
  children,
  duration,
  style
}) => {
  return <GrowContainer duration={duration} style={style}>
            {children}
        </GrowContainer>;
};
const growAnimation = keyframes`
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;
const GrowContainer = styled.div`
    animation: ${growAnimation} ${props => props.duration}
        cubic-bezier(0.4, 0, 0.2, 1);
`;
export default GrowAnimation;