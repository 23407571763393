import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { NavLink as Link } from "react-router-dom";
import PropTypes from "prop-types";
import { toast } from "react-hot-toast";
import RecaptchaV3 from "react-google-recaptcha3";
import { TransitionGroup } from "react-transition-group";
import { getActiveCoinflipGames } from "../services/api.service";
import { coinflipSocket } from "../services/websocket.service";

// MUI Components
import { History as HistoryIcon } from "@material-ui/icons";

// Components
import Game from "../components/coinflip/GameList";
import ListItemsSkeleton from "../components/ListItemsSkeleton";
import Color from "../components/coinflip/SidePick";
import TokenInput from "../components/TokenInput";
import GameHeader from "../components/GameHeader";
import GrowAnimation from "../components/GrowAnimation";

// Assets
import error from "../assets/error.wav";
import PageContainer from "../components/PageContainer";
import BREAKPOINTS from "../constants/breakpoints";
import Button from "../components/Button";
const errorAudio = new Audio(error);
const playSound = audioFile => {
  audioFile.play();
};
const PageLayout = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    @media (max-width: ${BREAKPOINTS.lg}) {
        flex-direction: column;
    }
`;
const NoGames = styled.div`
    display: flex;
    flex-direction: column;
    height: 40rem;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.colors.secondary};

    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1em;
`;
const Games = styled.div`
    width: 55%;
    @media (max-width: ${BREAKPOINTS.md}) {
        width: 100%;
        margin-top: 30px;
    }
`;
const GamesContainer = styled.div`
    width: 100%;
    height: 50rem;
    overflow-y: auto;
`;
const BetButtons = styled.div`
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px;
`;
const Controls = styled.div`
    width: 45%;
    max-width: 400px;
    height: fit-content;
    background: ${props => props.theme.background.primary};
    border: 1px solid ${props => props.theme.background.primary};
    padding: 1.5rem;
    border-radius: 10px;
    margin-right: 35px;
    @media (max-width: ${BREAKPOINTS.md}) {
        width: 100%;
        margin-right: 0px;
        max-width: 100%;
    }
`;
const MultiplierButton = styled.div`
    background-color: ${({
  theme
}) => theme.background.secondary};
    box-shadow: none;
    color: ${({
  theme
}) => theme.colors.secondary};

    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.1px;
    margin-right: 10px;
    padding: 3px 12px;
    line-height: 1.75;
    min-width: 60px;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    border-radius: 4px;
    &:hover {
        opacity: 0.9;
        background-color: ${({
  theme
}) => theme.background.secondary};
        box-shadow: none;
    }
`;
const Label = styled.label`
    color: ${({
  theme
}) => theme.colors.secondary};
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-transform: uppercase;
`;
const GamesHistory = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: ${props => props.theme.background.primary};
    backdrop-filter: blur(2.5px);
    padding: 7px;
    cursor: pointer;
    color: ${({
  theme
}) => theme.colors.secondary};
    margin-left: auto;
`;
const Coinflip = ({
  isAuthenticated,
  user,
  match,
  history,
  sweet
}) => {
  const [loading, setLoading] = useState(true);
  const [creating, setCreating] = useState(false);
  const [games, setGames] = useState([]);
  const [betAmount, setBetAmount] = useState("0");
  const [color, setColor] = useState("red");
  const onChange = value => {
    setBetAmount(value);
  };
  const colorOnChange = color => {
    setColor(color);
  };

  // Add new game
  const addGame = game => {
    setCreating(false);
    setGames(state => state ? [game, ...state] : null);
  };
  const onClick = async () => {
    setCreating(true);
    const reCaptcha = await RecaptchaV3.getToken({
      action: "create_coinflip_game"
    });
    coinflipSocket.emit("create-new-game", color, parseFloat(betAmount), reCaptcha);
  };

  // -- Websocket Events

  // Handle player join event
  const gameJoined = data => {
    const {
      _id,
      newPlayer
    } = data;

    // Update State
    setGames(state => state.map(game => game._id === _id ? {
      ...game,
      players: [...game.players, newPlayer]
    } : game));
  };

  // Handle game end event
  const gameRolled = newData => {
    // Update State
    setTimeout(() => {
      setGames(state => state.map(game => game._id === newData._id ? {
        ...game,
        ...newData,
        status: 3
      } : game));
    }, 1000);

    // Wait for the animation
    setTimeout(() => {
      // Make the game disabled
      setGames(state => state.map(game => game._id === newData._id ? {
        ...game,
        ended: true
      } : game));
    }, 18500);
  };

  // Handle game rolling event
  const gameRolling = gameId => {
    // Update State
    setGames(state => state.map(game => game._id === gameId ? {
      ...game,
      status: 2
    } : game));
  };

  // Handle game creation success event
  const successRedirect = gameId => {
    history.push(`/coinflip/${gameId}`);
  };

  // Handle game creation error event
  const creationError = msg => {
    setCreating(false);
    toast.error(msg);
    playSound(errorAudio);
  };
  useEffect(() => {
    // Fetch active games from the API
    const fetchData = async () => {
      setLoading(true);
      try {
        const games = await getActiveCoinflipGames();
        setGames(games);
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.error("There was an error while loading games data, please try again later!");
      }
    };

    // There was an error while joining a game
    const joinError = msg => {
      toast.error(msg);
      playSound(errorAudio);
    };

    // Fetch games when component mounts
    fetchData();

    // Listeners
    coinflipSocket.on("game-creation-error", creationError);
    coinflipSocket.on("game-creation-success", successRedirect);
    coinflipSocket.on("new-coinflip-game", addGame);
    coinflipSocket.on("game-joined", gameJoined);
    coinflipSocket.on("game-rolled", gameRolled);
    coinflipSocket.on("game-rolling", gameRolling);
    coinflipSocket.on("game-join-error", joinError);
    coinflipSocket.on("game-join-success", successRedirect);
    return () => {
      coinflipSocket.off("game-creation-error", creationError);
      coinflipSocket.off("game-creation-success", successRedirect);
      coinflipSocket.off("new-coinflip-game", addGame);
      coinflipSocket.off("game-joined", gameJoined);
      coinflipSocket.off("game-rolled", gameRolled);
      coinflipSocket.off("game-rolling", gameRolling);
      coinflipSocket.off("game-join-error", joinError);
      coinflipSocket.off("game-join-success", successRedirect);
    };
    // eslint-disable-next-line
  }, [match.params]);
  return <PageContainer maxWidth="lg" header={<GameHeader game="coinflip">
                    <GamesHistory exact to="/coinflip/history" data-tooltip-content="Check Game History" data-tooltip-id="default" data-tooltip-place="bottom">
                        <HistoryIcon style={{
        cursor: "pointer"
      }} />
                    </GamesHistory>
                </GameHeader>}>
            <GrowAnimation duration="820ms">
                <PageLayout>
                    <Controls>
                        <Label>BET AMOUNT</Label>
                        <TokenInput onChange={onChange} value={betAmount} fiatPrice={sweet.usdPrice} margin="5px 0 0 0" />
                        <BetButtons>
                            <MultiplierButton onClick={() => setBetAmount(state => parseFloat(state) / 2)}>
                                1/2
                            </MultiplierButton>
                            <MultiplierButton onClick={() => setBetAmount(state => parseFloat(state) * 2)}>
                                2x
                            </MultiplierButton>
                            <MultiplierButton onClick={() => setBetAmount(user.wallet)}>
                                Max
                            </MultiplierButton>
                        </BetButtons>
                        <Color value={color} onChange={colorOnChange} />
                        <Button onClick={onClick} disabled={!isAuthenticated || creating} style={{
            width: "100%"
          }}>
                            {creating ? "Starting..." : "START NEW GAME"}
                        </Button>
                    </Controls>
                    <Games>
                        {loading ? <ListItemsSkeleton length={5} height="100px" /> : games.length > 0 ? <GamesContainer>
                                <TransitionGroup>
                                    {games.map(game => <Game key={game._id} game={game} user={user} />)}
                                </TransitionGroup>
                            </GamesContainer> : <NoGames>NO CURRENTLY ACTIVE GAMES</NoGames>}
                    </Games>
                </PageLayout>
            </GrowAnimation>
        </PageContainer>;
};
Coinflip.propTypes = {
  user: PropTypes.object,
  isAuthenticated: PropTypes.bool
};
const mapStateToProps = state => ({
  user: state.auth.user,
  sweet: state.site.sweet,
  isAuthenticated: state.auth.isAuthenticated
});
export default connect(mapStateToProps)(Coinflip);