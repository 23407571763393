import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import styled from "styled-components";
import { getCratesSchema, getCrates } from "../services/api.service";
import { casesSocket } from "../services/websocket.service";
import useMediaQuery from "../hooks/useMediaQuery";
import BREAKPOINTS from "../constants/breakpoints";
import useFetch from "../hooks/useFetch";

// Components
import GameHeader from "../components/GameHeader";
import ItemFeed from "../components/cases/ItemFeed";
import Skeleton from "../components/Skeleton";
import GrowAnimation from "../components/GrowAnimation";
import CaseCard from "../components/cases/CaseCard";
import PageContainer from "../components/PageContainer";
const Cases = ({
  history
}) => {
  const isMobile = useMediaQuery(BREAKPOINTS.sm);
  const [loading, setLoading] = useState(true);
  const [cases, setCases] = useState([]);
  const [recentOpenings, setRecentOpenings] = useState([]);
  const [bestOpenings, setBestOpenings] = useState([]);
  const {
    isLoading: isLoadingCrates
  } = useFetch(getCrates, {
    onSuccess: data => {
      setCases(data);
    }
  });
  const newItemWon = item => {
    setRecentOpenings(prevItems => [item, ...prevItems]);
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const resp = await getCratesSchema();
        setBestOpenings(resp.data?.bestOpenings?.map(item => {
          return item?.item;
        }).filter(i => i));
        setRecentOpenings(resp.data?.recentOpenings?.map(item => {
          return item?.item;
        }).filter(i => i));
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.error("There was an error while loading crates, please try again later!");
        history.push("/crates");
      }
    };
    fetchData();
    casesSocket.on("new-item-won", newItemWon);
    return () => {
      casesSocket.off("new-item-won", newItemWon);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <PageContainer header={<GameHeader game="crates" />}>
            <ItemFeed bestOpenings={bestOpenings} recentOpenings={recentOpenings} />
            <GrowAnimation duration="620ms">
                <StyledGrid container spacing={4}>
                    {!loading && !isLoadingCrates ? <>
                            {cases.filter(x => x.isEventCrate) // filter out non-event crates
          .map((card, index) => <CaseCard key={index} crate={card} history={history} />)}
                            {cases.filter(x => !x.isEventCrate) // filter out event crates
          .sort((x, y) => x.priceUSD - y.priceUSD) // sort by price
          .map((card, index) => <CaseCard key={index} crate={card} history={history} />)}
                        </> : new Array(8).fill(0).map((_, index) => <Skeleton width={isMobile ? "90vw" : "250px"} height="250px" style={{
          margin: "15px",
          borderRadius: "10px"
        }} />)}
                </StyledGrid>
            </GrowAnimation>
        </PageContainer>;
};
export default Cases;
const StyledGrid = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .MuiGrid-item {
        padding: 15px;
    }
`;