import React from "react";
import styled from "styled-components";

// MUI Components
import { Dialog } from "@material-ui/core";
const BuyCrypto = ({
  open,
  handleClose
}) => {
  return <BuyCryptoDialog onClose={handleClose} open={open}>
            <iframe title="Buy Crypto" width="100%" height="600px" frameborder="0" allow="clipboard-read *; clipboard-write *; web-share *; accelerometer *; autoplay *; camera *; gyroscope *; payment *; geolocation *" src="https://flooz.xyz/embed/trade?swapDisabled=true&swapLockToToken=false&onRampDisabled=false&onRampAsDefault=false&onRampTokenAddress=eth&stakeDisabled=true&network=eth&lightMode=false&primaryColor=%23eb2584&backgroundColor=transparent&roundedCorners=8&padding=25&refId=8pWt8Y" />
        </BuyCryptoDialog>;
};
const BuyCryptoDialog = styled(Dialog)`
    & .MuiDialog-paperWidthSm {
        width: 50%;
        background: transparent;
        box-shadow: none;
    }
`;
export default BuyCrypto;