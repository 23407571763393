import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { toast } from "react-hot-toast";
import { getCrashSchema, getUserCrashData } from "../services/api.service";
import { crashSocket } from "../services/websocket.service";
import PropTypes from "prop-types";
import parseCommasToThousands from "../utils/parseCommasToThousands";

// MUI Components
import { TextField, InputAdornment, Switch } from "@material-ui/core";

// Icons
import { MonetizationOnOutlined, TrackChanges as TrackChangesIcon } from "@material-ui/icons";
import { AiOutlineInfoCircle } from "react-icons/ai";

// Components
import Bets from "../components/crash/Bets";
import CrashBox from "../components/crash/Crash";
import HistoryEntry from "../components/crash/HistoryEntry";
import GameHeader from "../components/GameHeader";
import { TimerBar } from "../components/TimerBar.jsx";
import TokenInput from "../components/TokenInput";
import GrowAnimation from "../components/GrowAnimation";
import PageContainer from "../components/PageContainer";
import placebet from "../assets/placebet.wav";
import error from "../assets/error.wav";
import success from "../assets/success.wav";
import crash from "../assets/crash.wav";
import BREAKPOINTS from "../constants/breakpoints.js";
import Button, { COLOURS } from "../components/Button.jsx";
const errorAudio = new Audio(error);
const placebetAudio = new Audio(placebet);
const successAudio = new Audio(success);
const crashAudio = new Audio(crash);
const playSound = audioFile => {
  audioFile.play();
};
const GameProvability = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: ${props => props.theme.background.primary};
    backdrop-filter: blur(2.5px);
    padding: 7px;
    cursor: pointer;
    color: ${({
  theme
}) => theme.colors.secondary};
`;

// Same game states as in backend
const GAME_STATES = {
  NotStarted: 1,
  Starting: 2,
  InProgress: 3,
  Over: 4,
  Blocking: 5,
  Refunded: 6
};
const BET_STATES = {
  Playing: 1,
  CashedOut: 2
};
const Crash = ({
  user,
  isAuthenticated,
  sweet
}) => {
  const [gameState, setGameState] = useState(1);
  const [gameId, setGameId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [joining, setJoining] = useState(false);
  const [betting, setBetting] = useState(false);
  const [plannedBet, setPlannedBet] = useState(false);
  const [ownBet, setOwnBet] = useState(null);
  const [autoCashoutEnabled, setAutoCashoutEnabled] = useState(false);
  const [autoBetEnabled, setAutoBetEnabled] = useState(false);
  const [cashedOut, setCashedOut] = useState(false);
  const [history, setHistory] = useState([]);
  const [players, setPlayers] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [payout, setPayout] = useState(1);
  const [betAmount, setBetAmount] = useState("0");
  const [lastBetAmount, setLastBetAmount] = useState("0");
  const [target, setTarget] = useState("2");
  const [privateHash, setPrivateHash] = useState(null);
  const [publicSeed, setPublicSeed] = useState(null);
  const [maxProfit, setMaxProfit] = useState(0);
  const clickBet = () => {
    if (parseFloat(betAmount) <= 0) return;
    if (gameState === GAME_STATES.Starting) {
      setJoining(true);

      // Emit new bet event
      crashSocket.emit("join-game", autoCashoutEnabled ? parseFloat(target) * 100 : null, parseFloat(betAmount));
      setLastBetAmount(betAmount);
    } else {
      if (plannedBet) {
        setPlannedBet(false);
      } else if (!autoBetEnabled) {
        setPlannedBet(true);
      }
    }
  };
  const clickCashout = () => {
    // Emit bet cashout
    crashSocket.emit("bet-cashout");
  };

  // TextField onChange event handler
  const onBetChange = value => {
    setBetAmount(value);
  };
  const onTargetChange = e => {
    setTarget(e.target.value);
  };
  const handleAutoCashoutChange = e => {
    if (!betting || cashedOut) setAutoCashoutEnabled(e.target.checked);
  };
  const handleAutoBetChange = e => {
    setAutoBetEnabled(e.target.checked);
    setPlannedBet(false);
  };

  // Add game to history
  const addGameToHistory = game => {
    setHistory(state => state.length >= 50 ? [...state.slice(1, state.length), game] : [...state, game]);
  };
  useEffect(() => {
    // Error event handler
    const joinError = msg => {
      setJoining(false);
      toast.error(msg);
      playSound(errorAudio);
    };

    // Success event handler
    const joinSuccess = bet => {
      setJoining(false);
      setOwnBet(bet);
      setBetting(true);
      toast.success("Successfully joined the game!");
      playSound(placebetAudio);
    };

    // Error event handler
    const cashoutError = msg => {
      toast.error(msg);
      playSound(errorAudio);
    };

    // Success event handler
    const cashoutSuccess = () => {
      toast.success("Successfully cashed out!");
      playSound(successAudio);

      // Reset betting state
      setTimeout(() => {
        setBetting(false);
      }, 1500);
    };

    // New round is starting handler
    const gameStarting = data => {
      // Update state
      setGameId(data._id);
      setStartTime(new Date(Date.now() + data.timeUntilStart));
      setGameState(GAME_STATES.Starting);
      setPrivateHash(data.privateHash);
      setPublicSeed(null);
      setPayout(1);
      setPlayers([]);
      setOwnBet(null);
      if (autoBetEnabled) {
        setJoining(true);

        // Emit new bet event
        crashSocket.emit("join-game", autoCashoutEnabled ? parseFloat(target) * 100 : null, parseFloat(betAmount));
      } else if (plannedBet) {
        setJoining(true);

        // Emit new bet event
        crashSocket.emit("join-game", autoCashoutEnabled ? parseFloat(target) * 100 : null, parseFloat(betAmount));

        // Reset planned bet
        setPlannedBet(false);
      }
    };

    // New round started handler
    const gameStart = data => {
      // Update state
      setStartTime(Date.now());
      setGameState(GAME_STATES.InProgress);
      setPublicSeed(data.publicSeed);
    };

    // Current round ended handler
    const gameEnd = data => {
      // Update state
      setGameState(GAME_STATES.Over);
      playSound(crashAudio);
      setPayout(data.game.crashPoint);
      addGameToHistory(data.game);
      setBetting(false);
      setCashedOut(false);
    };

    // ! clean this
    // Add new player to the current game
    const addNewPlayer = player => {
      setPlayers(state => [...state, player]);
    };

    // New game bets handler
    const gameBets = bets => {
      bets.forEach(addNewPlayer);
    };

    // New cashout handler
    const betCashout = bet => {
      // Check if local user cashed out
      if (bet.playerID === user._id) {
        setCashedOut(true);
        setOwnBet(Object.assign(ownBet, bet));

        // Reset betting state
        setTimeout(() => {
          setBetting(false);
        }, 1500);
      }

      // Update state
      setPlayers(state => state.map(player => player.playerID === bet.playerID ? Object.assign(player, bet) : player));
    };

    // Current round tick handler
    const gameTick = payout => {
      if (gameState !== GAME_STATES.InProgress) return;
      setPayout(payout);
    };

    // Listeners
    crashSocket.on("game-starting", gameStarting);
    crashSocket.on("game-start", gameStart);
    crashSocket.on("game-end", gameEnd);
    crashSocket.on("game-tick", gameTick);
    crashSocket.on("game-bets", gameBets);
    crashSocket.on("bet-cashout", betCashout);
    crashSocket.on("game-join-error", joinError);
    crashSocket.on("game-join-success", joinSuccess);
    crashSocket.on("bet-cashout-error", cashoutError);
    crashSocket.on("bet-cashout-success", cashoutSuccess);
    return () => {
      // Remove Listeners
      crashSocket.off("game-starting", gameStarting);
      crashSocket.off("game-start", gameStart);
      crashSocket.off("game-end", gameEnd);
      crashSocket.off("game-tick", gameTick);
      crashSocket.off("game-bets", gameBets);
      crashSocket.off("bet-cashout", betCashout);
      crashSocket.off("game-join-error", joinError);
      crashSocket.off("game-join-success", joinSuccess);
      crashSocket.off("bet-cashout-error", cashoutError);
      crashSocket.off("bet-cashout-success", cashoutSuccess);
    };
  }, [gameState, startTime, plannedBet, autoBetEnabled, autoCashoutEnabled, betAmount, target, ownBet, user]);
  useEffect(() => {
    // Fetch crash schema from API
    const fetchData = async () => {
      setLoading(true);
      try {
        const schema = await getCrashSchema();

        // Update state
        setGameId(schema.current._id);
        setPrivateHash(schema.current.privateHash);
        setPublicSeed(schema.current.publicSeed);
        setPlayers(schema.current.players);
        setStartTime(new Date(Date.now() - schema.current.elapsed));
        setHistory(schema.history.reverse());
        setLoading(false);
        setGameState(schema.current.status);
        setMaxProfit(schema.options.maxProfit);
      } catch (error) {
        console.log("There was an error while loading crash schema:", error);
      }
    };

    // Initially, fetch data
    fetchData();

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    // Fetch crash schema from API
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await getUserCrashData();

        // Update state
        if (data.bet && data.bet.status === BET_STATES.Playing) {
          setBetting(true);
          setOwnBet(data.bet);
        }
      } catch (error) {
        console.log("There was an error while loading crash schema:", error);
      }
    };

    // If user is signed in, check user data
    if (isAuthenticated) {
      fetchData();
    }
  }, [isAuthenticated]);
  return <PageContainer maxWidth="lg" header={<GameHeader game="crash">
                    <GrowAnimation duration="620ms" style={{
      marginLeft: "auto"
    }}>
                        <GameProvability data-tooltip-html={`Round ID: ${gameId} <br/> Private Hash: ${privateHash} <br/> Public Seed: ${publicSeed ? publicSeed : "Not created yet"}`} data-tooltip-id="default" data-tooltip-place="bottom">
                            <AiOutlineInfoCircle style={{
          fontSize: "1.5rem"
        }} />
                        </GameProvability>
                    </GrowAnimation>
                </GameHeader>}>
            <GrowAnimation duration="820ms">
                {!loading && gameState === GAME_STATES.Starting && <TimerBar maxDuration={9000} waitTime={startTime} gameStates={GAME_STATES} updateGameState={state => setGameState(state)} />}
                <History>
                    {history.map(game => <HistoryEntry key={game._id} game={game} />)}
                </History>
                <Contain>
                    <Game>
                        <StyledCrash>
                            <MaxProfit data-tooltip-content={`$${parseCommasToThousands(maxProfit, true)} Max Profit`} data-tooltip-id="default" data-tooltip-place="bottom">
                                <MonetizationOnOutlined style={{
                fontSize: 20
              }} />
                            </MaxProfit>
                            <CrashBox loading={loading} multiplier={payout} ownBet={ownBet} gameState={gameState} />
                        </StyledCrash>
                        <PlaceBet>
                            <Title>BET AMOUNT</Title>
                            <BetContainer>
                                <div style={{
                marginBottom: "5px",
                width: "100%"
              }}>
                                    <TokenInput value={betAmount} onChange={onBetChange} fiatPrice={sweet.usdPrice} />
                                </div>
                                <BetControllButton colour={COLOURS.secondary} onClick={() => setBetAmount(lastBetAmount)}>
                                    Last
                                </BetControllButton>
                                <BetControllButton colour={COLOURS.secondary} onClick={() => setBetAmount(state => parseFloat(state) / 2 || 0)}>
                                    1/2
                                </BetControllButton>
                                <BetControllButton colour={COLOURS.secondary} onClick={() => setBetAmount(state => parseFloat(state) * 2 || 0)}>
                                    2x
                                </BetControllButton>
                                <BetControllButton colour={COLOURS.secondary} onClick={() => setBetAmount(user ? parseFloat(user.wallet) : 0)}>
                                    MAX
                                </BetControllButton>
                                <BetButtonWrapper>
                                    {!betting ? <Button disabled={!isAuthenticated || joining || autoBetEnabled} onClick={clickBet}>
                                            {joining ? "BETTING..." : plannedBet ? "CANCEL BET" : "PLACE BET"}
                                        </Button> : <Button colour={COLOURS.secondary} disabled={!isAuthenticated || gameState !== GAME_STATES.InProgress || cashedOut} onClick={clickCashout}>
                                            <span>
                                                {cashedOut ? "CASHED OUT" : "CASHOUT"}
                                            </span>
                                        </Button>}
                                </BetButtonWrapper>
                            </BetContainer>
                            <SplitTitle>AUTO CASHOUT</SplitTitle>
                            <SplitTitle>AUTO BET</SplitTitle>
                            <CashoutContainer>
                                <Switch color="primary" checked={autoCashoutEnabled} onChange={handleAutoCashoutChange} />
                                <TargetInput label="" variant="filled" value={target} onChange={onTargetChange} InputProps={{
                startAdornment: <StyledInputAdornment position="start">
                                                <TrackChangesIcon style={{
                    fontSize: 16
                  }} />
                                            </StyledInputAdornment>
              }} />
                            </CashoutContainer>
                            <AutoContainer>
                                <Switch color="primary" checked={autoBetEnabled} onChange={handleAutoBetChange} />
                            </AutoContainer>
                        </PlaceBet>
                    </Game>
                    <Bets players={players} loading={loading} />
                </Contain>
            </GrowAnimation>
        </PageContainer>;
};
Crash.propTypes = {
  user: PropTypes.object,
  isAuthenticated: PropTypes.bool
};
const mapStateToProps = state => ({
  user: state.auth.user,
  sweet: state.site.sweet,
  isAuthenticated: state.auth.isAuthenticated
});
export default connect(mapStateToProps)(Crash);
const History = styled.div`
    display: flex;
    max-width: 100%;
    align-items: center;
    justify-content: flex-end;
    mask-image: linear-gradient(240deg, rgba(0, 0, 0, 1) 34%, rgba(0, 0, 0, 0));
    -webkit-mask-image: linear-gradient(
        240deg,
        rgba(0, 0, 0, 1) 34%,
        rgba(0, 0, 0, 0)
    );
    overflow: hidden;
    margin-left: auto;
    padding-top: 1.5rem;
    margin-bottom: 35px;

    @media (max-width: ${BREAKPOINTS.sm}) {
        margin-bottom: 20px;
    }
`;
const Game = styled.div`
    display: flex;
    flex-direction: column;
    width: 60%;
    max-width: 600px;

    @media (max-width: ${BREAKPOINTS.sm}) {
        height: auto;
        width: 100%;
    }
`;
const StyledCrash = styled.div`
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 400px;
    max-height: 800px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid ${props => props.theme.border.primary};
    background: ${props => props.theme.background.primary};
    backdrop-filter: blur(7.5px);

    @media (max-width: ${BREAKPOINTS.sm}) {
        height: 400px;
    }
`;
const Contain = styled.div`
    display: flex;
    justify-content: center;
    gap: 1rem;

    @media (max-width: ${BREAKPOINTS.sm}) {
        flex-direction: column;
    }
`;
const MaxProfit = styled.div`
    position: absolute;
    top: 7.5px;
    right: 10px;
    color: ${props => props.theme.colors.secondary};
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.1em;
    z-index: 10;

    & span {
        display: flex;
        align-items: center;
        cursor: pointer;

        & svg {
            margin-right: 4px;
        }
    }
`;
const PlaceBet = styled.div`
    border-radius: 10px;
    border: 1px solid ${props => props.theme.border.primary};
    background: ${props => props.theme.background.primary};
    backdrop-filter: blur(7.5px);
    margin-top: 0.9rem;
    padding: 1rem;
`;
const BetButtonWrapper = styled.div`
    margin-left: auto;
    @media (max-width: ${BREAKPOINTS.sm}) {
        margin-left: 0;
        margin-top: 0.5rem;
    }
`;
const Title = styled.div`
    color: ${({
  theme
}) => theme.colors.secondary};
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.1em;
    line-height: 1;
`;
const BetContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 1rem 0;
    gap: 5px;
`;
const BetControllButton = styled.div`
    color: ${({
  theme
}) => theme.colors.secondary};
    background: ${({
  theme
}) => theme.background.secondary};
    border-radius: 5px;
    padding: 5px 12px;
    text-transform: uppercase;
    font-weight: 500;

    cursor: pointer;
    transition: 125ms ease;
    &:hover {
        filter: brightness(1.1);
    }
`;
const SplitTitle = styled.div`
    display: inline-block;
    color: ${({
  theme
}) => theme.colors.secondary};
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.1em;
    line-height: 1;
    width: 50%;
    margin-bottom: 0.5rem;
`;
const CashoutContainer = styled.div`
    display: inline-flex;
    width: 50%;
    color: #ffffff;
    justify-content: flex-start;
    align-items: center;
`;
const StyledInputAdornment = styled(InputAdornment)`
    margin-top: 0 !important;
    color: ${({
  theme
}) => theme.colors.tertiary} !important;
    background: transparent !important;
`;
const AutoContainer = styled.div`
    display: inline-flex;
    width: 50%;
    justify-content: flex-start;
    align-items: center;
`;
const TargetInput = styled(TextField)`
    width: 50%;
    border-radius: 5px;
    background: ${({
  theme
}) => theme.background.secondary};

    &::before {
        display: none;
    }

    &::after {
        display: none;
    }

    & div input {
        color: ${({
  theme
}) => theme.colors.primary};

        font-size: 14px;
        margin-left: -15px;
        font-weight: 500;
        letter-spacing: 0.1em;
        padding: 0.5rem 1rem;
    }

    &.MuiFilledInput-root.Mui-focused {
        background: ${({
  theme
}) => theme.background.secondary};
    }
`;