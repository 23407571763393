import React from "react";
import styled, { keyframes } from "styled-components";
import { AiOutlineInfoCircle } from "react-icons/ai";
import GameHeader from "../../components/GameHeader";
const HeaderButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row !important;
    gap: 15px;
    margin-left: auto;
`;
const growAnimation = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`;
const GameProvability = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: ${props => props.theme.background.primary};
    backdrop-filter: blur(2.5px);
    padding: 7px;
    cursor: pointer;
    animation: ${growAnimation} 620ms ease-in-out;
`;
const InfoCircleIcon = styled(AiOutlineInfoCircle)`
    font-size: 1.5rem;
    color: ${props => props.theme.colors.secondary};
`;
const Header = ({
  gameId,
  privateHash
}) => {
  return <GameHeader game="roulette">
            <HeaderButtonsContainer>
                <GameProvability data-tooltip-html={gameId ? `Round ID:  ${gameId}  <br /> Private Hash:  ${privateHash}` : "Round Not Started"} data-tooltip-id="default" data-tooltip-place="bottom">
                    <InfoCircleIcon />
                </GameProvability>
            </HeaderButtonsContainer>
        </GameHeader>;
};
export default Header;