import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import * as Popover from "@radix-ui/react-popover";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DynamicConnectButton, useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { getUserVipData } from "../../services/api.service";
import { getLevelBadge } from "../../utils/vip";
import BREAKPOINTS from "../../constants/breakpoints";

// Components
import VipModal from "../modals/VIPModal";
import StatisticsModal from "../modals/StatisticsModal";
import Skeleton from "../Skeleton";
import { PopoverArrow, PopoverClose } from "./Balances";
import useMediaQuery from "../../hooks/useMediaQuery";

// Assets
import { ReactComponent as Arrow } from "../../assets/v2/icons/arrow.svg";
const UserComponent = ({
  isLoading,
  isAuthenticated,
  user
}) => {
  const history = useHistory();
  const {
    handleLogOut
  } = useDynamicContext();
  const isMobile = useMediaQuery(BREAKPOINTS.md);
  const [loading, setLoading] = useState(true);
  const [vipData, setVipData] = useState(null);

  // TODO: could replace it by moving it to /user/me
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const {
          data
        } = await getUserVipData();
        setVipData(data);
        setLoading(false);
      } catch (error) {
        console.log("There was an error while loading user level data:", error);
      }
    };
    if (isAuthenticated) {
      fetchData();
    }
  }, [isAuthenticated]);

  // Redirect to profile page
  const redirectToSettings = () => {
    history.push("/user/settings");
    setMenuOpen(false);
  };
  const redirectToThemes = () => {
    history.push("/themes");
    setMenuOpen(false);
  };
  const redirectToAffiliate = () => {
    history.push("/affiliates");
    setMenuOpen(false);
  };
  const redirectToTransactions = () => {
    history.push("/user/transactions");
    setMenuOpen(false);
  };
  const handleVIP = () => {
    setVIPOpen(true);
    setMenuOpen(false);
  };
  const handleStatistics = () => {
    setStatisticsOpen(true);
    setMenuOpen(false);
  };
  const onLogout = () => {
    handleLogOut();
    setMenuOpen(false);
    window.location.reload();
  };
  const [vipOpen, setVIPOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [statisticsOpen, setStatisticsOpen] = useState(false);
  return <Fragment>
            <VipModal open={vipOpen} handleClose={() => setVIPOpen(false)} />
            <StatisticsModal user={user} open={statisticsOpen} handleClose={() => setStatisticsOpen(false)} />
            <UserWrapper>
                {isLoading ? <Skeleton height="40px" width={isMobile ? "60px" : "120px"} /> : isAuthenticated ? loading ? <Skeleton height="40px" width={isMobile ? "60px" : "120px"} /> : <Popover.Root open={menuOpen} onOpenChange={setMenuOpen}>
                            <Popover.Trigger asChild>
                                <UserContainer>
                                    <LevelIcon src={getLevelBadge(vipData.rank)} alt="Level Badge" />
                                    {!isMobile && <Username>{user.username}</Username>}
                                    <Arrow />
                                </UserContainer>
                            </Popover.Trigger>
                            <Popover.Portal>
                                <PopoverContent>
                                    <UserOptions>
                                        <UserOption onClick={handleVIP}>
                                            VIP
                                        </UserOption>
                                        <UserOption onClick={handleStatistics}>
                                            Statistics
                                        </UserOption>
                                        <UserOption onClick={redirectToTransactions}>
                                            Transactions
                                        </UserOption>
                                        <UserOption onClick={redirectToAffiliate}>
                                            Affiliate
                                        </UserOption>
                                        <UserOption onClick={redirectToThemes}>
                                            Themes
                                        </UserOption>
                                        <UserOption onClick={redirectToSettings}>
                                            Settings
                                        </UserOption>
                                        <Seperator />
                                        <UserOption onClick={onLogout}>
                                            Logout
                                        </UserOption>
                                    </UserOptions>
                                    <PopoverClose aria-label="Close" />
                                    <PopoverArrow />
                                </PopoverContent>
                            </Popover.Portal>
                        </Popover.Root> : <DynamicConnectButton buttonClassName="authButton">
                        LOGIN / REGISTER
                    </DynamicConnectButton>}
            </UserWrapper>
        </Fragment>;
};
const UserWrapper = styled.div`
    .authButton {
        text-transform: uppercase;
        padding: 12px;
        background: ${({
  theme
}) => theme.colors.tertiary};
        border: none;
        outline: none;
        border-radius: 5px;
        color: ${({
  theme
}) => theme.colors.primary};
        letter-spacing: 0.1em;
        line-height: 1;
        font-size: 12px;
        font-weight: 700;
        box-shadow: ${({
  theme
}) => theme.shadow && `0px 0px 15px 0px ${theme.shadow.tertiary}`};

        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover {
            filter: brightness(1.1);
        }
    }
`;
const PopoverContent = styled(Popover.Content)`
    border-radius: 10px;
    padding: 7px;
    width: 175px;
    border: 1px solid ${props => props.theme.border.primary};
    background: ${props => props.theme.background.primary};
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
`;
const UserContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    background: ${props => props.theme.background.secondary};
    border-radius: 8px;
    padding: 10px;
    gap: 5px;
`;
const UserOptions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;
const UserOption = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    padding: 8px 15px;
    border-radius: 5px;
    transition: 0.2s ease-in-out;
    background: ${props => props.theme.background.secondary};
    color: ${props => props.theme.colors.primary};
    font-size: 13px;
    font-weight: 500;
    &:hover {
        opacity: 0.9;
    }

    & svg {
        height: 16px;
        width: auto;
    }
`;
const Seperator = styled.div`
    height: 1px;
    width: 100%;
    background: ${props => props.theme.border.primary};
    margin: 2px 0;
`;
const LevelIcon = styled.img`
    height: 18px;
    width: auto;
`;
const Username = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 14px;
    font-weight: 500;
`;
UserComponent.propTypes = {
  isLoading: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  isLoading: state.auth.isLoading,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});
export default connect(mapStateToProps)(UserComponent);