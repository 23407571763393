import React from "react";
import styled from "styled-components";

// MUI Components
import { Slider } from "@material-ui/core";
const StyledSlider = styled(Slider)`
    color: ${props => props.type === "over" ? "rgb(191, 57, 50)" : "#03df58"} !important;
    height: 8px;

    & .MuiSlider-thumb {
        height: 22px;
        width: 22px;
        background-color: ${({
  theme
}) => theme.colors.primary};
        margin-top: -8px;
        margin-left: -12px;
        &:focus,
        &:hover,
        &.Mui-active {
            box-shadow: inherit;
        }
    }

    & .MuiSlider-valueLabel {
        left: calc(-50% + 4px);
    }

    & .MuiSlider-track {
        height: 8px;
        border-radius: 4px;
    }

    & .MuiSlider-rail {
        height: 8px;
        border-radius: 4px;
        opacity: 1;
        color: ${props => props.type === "over" ? "#03df58" : "rgb(191, 57, 50)"};
    }
`;
const DiceSlider = ({
  type,
  value,
  onChange
}) => {
  return <div style={{
    width: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }}>
            <StyledSlider type={type} aria-label="Roll" value={value} onChange={onChange} min={3} max={97} />
        </div>;
};
export default DiceSlider;