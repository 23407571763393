import { REDIRECT_TO_BANNED_COUNTRY_PAGE } from "../actions/types";

const initialState = {
    shouldRedirectToBannedCountry: false,
};

const blocked = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
        case REDIRECT_TO_BANNED_COUNTRY_PAGE:
            return {
                ...state,
                shouldRedirectToBannedCountry: true,
            };
        default:
            return state;
    }
};

export default blocked;
