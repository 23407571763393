// Assets
import pearlCorporal from "../assets/badges/pearl/corporal.webp";
import pearlSergeant from "../assets/badges/pearl/sergeant.webp";
import pearlLieutenant from "../assets/badges/pearl/lieutenant.webp";
import pearlCaptain from "../assets/badges/pearl/captain.webp";
import pearlMajor from "../assets/badges/pearl/major.webp";
import pearlColonel from "../assets/badges/pearl/colonel.webp";
import pearlGeneral from "../assets/badges/pearl/general.webp";

import rubyCorporal from "../assets/badges/ruby/corporal.webp";
import rubySergeant from "../assets/badges/ruby/sergeant.webp";
import rubyLieutenant from "../assets/badges/ruby/lieutenant.webp";
import rubyCaptain from "../assets/badges/ruby/captain.webp";
import rubyMajor from "../assets/badges/ruby/major.webp";
import rubyColonel from "../assets/badges/ruby/colonel.webp";
import rubyGeneral from "../assets/badges/ruby/general.webp";

import sapphireCorporal from "../assets/badges/sapphire/corporal.webp";
import sapphireSergeant from "../assets/badges/sapphire/sergeant.webp";
import sapphireLieutenant from "../assets/badges/sapphire/lieutenant.webp";
import sapphireCaptain from "../assets/badges/sapphire/captain.webp";
import sapphireMajor from "../assets/badges/sapphire/major.webp";
import sapphireColonel from "../assets/badges/sapphire/colonel.webp";
import sapphireGeneral from "../assets/badges/sapphire/general.webp";

import emeraldCorporal from "../assets/badges/emerald/corporal.webp";
import emeraldSergeant from "../assets/badges/emerald/sergeant.webp";
import emeraldLieutenant from "../assets/badges/emerald/lieutenant.webp";
import emeraldCaptain from "../assets/badges/emerald/captain.webp";
import emeraldMajor from "../assets/badges/emerald/major.webp";
import emeraldColonel from "../assets/badges/emerald/colonel.webp";
import emeraldGeneral from "../assets/badges/emerald/general.webp";

import diamondCorporal from "../assets/badges/diamond/corporal.webp";
import diamondSergeant from "../assets/badges/diamond/sergeant.webp";
import diamondLieutenant from "../assets/badges/diamond/lieutenant.webp";
import diamondCaptain from "../assets/badges/diamond/captain.webp";
import diamondMajor from "../assets/badges/diamond/major.webp";
import diamondColonel from "../assets/badges/diamond/colonel.webp";
import diamondGeneral from "../assets/badges/diamond/general.webp";
import diamond5Star from "../assets/badges/diamond/5-star.webp";

export const getLevelBadge = (level) => {
    switch (level) {
        case "Pearl Corporal":
            return pearlCorporal;
        case "Pearl Sergeant":
            return pearlSergeant;
        case "Pearl Lieutenant":
            return pearlLieutenant;
        case "Pearl Captain":
            return pearlCaptain;
        case "Pearl Major":
            return pearlMajor;
        case "Pearl Colonel":
            return pearlColonel;
        case "Pearl General":
            return pearlGeneral;
        case "Ruby Corporal":
            return rubyCorporal;
        case "Ruby Sergeant":
            return rubySergeant;
        case "Ruby Lieutenant":
            return rubyLieutenant;
        case "Ruby Captain":
            return rubyCaptain;
        case "Ruby Major":
            return rubyMajor;
        case "Ruby Colonel":
            return rubyColonel;
        case "Ruby General":
            return rubyGeneral;
        case "Sapphire Corporal":
            return sapphireCorporal;
        case "Sapphire Sergeant":
            return sapphireSergeant;
        case "Sapphire Lieutenant":
            return sapphireLieutenant;
        case "Sapphire Captain":
            return sapphireCaptain;
        case "Sapphire Major":
            return sapphireMajor;
        case "Sapphire Colonel":
            return sapphireColonel;
        case "Sapphire General":
            return sapphireGeneral;
        case "Emerald Corporal":
            return emeraldCorporal;
        case "Emerald Sergeant":
            return emeraldSergeant;
        case "Emerald Lieutenant":
            return emeraldLieutenant;
        case "Emerald Captain":
            return emeraldCaptain;
        case "Emerald Major":
            return emeraldMajor;
        case "Emerald Colonel":
            return emeraldColonel;
        case "Emerald General":
            return emeraldGeneral;
        case "Diamond Corporal":
            return diamondCorporal;
        case "Diamond Sergeant":
            return diamondSergeant;
        case "Diamond Lieutenant":
            return diamondLieutenant;
        case "Diamond Captain":
            return diamondCaptain;
        case "Diamond Major":
            return diamondMajor;
        case "Diamond Colonel":
            return diamondColonel;
        case "Diamond General":
            return diamondGeneral;
        case "Diamond 5-Star":
            return diamond5Star;
        default:
            return null;
    }
};
