import styled from "styled-components";
const Create = () => <SVGWrapper>
    <svg viewBox="0 0 2000 2000" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1472.36 1047.23V1171.72C1472.36 1202.09 1447.74 1226.71 1417.37 1226.71H494.37C464 1226.71 439.38 1202.09 439.38 1171.72V915.72C439.38 885.35 464 860.73 494.37 860.73H1286.23C1292.33 860.73 1297.28 855.78 1297.28 849.68V833.76C1297.28 827.66 1292.33 822.71 1286.23 822.71H494.37C443 822.71 401.36 864.35 401.36 915.72V1171.72C401.36 1223.09 443 1264.73 494.37 1264.73H1417.37C1468.74 1264.73 1510.38 1223.09 1510.38 1171.72V1047.23C1510.38 1040.92 1505.27 1035.81 1498.96 1035.81H1483.79C1477.47 1035.81 1472.36 1040.92 1472.36 1047.23Z" fill="currentColor" />
        <path d="M1518.79 734.57H1464.69C1458.12 734.57 1452.79 739.9 1452.79 746.47V935.78C1452.79 942.35 1458.12 947.68 1464.69 947.68H1518.79C1525.36 947.68 1530.69 942.35 1530.69 935.78V746.47C1530.69 739.89 1525.36 734.57 1518.79 734.57Z" fill="currentColor" />
        <path d="M1598.29 868.17V814.07C1598.29 807.5 1592.96 802.17 1586.39 802.17H1397.08C1390.51 802.17 1385.18 807.5 1385.18 814.07V868.17C1385.18 874.74 1390.51 880.07 1397.08 880.07H1586.39C1592.97 880.07 1598.29 874.74 1598.29 868.17Z" fill="currentColor" />
        <path d="M1332.38 1130.92H580.71C560.05 1130.92 543.31 1114.17 543.31 1093.52V993.919C543.31 973.259 560.06 956.52 580.71 956.52H1332.38C1353.04 956.52 1369.78 973.269 1369.78 993.919V1093.52C1369.78 1114.17 1353.03 1130.92 1332.38 1130.92Z" fill="currentColor" />
    </svg>
</SVGWrapper>;
export default Create;
const SVGWrapper = styled.div`
    width: 100%;
    color: ${({
  theme
}) => theme.colors.tertiary};
`;