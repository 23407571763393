import React, { useState, Fragment } from "react";
import styled, { useTheme } from "styled-components";
import PropTypes from "prop-types";

// Components
import ProvablyModal from "../modals/ProvablyModal";
const HistoryEntry = ({
  game
}) => {
  const theme = useTheme();
  const [modalVisible, setModalVisible] = useState(false);
  const onClick = () => {
    setModalVisible(state => !state);
  };
  const _getCrashColor = crashPoint => {
    if (crashPoint > 1.5) {
      return "#2ecc71";
    }
    return theme.background.primary;
  };
  return <Fragment>
            <ProvablyModal open={modalVisible} handleClose={() => setModalVisible(state => !state)} fields={[{
      label: "Round ID",
      value: game._id
    }, {
      label: "Private Hash",
      value: game.privateHash
    }, {
      label: "Private Seed",
      value: game.privateSeed ? game.privateSeed : "Not Revealed"
    }, {
      label: "Public Seed",
      value: game.publicSeed ? game.publicSeed : "Not Revealed"
    }, {
      label: "Crash Point",
      value: game.crashPoint
    }]} />
            <Entry background={_getCrashColor(game.crashPoint)} onClick={onClick} data-tooltip-content="Click to view Provably Fair" data-tooltip-id="default" data-tooltip-place="bottom">
                {game.crashPoint.toFixed(2)}
            </Entry>
        </Fragment>;
};
const Entry = styled.div`
    background: ${props => props.background};
    color: white;
    padding: 5px 15px;
    min-width: 65px;
    border-radius: 4px;
    font-size: 0.9rem;
    line-height: 1.75;
    text-align: center;
    margin-right: 8px;

    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
        opacity: 0.9;
    }
`;
HistoryEntry.propTypes = {
  game: PropTypes.object.isRequired
};
export default HistoryEntry;