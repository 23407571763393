import numeral from "numeral";

/**
 * Formats a numerical value according to the specified mode. The function supports two modes: "auto" and "comma".
 *
 * @param {number} value - The numerical value to be formatted.
 *
 * @returns {string} Returns the formatted number as a string.
 */
export function formatFiatNumber(value) {
    if (isNaN(value)) {
        return "NaN";
    }

    if (value < 100000) {
        return numeral(value).format("0,0.00");
    } else if (value < 1000000) {
        return numeral(value).format("0a");
    } else {
        return numeral(value).format("0.0a");
    }
}

export function formatCryptoNumber(value, shownDecimals = 2) {
    if (isNaN(value)) {
        return "NaN";
    }

    if (typeof value !== "string") {
        value = value.toString();
    }

    let splitted = value.split(".");
    if (splitted.length === 1) {
        return value;
    }

    let decimals = splitted[1];

    let shortenedDecimals = decimals.substring(0, shownDecimals);

    // If the latest decimal is 0, show more until we find a non-zero decimal
    while (shortenedDecimals[shortenedDecimals.length - 1] === "0") {
        shownDecimals++;
        shortenedDecimals = decimals.substring(0, shownDecimals);
    }

    return `${splitted[0]}.${shortenedDecimals}`;
}
