import { type } from "@testing-library/user-event/dist/cjs/utility/type.js";
import NamespaceWrapper from "./namespace";

export default class WebSocketManager {
    constructor(url) {
        this.url = url;
        this.namespaces = {}; // Stores NamespaceWrapper instances keyed by namespace
        this.authToken = null;
        this.socket = null;

        this.connected = false;
        this.connectionPromise = null;
        this.connectionResolve = null;

        this.connect();

        // Ping the server every 60 seconds
        setInterval(() => {
            if (this.socket && this.socket.readyState === WebSocket.OPEN) {
                this.socket.send(JSON.stringify({ type: "ping" }));
            }
        }, 60_000);
    }

    setAuthToken(authToken) {
        this.authToken = authToken;
    }

    connect() {
        this.connectionPromise = new Promise((resolve) => {
            this.connectionResolve = resolve;
        });

        try {
            console.log("Connecting to WebSocket");
            this.socket = new WebSocket(this.url);

            this.socket.onopen = () => {
                console.log("WebSocket connected");
                this.connected = true;
                if (this.authToken) {
                    this.emit({ namespace: "auth", type: "authenticate" });
                }
                if (this.connectionResolve) {
                    this.connectionResolve();
                }
            };

            // Listen for messages on the WebSocket and route them
            this.socket.onmessage = (event) => {
                const message = JSON.parse(event.data);
                console.log("Received message:", message);
                const namespace = message.namespace;
                if (this.namespaces[namespace]) {
                    this.namespaces[namespace].routeMessage(message);
                }
            };

            // Handle WebSocket close event
            this.socket.onclose = (t, ev) => {
                this.socket = null;
                this.connected = false;
                console.log(
                    "WebSocket connection closed. Attempting to reconnect...",
                );
                this.reconnect();
            };
        } catch (error) {
            console.error("Error connecting to WebSocket:", error);
            this.socket = null;
            this.connected = false;
            this.reconnect();
        }
    }

    reconnect() {
        setTimeout(() => {
            if (this.socket === null) {
                this.connect();
            }
        }, 1000);
    }

    // Method to get or create a namespace wrapper
    getNamespace(namespace) {
        if (!this.namespaces[namespace]) {
            this.namespaces[namespace] = new NamespaceWrapper(namespace, this);
        }
        return this.namespaces[namespace];
    }

    // Asynchronous handler to check if WebSocket is connected
    async waitForConnection() {
        if (this.connected) {
            return true;
        }
        return this.connectionPromise;
    }

    // emits a message through the WebSocket connection
    async emit(message) {
        await this.waitForConnection();

        if (this.authToken) {
            message.token = this.authToken;
        }

        if (this.socket.readyState === WebSocket.OPEN) {
            try {
                this.socket.send(JSON.stringify(message));
            } catch (error) {
                console.error("Error sending message:", error);
            }
        } else {
            console.error("WebSocket is not open. Message not sent.");
        }
    }
}
