import { useEffect } from "react";
import { withRouter } from "react-router-dom";
function ScrollToTop({
  history
}) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      const mainElement = document.querySelector("main");
      if (mainElement) {
        mainElement.scrollTop = 0;
      }
    });
    return () => {
      unlisten();
    };
  }, [history]);
  return null;
}
export default withRouter(ScrollToTop);