import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
const UsersOnline = ({
  usersOnline
}) => {
  return <UsersOnlineContainer>
            {/* <Blinker>●</Blinker>
             <Text color="#03df58">{usersOnline}</Text> */}
        </UsersOnlineContainer>;
};
const UsersOnlineContainer = styled.div`
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.secondary};
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.1em;
`;
const Blinker = styled.span`
    color: #03df58;
    border-radius: 100%;
    animation: blink 2s linear infinite;
`;
const Text = styled.span`
    color: ${props => props.color};
    margin-left: 5px;
`;
const mapStateToProps = state => {
  return {
    usersOnline: state.site.usersOnline
  };
};
export default connect(mapStateToProps)(UsersOnline);