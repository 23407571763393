import styled from "styled-components";
import HistoryEntry from "../../components/roulette/HistoryEntry";
import CARD_COLORS from "../../constants/cardColors";
const Wrapper = styled.div`
    margin-bottom: 45px;
    margin-top: 25px;
    color: ${({
  theme
}) => theme.colors.primary};
    font-size: 19px;
    font-weight: 500;
    letter-spacing: 0.1em;
`;
const CountContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: 12px;
    letter-spacing: 0.09em;
    font-weight: 500;
`;
const CountIndicator = styled.div`
    background: ${props => props.background};
    margin-left: 15px;
    border-radius: 50%;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: 0.4rem;
    vertical-align: text-top;
`;
const Count = styled.div`
    font-size: 15px;
    font-weight: 500;
    color: ${({
  theme
}) => theme.colors.primary};
    line-height: 1;
`;
const HistoryContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    align-items: center;
    height: 2.25rem;
    transition: all 800ms ease;
    margin-top: 10px;
    overflow-x: hidden;
`;
const History = ({
  getAmountOfPastGames,
  history
}) => {
  return <Wrapper>
            <CountContainer>
                LAST 100
                <CountIndicator background={CARD_COLORS.RED}></CountIndicator>
                <Count>{getAmountOfPastGames("red")}</Count>
                <CountIndicator background={CARD_COLORS.GREEN}></CountIndicator>
                <Count>{getAmountOfPastGames("green")}</Count>
                <CountIndicator background={CARD_COLORS.BLACK}></CountIndicator>
                <Count>{getAmountOfPastGames("blue")}</Count>
            </CountContainer>
            <HistoryContainer>
                {history?.slice(-9)?.map((game, index) => <HistoryEntry key={index} game={game} />)}
            </HistoryContainer>
        </Wrapper>;
};
export default History;