import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Moment from "react-moment";
import toast from "react-hot-toast";
import { getUserStatistics } from "../../services/api.service";

// Components
import { StyledModal, CloseButton } from ".";
import Skeleton from "../Skeleton";
import { CURRENCIES } from "../../constants/currencies";
import Price from "../Price";
const Statistics = ({
  isAuthenticated,
  user,
  open,
  handleClose
}) => {
  const [loading, setLoading] = useState(true);
  const [statistics, setStatistics] = useState(null);
  const [matchingStatistics, setMatchingStatistics] = useState(null);
  const [selectedPlatform, setSelectedPlatform] = useState("all");
  const [selectedCurrency, setSelectedCurrency] = useState("all");
  useEffect(() => {
    if (!open) {
      return;
    }
    if (!isAuthenticated) {
      return;
    }
    const fetchStatistics = async () => {
      try {
        setLoading(true);
        const resp = await getUserStatistics();
        setStatistics(resp.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.error("Failed loading statistics");
      }
    };
    fetchStatistics();
  }, [open, isAuthenticated]);
  useEffect(() => {
    if (!statistics) {
      return;
    }
    const matchingStatistics = statistics.filter(statistic => {
      if (selectedPlatform === "all" && selectedCurrency === "all") {
        return true;
      }
      if (selectedPlatform === "all") {
        return statistic.currency === selectedCurrency;
      }
      if (selectedCurrency === "all") {
        return statistic.scope === selectedPlatform;
      }
      return statistic.scope === selectedPlatform && statistic.currency === selectedCurrency;
    });
    setMatchingStatistics(matchingStatistics);
  }, [statistics, selectedPlatform, selectedCurrency]);
  const getTotalBets = () => {
    if (!matchingStatistics) {
      return 0;
    }
    return matchingStatistics.reduce((acc, statistic) => {
      return acc + statistic.bets;
    }, 0);
  };
  const getTotalWins = () => {
    if (!matchingStatistics) {
      return 0;
    }
    return matchingStatistics.reduce((acc, statistic) => {
      return acc + statistic.wins;
    }, 0);
  };
  const getWagered = () => {
    if (!matchingStatistics) {
      return 0;
    }
    return matchingStatistics.reduce((acc, statistic) => {
      return acc + statistic.wagered;
    }, 0);
  };
  return <StyledModal onClose={handleClose} open={open} width="fit-content">
            <Title>Statistics</Title>
            {loading ? <Skeleton width="450px" height="200px" /> : <>
                    <Username>{user.username}</Username>
                    <MemberSince>
                        Member since{" "}
                        <Moment format="DD MMM YYYY">{user.date_joined}</Moment>
                    </MemberSince>
                    <StatisticsFilters>
                        <StatisticFilterSelector label="Platform" selected={selectedPlatform} handleSelecting={setSelectedPlatform} options={[{
          label: "All",
          value: "all"
        }, {
          label: "Casino",
          value: "casino"
        }, {
          label: "Sports",
          value: "sports"
        }]} />
                        <StatisticFilterSelector label="Currency" selected={selectedCurrency} handleSelecting={setSelectedCurrency} options={[{
          label: "All",
          value: "all"
        },
        // TODO: use CURRENCIES object to get the label and value map
        {
          label: "Sweet",
          value: CURRENCIES.SWEET.id
        }, {
          label: "Ethereum",
          value: CURRENCIES.ETH.id
        }]} />
                    </StatisticsFilters>
                    <StatisticRow>
                        <StatisticCard style={{
          width: "175px"
        }}>
                            <StatisticCardTitle>Total Bets</StatisticCardTitle>
                            <StatisticCardValue>
                                {getTotalBets()}
                            </StatisticCardValue>
                        </StatisticCard>
                        <StatisticCard style={{
          width: "175px"
        }}>
                            <StatisticCardTitle>
                                Number of Wins
                            </StatisticCardTitle>
                            <StatisticCardValue>
                                {getTotalWins()}
                            </StatisticCardValue>
                        </StatisticCard>
                    </StatisticRow>
                    <StatisticCard style={{
        width: "365px"
      }}>
                        <StatisticCardTitle>Wagered</StatisticCardTitle>
                        <StatisticCardValue>
                            {selectedCurrency === "all" ? "Select a currency" // TODO: once we have exchange rates, we can calculate this
          : <Price currency={selectedCurrency === CURRENCIES.SWEET.id ? CURRENCIES.SWEET : CURRENCIES.ETH} amount={getWagered()} textSize="18px" />}
                        </StatisticCardValue>
                    </StatisticCard>
                </>}
            <CloseButton onClick={handleClose} />
        </StyledModal>;
};
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});
export default connect(mapStateToProps)(Statistics);
const Title = styled.div`
    color: ${({
  theme
}) => theme.colors.secondary};
    font-weight: 500;
    font-size: 18px !important;
    margin-bottom: 25px;
`;
const Username = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 18px !important;
    font-weight: 500;
`;
const MemberSince = styled.div`
    color: ${props => props.theme.colors.secondary} !important;
    font-size: 14px;
    margin-top: 3px;
`;
const StatisticsFilters = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 25px;
    margin-bottom: 20px;
`;
const StatisticFilterSelector = ({
  label,
  selected,
  handleSelecting,
  options
}) => {
  return <StatisticFilterSelectorContainer>
            <StatisticFilterSelectorLabel>{label}</StatisticFilterSelectorLabel>
            <StatisticFilterSelectorSelect value={selected} onChange={e => handleSelecting(e.target.value)}>
                {options.map(option => <option key={option.value} value={option.value}>
                        {option.label}
                    </option>)}
            </StatisticFilterSelectorSelect>
        </StatisticFilterSelectorContainer>;
};
const StatisticFilterSelectorContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    background: ${({
  theme
}) => theme.background.secondary};
    border: 1px solid ${({
  theme
}) => theme.border.primary};
    border-radius: 8px;
    padding: 10px;
`;
const StatisticFilterSelectorLabel = styled.div`
    color: ${({
  theme
}) => theme.colors.secondary} !important;
    font-size: 14px !important;
`;
const StatisticFilterSelectorSelect = styled.select`
    background: ${({
  theme
}) => theme.background.secondary};
    border: none;
    outline: none;
    color: ${({
  theme
}) => theme.colors.primary} !important;
    font-size: 14px !important;
    text-transform: capitalize;
    width: 90px;
`;
const StatisticRow = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
    width: 100%;
`;
const StatisticCard = styled.div`
    background: ${({
  theme
}) => theme.background.secondary};
    border-radius: 8px;
    border: 1px solid ${({
  theme
}) => theme.border.primary};
    padding: 15px;
`;
const StatisticCardTitle = styled.div`
    color: ${({
  theme
}) => theme.colors.secondary} !important;
    font-size: 14px !important;
    margin-bottom: 10px;
    text-transform: uppercase;
`;
const StatisticCardValue = styled.div`
    color: ${({
  theme
}) => theme.colors.primary} !important;
    font-weight: 500;
    font-size: 18px !important;
`;