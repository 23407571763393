import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import styled from "styled-components";
import { CURRENCIES } from "../../../constants/currencies";
import { createCustodialWallet, getCustodialUserWallets } from "../../../services/api.service";

// MUI Components
import { TextField } from "@material-ui/core";

// Components
import Skeleton from "../../Skeleton";
import Button from "../../Button";
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 10rem;
    justify-content: space-around;
    margin-top: 15px;

    & > div {
        & label {
            color: ${({
  theme
}) => theme.colors.primary};

            font-size: 15px;
            font-weight: 300;
        }

        & label.Mui-focused {
            color: ${({
  theme
}) => theme.colors.primary};
        }

        & .MuiInput-underline:after {
            border-radius: 6px;
            border-color: ${props => props.theme.border.primary};
        }

        & .MuiOutlinedInput-root {
            & fieldset {
                border-radius: 6px;
                border-color: ${props => props.theme.border.primary};
            }

            &:hover fieldset {
                border-radius: 6px;
                border-color: ${props => props.theme.border.primary};
            }

            &.Mui-focused fieldset {
                border-radius: 6px;
                border-color: ${props => props.theme.border.primary};
            }
        }
    }
`;
const Inputs = styled.div`
    position: relative;

    & > div {
        width: 100%;

        & > div > input {
            width: 70%;
            font-weight: 500;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: ${({
  theme
}) => theme.colors.primary};
        }
    }

    & button {
        position: absolute;
        right: 0;
        top: 0.5rem;
        width: 6rem;
    }
`;
const Text = styled.div`
    color: ${({
  theme
}) => theme.colors.secondary} !important;
`;
const Sweet = () => {
  const [loading, setLoading] = useState(true);
  const [cryptoData, setCryptoData] = useState(null);
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    // Fetch crypto information from api
    const fetchData = async () => {
      setLoading(true);
      try {
        const {
          data
        } = await getCustodialUserWallets();
        if (!data[CURRENCIES.ETH.id]) {
          const {
            data
          } = await createCustodialWallet(CURRENCIES.SWEET.id);
          console.log(data);
          setCryptoData(data);
        } else {
          setCryptoData(data[CURRENCIES.SWEET.id]);
        }
        setLoading(false);
      } catch (error) {
        console.log("There was an error while fetching user crypto information:", error);
        toast.error("There was an error while fetching your crypto deposit information. Please try again later!", {
          appearance: "error"
        });
      }
    };
    fetchData();
  }, []);
  return <ContentWrapper>
            <Text>
                You will be credited after 8 confirmations. You might need to
                refresh the site once it is confirmed. Only send $SWEET to this
                address.
                <br />
                <br />
                Minimum deposit 1000 SWEET
                <br />
                <br />
            </Text>
            {loading ? <Skeleton height="55px" width="500px" /> : <Inputs>
                    <TextField label="SWEET DEPOSIT ADDRESS" variant="outlined" value={cryptoData.address} />
                    <Button style={{
        marginRight: "10px"
      }} onClick={() => {
        navigator.clipboard.writeText(cryptoData.address);
        setCopied(true);
      }}>
                        {copied ? "COPIED!" : "COPY"}
                    </Button>
                </Inputs>}
        </ContentWrapper>;
};
export default Sweet;