import { ETH_CURRENCY } from "../../constants/currencies";
import { UPDATE_CURRENCY } from "../actions/types";

// get currency from window local storage
const getPersistentCurrency = () => {
    let value = localStorage.getItem("currency");
    if (value) {
        return JSON.parse(value);
    }

    return ETH_CURRENCY;
};

// persist currency in window local storage
const persistCurrency = (currency) => {
    localStorage.setItem("currency", JSON.stringify(currency));
};

const initialState = {
    currency: getPersistentCurrency(),
};

const currency = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_CURRENCY:
            persistCurrency(payload);
            return {
                ...state,
                currency: payload,
            };
        default:
            return state;
    }
};

export default currency;
