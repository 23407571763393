import React from "react";
import ProviderGamePage from "../../components/ProviderGamePage";
const GameShowPage = ({
  match: {
    params: {
      id
    }
  }
}) => {
  return <ProviderGamePage type="game-show" id={id} />;
};
export default GameShowPage;