import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { TransitionGroup } from "react-transition-group";
import BREAKPOINTS from "../../constants/breakpoints";
import { getLevelBadge } from "../../utils/vip";

// Components
import Price from "../Price";

// MUI Components
import { Collapse } from "@material-ui/core";

// TODO: Clean code
const Bets = ({
  currency,
  players,
  won,
  multiplier = 2,
  color
}) => {
  const [previousTotal, setPreviousTotal] = useState(0);
  const [currentTotal, setCurrentTotal] = useState(0);
  const [blackBetsLength, setBlackBetsLength] = useState(0);
  useEffect(() => {
    const filteredPlayers = players.filter(player => player.color === color);

    // Calculate the previous total when the players prop changes
    const newTotal = filteredPlayers.reduce((total, player) => {
      return total + parseFloat(player.amount);
    }, 0);
    setBlackBetsLength(filteredPlayers.length);
    setPreviousTotal(currentTotal);
    setCurrentTotal(newTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [players]);
  return <Root>
            <BetAmount>
                <span style={{
        marginLeft: 0
      }}>
                    {blackBetsLength} {blackBetsLength === 1 ? "BET" : "BETS"}
                </span>
                <Price currency={currency} amount={won ? currentTotal * multiplier : currentTotal} countup countupData={{
        delay: 0,
        duration: 1,
        decimals: 5,
        start: previousTotal
      }} textColor={won ? "#11ca52" : "#fff"} />
            </BetAmount>
            <BetsContainer>
                <TransitionGroup>
                    {players.filter(player => player.color === color).sort((a, b) => b.amount - a.amount).map((player, index) => <Collapse key={player.id}>
                                <Bet>
                                    <Avatar alt="Level Badge" src={getLevelBadge(player.vip_rank)} />
                                    <Username>{player.username}</Username>
                                    <Price currency={currency} amount={won ? player.amount * multiplier : player.amount} textColor={won ? "#11ca52" : player.amount >= 10_000 ? "rgb(255, 224, 99)" : "#fff"} />
                                </Bet>
                            </Collapse>)}
                </TransitionGroup>
            </BetsContainer>
        </Root>;
};
const Root = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid ${props => props.theme.border.primary};
    backdrop-filter: blur(7.5px);
    width: 100%;
    height: auto;
    min-height: 250px;
    max-height: 800px;
    padding: 25px;

    @media (max-width: ${BREAKPOINTS.xs}) {
        width: 100%;
        margin-top: 20px;
    }
`;
const BetAmount = styled.div`
    width: 100%;
    display: flex;
    align-items: center;

    color: ${({
  theme
}) => theme.colors.secondary};
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    text-transform: uppercase;
    padding-bottom: 25px;
    border-bottom: 1px solid ${({
  theme
}) => theme.border.primary};

    & span {
        display: flex;
        margin-left: auto;

        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.1em;
    }
`;
const BetsContainer = styled.div`
    display: flex;
    color: white;
    flex-direction: column;
`;
const Bet = styled.div`
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 5px;
    width: 100%;
    padding-top: 15px;
    color: ${({
  theme
}) => theme.colors.primary};

    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.1em;
    position: relative;
    overflow: hidden;
`;
const Avatar = styled.img`
    height: auto;
    width: 25px;
`;
const Username = styled.div`
    margin-left: 5px;
    margin-right: auto;
    color: ${({
  theme
}) => theme.colors.primary};
    font-weight: 500;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
Bets.propTypes = {
  players: PropTypes.array.isRequired,
  won: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired
};
export default Bets;