import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

// Assets
import { ReactComponent as ExitIcon } from "../assets/v2/icons/exit.svg";
import { ReactComponent as Roulette } from "../assets/v2/game-icons/roulette.svg";
import { ReactComponent as Dice } from "../assets/v2/game-icons/dice.svg";
import { ReactComponent as Coinflip } from "../assets/v2/game-icons/coinflip.svg";
import { ReactComponent as Crash } from "../assets/v2/game-icons/crash.svg";
import { ReactComponent as Crates } from "../assets/v2/game-icons/crates.svg";
import { ReactComponent as Battles } from "../assets/v2/game-icons/battles.svg";
import { ReactComponent as Blackjack } from "../assets/v2/game-icons/blackjack.svg";
import { ReactComponent as Slots } from "../assets/v2/game-icons/slots.svg";
import { ReactComponent as LiveCasino } from "../assets/v2/game-icons/live-casino.svg";
import { ReactComponent as GameShows } from "../assets/v2/game-icons/game-shows.svg";

// ? GAMES are the games that are available in the app and their respective icons and styles
const GAMES = {
  dice: {
    icon: Dice,
    title: "dice"
  },
  roulette: {
    icon: Roulette,
    title: "roulette"
  },
  coinflip: {
    icon: Coinflip,
    title: "coinflip"
  },
  crash: {
    icon: Crash,
    title: "crash"
  },
  crates: {
    icon: Crates,
    title: "crates"
  },
  battles: {
    icon: Battles,
    title: "crate battles"
  },
  blackjack: {
    icon: Blackjack,
    title: "blackjack"
  },
  slots: {
    icon: Slots,
    title: "slots"
  },
  "live-casino": {
    icon: LiveCasino,
    title: "live casino"
  },
  "game-show": {
    icon: GameShows,
    title: "game shows"
  }
};
export const getGameTitle = game => {
  return GAMES[game].title;
};
export const GameIcon = ({
  game,
  height,
  width
}) => {
  const Icon = GAMES[game].icon;
  return <Icon height={height} width={width} />;
};

// GameHeader is the header that is displayed on the top of the game pages
// game is the game that is currently being played
// children is the content that is displayed on the right side of the header
// returnTo is the object that contains the route and tooltip for the return button
// removePlural is a boolean that determines whether the game title should be plural or not
const GameHeader = ({
  game,
  children,
  returnTo
}) => {
  const history = useHistory();
  return <GameHeaderContainer>
            {returnTo && <ReturnTo data-tooltip-content={returnTo.tooltip} data-tooltip-id="default" onClick={() => {
      history.push(returnTo.route);
    }} />}
            <GameIcon game={game} height="35px" width="35px" />
            <GameHeaderTitle>{GAMES[game].title}</GameHeaderTitle>
            {children}
        </GameHeaderContainer>;
};
const GameHeaderContainer = styled.div`
    padding-bottom: 15px;
    border-bottom: 1px solid ${({
  theme
}) => theme.border.primary};
    color: ${({
  theme
}) => theme.colors.primary};
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
`;
const GameHeaderTitle = styled.div`
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
`;
const ReturnTo = styled(ExitIcon)`
    color: ${({
  theme
}) => theme.colors.secondary};
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
        filter: brightness(1.1);
    }
`;
export default GameHeader;