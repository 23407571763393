import React from "react";
import styled from "styled-components";

// MUI Components
import { Dialog } from "@material-ui/core";
import BREAKPOINTS from "../../constants/breakpoints";
const BuySweet = ({
  open,
  handleClose
}) => {
  return <BuySweetDialog onClose={handleClose} open={open}>
            <iframe title="Buy Crypto" width="100%" height="600px" frameborder="0" allow="clipboard-read *; clipboard-write *; web-share *; accelerometer *; autoplay *; camera *; gyroscope *; payment *; geolocation *" src="https://app.uniswap.org/swap?outputCurrency=0x2df7d0e4903029717c949cad204075a3d75c8806" />
        </BuySweetDialog>;
};
const BuySweetDialog = styled(Dialog)`
    & .MuiDialog-paperWidthSm {
        width: 50%;
        background: transparent;
        box-shadow: none;
    }

    @media (max-width: ${BREAKPOINTS.sm}) {
        & .MuiDialog-paperWidthSm {
            width: 100%;
        }
    }
`;
export default BuySweet;