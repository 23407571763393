import React from "react";
import PropTypes from "prop-types";
import makeBlockie from "ethereum-blockies-base64";
import styled from "styled-components";
const Avatar = styled.div`
    position: relative;
    padding: 3px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
`;
const Image = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 50%;
`;
const Level = styled.span`
    min-height: 12px;
    min-width: 12px;
    width: 45%;
    height: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -2px;
    bottom: -2px;
    color: ${({
  theme
}) => theme.colors.primary};
    font-weight: 500;
    font-size: 8px;
    padding-top: 2px;
    border: 1px solid #242b33;
    border-radius: 50%;
`;
const PlayerAvatar = ({
  address = "0x0000000000000000000000000000000000000000",
  size = "40px",
  levelColor,
  levelName
}) => {
  return <Avatar style={{
    width: size,
    minWidth: size,
    height: size,
    minHeight: size,
    background: levelColor
  }}>
            <Image alt="Avatar" src={makeBlockie(address)} />
            <Level style={{
      background: levelColor
    }}>{levelName}</Level>
        </Avatar>;
};
PlayerAvatar.propTypes = {
  address: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  levelColor: PropTypes.string.isRequired,
  levelName: PropTypes.string.isRequired
};
export default PlayerAvatar;