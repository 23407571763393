export default class NamespaceWrapper {
    constructor(namespace, manager) {
        this.namespace = namespace;
        this.manager = manager;
        this.handlers = {}; // Stores handlers based on message type
    }

    // Adds a handler for a specific message type
    on(type, handler) {
        if (!this.handlers[type]) {
            this.handlers[type] = [];
        }
        this.handlers[type].push(handler);
    }

    // Removes a specific handler for a given message type
    off(type, handler) {
        const handlers = this.handlers[type];
        if (handlers) {
            const index = handlers.indexOf(handler);
            if (index !== -1) {
                handlers.splice(index, 1);
            }
        }
    }

    // Routes an incoming message to the correct handler based on type
    routeMessage(message) {
        const handlers = this.handlers[message.type];
        if (handlers) {
            handlers.forEach((handler) => handler(message.data));
        }
    }

    // Sends a message with the namespace included
    emit(type, data) {
        this.manager.emit({ namespace: this.namespace, type, data });
    }
}
