import React, { useState } from "react";
import styled from "styled-components";
import { chatSocket } from "../../services/websocket.service";

// MUI Components
import { TextField } from "@material-ui/core";

// Components
import { CloseButton, StyledModal } from ".";
import Button from "../Button";
const ContentContainer = styled.div`
    margin: 1rem 0;
    position: relative;

    & > div {
        width: 100%;

        & label {
            color: ${props => props.theme.colors.secondary};

            font-size: 14px;
            font-weight: 300;
        }

        & label.Mui-focused {
            color: ${props => props.theme.colors.secondary};

            font-size: 14px;
            font-weight: 300;
        }

        & .MuiInput-underline:after {
            border: 1px solid ${props => props.theme.border.primary};
            border-radius: 6px;
        }

        & .MuiOutlinedInput-root {
            & fieldset {
                border: 1px solid ${props => props.theme.border.primary};
                border-radius: 6px;
            }

            &:hover fieldset {
                border: 1px solid ${props => props.theme.border.primary};
                border-radius: 6px;
            }

            &.Mui-focused fieldset {
                border: 1px solid ${props => props.theme.border.primary};
                border-radius: 6px;
            }
        }
    }
`;
const Title = styled.div`
    font-weight: 500;
    font-size: 18px !important;
`;
const CreateRainModal = ({
  open,
  handleClose,
  sweet
}) => {
  const [amount, setAmount] = useState("");
  const onChange = e => {
    setAmount(e.target.value);
  };
  const onClick = () => {
    chatSocket.emit("send_chat_message", `.create-rain ${amount}`);
    setAmount("");
    handleClose();
  };
  return <StyledModal onClose={handleClose} open={open}>
            <Title>Create Sugar Rush</Title>
            <ContentContainer>
                <p>
                    Amount will be divided evenly for those who have joined. If
                    there is already an existing rain, your amount will be added
                    to the current pot.
                </p>
                <br />
                <TextField className="input" variant="outlined" label="Min. 100" onChange={onChange} value={amount} />
                <Button onClick={onClick} style={{
        marginTop: "1rem"
      }}>
                    Create Sugar Rush
                </Button>
            </ContentContainer>
            <CloseButton autoFocus onClick={handleClose} color="primary">
                CLOSE
            </CloseButton>
        </StyledModal>;
};
export default CreateRainModal;