import React from "react";
import styled from "styled-components";
import Skeleton from "./Skeleton";
const ListItemsSkeleton = ({
  length,
  height
}) => {
  return <SkeletonContainer>
            {Array.from({
      length
    }).map((_, index) => <Skeleton key={index} variant="rounded" height={height} width="100%" />)}
        </SkeletonContainer>;
};
const SkeletonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
export default ListItemsSkeleton;