import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import CountUp from "react-countup";
import { getExchangeRate, isFiat as _isFiat } from "../constants/currencies";
import { formatCryptoNumber, formatFiatNumber } from "../utils/formatNumber";

// Price expects the highest denomination of currency.
const Price = ({
  amount,
  currency,
  exchangeRates,
  showInFiat,
  countup = false,
  countupData = {
    delay: 0,
    duration: 1,
    decimals: 2,
    start: 0
  },
  textColor = "white",
  textSize = "12px",
  textWeight = "500",
  imageSize = "15px",
  showExchangedCurrencyIcons = false,
  centered = true
}) => {
  const isFiat = _isFiat(currency);
  const getAmountDecimals = amt => {
    return amt?.toString()?.split(".")?.[1]?.length || 0;
  };
  const getUSDValue = () => {
    const rate = getExchangeRate(exchangeRates, currency.id, "usd");
    return (amount * parseFloat(rate)).toFixed(2);
  };
  const getAmount = () => {
    if (!currency || isNaN(amount)) {
      return "NaN";
    }
    if (isFiat) {
      return amount;
    }
    if (showInFiat && !exchangeRates) {
      return "NaN";
    }
    if (showInFiat && exchangeRates) {
      return getUSDValue(amount);
    }
    return amount;
  };
  const displayAmount = getAmount();
  console.log("rednered price", {
    amount,
    currency,
    exchangeRates,
    showInFiat,
    countup,
    countupData,
    displayAmount,
    usdv: getUSDValue()
  });
  return <PriceContainer centered={centered}>
            <Text data-tooltip-content={isFiat || showInFiat ? "" : `$${getUSDValue()}`} data-tooltip-id="default" style={{
      fontSize: textSize,
      color: isNaN(displayAmount) ? "red" : textColor,
      fontWeight: textWeight
    }}>
                {isNaN(displayAmount) ? "NaN" : countup ? <CountupContainer>
                        {isFiat || showInFiat ? <div>$</div> : <></>}
                        <CountUp delay={countupData.delay} duration={countupData.duration} decimals={getAmountDecimals(displayAmount)} start={countupData.start} end={displayAmount} />
                    </CountupContainer> : showInFiat || isFiat ? `$${formatFiatNumber(displayAmount)}` : formatCryptoNumber(amount, 5)}
            </Text>

            {(!showInFiat || showExchangedCurrencyIcons) && !isNaN(displayAmount) && <CurrencyImage src={currency.image} alt="Currency Icon" style={{
      height: imageSize
    }} />}
        </PriceContainer>;
};
const PriceContainer = styled.span`
    display: flex;
    align-items: center;
    justify-content: ${props => props.centered ? "center" : "flex-start"};
    gap: 5px;
`;
const Text = styled.div`
    line-height: 1;
`;
const CurrencyImage = styled.img`
    width: auto;
`;
const CountupContainer = styled.div`
    display: flex;
    flex-direction: row;
`;
const mapStateToProps = state => ({
  sweet: state.site.sweet,
  showInFiat: state.site.showInFiat,
  exchangeRates: state.site.exchangeRates
});
export default connect(mapStateToProps)(Price);