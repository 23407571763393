import { useState, useEffect } from "react";

const useMediaQuery = (breakpoint) => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        const media = window.matchMedia(`(max-width: ${breakpoint})`);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }

        const listener = () => {
            setMatches(media.matches);
        };

        window.addEventListener("resize", listener);
        return () => {
            window.removeEventListener("resize", listener);
        };
    }, [matches, breakpoint]);

    return matches;
};

export default useMediaQuery;
