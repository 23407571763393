import styled from "styled-components";
import red from "../../assets/roulette/red_icon.svg";
import black from "../../assets/roulette/blue_icon.svg";
import green from "../../assets/roulette/green_icon.svg";
import BREAKPOINTS from "../../constants/breakpoints";
import CARD_COLORS from "../../constants/cardColors";
const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 18px;
    margin-top: 36px;

    @media (max-width: ${BREAKPOINTS.md}) {
        display: flex;
        flex-direction: column;
    }
`;
const ButtonWrapper = styled.div`
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    place-content: stretch center;
    align-items: stretch;
    border-radius: 0.5rem;
    background: #1a1e23;
    box-shadow: 0 1px #ffffff05, 0 1px 1px #0000001a inset;
`;
const Button = styled.button`
    background: ${props => props.background};
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
    opacity: ${props => props.disabled ? 0.25 : 1};
    pointer-events: ${props => props.disabled ? "none" : "auto"};

    &:hover {
        transform: translateY(-2px);
        transition: all 200ms ease;
    }
    &:active {
        transform: translateY(1px);
        transition: all 200ms ease;
        box-shadow: none;
    }

    transition: 0.25s ease;
    min-height: 55px;
    border-radius: 0.5rem;
    flex: 1 1 100%;
    box-sizing: border-box;
    max-height: 50%;
    color: #ffffff;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    overflow: visible;
    padding: 0 10px 0 15px;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    user-select: none;
`;
const ButtonLabel = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    img {
        width: 25px;
        height: 25px;
    }

    span {
        font-weight: 700;
    }
`;
const BetPayout = styled.div`
    background: ${props => props.background};
    color: ${props => props.color};

    padding: 12px 11px;
    border-radius: 6px;
    font-size: 14px;

    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    text-transform: uppercase;
`;
const BetSelection = ({
  buttonsDisabled,
  onJoinGame
}) => {
  return <Container>
            <ButtonWrapper>
                <Button onClick={() => onJoinGame("blue")} background={CARD_COLORS.BLACK} disabled={buttonsDisabled}>
                    <ButtonLabel>
                        <img src={black} alt="Blue" />
                        <span>Place Bet</span>
                    </ButtonLabel>
                    <BetPayout background="#496FD8" color="#AEC4FF">
                        win 2x
                    </BetPayout>
                </Button>
            </ButtonWrapper>
            <ButtonWrapper>
                <Button onClick={() => onJoinGame("green")} background={CARD_COLORS.GREEN} disabled={buttonsDisabled}>
                    <ButtonLabel>
                        <img src={green} alt="Green" />
                        <span>Place Bet</span>
                    </ButtonLabel>
                    <BetPayout background="#4E8225" color="#A5EF6C">
                        win 14x
                    </BetPayout>
                </Button>
            </ButtonWrapper>
            <ButtonWrapper>
                <Button onClick={() => onJoinGame("red")} background={CARD_COLORS.RED} disabled={buttonsDisabled}>
                    <ButtonLabel>
                        <img src={red} alt="Red" />
                        <span>Place Bet</span>
                    </ButtonLabel>
                    <BetPayout background="#B53F36" color="#FFB4AE">
                        win 2x
                    </BetPayout>
                </Button>
            </ButtonWrapper>
        </Container>;
};
export default BetSelection;