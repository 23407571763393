import styled from "styled-components";
import Countdown from "react-countdown";
import { TimerBar } from "../../components/TimerBar.jsx";
import BREAKPOINTS from "../../constants/breakpoints";
const CountdownTextContainer = styled.div`
    font-size: 20px;
    display: flex;
    text-align: center;
    justify-content: center;
    column-gap: 8px
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;

    @media (max-width: ${BREAKPOINTS.sm}) {
        font-size: 16px;
    }
`;
const CountdownBarContainer = styled.div`
    position: relative;
`;
const CountdownBar = styled.div`
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 0px;
    box-shadow: 0 1px #ffffff05, 0 1px 1px #0000001a inset;
    height: 0.25rem;

    @media (max-width: ${BREAKPOINTS.sm}) {
        margin-top: -8px;
    }
`;
const TimerText = styled.div`
    font-size: 1.2em;
    font-weight: 500;
    color: ${props => props.theme.colors.primary};
`;
const Timer = ({
  loading,
  gameState,
  GAME_STATES,
  waitTime,
  rendererR,
  setGameState
}) => {
  return <>
            <CountdownTextContainer>
                {!loading && gameState === GAME_STATES.InProgress ? <div style={{
        height: "1.8em"
      }} /> : gameState === GAME_STATES.NotStarted ? <TimerText>WAITING FOR BETS</TimerText> : <>
                        <TimerText>ROLLING IN</TimerText>
                        <Countdown key={waitTime} date={waitTime} renderer={props => rendererR(props, waitTime)} intervalDelay={0} precision={3} />
                    </>}
            </CountdownTextContainer>
            <CountdownBarContainer>
                <CountdownBar>
                    {!loading && gameState === "PLACE YOUR BETS" && <TimerBar maxDuration={15000} waitTime={waitTime} gameStates={GAME_STATES} updateGameState={state => setGameState(state)} />}
                </CountdownBar>
            </CountdownBarContainer>
        </>;
};
export default Timer;