import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import BREAKPOINTS from "../../constants/breakpoints";

// Components
import CashierModal from "../modals/CashierModal";

// Assets
import { ReactComponent as CashierIcon } from "../../assets/v2/cashier.svg";
const CashierComponent = ({
  isLoading,
  isAuthenticated,
  user,
  currency
}) => {
  const [openCashier, setOpenCashier] = useState(false);
  return <Fragment>
            <CashierModal handleClose={() => setOpenCashier(!openCashier)} open={openCashier} user={user} isAuthenticated={isAuthenticated} activeCurrency={currency} />
            <CashierButton onClick={() => setOpenCashier(!openCashier)}>
                <CashierIcon />
            </CashierButton>
        </Fragment>;
};
const CashierButton = styled.button`
    background: ${props => props.theme.colors.tertiary};
    color: ${props => props.theme.colors._colorOnTeritary // if has special color, use it
? props.theme.colors._colorOnTeritary : props.theme.colors.primary};
    font-weight: 700;
    font-size: 13px;
    border: none;
    outline: none;
    padding: 7px 10px;
    border-radius: 7px;

    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
        filter: brightness(1.1);
    }

    @media (max-width: ${BREAKPOINTS.sm}) {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        padding: 0;
        border-radius: 50%;
    }

    & > svg {
        height: 14px;
        width: 14px;
    }
`;
CashierComponent.propTypes = {
  isLoading: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object
};
const mapStateToProps = state => ({
  isLoading: state.auth.isLoading,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  currency: state.currency.currency
});
export default connect(mapStateToProps)(CashierComponent);