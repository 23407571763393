import React from "react";
import styled from "styled-components";
import Moment from "react-moment";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
const RareOpening = ({
  message
}) => {
  const history = useHistory();
  const clickOnCrate = () => {
    history.push(`/crates/${message.case.id}`);
  };
  return <Chatbox>
            <Time format="hh:mm A">{message.created}</Time>
            <Username>{message.user.username}</Username>
            <Content>
                <TextContent>
                    won{" "}
                    <span style={{
          color: "#fff"
        }}>
                        {message.item.name} (${message.item.fiatPrice})
                    </span>{" "}
                    from a{" "}
                    <CrateLink href={`/crates/${message.case.id}`}>
                        {message.case.name}
                    </CrateLink>
                </TextContent>
                <img src={message.item.imageUrl} alt={message.item.name} style={{
        width: "auto",
        height: "60px",
        cursor: "pointer"
      }} onClick={() => clickOnCrate} />
            </Content>
        </Chatbox>;
};
const Chatbox = styled.div`
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    background: ${props => props.theme.background.secondary};
    padding: 7px;
    border-radius: 10px;
    font-weight: 500;
    letter-spacing: 0.1em;
    position: relative;
    font-size: 11px;

    & > div {
        max-width: 240px;
    }
`;
const Username = styled.div`
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    position: relative;
    margin-top: 7px;
    margin-left: 5px;
    font-size: 9.6px;
    color: ${props => props.theme.colors.primary};
`;
const Content = styled.div`
    color: ${props => props.theme.colors.secondary};
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.1em;
    white-space: normal;
    word-wrap: break-word;
    hyphens: auto;
    display: flex;
    justify-content: center;
    width: 100%;
`;
const Time = styled(Moment)`
    margin-left: auto;
    margin-bottom: -20px;
    opacity: 0.7;
    color: ${props => props.theme.colors.secondary};
`;
const CrateLink = styled.a`
    text-decoration: none;
    color: ${props => props.theme.colors.tertiary};
`;
const TextContent = styled.div`
    margin: 5px;
    font-size: 12px;
`;
RareOpening.propTypes = {
  message: PropTypes.object.isRequired
};
export default RareOpening;