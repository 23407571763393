import React from "react";
import PropTypes from "prop-types";
import Card from "./Card";
import styled from "styled-components";
import { CSSTransition, TransitionGroup } from "react-transition-group";
const Hand = ({
  hand,
  isActive,
  setActiveHand
}) => {
  return <HandContainer>
            {hand.total && <HeaderContainer>
                    <Score>{hand.total}</Score>
                </HeaderContainer>}
            <TransitionGroup appear={true} onClick={setActiveHand} style={hand.done ? {
      opacity: "0.5"
    } : {}}>
                {hand.cards.map((card, i) => <CSSTransition key={i} classNames="list" timeout={{
        enter: 800,
        exit: 300
      }}>
                        <Card state={hand.state} value={card.value} suit={card.suit} isPrivate={card.value === "dummy"} isActive={isActive} setActiveHand={setActiveHand} />
                    </CSSTransition>)}
            </TransitionGroup>
            {hand.betAmount && <BetContainer>
                    <span>Bet: {hand.betAmount}</span>
                </BetContainer>}
        </HandContainer>;
};
Hand.propTypes = {
  cards: PropTypes.array.isRequired,
  score: PropTypes.number,
  inProgress: PropTypes.bool.isRequired,
  owner: PropTypes.string
};
export default Hand;
const HandContainer = styled.div`
    margin-bottom: 20px;

    > div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .state {
        font-weight: 500;
        text-transform: uppercase;
        font-size: 16px;
        color: ${({
  theme
}) => theme.colors.primary};
    }
`;
const HeaderContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
`;
const BetContainer = styled.div`
    width: 100%;
    margin-top: 10px;
    text-align: center;
    color: ${props => props.theme.colors.secondary};
`;
const Score = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    background: ${({
  theme
}) => theme.background.secondary};
    color: ${({
  theme
}) => theme.colors.primary};
    font-weight: 500;
    font-size: 14px;
`;