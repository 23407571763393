import React, { useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer } from "recharts";
import parseCommasToThousands from "../../utils/parseCommasToThousands";

// Same game states as in backend
const GAME_STATES = {
  NotStarted: 1,
  Starting: 2,
  InProgress: 3,
  Over: 4,
  Blocking: 5,
  Refunded: 6
};
const BET_STATES = {
  Playing: 1,
  CashedOut: 2
};
const CrashAnimation = ({
  loading,
  multiplier,
  ownBet,
  gameState
}) => {
  const theme = useTheme();
  let crashed = gameState === GAME_STATES.Over;
  let lost = crashed && ownBet?.status === BET_STATES.Playing;
  let cashed = ownBet?.status === BET_STATES.CashedOut;
  const [crashData, setCrashData] = useState([]);
  useEffect(() => {
    if (gameState === GAME_STATES.Starting) {
      console.log(gameState, "resetting crash data");
      setCrashData([]);
    }
    if (gameState === GAME_STATES.InProgress) {
      if (multiplier === 1) {
        return;
      }
      if (crashData.length > 0) {
        setCrashData(data => [...data, {
          multiplier
        }]);
      } else {
        let pastMultipliers = [];
        for (let i = 1; i < multiplier; i += 0.01) {
          pastMultipliers.push({
            multiplier: i
          });
        }
        setCrashData([...pastMultipliers, {
          multiplier
        }]);
      }
    }
  }, [gameState, multiplier]);
  const _getNextHigherNumber = multiplier => {
    return Math.ceil(multiplier + 0.5);
  };
  return <>
            {gameState !== GAME_STATES.Starting && <ResponsiveContainer width="100%" height="100%">
                    <AreaChart data={crashData}>
                        <XAxis tick={false} stroke={theme.colors.secondary} />
                        <YAxis type="number" dataKey="multiplier" domain={[1, multiplier > 2 ? _getNextHigherNumber(multiplier) : 2]} stroke={theme.colors.secondary} width={30} tick={{
          fill: theme.colors.secondary,
          fontSize: "12px",
          fontWeight: "500"
        }} />
                        <Area type="monotone" dataKey="multiplier" stroke={crashed ? "#ff4d4d" : "#00cc66"} fill={crashed ? "#ff4d4d" : "#00cc66"} />
                    </AreaChart>
                </ResponsiveContainer>}
            <Meta>
                <Payout>
                    {loading ? "LOADING" : `x${multiplier.toFixed(2)}`}
                </Payout>
                {(gameState === GAME_STATES.InProgress || gameState === GAME_STATES.Over) && ownBet && <Profit className={cashed ? "cashed" : lost ? "lost" : ""}>
                            {lost ? "-" : "+"}
                            {ownBet.status === BET_STATES.Playing ? parseCommasToThousands(ownBet.betAmount * multiplier) : parseCommasToThousands(ownBet.winningAmount)}
                        </Profit>}
            </Meta>
        </>;
};
const Meta = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
`;
const Payout = styled.div`
    color: #f8f8f8;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 0.1em;
    user-select: none;
    line-height: 1;
`;
const Profit = styled.span`
    color: #7b9efd;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0.1em;
    user-select: none;
    line-height: 1;
    margin-top: 3px;
    transition: color 0.4s ease;

    &.cashed {
        color: #11ca52;
    }

    &.lost {
        color: #ff4d4d;
    }
`;
export default CrashAnimation;