import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { chatSocket } from "../../services/websocket.service";
import { getActiveSugarRush, getChatMessages } from "../../services/api.service";
import useFetch from "../../hooks/useFetch";

// Components
import Header from "./ChatHeader";
import Messages from "./Messages";
import Controls from "./Controls";
const Chat = () => {
  const [chatMessages, setChatMessages] = useState([]);
  const [rain, setRain] = useState(null);
  const {
    isLoading: isLoadingMessages
  } = useFetch(getChatMessages, {
    onSuccess: data => {
      setChatMessages(data);
    }
  });
  const {
    isLoading: isLoadingRain
  } = useFetch(getActiveSugarRush, {
    onSuccess: data => {
      setRain(data);
    }
  });

  // Rain server state changed
  const rainStateChanged = newState => {
    setRain(newState);
  };

  // Add new chat message to the state
  const addMessage = message => {
    const data = JSON.parse(message);
    setChatMessages(state => state.length > 29 ? [...state.slice(1, state.length), data] : [...state, data]);
  };

  // Remove message from state
  const removeMessage = msgId => {
    setChatMessages(state => state.filter(message => message.id !== msgId));
  };
  useEffect(() => {
    chatSocket.on("chat_message", addMessage);
    chatSocket.on("remove_chat_message", removeMessage);
    // chatSocket.on("rain-state-changed", rainStateChanged);

    return () => {
      chatSocket.off("chat_message", addMessage);
      chatSocket.off("remove_chat_message", removeMessage);
      // chatSocket.off("rain-state-changed", rainStateChanged);
    };
  }, []);
  return <ChatContainer>
            <Header />
            <Messages loading={isLoadingMessages || isLoadingRain} chatMessages={chatMessages} />
            <Controls rain={rain} />
        </ChatContainer>;
};
const ChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: 1px solid ${({
  theme
}) => theme.border.primary};
    background: ${({
  theme
}) => theme.background.primary};
`;
export default Chat;