import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import BREAKPOINTS from "../../constants/breakpoints";
import { HEADER_HEIGHT } from "../../constants/ui";
import useMediaQuery from "../../hooks/useMediaQuery";

// Components;
import GameItem from "../navigation/GameItem";
import Item from "../navigation/Item";
import OfficialLinks from "./OfficialLinks";
import { Label } from "../navigation/Item";
import capitalize from "../../utils/capatlize";

// Assets
import { ReactComponent as Casino } from "../../assets/v2/platform-icons/casino.svg";
import { ReactComponent as Sports } from "../../assets/v2/platform-icons/sports.svg";
import affiliate from "../../assets/v2/affiliate.webp";
import blastAffiliate from "../../assets/v2/blast/blast-affiliate.webp";
import { ReactComponent as Race } from "../../assets/v2/nav-icons/race.svg";
import { ReactComponent as Twitter } from "../../assets/v2/social-icons/twitter.svg";
import { ReactComponent as Telegram } from "../../assets/v2/social-icons/telegram.svg";
import { ReactComponent as Etherscan } from "../../assets/v2/social-icons/etherscan.svg";
import { ReactComponent as Coingecko } from "../../assets/v2/social-icons/coingecko.svg";
import { ReactComponent as ExpandIcon } from "../../assets/v2/icons/expand.svg";
import { ReactComponent as StakingIcon } from "../../assets/v2/nav-icons/staking.svg";
import { RiTokenSwapFill } from "react-icons/ri";
import BuySweet from "../modals/BuySweet";
const NavigationBar = styled.div`
    position: relative;
    height: 100%;
    width: ${props => props.isWide ? "235px" : "60px"};
    min-width: ${props => props.isWide ? "235px" : "60px"};
    padding-left: 0px;
    padding-right: 0px;
    overflow-y: auto;
    border-right: 1px solid ${props => props.theme.border.primary};
    background: ${props => props.theme.background.primary};
    white-space: nowrap;

    transition: all 0.2s ease-in-out;

    @media (max-width: ${BREAKPOINTS.md}) {
        width: 100%;
        min-width: 100%;
        display: flex;
        flex-direction: column;
    }
`;
const LoginButtonWrapper = styled.div`
    .authButton {
        text-transform: uppercase;
        padding: 12px;
        margin: 15px 0;
        justify-self: center;
        background: ${({
  theme
}) => theme.colors.tertiary};
        border: none;
        outline: none;
        border-radius: 5px;
        color: ${({
  theme
}) => theme.colors.primary};
        letter-spacing: 0.1em;
        line-height: 1;
        font-size: 12px;
        font-weight: 700;
        box-shadow: ${({
  theme
}) => theme.shadow && `0px 0px 15px 0px ${theme.shadow.tertiary}`};

        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover {
            filter: brightness(1.1);
        }
    }

    display: flex;
    justify-content: center;
`;
const NavHeaderWrapper = styled.div`
    display: flex;
    flex-direction: ${props => props.isWide ? "row" : "column"};
    border-bottom: 1px solid ${props => props.theme.border.primary};
    margin-bottom: 15px;
    margin-top: ${props => props.isWide ? "none" : "15px"};
    height: ${props => props.isWide ? HEADER_HEIGHT : "auto"};
    align-items: center;
    padding-bottom: ${props => props.isWide ? "none" : "5px"};

    @media (max-width: ${BREAKPOINTS.md}) {
        justify-content: center;
    }
`;
const PlatformContainer = styled.div`
    display: flex;
    flex-direction: ${props => props.isWide ? "row" : "column"};
    margin: ${props => props.isWide ? "15px 0" : "10px 7px"};
    border-radius: 2rem;
    background: ${props => props.theme.background._platformSwitch};
    padding: 3px 5px;
    width: ${props => props.isWide ? "75%" : "auto"};

    @media (max-width: ${BREAKPOINTS.md}) {
        width: 100%;
        margin: 15px;
    }
`;
const Platform = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: ${props => props.isWide ? "50%" : "100%"};
    padding: ${props => props.isWide ? "8px 0;" : "10px"};
    background: ${props => props.selected ? props.theme.colors.tertiary : "transparent"};
    color: ${props => props.selected ? props.theme.colors._colorOnTeritary // if has special color, use it
? props.theme.colors._colorOnTeritary : props.theme.colors.primary : props.theme.colors.secondary};
    border-radius: ${props => props.isWide ? "1rem" : "50%"};
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background-size: cover;
    font-size: 12.5px;

    &:hover {
        opacity: 0.9;
    }

    & > svg {
        height: 16px;
        width: auto;
    }
`;
const Seperator = styled.div`
    border-bottom: 1px solid ${props => props.theme.border.primary};
    margin: 15px 0;
`;
const Footer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 25px;

    @media (min-height: 850px) {
        position: absolute;
        bottom: 0;
        margin-top: 0;
    }
`;
const Socials = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;
const LinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: ${props => props.isWide ? "0 15px" : "0 7px"};
`;
const BecomeAffiliateContainer = styled.div`
    padding: 10px 15px;
    margin: 0 20px;
    margin-bottom: 10px;
    display: flex;
    border: 1px solid ${props => props.theme.border.primary};
    border-radius: 10px;
    background: linear-gradient(124deg, #622e94 37.56%, #5f3797 70.23%);
    background-image: url(${props => props.isBlast ? blastAffiliate : affiliate});
    background-repeat: no-repeat;
    background-size: cover;
    backdrop-filter: blur(22px);

    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
        filter: brightness(1.1);
    }
`;
const AffiliateText = styled.div`
    width: 50%;
    text-align: left;
    font-size: 10px;
    color: ${props => props.theme.colors.primary};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
`;
const SocialsContainer = styled.div`
    padding: 0 15px 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${props => props.theme.colors.secondary};
`;
const SocialLink = styled.a`
    height: 18px;
    width: 18px;
    outline: none;
    color: ${props => props.theme.colors.secondary};
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
        opacity: 0.9;
    }

    & > svg {
        height: 100%;
        width: 100%;
    }
`;
const CategoryLabel = styled.div`
    color: ${props => props.theme.colors.secondary};
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 5px;
    margin-left: 20px;
    margin-bottom: 10px;
`;
const ExpandIconWrapper = styled.div`
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.primary};
    cursor: pointer;

    margin: 0 10px;

    transition: all 0.2s ease-in-out;
    &:hover {
        opacity: 0.9;
    }

    transform: rotate(${props => props.toggled ? "0deg" : "180deg"});

    & > svg {
        height: 24px;
        width: auto;
    }
`;
const EarnNowLink = styled.a`
    color: gold;
    font-weight: 500;
    font-size: 12.5px;
    text-decoration: none;
    letter-spacing: 0.05em;

    cursor: pointer;
    transition: all 0.2s ease-in-out;

    border: 1px solid transparent;
    &:hover {
        border: 1px solid ${props => props.theme.border.primary};
        background: ${props => props.theme.background._activeNavigation};
    }

    width: 100%;
    padding: ${props => props.isWide ? "0 15px" : "0 10px"};
    height: 2.5rem;
    display: flex;
    align-items: center;

    justify-content: ${props => props.isWide ? "inherit" : "center"};
    gap: 10px;
    outline: none;
    border-radius: 10px;
`;
const BuySweetLink = styled.div`
    color: ${props => props.theme.colors.secondary};
    pointer-events: ${props => props.disabled ? "none" : "auto"};
    width: 100%;
    padding: ${props => props.isWide ? "0 15px" : "0 10px"};
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: ${props => props.isWide ? "inherit" : "center"};
    gap: 10px;
    text-decoration: none;
    font-size: 12.5px;
    font-weight: 500;
    letter-spacing: 0.05em;
    outline: none;
    border: 1px solid transparent;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
    opacity: ${props => props.disabled ? "0.5" : "1"};

    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
        border: 1px solid ${props => props.theme.border.primary};
        background: ${props => props.theme.background._activeNavigation};
    }
`;
const BuySweetIcon = styled(RiTokenSwapFill)`
    height: 20px;
    width: 20px;
`;
const RacePrize = styled.div`
    color: ${props => props.theme.colors.primary};
    background: ${props => props.theme.colors.tertiary};
    padding: 3px 10px;
    border-radius: 7px;

    box-shadow: 0 0 10px ${props => props.theme.colors.tertiary};
`;
const Navigation = ({
  platform,
  setPlatform,
  isAuthenticated,
  activeTheme
}) => {
  const history = useHistory();
  const isMobile = useMediaQuery(BREAKPOINTS.md);
  const [isWide, setIsWide] = useState(false);
  const [open, setOpen] = useState(false);

  // ? Set the navigation to wide if the user is on mobile
  useEffect(() => {
    setIsWide(true);
  }, [isMobile]);
  return <NavigationBar isWide={isWide}>
            <BuySweet open={open} handleClose={() => setOpen(false)} />
            <NavHeaderWrapper isWide={isWide}>
                {!isMobile && <ExpandIconWrapper toggled={isWide}>
                        <ExpandIcon onClick={() => setIsWide(!isWide)} />
                    </ExpandIconWrapper>}
                <PlatformContainer isWide={isWide}>
                    <Platform isWide={isWide} selected={platform === "casino"} onClick={() => {
          history.push("/");
          setPlatform("casino");
        }}>
                        <Casino />
                        {isWide && <span>CASINO</span>}
                    </Platform>
                    <Platform isWide={isWide} selected={platform === "sports"} data-tooltip-id="default" data-tooltip-content="Temporarily Disabled"
        // onClick={() => {
        //     history.push("/sports/live");
        //     setPlatform("sports");
        // }}
        >
                        <Sports />
                        {isWide && <span>SPORTS</span>}
                    </Platform>
                </PlatformContainer>
            </NavHeaderWrapper>
            {platform === "casino" ? <>
                    {isWide && <CategoryLabel>SWEET GAMES</CategoryLabel>}
                    <LinksContainer isWide={isWide}>
                        <GameItem root="/slots" game="slots" isWide={isWide} />
                        <GameItem root="/live-casino" game="live-casino" isWide={isWide} />
                        <GameItem root="/game-show" game="game-show" isWide={isWide} />
                        <GameItem root="/crates" game="crates" isWide={isWide} disabled={false} />
                        <GameItem root="/roulette" game="roulette" isWide={isWide} disabled={false} />
                        <GameItem root="/crate-battles" game="battles" isWide={isWide} disabled={true} />
                        <GameItem root="/blackjack" game="blackjack" isWide={isWide} disabled={true} />
                        <GameItem root="/crash" game="crash" isWide={isWide} disabled={true} />
                        <GameItem root="/dice" game="dice" isWide={isWide} disabled={true} />
                        <GameItem root="/coinflip" game="coinflip" isWide={isWide} disabled={true} />
                    </LinksContainer>
                </> : <></>}
            <Seperator />
            {isWide && <CategoryLabel>More</CategoryLabel>}
            <LinksContainer isWide={isWide}>
                <Item root="/race" label="Race" icon={Race} isWide={isWide}>
                    <RacePrize>$10,000</RacePrize>
                </Item>
                <BuySweetLink isWide={isWide} onClick={() => setOpen(true)}>
                    <BuySweetIcon />
                    {isWide && <Label>BUY $SWEET</Label>}
                </BuySweetLink>
                <EarnNowLink href="https://stake.sweet.gg" target="_blank" rel="noreferrer" data-tooltip-content={isWide ? "" : capitalize("Earn Now")} data-tooltip-id="default" isWide={isWide}>
                    <StakingIcon style={{
          height: "20px",
          width: "20px"
        }} />
                    {isWide && <Label>Earn Now</Label>}
                </EarnNowLink>
            </LinksContainer>
            {isWide && <Footer>
                    <OfficialLinks />
                    <BecomeAffiliateContainer isBlast={activeTheme === "blast"} onClick={() => history.push("/affiliates")}>
                        <AffiliateText>
                            Become an <br /> Affiliate ➜
                        </AffiliateText>
                    </BecomeAffiliateContainer>
                    <Seperator />
                    <SocialsContainer>
                        Find us on:
                        <Socials>
                            <SocialLink href="https://twitter.com/sweetdotgg" target="_blank" rel="noreferrer" alt="Twitter">
                                <Twitter />
                            </SocialLink>
                            <SocialLink href="https://t.me/sweetportal" target="_blank" rel="noreferrer" alt="Telegram">
                                <Telegram />
                            </SocialLink>
                            <SocialLink href="https://etherscan.io/token/0x2df7d0e4903029717c949cad204075a3d75c8806" target="_blank" rel="noreferrer" alt="Etherscan">
                                <Etherscan />
                            </SocialLink>
                            <SocialLink href="https://www.coingecko.com/coins/sweet" target="_blank" rel="noreferrer" alt="Coingecko">
                                <Coingecko />
                            </SocialLink>
                        </Socials>
                    </SocialsContainer>
                </Footer>}
        </NavigationBar>;
};
Navigation.propTypes = {
  user: PropTypes.object
};
const mapStateToProps = state => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
  activeTheme: state.theme.themeType
});
export default connect(mapStateToProps)(Navigation);