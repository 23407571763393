// Assets
import ogBackground from "../assets/background.webp";

// * NOTE: we need to keep the structure of each theme identical. otherwise styles will not be applied consistently!

export const LIGHT_THEME = {
    background: {
        content: "#15191e", // the main page background (can be a image aswell)
        primary: "#1b232d", // card background
        secondary: "#292f3c", // inner card background
        tertiary: "linear-gradient(180deg, #2C3644 0%, #222A34 100%)", // e.g sugar rush, selected game filter, official links
        _activeNavigation: "linear-gradient(180deg, #2c3644 0%, #222a34 100%)", // active navigation item
        _chatMessageInput: "#1f2731", // chat message input
        _platformSwitch: "#151c23", // platform switch
        _chatMessage: "#242b37",
    },
    colors: {
        primary: "#ffffff", // title
        secondary: "#7B8398", // text
        tertiary: "#eb2584", // highlight
        green: "#03df58", // green
    },
    border: {
        primary: "rgba(255, 255, 255, 0.08)",
    },
    button: {
        primary: "#EB2584",
        secondary: "#222d39",
    },
};

export const OG_THEME = {
    background: {
        content: `url(${ogBackground}) center center / cover no-repeat rgb(26, 1, 45)`, // the main page background (can be a image aswell)
        primary: "#1a012d", // card background
        secondary: "rgba(48, 15, 72, 0.45)", // inner card background
        tertiary: "#25083a",
        _activeNavigation: "#2b1542", // active navigation item
        _chatMessageInput: "#130120", // chat message input
        _platformSwitch: "rgba(48, 15, 72, 0.45)",
        _chatMessage: "#240839",
    },
    colors: {
        primary: "#ffffff", // title
        secondary: "rgb(128, 87, 158)", // text
        tertiary: "rgb(146, 64, 238)", // highlight
        green: "#03df58", // green
    },
    border: {
        primary: "rgb(52, 18, 78)",
    },
    button: {
        primary: "rgb(146, 64, 238)",
        secondary: "#3c1855",
    },
};

export const MIDNIGHT_THEME = {
    background: {
        content: "#101520", // the main page background (can be a image aswell)
        primary: "#0B0F18", // card background
        secondary: "#171D2B", // inner card background
        tertiary: "linear-gradient(180deg, #2C3644 0%, #222A34 100%)",
        _activeNavigation: "#121622", // active navigation item
        _chatMessageInput: "#111520", // chat message input
        _platformSwitch: "#121622", // platform switch
        _chatMessage: "#10141f",
    },
    colors: {
        primary: "#ffffff", // title
        secondary: "#7B8398", // text
        tertiary: "#eb2584", // highlight
        green: "#03df58", // green
    },
    border: {
        primary: "rgba(255, 255, 255, 0.04)",
    },
    button: {
        primary: "#EB2584",
        secondary: "#222d39",
    },
};

export const BLAST_THEME = {
    background: {
        content: "#000000", // the main page background (can be a image aswell)
        primary: "#11140c", // card background
        secondary: "#2e3129", // inner card background
        tertiary: "#1d2018", // e.g sugar rush, selected game filter, official links
        _activeNavigation: "#292904", // active navigation item
        _chatMessageInput: "#1d2018", // chat message input
        _platformSwitch: "#1d2018", // platform switch
        _chatMessage: "#1d2018",
    },
    colors: {
        primary: "#fcfdc7", // title
        secondary: "#9ba885", // text
        tertiary: "#fcfc03", // highlight
        green: "#98dd28", // green
        _colorOnTeritary: "#000000",
    },
    border: {
        primary: "rgba(255, 255, 255, 0.08)",
    },
    button: {
        primary: "#fcfc03",
        _colorOn_primary: "#000000",
        secondary: "#2e3129",
    },
};
