import PropTypes from "prop-types";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

// Redux Actions
import { changeWallet, changeEventKeys } from "./store/actions/auth";
import { updateSweetTokenData, updateUsersOnline } from "./store/actions/site";

// Socket Services
import {
    walletSocket,
    exchangeSocket,
    chatSocket,
    notificationsSocket,
} from "./services/websocket.service";
import toast from "react-hot-toast";

// TODO: look if there is a cleaner way to do this instead of via a component
const EventHandler = withRouter(
    ({
        changeWallet,
        changeEventKeys,
        updateSweetTokenData,
        updateUsersOnline,
    }) => {
        useEffect(() => {
            // Change wallet amount
            const handleWalletUpdate = (update) => {
                changeWallet({
                    currency_id: update.currency_id,
                    type: update.wallet_type,
                    amount: update.amount,
                });
            };

            // Update sweet token data
            const handleSweetTokenDataUpdate = (update) => {
                if (!update) {
                    return;
                }

                const data = JSON.parse(update);

                let formattedData = {
                    usdPrice: parseFloat(data.price_usd),
                    dailyPriceChange: parseFloat(data.price_change_24h),
                    marketCap: parseFloat(data.market_cap_usd),
                    dailyVolume: parseFloat(data.volume_usd_24h),
                };

                updateSweetTokenData(formattedData);
            };

            // Update online users
            const handleUsersOnlineUpdate = (count) => {
                updateUsersOnline(count);
            };

            const handleError = (error) => {
                toast.error(error);
            };

            const handleNotification = (notification) => {
                toast.success(notification);
            };

            walletSocket.on("update_wallet_balance", handleWalletUpdate);
            exchangeSocket.on(
                "update_sweet_token_data",
                handleSweetTokenDataUpdate,
            );
            chatSocket.on("update_online_users", handleUsersOnlineUpdate);
            notificationsSocket.on("send_notification", handleNotification);

            [walletSocket, exchangeSocket, chatSocket].forEach((socket) => {
                socket.on("error", handleError);
            });

            return () => {
                walletSocket.off("update_wallet_balance", handleWalletUpdate);
                exchangeSocket.off(
                    "update_sweet_token_data",
                    handleSweetTokenDataUpdate,
                );
                chatSocket.off("update_online_users", handleUsersOnlineUpdate);
                [walletSocket, exchangeSocket, chatSocket].forEach((socket) => {
                    socket.off("error", handleError);
                });
            };
        }, []);

        return null;
    },
);

EventHandler.propTypes = {
    changeWallet: PropTypes.func.isRequired,
    changeEventKeys: PropTypes.func.isRequired,
    updateSweetTokenData: PropTypes.func.isRequired,
    updateUsersOnline: PropTypes.func.isRequired,
};

export default connect(() => ({}), {
    changeWallet,
    changeEventKeys,
    updateSweetTokenData,
    updateUsersOnline,
})(EventHandler);
