import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { styled, ThemeProvider } from "styled-components";
import RecaptchaV3 from "react-google-recaptcha3";
import { Toaster } from "react-hot-toast";
import { Tooltip } from "react-tooltip";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";

import { RECAPTCHA_SITE_KEY } from "./constants/site";
import { loadUser } from "./store/actions/auth";
import { getSiteConfig, registerAffiliate } from "./services/api.service";
import EventHandler from "./EventHandler";
import useMediaQuery from "./hooks/useMediaQuery";
import BREAKPOINTS from "./constants/breakpoints";
import useFetch from "./hooks/useFetch.js";
import { HEADER_HEIGHT } from "./constants/ui.js";
import { loadExchangeRates, toggleNavbar } from "./store/actions/site.js";

// MUI Components
import { Drawer } from "@material-ui/core";

// Components
import Header from "./components/header/Header";
import Navigation from "./components/navigation/Navigation";
import Chat from "./components/chat/Chat";
import Preloader from "./components/Preloader";
import MobileHeader from "./components/header/MobileHeader.jsx";
import ScrollToTop from "./components/ScrollToTop.jsx";

// Views
import NotFound from "./views/404.jsx";
import Coinflip from "./views/Coinflip";
import CoinflipGames from "./views/CoinflipGames";
import CoinflipHistory from "./views/CoinflipHistory";
import Affiliates from "./views/Affiliates";
import Settings from "./views/Settings.jsx";
import Roulette from "./views/Roulette";
import Crash from "./views/Crash";
import Race from "./views/Race";
import Terms from "./views/Terms";
import Main from "./views/Main";
import Banned from "./views/Banned";
import AffiliatesRedirect from "./views/AffiliatesRedirect";
import Maintenance from "./views/Maintenance";
import Dice from "./views/Dice";
import Cases from "./views/Cases";
import Case from "./views/Case";
import CratesBattles from "./views/CratesBattles";
import CratesBattle from "./views/CratesBattle";
import Blackjack from "./views/Blackjack";
import UserTransactions from "./views/UserTransactions.jsx";
import Themes from "./views/Themes.jsx";
import SlotPage from "./views/Slots/SlotPage.jsx";
import SlotsPage from "./views/Slots/Slots.jsx";
import LiveCasinoPage from "./views/LiveCasino/LiveCasino.jsx";
import GameShowsPage from "./views/GameShows/GameShows.jsx";
import GameShowPage from "./views/GameShows/GameShowPage.jsx";
import LiveCasinoGamePage from "./views/LiveCasino/LiveCasinoPage.jsx";
import BlockedCountry from "./views/BlockedCountry";

// Assets
import logo from "./assets/v2/sweet-textlogo.webp";
import { ReactComponent as SupportIcon } from "./assets/v2/icons/support.svg";

const PlatformTypes = {
    CASINO: "casino",
    SPORTS: "sports",
};

const INTERCOM_APP_ID = "b3z6uuyr";

const App = ({
    isLoading,
    isAuthenticated,
    user,
    theme,
    showNavbar,
    showChat,
}) => {
    const isMobile = useMediaQuery(BREAKPOINTS.md);
    const dispatch = useDispatch();
    const newUserRef = useRef(false);
    const { user: authUser } = useDynamicContext();

    const [platform, setPlatform] = useState(PlatformTypes.CASINO);
    const { data, isLoading: isLoadingSiteData } = useFetch(getSiteConfig, {
        initialData: {
            maintenanceEnabled: false,
        },
        reloadOnError: false,
    });

    const shouldRedirect = useSelector(
        (state) => state.blocked.shouldRedirectToBannedCountry,
    );

    useEffect(() => {
        dispatch(loadUser());
        dispatch(loadExchangeRates());

        RecaptchaV3.init(RECAPTCHA_SITE_KEY);

        // Set platform based on url path (casino or sports) on first load
        if (window.location.pathname.includes("/sports")) {
            setPlatform(PlatformTypes.SPORTS);
        } else {
            setPlatform(PlatformTypes.CASINO);
        }
    }, []);

    // Save the new user status to a ref
    useEffect(() => {
        if (authUser) {
            if (authUser.newUser) {
                newUserRef.current = true;
            }
        }
    }, [authUser]);

    // Register affiliate if user is new and is authenticated
    useEffect(() => {
        if (newUserRef.current) {
            console.log("new user");
            let affCode = localStorage.getItem("affiliate");
            if (affCode) {
                let parsed = JSON.parse(affCode);
                registerAffiliate(parsed.code);
                localStorage.removeItem("affiliate");
            }
        }
    }, [isAuthenticated]);

    // ? This is to prevent the navbar & chat from being open on mobile when the page is loaded
    useEffect(() => {
        if (isMobile) {
            dispatch(toggleNavbar());
        }
    }, [isMobile]);

    const handleNavbarClose = () => {
        dispatch(toggleNavbar());
    };
    return data.maintenanceEnabled ? (
        <ThemeProvider theme={theme}>
            <Maintenance />
        </ThemeProvider>
    ) : shouldRedirect ? (
        <ThemeProvider theme={theme}>
            <BlockedCountry />
        </ThemeProvider>
    ) : (
        <ThemeProvider theme={theme}>
            <Tooltip
                id="default"
                style={{
                    zIndex: 9999,
                    background: theme.background.secondary,
                    color: theme.colors.primary,
                    fontSize: "10px",
                    padding: "0.3rem",
                }}
            />
            <Toaster
                position="bottom-center"
                toastOptions={{
                    style: {
                        background: theme.background.secondary,
                        color: theme.colors.primary,
                        fontWeight: "500",
                        marginBottom: "1rem",
                    },
                }}
            />
            {isLoadingSiteData || isLoading ? (
                <Preloader />
            ) : (
                <Router>
                    <IntercomProvider
                        appId={INTERCOM_APP_ID}
                        shouldInitialize={true}
                        autoBoot
                        autoBootProps={{
                            name: user ? user.username : "Guest",
                            ...(user && {
                                customAttributes: {
                                    userId: user.id,
                                },
                            }),
                        }}
                    >
                        <EventHandler />
                        {isMobile && (
                            <MobileHeader
                                platform={platform}
                                isAuthenticated={isAuthenticated}
                            />
                        )}
                        <PageContainer>
                            {isMobile ? (
                                <MobileDrawer
                                    variant="temporary"
                                    anchor="left"
                                    onClose={handleNavbarClose}
                                    open={showNavbar}
                                    ModalProps={{ disableEnforceFocus: true }}
                                >
                                    <Navigation
                                        platform={platform}
                                        setPlatform={setPlatform}
                                    />
                                </MobileDrawer>
                            ) : (
                                <>
                                    <Navigation
                                        platform={platform}
                                        setPlatform={setPlatform}
                                    />
                                    <LiveSupport
                                        style={{
                                            right: showChat ? "275px" : "15px",
                                        }}
                                    />
                                </>
                            )}
                            <Content>
                                {!isMobile && <Header platform={platform} />}
                                <InnerPageContainer>
                                    <ScrollToTop />
                                    <Switch>
                                        <Route
                                            exact
                                            path="/"
                                            component={Main}
                                        />
                                        <Route
                                            exact
                                            path="/affiliates"
                                            component={Affiliates}
                                        />
                                        <Route
                                            exact
                                            path="/roulette"
                                            component={Roulette}
                                        />
                                        <Route
                                            exact
                                            path="/crash"
                                            component={Crash}
                                        />
                                        <Route
                                            exact
                                            path="/race"
                                            component={Race}
                                        />
                                        <Route
                                            exact
                                            path="/terms"
                                            component={Terms}
                                        />
                                        <Route
                                            exact
                                            path="/banned"
                                            component={Banned}
                                        />
                                        <Route
                                            exact
                                            path="/dice"
                                            component={Dice}
                                        />
                                        <Route
                                            exact
                                            path="/crates"
                                            component={Cases}
                                        />
                                        <Route
                                            exact
                                            path="/crates/:slug"
                                            component={Case}
                                        />
                                        <Route
                                            exact
                                            path="/coinflip"
                                            component={CoinflipGames}
                                        />
                                        <Route
                                            exact
                                            path="/coinflip/history"
                                            component={CoinflipHistory}
                                        />
                                        <Route
                                            exact
                                            path="/coinflip/:id"
                                            component={Coinflip}
                                        />
                                        <Route
                                            exact
                                            path="/a/:affiliateCode"
                                            component={AffiliatesRedirect}
                                        />
                                        <Route
                                            exact
                                            path="/crate-battles"
                                            component={CratesBattles}
                                        />
                                        <Route
                                            exact
                                            path="/crate-battles/:id"
                                            component={CratesBattle}
                                        />
                                        <Route
                                            exact
                                            path="/blackjack"
                                            component={Blackjack}
                                        />
                                        <Route
                                            exact
                                            path="/user/transactions"
                                            component={UserTransactions}
                                        />
                                        <Route
                                            exact
                                            path="/user/settings"
                                            component={Settings}
                                        />
                                        <Route
                                            exact
                                            path="/themes"
                                            component={Themes}
                                        />
                                        <Route
                                            exact
                                            path="/slots"
                                            component={SlotsPage}
                                        />
                                        <Route
                                            exact
                                            path="/slots/:id"
                                            component={SlotPage}
                                        />
                                        <Route
                                            exact
                                            path="/live-casino"
                                            component={LiveCasinoPage}
                                        />
                                        <Route
                                            exact
                                            path="/live-casino/:id"
                                            component={LiveCasinoGamePage}
                                        />
                                        <Route
                                            exact
                                            path="/game-show"
                                            component={GameShowsPage}
                                        />
                                        <Route
                                            exact
                                            path="/game-show/:id"
                                            component={GameShowPage}
                                        />
                                        <Route
                                            exact
                                            path="*"
                                            component={NotFound}
                                        />
                                    </Switch>
                                </InnerPageContainer>
                                <Footer>
                                    <Logo src={logo} alt="Sweet.gg" />
                                    <SubText>
                                        The Sweetest Sensation of GambleFi
                                    </SubText>
                                    <CopyrightText>
                                        © 2024 Sweet.gg | All rights reserved.
                                    </CopyrightText>
                                    <Categories>
                                        <Category href="/terms">
                                            Terms of Service
                                        </Category>
                                        <Category
                                            href="https://sweetgg.gitbook.io/sweet-whitepaper/social-casino/faq"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            FAQ
                                        </Category>
                                        <Category
                                            href="https://sweetgg.gitbook.io/sweet-whitepaper/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Documentation
                                        </Category>
                                        <Category
                                            href="https://t.me/sweetportal"
                                            target="_blank"
                                            rel="noreferrer"
                                            alt="Telegram"
                                        >
                                            Support
                                        </Category>
                                        <Category
                                            href="https://forms.gle/oCrVNrm2q4dPE2EG8"
                                            target="_blank"
                                            rel="noreferrer"
                                            alt="Partnership"
                                        >
                                            Partnership Request
                                        </Category>
                                    </Categories>
                                </Footer>
                            </Content>
                            <ChatDrawer
                                anchor={isMobile ? "bottom" : "right"}
                                variant="persistent"
                                open={showChat}
                                isMobile={isMobile}
                            >
                                <Chat />
                            </ChatDrawer>
                        </PageContainer>
                    </IntercomProvider>
                </Router>
            )}
        </ThemeProvider>
    );
};

const mapStateToProps = (state) => ({
    isLoading: state.auth.isLoading,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    theme: state.theme.theme,
    showNavbar: state.site.showNavbar,
    showChat: state.site.showChat,
});

export default connect(mapStateToProps)(App);

const PageContainer = styled.div`
    display: flex;
    height: 100vh;
    width: 100vw;

    @media (max-width: ${BREAKPOINTS.md}) {
        padding-top: 70px;
    }
`;

const LiveSupportButton = styled.div`
    position: fixed;
    bottom: 15px;
    // right: 15px;
    z-index: 9999;
    background: ${(props) => props.theme.background.secondary};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border: 1px solid ${(props) => props.theme.border.primary};
    color: ${(props) => props.theme.colors.secondary};

    & svg {
        height: 20px;
        width: auto;
    }

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0.7rem;
    border-radius: 50%;

    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
        filter: brightness(1.1);
    }
`;

const LiveSupport = ({ style }) => {
    const { show, hide, isOpen } = useIntercom();
    return (
        <LiveSupportButton
            data-tooltip-content="Live Support"
            data-tooltip-id="default"
            onClick={isOpen ? hide : show}
            style={style}
        >
            <SupportIcon />
        </LiveSupportButton>
    );
};

const Content = styled.main`
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    background: ${(props) => props.theme.background.content};
    backdrop-filter: blur(20px);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;

const Footer = styled.footer`
    margin-top: 2rem;
    background: ${(props) => props.theme.background.primary};
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;

    @media (max-width: ${BREAKPOINTS.md}) {
        margin-bottom: 70px;
    }
`;

const Logo = styled.img`
    height: 40px;
    width: auto;
`;

const SubText = styled.div`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 500;
`;

const CopyrightText = styled.div`
    font-size: 12px;
    color: ${(props) => props.theme.colors.secondary};
`;

const Categories = styled.div`
    display: flex;
    width: 100%;
    margin-top: 1rem;
    justify-content: center;
    flex-wrap: wrap;
`;

const Category = styled.a`
    font-size: 12px;
    color: ${(props) => props.theme.colors.secondary};
    margin-right: 1rem;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    &:hover {
        opacity: 0.7;
    }
`;

const MobileDrawer = styled(Drawer)`
    & .MuiDrawer-paper {
        display: flex;
        flex-direction: column;
        background: ${(props) => props.theme.background.primary};
        position: absolute;
        overflow: auto;
        width: 230px;
        height: 100vh;
    }
`;

const ChatDrawer = styled(Drawer)`
    &.MuiDrawer-root {
        background: ${(props) => props.theme.background.primary};
        display: ${(props) => (props.open ? "block" : "none")};
    }

    & .MuiPaper-root {
        background: ${(props) => props.theme.background.primary};
        width: ${(props) => (props.isMobile ? "100%" : "260px")};
        height: ${(props) => (props.isMobile ? "calc(100% - 70px)" : "100%")};

        ${(props) =>
            !props.isMobile &&
            `
                    position: relative;
                    white-space: nowrap;
                    z-index: 0;
            `}
    }
`;

const InnerPageContainer = styled.div`
    min-height: calc(100vh - ${HEADER_HEIGHT});
`;
