import styled from "styled-components";
const Friends = () => <SVGWrapper>
    <svg viewBox="0 0 2000 2000" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1243.3 1081.69V1138.92C1243.3 1145.26 1238.5 1150.4 1232.57 1150.4H912.31C906.39 1150.4 901.58 1145.26 901.58 1138.92V1081.69C901.58 1028.27 926.68 980.63 965.9 949.7C975.4 942.21 988.23 941.7 998.17 948.51C1019.62 963.17 1045.09 971.64 1072.43 971.64C1099.73 971.64 1125.17 963.17 1146.63 948.48C1156.57 941.67 1169.4 942.18 1178.9 949.67C1218.14 980.59 1243.3 1028.23 1243.3 1081.69Z" fill="currentColor" />
        <path d="M1174.61 823.72C1174.61 862.46 1155.75 896.56 1127.28 915.93C1125.29 917.28 1123.23 918.61 1121.14 919.82C1106.65 928.25 1090.07 933.06 1072.45 933.06C1054.8 933.06 1038.22 928.25 1023.73 919.85C1021.61 918.63 1019.55 917.31 1017.56 915.96C989.15 896.52 970.29 862.46 970.29 823.72C970.29 763.34 1016.01 714.38 1072.45 714.38C1128.82 714.38 1174.61 763.34 1174.61 823.72Z" fill="currentColor" />
        <path d="M1408 1098.25V1142.54C1408 1147.45 1404.27 1151.45 1399.68 1151.45H1272.84C1273.66 1147.76 1274.07 1143.93 1274.07 1139.97V1082.74C1274.07 1058.26 1269.86 1034.38 1261.83 1012.07C1266.36 1012.71 1271.01 1013.02 1275.69 1013.02C1296.83 1013.02 1316.54 1006.45 1333.15 995.07C1336.76 992.6 1340.87 991.34 1344.98 991.34C1349.6 991.34 1354.25 992.9 1358.14 995.98C1388.51 1019.95 1408 1056.83 1408 1098.25Z" fill="currentColor" />
        <path d="M1354.82 898.45C1354.82 928.45 1340.2 954.87 1318.15 969.87C1316.6 970.92 1315.02 971.94 1313.4 972.88C1302.17 979.42 1289.32 983.14 1275.69 983.14C1264.84 983.14 1254.52 980.8 1245.13 976.57L1245.1 976.5L1200.17 923.67C1197.83 915.71 1196.6 907.25 1196.6 898.44C1196.6 851.71 1232 813.78 1275.7 813.78C1319.35 813.8 1354.82 851.72 1354.82 898.45Z" fill="currentColor" />
        <path d="M736.88 1098.25V1142.54C736.88 1147.45 740.61 1151.45 745.2 1151.45H872.04C871.22 1147.76 870.81 1143.93 870.81 1139.97V1082.74C870.81 1058.26 875.02 1034.38 883.05 1012.07C878.52 1012.71 873.87 1013.02 869.19 1013.02C848.05 1013.02 828.34 1006.45 811.73 995.07C808.12 992.6 804.01 991.34 799.9 991.34C795.28 991.34 790.63 992.9 786.74 995.98C756.37 1019.95 736.88 1056.83 736.88 1098.25Z" fill="currentColor" />
        <path d="M790.07 898.45C790.07 928.45 804.69 954.87 826.74 969.87C828.29 970.92 829.87 971.94 831.49 972.88C842.72 979.42 855.57 983.14 869.2 983.14C880.05 983.14 890.37 980.8 899.76 976.57L899.79 976.5L944.72 923.67C947.06 915.71 948.29 907.25 948.29 898.44C948.29 851.71 912.89 813.78 869.19 813.78C825.54 813.8 790.07 851.72 790.07 898.45Z" fill="currentColor" />
    </svg>
</SVGWrapper>;
export default Friends;
const SVGWrapper = styled.div`
    width: 100%;
    color: ${({
  theme
}) => theme.colors.tertiary};
`;