import styled from "styled-components";
import BREAKPOINTS from "../../constants/breakpoints";

// MUI Components
import { Dialog } from "@material-ui/core";

// Assets
import { ReactComponent as CloseIcon } from "../../assets/v2/icons/x.svg";
export const StyledModal = styled(Dialog)`
    & div > div {
        color: ${({
  theme
}) => theme.colors.primary};
        font-size: 13px;
    }

    & .MuiDialog-paperWidthSm {
        width: ${props => props.width || "50%"};
        background: ${props => props.theme.background.primary};
        border: 2px solid ${props => props.theme.border.primary};
        border-radius: 20px;
        padding: 15px 24px;

        @media (max-width: ${BREAKPOINTS.md}) {
            width: 100%;
            margin: 15px;
        }
    }

    & .MuiFormLabel-root.Mui-disabled {
        color: #3a3f64;
    }

    & .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
        background: rgb(27, 33, 41);
    }

    & .MuiFormHelperText-root.Mui-disabled {
        color: #4960ed;
    }
`;
export const CloseButton = styled(CloseIcon)`
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    color: ${props => props.theme.colors.secondary};

    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
`;