import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
const Slots_1 = _compiledBlock(() => <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_1315_330)">
    <path d="M15.5565 1.3772V1.9212H4.22852V1.3772C4.22852 0.727195 4.75552 0.200195 5.40602 0.200195H14.3795C15.0295 0.200195 15.5565 0.727195 15.5565 1.3772Z" fill="currentColor" />
    <path d="M4.22852 15.3535H15.5565V16.1325C15.5565 16.783 15.0295 17.31 14.3795 17.31H5.40602C4.75552 17.31 4.22852 16.783 4.22852 16.1325V15.3535Z" fill="currentColor" />
    <path d="M16.3025 3.8916C16.858 3.8916 17.308 4.3416 17.308 4.8971V12.5621C17.308 13.1176 16.858 13.5676 16.3025 13.5676H3.65552C3.10002 13.5676 2.65002 13.1176 2.65002 12.5621V4.8971C2.65002 4.3416 3.10002 3.8916 3.65552 3.8916H16.3025ZM16.3025 1.8916H3.65552C1.99852 1.8916 0.650024 3.2396 0.650024 4.8971V12.5621C0.650024 14.2191 1.99802 15.5676 3.65552 15.5676H16.3025C17.9595 15.5676 19.308 14.2196 19.308 12.5621V4.8971C19.308 3.2401 17.96 1.8916 16.3025 1.8916Z" fill="currentColor" />
    <path d="M4.17801 10.8001L5.33486 8.07109H4.02966V6.97949H6.96036V7.76854L5.67301 10.8001H4.17801Z" fill="currentColor" />
    <path d="M13.1935 10.8001L14.3504 8.07109H13.0452V6.97949H15.9759V7.76854L14.6885 10.8001H13.1935Z" fill="currentColor" />
    <path d="M8.6652 10.8001L9.82205 8.07109H8.51685V6.97949H11.4476V7.76854L10.1602 10.8001H8.6652Z" fill="currentColor" />
  </g>
  <defs>
    <clipPath id="clip0_1315_330">
        <rect width="20" height="17.5" fill="currentColor" />
    </clipPath>
  </defs>
</svg>, {
  name: "Slots_1",
  svg: true
});
const Slots = () => /*@million jsx-skip*/<Slots_1 />;
export default Slots;