import { useState } from "react";
import styled from "styled-components";
import BREAKPOINTS from "../constants/breakpoints";
import { CURRENCIES } from "../constants/currencies";
import { formatFiatNumber, formatCryptoNumber } from "../utils/formatNumber";
const CurrencyInput = ({
  currency,
  exchangeRate,
  showInFiat,
  onChange,
  value,
  background,
  width,
  margin
}) => {
  const [displayValue, _setDisplayValue] = useState(0);
  const [exchangeValue, setExchangeValue] = useState(0);
  const setDisplayValue = ({
    target: {
      value
    }
  }) => {
    let parsedValue = Math.abs(parseFloat(value));
    if (showInFiat) {
      let val = Math.abs(parsedValue / exchangeRate);
      setExchangeValue(val);
      onChange(val);
    } else {
      let val = Math.abs(parsedValue);
      setExchangeValue(val);
      onChange(val);
    }
    _setDisplayValue(parsedValue);
  };
  const displayCurrency = showInFiat ? CURRENCIES.USD : currency;
  return <BetContainer width={width} margin={margin} background={background}>
            <InputBox>
                <BetInput type="number" min="0" onChange={setDisplayValue} value={displayValue} />
                <InputIcon textColor={displayCurrency.textColor}>
                    {displayCurrency.image ? <img src={displayCurrency.image} alt={displayCurrency.name} /> : null}
                    <p>{displayCurrency.name}</p>
                </InputIcon>
            </InputBox>
            <ExchangeText>
                {showInFiat ? `${currency.name}: ${formatCryptoNumber(exchangeValue || 0, 5)}` : `$${formatFiatNumber(parseFloat(exchangeValue * exchangeRate || 0))}`}
            </ExchangeText>
        </BetContainer>;
};
export default CurrencyInput;
const BetContainer = styled.div`
    height: fit-content;
    width: ${props => props.width || "100%"};
    margin: ${props => props.margin || "none"};
    border-radius: 10px;
    padding: 5px 10px;
    background: ${props => props.background || props.theme.background.secondary};

    @media (max-width: ${BREAKPOINTS.sm}) {
        width: 100%;
    }
`;
const ExchangeText = styled.div`
    color: ${props => props.theme.colors.secondary};
    font-size: 0.75em;
    font-weight: 500;
    padding: 0 0 0.5rem 0.5rem;
`;
const InputIcon = styled.div`
    display: flex;
    align-items: center;
    height: 2.5rem;
    margin-right: 0.5rem;

    font-weight: 700;
    line-height: 1rem;
    font-size: 1rem;
    letter-spacing: 0.05em;

    & img {
        width: 1rem;
        height: 1rem;
        margin-right: 0.4rem;
    }

    & p {
        color: ${props => props.textColor};
    }
`;
const BetInput = styled.input`
    background: transparent;
    border: none;
    outline: none;
    color: ${({
  theme
}) => theme.colors.primary};
    font-weight: 900;
    font-size: 1.1em;
    letter-spacing: 0.1em;
    width: 100%;
    height: fit-content;
    padding: 0.5rem;
`;
const InputBox = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;