import React, { useState, Fragment } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Assets
import red from "../../assets/roulette/red_icon.svg";
import black from "../../assets/roulette/blue_icon.svg";
import green from "../../assets/roulette/green_icon.svg";

// Components
import ProvablyModal from "../modals/ProvablyModal";
import CARD_COLORS from "../../constants/cardColors";
const _getBackgroundColor = winner => {
  if (winner === "red") {
    return CARD_COLORS.RED;
  } else if (winner === "blue") {
    return CARD_COLORS.BLACK;
  } else if (winner === "green") {
    return CARD_COLORS.GREEN;
  }
};
const HistoryEntry = ({
  game
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const onClick = () => {
    setModalVisible(state => !state);
  };
  return <Fragment>
            <ProvablyModal open={modalVisible} handleClose={() => setModalVisible(state => !state)} fields={[{
      label: "Round ID",
      value: game.id
    }, {
      label: "Private Hash",
      value: game.server_seed_hash
    }, {
      label: "Private Seed",
      value: game.server_seed ? game.server_seed : "Not Revealed"
    }, {
      label: "Public Seed",
      value: game.eos_public_hash ? game.eos_public_hash : "Not Revealed"
    }, {
      label: "Round Ticket",
      value: `${game.module} (${game.winning_color})`
    }]} />
            <HistoryComponent onClick={onClick} data-tooltip-content="Click to view Provably Fair" data-tooltip-id="default" data-tooltip-place="bottom" height="1.9rem" background={_getBackgroundColor(game.winning_color)}>
                {game.winning_color === "red" ? <img src={red} alt="Red" style={{
        height: "60%",
        width: "60%"
      }} /> : game.winning_color === "blue" ? <img src={black} alt="Blue" style={{
        height: "60%",
        width: "60%"
      }} /> : game.winning_color === "green" ? <img src={green} alt="Green" style={{
        height: "60%",
        width: "60%"
      }} /> : <span>INVALID</span>}
            </HistoryComponent>
        </Fragment>;
};
const HistoryComponent = styled.div`
    cursor: pointer;
    margin-right: 8px;
    background: ${props => props.background};
    color: ${({
  theme
}) => theme.colors.primary};

    font-size: 12px;
    width: 2rem;
    font-weight: 500;
    letter-spacing: 0.05em;
    height: ${props => props.height};
    box-shadow: ${props => props.glow};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    cursor: pointer;
    &:hover {
        transition: all 300ms;
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
        filter: brightness(1.15);
    }
`;
HistoryEntry.propTypes = {
  game: PropTypes.object.isRequired
};
export default HistoryEntry;