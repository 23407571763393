import React, { useRef } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useDraggable } from "react-use-draggable-scroll";
import BREAKPOINTS from "../../constants/breakpoints";
import { _getRarityColor } from "./Wheel";
import { CURRENCIES } from "../../constants/currencies";
import Price from "../Price";
const FeedContainer = styled.div`
    margin: 20px 0;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 25px;
    white-space: nowrap;
`;
const Label = styled.div`
    color: ${props => props.color || props.theme.colors.primary};

    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
`;
const AnimationNetwork = styled.div`
    color: #4caf50;
    border-radius: 100%;
    animation: blink 2s linear infinite;
    margin-right: 5px;
`;
const Items = styled.div`
    margin-top: 10px;
    display: flex;
    gap: 1rem;
    width: 100%;

    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const Item = styled.div`
    height: 100%;
    color: ${props => props.theme.colors.primary};
    background: ${props => props.theme.background.primary};
    backdrop-filter: blur(7.5px);
    font-size: 11px;
    text-align: center;
    padding: 10px;
    border-radius: 10px;

    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
        filter: brightness(1.1);
    }
`;
const ItemImage = styled.img`
    height: 50px;
    width: auto;
    filter: drop-shadow(0 0.5rem 1rem ${props => props.shadow});
`;
const ItemName = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100px;
    margin-bottom: 2px;
`;
const ItemComponent = ({
  item,
  handleClick
}) => {
  if (!item) return null;
  return <Item onClick={() => handleClick(`crates/${item.case.id}`)}>
            <ItemImage src={item.imageUrl} alt={item.name} shadow={_getRarityColor(item.priceUSD)} />
            <ItemName>{item.name}</ItemName>
            <Price amount={item.priceUSD} currency={CURRENCIES.USD} textColor="#03df58" textSize="11px" imageSize="12px" />
        </Item>;
};
const BestOpenings = styled.div`
    width: 30%;
    overflow: hidden;
    padding: 10px 15px;
    background: ${props => props.theme.border.primary};
    border-radius: 10px;

    @media (max-width: ${BREAKPOINTS.sm}) {
        width: 50%;
    }
`;
const RecentOpenings = styled.div`
    width: 70%;
    overflow: hidden;

    @media (max-width: ${BREAKPOINTS.sm}) {
        width: 50%;
    }
`;
const ItemFeed = ({
  bestOpenings = [],
  recentOpenings = []
}) => {
  const history = useHistory();
  const bestOpeningsRef = useRef();
  const {
    events: bestOpeningsEvents
  } = useDraggable(bestOpeningsRef);
  const recentOpeningsRef = useRef();
  const {
    events: recentOpeningsEvents
  } = useDraggable(recentOpeningsRef);
  return <FeedContainer>
            <BestOpenings>
                <Label color="#ffe063">Best Openings</Label>
                <Items {...bestOpeningsEvents} ref={bestOpeningsRef}>
                    {bestOpenings && bestOpenings.map(item => <ItemComponent key={item.id} item={item} handleClick={history.push} />)}
                </Items>
            </BestOpenings>
            <RecentOpenings>
                <Label>
                    <AnimationNetwork>&#9679;</AnimationNetwork>
                    Recent Openings
                </Label>
                <Items {...recentOpeningsEvents} ref={recentOpeningsRef}>
                    {recentOpenings.map(item => <ItemComponent key={item.id} item={item} handleClick={history.push} />)}
                </Items>
            </RecentOpenings>
        </FeedContainer>;
};
export default ItemFeed;