import React from "react";
import styled from "styled-components";

// Assets
import logo from "../assets/v2/sweet-textlogo.webp";
const Root = styled.div`
    height: 100vh;
    width: 100vw;
    display: block;
    text-align: left;
    padding: 3rem 5rem;
    color: white;
    background: ${props => props.theme.background.primary};
`;
const Title = styled.h1`
    font-size: 45px;
`;
const Link = styled.a`
    color: ${props => props.theme.colors.tertiary};

    transition: 0.2s ease-in-out;
    text-decoration: none;
    &:hover {
        opacity: 0.9;
        text-decoration: none;
    }
`;
const Logo = styled.img`
    height: 60px;
    width: auto;
`;
const Maintenance = () => {
  return <Root>
            <Logo src={logo} alt="Sweet.gg" />
            <Title>We&rsquo;ll be back soon!</Title>
            <p>
                We apologize for the inconvenience. We&rsquo;re performing some
                maintenance at the moment. Do not worry, your funds are safe.
                <br />
                You can find more information on our{" "}
                <Link href="https://twitter.com/sweetdotgg">Twitter</Link> ,
                otherwise we&rsquo;ll be back online shortly!
            </p>
            <p>&mdash; Sweet Team</p>
        </Root>;
};
export default Maintenance;