import React from "react";
import styled from "styled-components";
import parseCommasToThousands from "../utils/parseCommasToThousands";
import BREAKPOINTS from "../constants/breakpoints";

// Assets
import coin from "../assets/coin.svg";

// TODO: Add min and max
const TokenInput = ({
  fiatPrice,
  onChange,
  value,
  background,
  width,
  margin
}) => {
  const _handleChange = event => {
    const {
      value
    } = event.target;
    const parsedValue = parseFloat(value);
    onChange(Math.abs(parsedValue));
  };
  return <BetContainer width={width} margin={margin} background={background}>
            <InputBox>
                <BetInput type="number" min="0" onChange={_handleChange} value={value} />
                <InputIcon>
                    <img src={coin} alt="Sweet Coin" />
                    <p>SWEET</p>
                </InputIcon>
            </InputBox>
            <USDPriceText>
                ${parseCommasToThousands(parseFloat(value * fiatPrice || 0))}
            </USDPriceText>
        </BetContainer>;
};
export default TokenInput;
const BetContainer = styled.div`
    height: fit-content;
    width: ${props => props.width || "100%"};
    margin: ${props => props.margin || "none"};
    border-radius: 10px;
    padding: 5px 10px;
    background: ${props => props.background || props.theme.background.secondary};

    @media (max-width: ${BREAKPOINTS.sm}) {
        width: 100%;
    }
`;
const USDPriceText = styled.div`
    color: ${props => props.theme.colors.secondary};
    font-size: 0.75em;
    font-weight: 500;
    padding: 0 0 0.5rem 0.5rem;
`;
const InputIcon = styled.div`
    display: flex;
    align-items: center;
    height: 2.5rem;
    margin-right: 0.5rem;

    font-weight: 700;
    line-height: 1rem;
    font-size: 1rem;
    letter-spacing: 0.05em;
    color: ${props => props.theme.colors.tertiary};

    & img {
        margin-right: 0.4rem;
    }
`;
const BetInput = styled.input`
    background: transparent;
    border: none;
    outline: none;
    color: ${({
  theme
}) => theme.colors.primary};
    font-weight: 900;
    font-size: 1.1em;
    letter-spacing: 0.1em;
    width: 100%;
    height: fit-content;
    padding: 0.5rem;
`;
const InputBox = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;