import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getCoinflipGame } from "../services/api.service";
import { coinflipSocket } from "../services/websocket.service";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-hot-toast";
import BREAKPOINTS from "../constants/breakpoints";

// Components
import Game from "../components/coinflip/Game";
import GameHeader from "../components/GameHeader";
import GrowAnimation from "../components/GrowAnimation";
import CircularLoader from "../components/CircularLoader";
import PageContainer from "../components/PageContainer";
const GameContainer = styled.div`
    height: 100%;
    width: 100%;
    padding: 25px 40px;
    @media (max-width: ${BREAKPOINTS.md}) {
        padding: 25px 15px;
    }
`;
const Coinflip = ({
  user,
  match,
  history
}) => {
  const [loading, setLoading] = useState(true);
  const [game, setGame] = useState(null);
  const [finalCountdown, setFinalCountdown] = useState(0);

  // Handle player join event
  const gameJoined = data => {
    const {
      _id,
      newPlayer
    } = data;

    // Update State
    setGame(game => game._id === _id ? {
      ...game,
      players: [...game.players, newPlayer]
    } : game);
  };

  // Handle game end event
  const gameRolled = async newData => {
    // TODO: figure out how to do it conditionally only on this game

    // Coundown stage commences
    await new Promise(resolve => {
      let secunde = 5;
      setFinalCountdown(secunde);
      let int = setInterval(() => {
        secunde -= 1;
        setFinalCountdown(secunde);
        if (secunde <= 0) {
          clearInterval(int);
          setFinalCountdown("");
          resolve();
        }
      }, 1000);
    });
    setGame(game => game._id === newData._id ? {
      ...game,
      ...newData,
      status: 3
    } : game);

    // Wait for the animation
    setTimeout(() => {
      setGame(game => game._id === newData._id ? {
        ...game,
        ended: true
      } : game);
    }, 3000);
  };

  // Handle game rolling event
  const gameRolling = gameId => {
    // TODO: figure out how to do it conditionally only on this game

    setGame(game => game._id === gameId ? {
      ...game,
      status: 2
    } : game);
  };
  useEffect(() => {
    // Fetch private game data from API
    const fetchGameData = async id => {
      setLoading(true);
      try {
        const coinflipGame = await getCoinflipGame(id);
        setGame(coinflipGame);
        setLoading(false);
      } catch (error) {
        console.error(error);
        if (error.response && error.response.status === 400) {
          toast.error("Couldn't find any active games with that invite link!");
          history.push("/coinflip");
        } else {
          toast.error("There was an error while loading that private game data, please try again later!");
          history.push("/coinflip");
        }
      }
    };

    // If game id was passed in url, fetch that game data
    if (match.params.id) {
      fetchGameData(match.params.id);
    }

    // Joining a game was successfull
    const joinSuccess = () => {
      toast.success("Successfully joined a game!");
    };

    // There was an error while joining a game
    const joinError = msg => {
      toast.error(msg);
    };
    coinflipSocket.on("game-joined", gameJoined);
    coinflipSocket.on("game-rolled", gameRolled);
    coinflipSocket.on("game-rolling", gameRolling);
    coinflipSocket.on("game-join-error", joinError);
    coinflipSocket.on("game-join-success", joinSuccess);

    // componentDidUnmount
    return () => {
      coinflipSocket.off("game-joined", gameJoined);
      coinflipSocket.off("game-rolled", gameRolled);
      coinflipSocket.off("game-rolling", gameRolling);
      coinflipSocket.off("game-join-error", joinError);
      coinflipSocket.off("game-join-success", joinSuccess);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params]);
  return <PageContainer maxWidth="lg" header={<GameHeader game="coinflip" returnTo={{
    route: "/coinflip",
    tooltip: "Return to Games"
  }} />}>
            <GrowAnimation duration="620ms">
                <GameContainer>
                    {loading ? <CircularLoader /> : <Game key={game._id} game={game} user={user} finalCountdown={finalCountdown} />}
                </GameContainer>
            </GrowAnimation>
        </PageContainer>;
};
Coinflip.propTypes = {
  user: PropTypes.object
};
const mapStateToProps = state => ({
  user: state.auth.user
});
export default connect(mapStateToProps)(Coinflip);