import React from "react";
import ProviderGamePage from "../../components/ProviderGamePage";
const SlotPage = ({
  match: {
    params: {
      id
    }
  }
}) => {
  return <ProviderGamePage type="slots" id={id} />;
};
export default SlotPage;