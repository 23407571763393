import styled from "styled-components";
const Wallet = () => <SVGWrapper>
    <svg viewBox="0 0 2000 2000" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M980.15 958.87V1077.19C980.15 1102.33 1000.53 1122.71 1025.67 1122.71H1301.71V1154.05C1301.71 1201.54 1263.21 1240.04 1215.72 1240.04H727.02C679.53 1240.04 641.04 1201.54 641.04 1154.05V884.01C641.04 836.52 679.53 798.02 727.02 798.02H1215.72C1263.21 798.02 1301.71 836.52 1301.71 884.01V913.349H1025.67C1000.53 913.349 980.15 933.73 980.15 958.87Z" fill="currentColor" />
        <path d="M1327.95 1083.02H1051.95C1034.82 1083.02 1020.93 1069.13 1020.93 1052V984.05C1020.93 966.92 1034.82 953.03 1051.95 953.03H1327.95C1345.08 953.03 1358.97 966.92 1358.97 984.05V1052C1358.96 1069.13 1345.08 1083.02 1327.95 1083.02Z" fill="currentColor" />
        <path d="M1211.17 762.69H860.04C864.82 757.46 871.09 753.48 878.44 751.46L1064.74 700.35L1147.43 677.67C1151.19 676.64 1154.97 676.19 1158.67 676.29C1175.44 676.67 1190.66 687.93 1195.33 704.94L1211.17 762.69Z" fill="currentColor" />
        <path d="M1199.42 848.58L930.43 922.37C918.68 925.6 906.72 923.09 897.51 916.53C891.91 912.56 887.33 907.08 884.45 900.49C883.68 898.77 883.04 896.969 882.53 895.099L855.9 798.02H1225.96L1226.69 800.68C1232.38 821.44 1220.17 842.88 1199.42 848.58Z" fill="currentColor" />
        <path d="M1097.98 798.02C1095.12 801.46 1091.62 804.45 1087.52 806.82L956.64 882.39L897.51 916.53L845.96 946.289C827.32 957.049 803.48 950.66 792.72 932.02L741.01 842.46C732.61 827.91 734.66 810.21 744.82 798.02H1097.98Z" fill="currentColor" />
        <path d="M1048.4 672.05L882.16 717.65L992.83 653.75C1011.48 642.99 1035.31 649.38 1046.07 668.02L1048.4 672.05Z" fill="currentColor" />
        <path d="M1100.26 762.69H860.04C864.82 757.46 871.09 753.48 878.44 751.46L1064.74 700.35L1097.79 757.58C1098.75 759.25 1099.57 760.95 1100.26 762.69Z" fill="currentColor" />
        <path d="M1182.81 762.69H860.04C864.82 757.46 871.09 753.48 878.44 751.46L1064.74 700.35L1147.43 677.67C1151.19 676.64 1154.97 676.19 1158.67 676.29C1159.44 678.01 1160.08 679.81 1160.59 681.68L1182.81 762.69Z" fill="currentColor" />
        <path d="M1191.95 798.02C1188.44 810.85 1178.43 821.54 1164.67 825.31L956.64 882.39L895.69 899.109C891.93 900.139 888.15 900.59 884.45 900.49C867.68 900.11 852.45 888.84 847.79 871.84L827.54 798.02H1191.95Z" fill="currentColor" />
    </svg>
</SVGWrapper>;
export default Wallet;
const SVGWrapper = styled.div`
    width: 100%;
    color: ${({
  theme
}) => theme.colors.tertiary};
`;