import React from "react";
import styled from "styled-components";
import BREAKPOINTS from "../constants/breakpoints";
const PageContainer = ({
  children,
  maxWidth,
  header,
  style
}) => {
  return <Container style={style}>
            {header}
            {maxWidth ? <Content maxWidth={maxWidth}>{children}</Content> : children}
        </Container>;
};
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 30px;
    padding-bottom: 2rem;

    @media (max-width: ${BREAKPOINTS.md}) {
        padding: 15px;
    }
`;
const Content = styled.div`
    width: 100%;
    max-width: ${props => props.maxWidth === "xl" && "1900px" || props.maxWidth === "lg" && "1200px" || props.maxWidth === "md" && "960px" || props.maxWidth === "sm" && "600px" || props.maxWidth === "xs" && "400px"};
`;
export default PageContainer;