import React, { useState, Fragment } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { toggleChat } from "../../store/actions/site";
import { HEADER_HEIGHT } from "../../constants/ui";

// Components
import CreateRainModal from "../modals/CreateRainModal";
import UsersOnline from "../UsersOnline";

// Assets
import SugarRushIcon from "../../assets/sugar_rush.svg";
import { IoIosClose } from "react-icons/io";
const Header = ({
  isAuthenticated,
  sweet
}) => {
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const openCreateRainModal = () => {
    setModalVisible(true);
  };
  const handleLeftClick = e => {
    if (isAuthenticated) {
      openCreateRainModal();
    }
  };
  return <Fragment>
            <CreateRainModal handleClose={() => setModalVisible(!modalVisible)} open={modalVisible} sweet={sweet} />
            <HeaderComponent>
                <CloseButton>
                    <CloseIcon onClick={() => dispatch(toggleChat())} />
                </CloseButton>
                <Title>SWEET CHAT</Title>
                <UsersOnline />
                <SugarRushIconComponent onClick={handleLeftClick} src={SugarRushIcon} alt="Sugar Rush" disabled={!isAuthenticated} data-tooltip-content={isAuthenticated ? "Create Sugar Rush" : ""} data-tooltip-id="default" data-tooltip-place="bottom" />
            </HeaderComponent>
        </Fragment>;
};
const HeaderComponent = styled.div`
    display: flex;
    align-items: center;
    height: ${HEADER_HEIGHT};
    color: ${({
  theme
}) => theme.colors.primary};
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.05em;
    padding: 0 15px;
    border-bottom: 1px solid ${props => props.theme.border.primary};
`;
const Title = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: ${props => props.theme.colors.primary};
    margin-right: 10px;
`;
const SugarRushIconComponent = styled.img`
    margin-left: auto;
    height: 30px;
    width: 30px;

    ${props => props.disabled ? "opacity: 0.5;" : `
    &:hover {
        cursor: pointer;
        transition: 125ms ease;
        transform: scale(1.07);
    }
    `}
`;
const CloseIcon = styled(IoIosClose)`
    font-size: 26px;
`;
const CloseButton = styled.div`
    margin-right: 10px;
    color: ${props => props.theme.colors.secondary};
    background: ${props => props.theme.background.secondary};
    border-radius: 8px;

    height: 23px;
    width: 23px;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    transition: 125ms ease all;
    &:hover {
        color: ${props => props.theme.colors.primary};
    }
`;
Header.propTypes = {
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object
};
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  sweet: state.site.sweet
});
export default connect(mapStateToProps)(Header);