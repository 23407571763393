import React from "react";
import styled from "styled-components";
import PageContainer from "../components/PageContainer";
const Terms = () => {
  return <PageContainer maxWidth="lg">
            <Content>
                <h1>TERMS OF SERVICE</h1>
                <LastUpdate>Last Update: 2nd August 2023</LastUpdate>
                <section>
                    <p>
                        This end user agreement (the "Agreement") should be read
                        by you (the "User" or "you") in its entirety prior to
                        your use of Sweet's service or products. Please note
                        that the Agreement constitutes a legally binding
                        agreement between you and Sweet (referred to herein as
                        "Sweet", "us" or "we") which owns and operates the
                        Internet site found and games described at sweet.gg (the
                        "Service"). By clicking the "Accept Terms" button if and
                        where provided and/or using the Service, you consent to
                        the terms and conditions set forth in this Agreement.
                        <br />
                        <br />
                        We reserve the right to update, change, or replace any
                        part of these Terms of Service without notice. It is
                        your responsibility to check these Terms of Service
                        periodically for updates. Your continued use or access
                        to the Service following any updates constitutes your
                        acceptance of those updates.
                    </p>
                </section>
                <section>
                    <b>
                        <CounterUp>1.</CounterUp> LICENSE GRANT
                    </b>
                    <p>
                        <CounterUp>1.1.</CounterUp> Sweet grants you a limited,
                        non-transferable, royalty-free license to use the
                        Service in accordance with the terms of this agreement.
                    </p>
                    <p>
                        <CounterUp>1.2.</CounterUp> Use of this website is
                        restricted to Users that are (a) 18 years of age or
                        older and (b) over the legal age of majority in their
                        jurisdiction.
                    </p>
                    <p>
                        <CounterUp>1.3.</CounterUp> You are under no legal
                        disability that would prevent you from forming a binding
                        contract with us.
                    </p>
                </section>
                <section>
                    <b>
                        <CounterUp>2.</CounterUp> PROHIBITED USES
                    </b>
                    <p>
                        <CounterUp>2.1.</CounterUp> The Service is intended
                        solely for the User’s personal use. The User is only
                        allowed to wager for his/her personal entertainment and
                        may not create multiple accounts, including for the
                        purpose of collusion and/or abuse of service.
                    </p>
                    <p>
                        <CounterUp>2.2.</CounterUp> Persons located in or
                        residents of the United States, Australia, Aruba,
                        Bonaire, Curaçao, Cyprus, France, Netherlands, Saba,
                        Statia, Iran, or St Martin (the ”Prohibited
                        Jurisdictions”) are not permitted to make use of the
                        Service. For the avoidance of doubt, the foregoing
                        restrictions on engaging in real-money play from
                        Prohibited Jurisdictions applies equally to residents
                        and citizens of other nations while located in a
                        Prohibited Jurisdiction. Any attempt to circumvent the
                        restrictions on play by any persons located in a
                        Prohibited Jurisdiction or Restricted Jurisdiction, is a
                        breach of this Agreement. An attempt at circumvention
                        includes, but is not limited to, manipulating the
                        information used by Sweet to identify your location and
                        providing Sweet with false or misleading information
                        regarding your location or place of residence.
                    </p>
                    <p>
                        <CounterUp>2.3.</CounterUp> Sweet may not be used for
                        illegal or unauthorized purposes and you must abide
                        completely by the laws, rules, and regulations that
                        apply to you. Whether that be federal, state, or local
                        laws.
                    </p>
                </section>
                <section>
                    <b>3. LIMITATIONS AND LIABILITY.</b>
                    <p>
                        <CounterUp>3.1.</CounterUp> You understand and agree to
                        defend, indemnify and hold no liability or
                        accountability to Sweet, its licensors, licensees,
                        distributors, agents representatives and other
                        authorized users, and all of the foregoing entities'
                        respective officers, directors, owners, employees,
                        agents, representatives, and assigns from and against
                        any and all claims, damages, obligations, losses,
                        liabilities, costs, attorneys’ fees, and expenses in the
                        occasion of or in connection with, your use of the
                        website, your violation construed to the terms of
                        service, the sale, purchase or use of any items, your
                        violation of any third party right, including but not
                        limited to copyright, trademark or privacy right and any
                        submittance by you that causes damage to a third party.
                        You are expected to participate and cooperate to
                        whatever extent required in the defence of any claim.
                        Sweet reserves the right to assume the exclusive defence
                        and control of any matter otherwise subject to
                        indemnification by you. You will not discuss or enter
                        any settlement agreement that affects the rights of
                        Sweet without Sweet's written permission.
                    </p>
                </section>
                <section>
                    <b>4. USER SUBMITTED CONTENT.</b>
                    <p>
                        <CounterUp>4.1.</CounterUp> You understand that you own
                        all intellectual property rights in any user submitted
                        content or that you have the applicable rights and or
                        permissions from the owner of the content. By submitting
                        user submitted content to the website you automatically
                        allow permission for Sweet to a worldwide, perpetual,
                        irrevocable royalty free transferable right and license
                        to use, copy, modify adapt, publish, translate, create
                        derivative works, and distribute your user submitted
                        content.
                    </p>
                    <p>
                        <CounterUp>4.2.</CounterUp> Sweet is not liable for any
                        user submitted content or third party content displayed
                        or submitted to the website.
                        <br />
                        <br />
                        If you believe that any content on the website breaches
                        your copyright, trademarks, or other intellectual
                        property right please send a written notification of
                        your claim to Sweet via Live Chat Ticket or Discord.
                    </p>
                </section>
                <section>
                    <b>5. RELATIONSHIP OF PARTIES.</b>
                    <p>
                        <CounterUp>5.1.</CounterUp> No joint venture, employment
                        or agency exists between you and Sweet and nothing in
                        these terms of use will be interpreted as creating any
                        type of joint venture, employment or partnership.
                    </p>
                </section>
                <section>
                    <b>6. PAYMENTS AND FEES</b>
                    <p>
                        <CounterUp>6.1.</CounterUp> You the user comprehends
                        that you are the owner of any cryptocurrency address or
                        accounts you use on Sweet services. You understand and
                        agree that any billing information that you provide to
                        Sweet may be passed on by Sweet to companies working in
                        line with or on Sweet behalf such as payment processors
                        and/or credit agencies solely for the purposes of
                        effecting payment to Sweet and Sellers.
                    </p>
                    <p>
                        <CounterUp>6.2.</CounterUp> You agree that Sweet is not
                        liable for the actions for any third-party payment
                        processors and, on any dispute resulting from the
                        third-party payment processor such as payment holds, you
                        agree to resolve your dispute directly with them.
                    </p>
                    <p>
                        <CounterUp>6.2.</CounterUp> You agree that Sweet is not
                        liable for the actions for any third-party payment
                        processors and, on any dispute resulting from the
                        third-party payment processor such as payment holds, you
                        agree to resolve your dispute directly with them.
                    </p>
                    <p>
                        <CounterUp>6.3.</CounterUp> On the occasion that a buyer
                        on the site receives a refund through a third-party
                        payment processor used by Sweet the Buyer shall abide by
                        Sweet to pay any applicable transaction fees involved.
                        Also including any further fees or charges imposed by
                        third parties by the use of external services related to
                        the transaction.
                    </p>
                </section>
                <section>
                    <b>7. KNOW YOUR CUSTOMER.</b>
                    <p>
                        <CounterUp>7.1.</CounterUp> Sweet reserves the right, at
                        any time, to request KYC documentation it deems
                        necessary to determine the identity and location of a
                        User. Sweet reserves the right to restrict service and
                        payment until identity is sufficiently determined.
                    </p>
                </section>
                <section>
                    <b>8. SEVERABILITY.</b>
                    <p>
                        <CounterUp>8.1.</CounterUp> If any part of these terms
                        of service is represented as invalid or unenforceable
                        under any statute, regulation, ordinance, or by any
                        arbitrator or court of competent jurisdiction, then such
                        action will be taken to ensure the terms of service is
                        amended, parts deleted or reformed in any such way to
                        meet the guidelines of the aforementioned regulations.
                        All other remaining passages within the terms of service
                        will remain in effect and must be adhered to at all
                        times.
                    </p>
                </section>
                <section>
                    <b>ADDITIONAL TERMS AND CONDITIONS.</b>
                    <p>
                        GOOGLE. We make use of reCAPTCHA services and your use
                        of reCAPTCHA is subject to the Google Privacy Policy and
                        Terms of Use.
                    </p>
                </section>
            </Content>
        </PageContainer>;
};
const Content = styled.div`
    color: ${props => props.theme.colors.secondary};

    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.005em;

    & img {
        width: 5rem;
        margin-bottom: 1rem;
    }

    & h1 {
        color: ${({
  theme
}) => theme.colors.primary};

        font-size: 19px;
        font-weight: 500;
    }

    & b {
        color: ${props => props.theme.colors.secondary};

        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.005em;
    }

    & section {
        margin-bottom: 3rem;
    }
`;
const LastUpdate = styled.div`
    color: ${props => props.theme.colors.secondary};

    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.005em;
    margin-bottom: 2rem;
`;
const CounterUp = styled.span`
    font-weight: 500;
`;
export default Terms;