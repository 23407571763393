import React, { useState, useEffect, Fragment } from "react";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-hot-toast";
import styled from "styled-components";
import PropTypes from "prop-types";
import { claimRakebackBalance, getUserVipData } from "../../services/api.service";
import { connect } from "react-redux";

// Components
import ProgressBar from "../ProgressBar";
import CircularLoader from "../CircularLoader";
import { StyledModal, CloseButton } from ".";
import Button from "../Button";
import parseCommasToThousands from "../../utils/parseCommasToThousands";
import BREAKPOINTS from "../../constants/breakpoints";
import { getCurrencyById } from "../../constants/currencies";
import { getLevelBadge } from "../../utils/vip";
import { getWalletsByType } from "../../utils/getWalletsByType";
import Price from "../Price";

// Assets
import lossbackChain from "../../assets/v2/lossback-lock.png";
const Vip = ({
  user,
  open,
  handleClose
}) => {
  const [completed, setCompleted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [vipData, setVipData] = useState(null);
  useEffect(() => {
    // Fetch vip data from API
    const fetchData = async () => {
      setLoading(true);
      try {
        const resp = await getUserVipData();
        console.log(resp);
        setVipData(resp.data);
        if (resp.data.level === 100) {
          setCompleted(true);
        }
        setLoading(false);
      } catch (error) {
        console.log("There was an error while loading user vip data:", error);
        toast.error("There was an error while getting your VIP data, please try again later!");
      }
    };

    // If modal is opened, fetch data
    if (open) {
      fetchData();
    }
  }, [open]);
  const [claimingRakeback, setClaimingRakeback] = useState(false);
  const [claimingLossback, setClaimingLossback] = useState(false);
  const claimRakeback = async () => {
    setClaimingRakeback(true);
    try {
      const rakebackWallets = getWalletsByType(user.wallets, "rakeback");
      const isZero = rakebackWallets.reduce((acc, wallet) => {
        return acc + wallet.amount;
      }, 0);
      if (isZero <= 0) {
        toast.error("You don't have any Rakeback to claim!");
        setClaimingRakeback(false);
        return;
      }
      const idempotencyKey = uuidv4();
      const response = await claimRakebackBalance(idempotencyKey);
      console.log(response);
      toast.success("Successfully claimed Rakeback!");
      setClaimingRakeback(false);
    } catch (error) {
      setClaimingRakeback(false);
      console.error(error);
      toast.error(error);
    }
  };
  const claimLossback = async () => {};
  return <StyledModal onClose={handleClose} open={open}>
            <Title>VIP Rewards</Title>
            {loading ? <LoadingContainer>
                    <CircularLoader />
                </LoadingContainer> : <Fragment>
                    <Description>
                        Introducing Sweet's VIP Program, gamble and tier up
                        based on your wager activity to unlock exclusive rewards
                        and rakeback.
                    </Description>
                    <ActionContainer>
                        <VIPBalanceContainer>
                            <VaultBoxTitle>Available Rakeback</VaultBoxTitle>
                            <InnerFlexContainer>
                                <RakebackBalances>
                                    {user.wallets && getWalletsByType(user.wallets, "rakeback").map(wallet => {
                const currency = getCurrencyById(wallet.currency_id);
                const highestDenomination = wallet.amount / 10 ** currency.decimalPlaces;
                return <Price amount={highestDenomination} currency={getCurrencyById(wallet.currency_id)} showExchangedCurrencyIcons={true} />;
              })}
                                </RakebackBalances>
                                <Button onClick={claimRakeback} disabled={claimingRakeback}>
                                    CLAIM
                                </Button>
                            </InnerFlexContainer>
                        </VIPBalanceContainer>
                        <VIPBalanceContainer>
                            {!vipData.rank.includes("Diamond") && <LossbackChain src={lossbackChain} alt="Lossback Chain" />}
                            <OverlayWrapper $disabled={!vipData.rank.includes("Diamond")}>
                                <VaultBoxTitle>
                                    Available Lossback
                                </VaultBoxTitle>
                                <InnerFlexContainer>
                                    {user.wallets && getWalletsByType(user.wallets, "lossback").map(wallet => <Price amount={wallet.amount} currency={getCurrencyById(wallet.currency_id)} />)}
                                    <Button onClick={claimLossback} disabled={claimingLossback || !vipData.rank.includes("Diamond")}>
                                        CLAIM
                                    </Button>
                                </InnerFlexContainer>
                            </OverlayWrapper>
                        </VIPBalanceContainer>
                    </ActionContainer>
                    <CurrentRankContainer>
                        Current Rank:{" "}
                        <LevelBadge alt="Level Badge" src={getLevelBadge(vipData.rank)} />
                        <CurrentRankText>{vipData.rank}</CurrentRankText>
                    </CurrentRankContainer>
                    <ProgressBox>
                        {!completed ? <>
                                <VIPStatus>
                                    {(vipData.next_rank_progress * 100).toFixed(2)}
                                    % Next Rank Progress
                                </VIPStatus>
                                <ProgressBar style={{
            borderRadius: "5px",
            height: "30px"
          }} progress={vipData.next_rank_progress * 100} />
                            </> : <>
                                <VIPStatus>
                                    You reached the highest level.
                                    Congratulations!
                                </VIPStatus>
                                <ProgressBar style={{
            borderRadius: "5px"
          }} variant="success" animated striped={false} min={0} max={100} now={100} />
                            </>}
                    </ProgressBox>
                    <RanksContainer>
                        {vipData.next_ranks.map((rank, index) => <RankBox key={index}>
                                <RankImage src={getLevelBadge(rank.name)} data-tooltip-content={rank.name} data-tooltip-id="default" />
                                <WagerRequirement next={index === 0}>
                                    $
                                    {parseCommasToThousands(rank.min_wager, true)}{" "}
                                    Wager
                                </WagerRequirement>
                            </RankBox>)}
                    </RanksContainer>
                </Fragment>}
            <CloseButton onClick={handleClose} />
        </StyledModal>;
};
const CurrentRankContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: 15px;
`;
const VIPStatus = styled.h4`
    width: 90%;
    position: absolute;
    color: ${({
  theme
}) => theme.colors.primary};

    font-size: 14px;
    font-weight: 300;
    text-align: center;
    margin-top: 5px;
`;
const LevelBadge = styled.img`
    height: auto;
    width: 25px;
    margin-left: 15px;
    margin-right: 10px;
`;
const CurrentRankText = styled.div`
    font-size: 14px !important;
    font-weight: 500;
    color: ${({
  theme
}) => theme.colors.primary};
`;
const ProgressBox = styled.div`
    margin-top: 15px;
    margin-bottom: 25px;
`;
const Title = styled.div`
    font-weight: 500;
    font-size: 18px !important;
    margin: 1rem 0;
    margin-top: 0.5rem;
`;
const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15rem;
`;
const VaultBoxTitle = styled.div`
    color: ${props => props.theme.colors.secondary};
    font-size: 15px;
    font-weight: 500;
`;
const RakebackBalances = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
const VIPBalanceContainer = styled.div`
    position: relative;
    width: calc(50% - 7.5px);
    background: ${props => props.theme.background.secondary};
    padding: 10px 15px;
    border-radius: 8px;
    border: 1px solid ${props => props.theme.border.primary};

    @media (max-width: ${BREAKPOINTS.sm}) {
        width: 100%;
    }
`;
const OverlayWrapper = styled.div`
    ${({
  $disabled
}) => $disabled && `
        opacity: 0.5;
        cursor: not-allowed;
    `}
`;
const InnerFlexContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: right;
    justify-content: space-between;
    margin-top: 10px;
`;
const RanksContainer = styled.div`
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
`;
const RankBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
`;
const RankImage = styled.img`
    height: 40px;
    width: auto;
`;
const WagerRequirement = styled.div`
    font-size: 12px !important;
    font-weight: 500;
    color: ${({
  theme,
  next
}) => next ? theme.colors.tertiary : theme.colors.secondary} !important;
`;
const ActionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 30px;

    @media (max-width: ${BREAKPOINTS.sm}) {
        flex-direction: column;
        gap: 20px;
    }
`;
const Description = styled.div`
    font-size: 12px;
    color: ${({
  theme
}) => theme.colors.secondary} !important;
    margin-bottom: 10px;
`;
const LossbackChain = styled.img`
    position: absolute;
    top: -7px;
    left: -7px;
    height: calc(100% + 14px);
    width: calc(100% + 14px);
    z-index: 1;
`;
Vip.propTypes = {
  user: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  user: state.auth.user
});
export default connect(mapStateToProps)(Vip);