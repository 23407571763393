import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useDraggable } from "react-use-draggable-scroll";
import { getRecentWinsByFilter } from "../services/api.service";
import { dataSocket } from "../services/websocket.service";
import BREAKPOINTS from "../constants/breakpoints";
import { getCurrencyById } from "../constants/currencies";

// Components
import Price from "./Price";
import Skeleton from "./Skeleton";

// Assets
import dicebanner from "../assets/v2/game-cards/dice.webp";
import roulettebanner from "../assets/v2/game-cards/roulette.webp";
import coinflipbanner from "../assets/v2/game-cards/coinflip.webp";
import crashbanner from "../assets/v2/game-cards/crash.webp";
import cratesbanner from "../assets/v2/game-cards/crates.webp";
import battlesbanner from "../assets/v2/game-cards/battles.webp";
import blackjackbanner from "../assets/v2/game-cards/blackjack.webp";
import { ReactComponent as DiceIcon } from "../assets/v2/game-icons/dice.svg";
import { ReactComponent as RouletteIcon } from "../assets/v2/game-icons/roulette.svg";
import { ReactComponent as CoinflipIcon } from "../assets/v2/game-icons/coinflip.svg";
import { ReactComponent as CrashIcon } from "../assets/v2/game-icons/crash.svg";
import { ReactComponent as CratesIcon } from "../assets/v2/game-icons/crates.svg";
import { ReactComponent as BattlesIcon } from "../assets/v2/game-icons/battles.svg";
import { ReactComponent as BlackjackIcon } from "../assets/v2/game-icons/blackjack.svg";
import { ReactComponent as SlotsIcon } from "../assets/v2/game-icons/slots.svg";
import { ReactComponent as LiveCasinoIcon } from "../assets/v2/game-icons/live-casino.svg";
import { ReactComponent as GameShowIcon } from "../assets/v2/game-icons/game-shows.svg";
const _getBanner = game => {
  switch (game) {
    case "crash":
      return crashbanner;
    case "dice":
      return dicebanner;
    case "roulette":
      return roulettebanner;
    case "coinflip":
      return coinflipbanner;
    case "crates":
      return cratesbanner;
    case "battles":
      return battlesbanner;
    case "blackjack":
      return blackjackbanner;
    default:
      return "";
  }
};
const _getPage = game => {
  switch (game) {
    case "crash":
      return "/crash";
    case "dice":
      return "/dice";
    case "roulette":
      return "/roulette";
    case "coinflip":
      return "/coinflip";
    case "crates":
      return "/crates";
    case "battles":
      return "/crate-battles";
    case "blackjack":
      return "/blackjack";
    default:
      return "/";
  }
};
const Win = ({
  win,
  onClick
}) => {
  return <Item onClick={onClick}>
            <GameImage src={win.gameImageURL !== "" ? win.gameImageURL : _getBanner(win.gameType)} alt={win.game} />
            <Price amount={win.amount} textColor="#03df58" currency={getCurrencyById(win.currency)} />
        </Item>;
};
const GAME_FILTER = {
  ALL: "all",
  CRASH: "crash",
  DICE: "dice",
  ROULETTE: "roulette",
  BLACKJACK: "blackjack",
  CRATES: "crates",
  BATTLES: "battles",
  COINFLIP: "coinflip",
  SLOTS: "slots",
  LIVE_CASINO: "live-casino",
  GAME_SHOW: "game-show"
};
const GAME_FILTERS = [{
  name: "Slots",
  value: GAME_FILTER.SLOTS,
  icon: <SlotsIcon />
}, {
  name: "Live Casino",
  value: GAME_FILTER.LIVE_CASINO,
  icon: <LiveCasinoIcon />
}, {
  name: "Game Show",
  value: GAME_FILTER.GAME_SHOW,
  icon: <GameShowIcon />
}, {
  name: "Crates",
  value: GAME_FILTER.CRATES,
  icon: <CratesIcon />
}, {
  name: "Crash",
  value: GAME_FILTER.CRASH,
  icon: <CrashIcon />
}, {
  name: "Dice",
  value: GAME_FILTER.DICE,
  icon: <DiceIcon />
}, {
  name: "Roulette",
  value: GAME_FILTER.ROULETTE,
  icon: <RouletteIcon />
}, {
  name: "Blackjack",
  value: GAME_FILTER.BLACKJACK,
  icon: <BlackjackIcon />
}, {
  name: "Battles",
  value: GAME_FILTER.BATTLES,
  icon: <BattlesIcon />
}, {
  name: "Coinflip",
  value: GAME_FILTER.COINFLIP,
  icon: <CoinflipIcon />
}];

// TODO: add differet currency support
const WinFeed = () => {
  const history = useHistory();
  const winsRef = useRef();
  const {
    events: winsEvents
  } = useDraggable(winsRef);
  const filtersRef = useRef();
  const {
    events: filtersEvents
  } = useDraggable(filtersRef);
  const [gameFilter, setGameFilter] = useState(GAME_FILTER.ALL);
  const [isLoading, setIsLoading] = useState(true);
  const [liveWins, setLiveWins] = useState([]);
  useEffect(() => {
    const addNewWin = winData => {
      let win = JSON.parse(winData);
      if (gameFilter !== "all" && win.gameType !== gameFilter) {
        return;
      }
      setLiveWins(prev => [win, ...prev]);
    };
    dataSocket.on("new_win", addNewWin);
    return () => {
      dataSocket.off("new_win", addNewWin);
    };
  }, [gameFilter]);
  useEffect(() => {
    setIsLoading(true);
    getRecentWinsByFilter(gameFilter).then(({
      data
    }) => {
      const sorted = data.sort((a, b) => b.createdAt - a.createdAt);
      setLiveWins(sorted);
      setIsLoading(false);
    });
  }, [gameFilter]);
  const onGameClick = win => {
    if (win.gameURL !== "") {
      history.push(win.gameURL);
    } else {
      history.push(_getPage(win.gameType));
    }
  };
  return <Root>
            <Content>
                <Label>
                    <AnimationNetwork>●</AnimationNetwork>
                    LIVE WINS
                </Label>
                <Items {...winsEvents} ref={winsRef}>
                    {isLoading ? <Skeleton variant="rounded" height="100px" width="100%" /> : liveWins.map((win, i) => <Win key={i} win={win} onClick={() => onGameClick(win)} />)}
                </Items>
            </Content>
            <GamesFilterContainer>
                <GamesFilter selected={gameFilter === "all"} onClick={() => setGameFilter(GAME_FILTER.ALL)}>
                    All
                </GamesFilter>
                <ScrollableContainer {...filtersEvents} ref={filtersRef}>
                    {GAME_FILTERS.map((filter, i) => <GamesFilter key={i} selected={gameFilter === filter.value} onClick={() => setGameFilter(filter.value)}>
                            {filter.icon}
                            {filter.name}
                        </GamesFilter>)}
                </ScrollableContainer>
            </GamesFilterContainer>
        </Root>;
};
const Root = styled.div`
    width: 75%;
    height: 100%;
    white-space: nowrap;
    border-radius: 10px;
    background: ${({
  theme
}) => theme.background.primary};
    border: 1px solid ${({
  theme
}) => theme.border.primary};

    @media (max-width: ${BREAKPOINTS.md}) {
        width: 100%;
    }
`;
const Content = styled.div`
    padding: 10px 15px;
    padding-bottom: 0;
`;
const Label = styled.div`
    color: ${({
  theme
}) => theme.colors.primary};
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
`;
const AnimationNetwork = styled.div`
    color: ${({
  theme
}) => theme.colors.tertiary};
    border-radius: 100%;
    animation: blink 2s linear infinite;
    margin-right: 5px;
`;
const Items = styled.div`
    margin-top: 10px;
    display: flex;
    gap: 10px;
    width: 100%;
    min-height: 100px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
`;
const Item = styled.div`
    height: 100%;
    color: white;
    background: ${({
  theme
}) => theme.background.secondary};
    font-size: 11px;
    text-align: center;
    padding: 10px;
    padding-bottom: 7px;
    border-radius: 10px;

    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
        opacity: 0.9;
    }
`;
const GameImage = styled.img`
    height: 100px;
    width: 75px;
    object-fit: cover;
    border-radius: 5px;
`;
const GamesFilterContainer = styled.div`
    margin-top: 15px;
    border-top: 1px solid ${({
  theme
}) => theme.border.primary};
    display: flex;
    align-items: center;
    padding: 5px 15px;
`;
const ScrollableContainer = styled.div`
    margin-left: 5px;
    display: flex;
    align-items: center;

    overflow-x: hidden;
    @media (max-width: ${BREAKPOINTS.md}) {
        overflow-x: scroll;
    }

    &::-webkit-scrollbar {
        display: none;
    }
`;
const GamesFilter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    ${({
  theme,
  selected
}) => selected && `background: ${theme.background.tertiary};
         border: 1px solid ${({
  theme
}) => theme.border.primary};
    `}

    padding: 5px 10px;
    border-radius: 0.75rem;

    svg {
        height: 18px;
        width: 18px;
    }

    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
        opacity: 0.9;
    }
`;
export default WinFeed;