import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { toast } from "react-hot-toast";
import { getCoinflipGamesHistory } from "../services/api.service";

// Components
import Game from "../components/coinflip/GameList";
import ListItemsSkeleton from "../components/ListItemsSkeleton";
import PageContainer from "../components/PageContainer";
const Content = styled.div`
    padding: 20px;
`;
const Title = styled.div`
    color: ${({
  theme
}) => theme.colors.primary};
    font-weight: 500;
    font-size: 20px;
`;
const Subtitle = styled.div`
    color: ${({
  theme
}) => theme.colors.secondary};
    margin-top: 5px;
`;
const Coinflip = () => {
  const [loading, setLoading] = useState(true);
  const [prevGames, setPrevGames] = useState([]);

  // Fetch games history on component mount
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const games = await getCoinflipGamesHistory();
        setPrevGames(games);
        setLoading(false);
      } catch (error) {
        toast.error("There was an error while loading games data, please try again later!");
        console.log("There was an error while loading Coinflip games:", error);
      }
    };
    fetchData();
  }, []);
  return <PageContainer maxWidth="md">
            <Title>Coinflip History</Title>
            <Subtitle>Last 25 Rounds</Subtitle>
            <Content>
                {loading ? <ListItemsSkeleton length={6} height="125px" /> : prevGames.map(game => <Game key={game._id} game={game} />)}
            </Content>
        </PageContainer>;
};
export default Coinflip;

// TODO: make a shared game history page component