import styled from "styled-components";
import CurrencyInput from "../../components/CurrencyInput";
import BREAKPOINTS from "../../constants/breakpoints";
const Wrapper = styled.div`
    margin-top: 60px;
    width: 100%;
    height: 78px;
    border-radius: 10px;
    border: 1px solid ${props => props.theme.border.primary};
    background: ${props => props.theme.background.primary};
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px 0 20px;
    @media (max-width: ${BREAKPOINTS.md}) {
        flex-direction: column;
        height: fit-content;
        gap: 10px;
        padding: 0 15px 15px 20px;
    }
`;
const InputContainer = styled.div`
    display: flex;
    max-width: 330px;
    height: 60px;
    border-right: 1px solid ${({
  theme
}) => theme.background.secondary};
    padding-right: 15px;
    justify-content: space-between;

    @media (max-width: ${BREAKPOINTS.md}) {
        border-right: none;
        padding-right: 0;
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    gap: 10px;
`;
const MultiplierButton = styled.button`
    padding: 12px 15px;
    background-color: ${({
  theme
}) => theme.background.secondary};
    color: ${({
  theme
}) => theme.colors.secondary};

    font-size: 13px;
    line-height: 1;
    font-weight: 600;
    text-transform: uppercase;

    border-radius: 8px;
    border: none;

    cursor: pointer;
    transition: all 200ms ease;
    &:hover {
        filter: brightness(1.1);
    }
`;
const BetAmount = ({
  betAmount,
  setBetAmount,
  lastBetAmount,
  showInFiat,
  exchangeRate,
  user,
  currency
}) => {
  return <Wrapper>
            <InputContainer>
                <CurrencyInput showInFiat={showInFiat} currency={currency} onChange={value => setBetAmount(value)} value={betAmount} exchangeRate={exchangeRate} background="transparent" />
            </InputContainer>
            <ButtonContainer>
                <MultiplierButton onClick={() => setBetAmount(lastBetAmount)}>
                    LAST
                </MultiplierButton>
                <MultiplierButton onClick={() => setBetAmount(state => parseFloat(state) / 2 || 0)}>
                    1/2
                </MultiplierButton>
                <MultiplierButton onClick={() => setBetAmount(state => parseFloat(state) * 2 || 0)}>
                    X2
                </MultiplierButton>
                <MultiplierButton onClick={() => setBetAmount(user ? user.wallet : 0)}>
                    Max
                </MultiplierButton>
            </ButtonContainer>
        </Wrapper>;
};
export default BetAmount;