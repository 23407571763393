import React from "react";
import { Redirect } from "react-router-dom";
const AffiliatesRedirect = ({
  match
}) => {
  window.localStorage.setItem("affiliate", JSON.stringify({
    code: match.params.affiliateCode,
    expiry: new Date().getTime() + 1000 * 60 * 60 * 24 * 7 // 1 week
  }));
  return <Redirect to="/" />;
};
export default AffiliatesRedirect;