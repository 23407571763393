import React from "react";
import styled from "styled-components";

// Assets
import logo from "../assets/v2/sweet-textlogo.webp";
const Root = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 3rem 5rem;
    color: white;
    background: ${props => props.theme.background.primary};
`;
const Title = styled.h1`
    font-size: 35px;
`;
const Link = styled.a`
    color: ${props => props.theme.colors.tertiary};

    transition: 0.2s ease-in-out;
    text-decoration: none;
    &:hover {
        opacity: 0.9;
        text-decoration: none;
    }
`;
const Text = styled.p`
    font-size: 1rem;
`;
const Logo = styled.img`
    height: 60px;
    width: auto;
`;
const BlockedCountry = () => {
  return <Root>
            <Logo src={logo} alt="Sweet.gg" />
            <Title>ACCESS RESTRICTED</Title>
            <p>
                For legal reasons, this website and its services are not available in your country.<br />If you are using a VPN, please disable it and try again.
            </p>
        </Root>;
};
export default BlockedCountry;