import React from "react";
import { useSpring, animated, config } from "react-spring";
import styled from "styled-components";
export const TimerBar = props => {
  const maxDuration = props.maxDuration;
  const timerDuration = props.waitTime - new Date().getTime();
  const current_width = 100 - (maxDuration - timerDuration) / maxDuration * 100;
  const timerAnimation = useSpring({
    from: {
      width: `${current_width}%`
    },
    to: {
      width: "0%"
    },
    config: {
      duration: timerDuration,
      config: config.wobbly
    },
    onRest(ds) {
      props.updateGameState(props.gameStates.InProgress);
    }
  });
  return <Background>
            <Timer as={animated.div} style={timerAnimation} />
        </Background>;
};
const Background = styled.div`
    border-radius: 0px;
    height: 0.25rem;
`;
const Timer = styled.div`
    background-color: #00e258 !important;
    height: 0.25rem;
`;