import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
const Card = ({
  state,
  value,
  suit,
  isPrivate,
  isActive
}) => {
  const renderContainer = () => {
    return <div className="container">
                <span className="rank">{value}</span>
                <span className="suit">{suit}</span>
            </div>;
  };
  const wonGame = state === "player_win" || state === "player_blackjack";
  return <CardContainer data-private={isPrivate} isHeartsOrDiamonds={suit === "H" || suit === "D"} isHearts={suit === "H"} isClubs={suit === "C"} isSpades={suit === "S"} isActive={isActive} wonGame={wonGame}>
            {isPrivate ? <div className="back" /> : <div className="front">
                    <div className="section top">{renderContainer()}</div>
                    <div className="section center suit">{suit}</div>
                    <div className="section bottom">{renderContainer()}</div>
                </div>}
        </CardContainer>;
};
Card.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  suit: PropTypes.string.isRequired,
  isPrivate: PropTypes.bool
};
export default Card;
const cardEnterDuration = "0.4s";
const cardFlipDelay = "0.1s";
const cardDealBezier = "cubic-bezier(0.16, 0.84, 0.32, 1.04)";
const cardRotateHearts = "rotate(-1deg)";
const cardRotateClubs = "rotate(1deg)";
const cardHeight = "180px";
const cardWidth = "120px";
const CardContainer = styled.div`
    color: #000;
    height: ${cardHeight};
    width: ${cardWidth};
    position: relative;
    perspective: 600px;
    margin-left: ${() => `calc(${cardWidth} / -1.5)`};

    ${props => props.isActive ? css`
                  .front,
                  .back {
                      border: 4px solid
                          ${({
  theme,
  wonGame
}) => wonGame ? "#03df58" : theme.colors.tertiary} !important;
                  }
              ` : css`
                  cursor: pointer;
              `}

    .front,
    .back {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        border: 4px solid transparent;
        position: absolute;
        top: 0;
        left: 0;
        width: inherit;
        height: inherit;
        transform-style: preserve-3d;
        backface-visibility: hidden;
    }

    .front {
        display: flex;
        flex-direction: column;
        z-index: 3;
        transform: rotateX(0) rotateY(0);

        .suit {
            font-size: 0;
        }

        .section {
            &.top,
            &.bottom {
                flex: 0 1 auto;
                padding-top: 5px;

                span {
                    display: block;
                    font-weight: bold;
                    line-height: 20px;
                    height: 20px;

                    &.rank {
                        font-size: 20px;
                    }

                    &.suit {
                        padding-left: 1px;

                        &:after {
                            font-size: 20px;
                        }
                    }
                }
            }

            &.center {
                flex: 1 1 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                &:after {
                    font-size: 50px;
                }
            }

            &.bottom {
                transform: rotate(180deg);
            }

            .container {
                max-width: 25%;
                text-align: center;
            }
        }
    }

    .back {
        z-index: 2;
        transform: rotateY(-179deg);
        border: 10px solid #fff;
        background-color: ${({
  theme
}) => theme.colors.tertiary};
        background-image: repeating-linear-gradient(
                120deg,
                rgba(255, 255, 255, 0.1),
                rgba(255, 255, 255, 0.1) 1px,
                transparent 1px,
                transparent 60px
            ),
            repeating-linear-gradient(
                60deg,
                rgba(255, 255, 255, 0.1),
                rgba(255, 255, 255, 0.1) 1px,
                transparent 1px,
                transparent 60px
            ),
            linear-gradient(
                60deg,
                rgba(0, 0, 0, 0.1) 25%,
                transparent 25%,
                transparent 75%,
                rgba(0, 0, 0, 0.1) 75%,
                rgba(0, 0, 0, 0.1)
            ),
            linear-gradient(
                120deg,
                rgba(0, 0, 0, 0.1) 25%,
                transparent 25%,
                transparent 75%,
                rgba(0, 0, 0, 0.1) 75%,
                rgba(0, 0, 0, 0.1)
            );
        background-size: 70px 120px;
    }

    ${props => props.isHeartsOrDiamonds && css`
            color: #e40909;

            .suit:after {
                content: ${props.isHearts ? "'\\2665'" : "'\\2666'"};
            }
        `}

    ${props => props.isClubs && css`
            .suit:after {
                content: "\\2663";
            }
        `}

    ${props => props.isSpades && css`
            .suit:after {
                content: "\\2660";
            }
        `}

    &[data-private="true"] {
        .front {
            transform: rotateY(179deg);
        }

        .back {
            transform: rotateX(0) rotateY(0);
        }
    }

    &.list-enter,
    &.list-appear {
        transform: translate3d(100vw, -100vh, 0);
        transition: transform ${cardEnterDuration} ${cardDealBezier};
        transition-delay: 0;

        &[data-private="false"] {
            .front,
            .back {
                transition: transform ${cardEnterDuration} ${cardDealBezier};
                transition-delay: ${cardFlipDelay} * 0.8;
            }
            .front {
                transform: rotateY(179deg);
            }
            .back {
                transform: rotateX(0) rotateY(0);
            }
        }

        &.list-enter-active,
        &.list-appear-active {
            &.H {
                transform: translate3d(0, 0, 0) ${cardRotateHearts};
            }

            &.C {
                transform: translate3d(0, 0, 0) ${cardRotateClubs};
            }

            &:nth-child(odd) {
                transform: translate3d(0, 0, 0);
            }

            &:nth-child(even) {
                transform: translate3d(0, 0, 0);
            }

            &[data-private="false"] {
                .front {
                    transform: rotateX(0) rotateY(0);
                }
                .back {
                    transform: rotateY(-179deg);
                }
            }
        }
    }

    &.list-exit {
        transform: translate3d(0, 0, 0);
        transition: transform ${cardEnterDuration} ${cardDealBezier};

        &.list-exit-active {
            transform: translate3d(0, -100vh, 0);
        }
    }
`;