import React, { useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { caseBattleSocket } from "../../services/websocket.service";
import { CURRENCIES } from "../../constants/currencies";

// MUI Components
import { Visibility as VisibilityIcon, Replay as ReplayIcon } from "@material-ui/icons";

// Components
import { JoinButton } from "./JoinButton";
import PlayerAvatar from "../PlayerAvatar";
import BotAvatar from "../BotAvatar";
import Price from "../Price";
import BREAKPOINTS from "../../constants/breakpoints";

// Assets
import { ReactComponent as PvPIcon } from "../../assets/cases/pvp.svg";
const Battle = styled.div`
    display: flex;
    height: 6rem;
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    background: ${props => props.theme.background.primary};
    border: 1px solid ${props => props.theme.border.primary};
    border-radius: 1rem;

    @media (max-width: ${BREAKPOINTS.md}) {
        display: grid;
        height: 100%;
        padding-bottom: 1rem;
        margin-bottom: 30px;
    }
`;
const PvPIconWrapper = styled.div`
    color: ${({
  theme
}) => theme.colors.secondary};
    margin: 0 3px;

    & svg {
        width: 15px;
        height: auto;
    }
`;
const ActionWrapper = styled.div`
    color: ${({
  theme
}) => theme.colors.secondary};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 2rem;
    padding: 0 35px;
    border-radius: 1rem 0 0 1rem;

    @media (max-width: ${BREAKPOINTS.md}) {
        height: fit-content;
        margin-bottom: 0.5rem;
        width: 100%;
        border-radius: 1rem 1rem 0 0;
        padding: 15px 0;
    }

    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
        opacity: 0.9;
        background: rgba(255, 255, 255, 0.05);
    }
`;
const BattleInfo = styled.div`
    min-width: 25%;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-right: 25px;
    margin-left: 15px;

    @media (max-width: ${BREAKPOINTS.md}) {
        margin-bottom: 1rem;
    }
`;
const Cases = styled.div`
    width: 40%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background: ${props => props.theme.background.secondary};
    padding: 5px;
    border-radius: 5px;

    @media (max-width: ${BREAKPOINTS.md}) {
        width: 85%;
        margin-bottom: 1rem;
    }
`;
const BattleContent = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    white-space: nowrap;
    align-items: center;
    @media (max-width: ${BREAKPOINTS.md}) {
        flex-direction: column;
        margin-top: 1rem;
        gap: 0.5rem;
    }
`;
const Players = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: ${({
  theme
}) => theme.colors.primary};
    font-size: 10px;
    width: 35%;
    @media (max-width: ${BREAKPOINTS.md}) {
        width: 100%;
        margin-bottom: 1rem;
    }
`;
const TotalUnboxed = styled.div`
    color: ${({
  theme
}) => theme.colors.secondary};
    font-weight: 500;
    margin-bottom: 3px;
`;
const _renderTeam = (game, team, players) => {
  const teamPlayers = players.filter(player => player.teamId === team._id);
  return <>
            {teamPlayers.map(player => {
      if (player.isBot) {
        return <BotAvatar key={player._id} />;
      }
      return <PlayerAvatar key={player.address} size={35} address={player.address} levelColor={player.level?.levelColor} levelName={player.level?.name} />;
    })}
            {Array.from({
      length: game.teamSize - teamPlayers.length
    }).map(() => <div style={{
      height: "35px",
      width: "35px",
      borderRadius: "50%",
      background: "rgba(118, 58, 161, 0.25)"
    }} />)}
        </>;
};
const Game = ({
  game
}) => {
  const history = useHistory();
  const theme = useTheme();
  const [joining, setJoining] = useState(false);
  const onClickView = () => {
    history.push(`/crate-battles/${game._id}`);
  };
  const joinEvent = () => {
    setJoining(false);
  };
  useEffect(() => {
    caseBattleSocket.on("battle-join-error", joinEvent);
    caseBattleSocket.on("battle-join-success", joinEvent);
    return () => {
      caseBattleSocket.off("battle-join-error", joinEvent);
      caseBattleSocket.off("battle-join-success", joinEvent);
    };
  }, []);
  const battlePrice = game.cases.reduce((acc, curr) => acc + curr.fiatPrice, 0);
  return <Battle key={game._id} style={game.ended && {
    animation: "blinker 1.5s forwards",
    WebkitAnimation: "blinker 1.5s forwards",
    MozAnimation: "blinker 1.5s forwards"
  }}>
            {(game.status === 1 || game.status === 2) && <ActionWrapper onClick={onClickView} data-tooltip-content="Spectate" data-tooltip-id="default">
                    <VisibilityIcon />
                </ActionWrapper>}
            {game.status === 3 && <ActionWrapper onClick={onClickView} data-tooltip-content="Rewatch Game" data-tooltip-id="default">
                    <ReplayIcon />
                </ActionWrapper>}
            <BattleContent>
                <Players>
                    {game.teams.map((team, index) => {
          if (index === 0) {
            return _renderTeam(game, team, game.players);
          } else {
            return <>
                                    <PvPIconWrapper>
                                        <PvPIcon />
                                    </PvPIconWrapper>
                                    {_renderTeam(game, team, game.players)}
                                </>;
          }
        })}
                </Players>
                <Cases>
                    {game.cases.length > 4 ? <>
                            {game.cases.slice(0, 4).map(crate => <img src={crate.imageUrl} style={{
            width: "3.5rem"
          }} alt={crate.name} />)}
                            <div style={{
            color: "#fff",
            fontSize: "11px",
            fontWeight: 500
          }}>
                                & {game.cases.length - 4} more
                            </div>
                        </> : <>
                            {game.cases.map(crate => <img src={crate.imageUrl} style={{
            width: "3.5rem"
          }} alt={crate.name} />)}
                        </>}
                </Cases>
                <BattleInfo>
                    {game.status === 1 && <JoinButton onClick={onClickView} disabled={joining ? true : false}>
                            {joining ? "Joining..." : <>
                                    <span style={{
              marginRight: "3px"
            }}>
                                        JOIN FOR
                                    </span>
                                    <Price amount={battlePrice} currency={CURRENCIES.USD} />
                                </>}
                        </JoinButton>}
                    {game.status === 3 && <>
                            <TotalUnboxed>TOTAL UNBOXED</TotalUnboxed>
                            <Price amount={game.payout} textSize="14px" textColor={theme.colors.primary} />
                        </>}
                </BattleInfo>
            </BattleContent>
        </Battle>;
};
export default Game;