import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Countdown from "react-countdown";
import { connect } from "react-redux";
import BREAKPOINTS from "../constants/breakpoints";
import parseCommasToThousands from "../utils/parseCommasToThousands";
import { chatSocket } from "../services/websocket.service";
import useMediaQuery from "../hooks/useMediaQuery";

// Components
import Skeleton from "../components/Skeleton";
import WinFeed from "../components/WinFeed";
import PageContainer from "../components/PageContainer";
import BuyCrypto from "../components/BuyCrypto";
import PopularSlots from "../components/PopularSlots";

// Assets
import banner from "../assets/v2/sweet-banner.webp";
import mobileBanner from "../assets/v2/sweet-mobile-banner.webp";
import hourlysugarrush from "../assets/v2/sugar-rush.webp";
import blastBanner from "../assets/v2/blast/sweet-v2-blast-banner.webp";
import blastHourlySugarRush from "../assets/v2/blast/blast-sugar-rush.webp";

// Assets - Game Cards
import dicebanner from "../assets/v2/game-cards/dice.webp";
import roulettebanner from "../assets/v2/game-cards/roulette.webp";
import coinflipbanner from "../assets/v2/game-cards/coinflip.webp";
import crashbanner from "../assets/v2/game-cards/crash.webp";
import cratesbanner from "../assets/v2/game-cards/crates.webp";
import battlesbanner from "../assets/v2/game-cards/battles.webp";
import blackjackbanner from "../assets/v2/game-cards/blackjack.webp";

// Game Link Component
const GameLink = ({
  history,
  image,
  link,
  isNew
}) => {
  const handleRedirect = path => {
    history.push(path);
  };
  return <OriginalGame onClick={() => handleRedirect(link)} style={{
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundPosition: "center"
  }}>
            {isNew && <NewGameBadge>New</NewGameBadge>}
        </OriginalGame>;
};
const Main_1 = _compiledBlock(_props => _props.v0, {
  name: "Main_1",
  portals: ["v0"]
});
const Main = ({
  activeTheme
}) => {
  const history = useHistory();
  const isMobile = useMediaQuery(BREAKPOINTS.sm);
  const [sugarRushPrize, setSugarRushPrize] = useState(null);
  const [sugarRushStartTime, setSugarRushStartTime] = useState(null);
  const updateSugarRushPrize = prize => {
    setSugarRushPrize(prize.value);
    setSugarRushStartTime(prize.startTime);
  };
  useEffect(() => {
    chatSocket.on("update-hourly-prize", updateSugarRushPrize);
    return () => {
      chatSocket.off("update-hourly-prize", updateSugarRushPrize);
    };
  }, []);

  // Renderer callback with condition
  const renderer = ({
    hours,
    minutes,
    seconds,
    completed
  }) => {
    if (completed) {
      return <div>ENDED</div>;
    } else {
      function z(t) {
        return t < 10 ? `0${t}` : t;
      }
      return <span style={{
        display: "flex",
        background: "rgba(255, 255, 255, 0.30)",
        border: "1px solid rgba(255, 255, 255, 0.45)",
        borderRadius: "10px",
        backdropFilter: "blur(15px)",
        padding: "5px 10px",
        color: "#fff",
        maxWidth: "fit-content"
      }}>
                    <span style={{
          width: "50px",
          textAlign: "center"
        }}>
                        {z(hours)}
                        <br />
                        <span style={{
            fontSize: "11px"
          }}>HRS</span>
                    </span>
                    <span style={{
          width: "50px",
          textAlign: "center"
        }}>
                        {z(minutes)}
                        <br />
                        <span style={{
            fontSize: "11px"
          }}>MIN</span>
                    </span>
                    <span style={{
          width: "50px",
          textAlign: "center"
        }}>
                        {z(seconds)}
                        <br />
                        <span style={{
            fontSize: "11px"
          }}>SECS</span>
                    </span>
                </span>;
    }
  };
  return /*@million jsx-skip*/<Main_1 v0={<PageContainer maxWidth="lg">
            <Container>
                <BannerWrapper href="/slots" target="_blank" rel="noopener noreferrer">
                    <img alt="Banner" src={activeTheme === "blast" ? blastBanner : isMobile ? mobileBanner : banner} style={{
          borderRadius: "10px",
          width: "100%"
        }} />
                </BannerWrapper>
                <FlexContainer>
                    <SugarRushContainer isBlast={activeTheme === "blast"}>
                        <SugarRushTitle>Sugar Rush</SugarRushTitle>
                        <SugarRushDescription>
                            <div>Win up to</div>
                            <PrizeAmountContainer>
                                {sugarRushPrize ? parseCommasToThousands(sugarRushPrize) : <Skeleton width="50px" height="20px" opacity={0.3} />}{" "}
                                $SWEET{" "}
                            </PrizeAmountContainer>
                        </SugarRushDescription>
                        {sugarRushStartTime ? <Countdown date={sugarRushStartTime} renderer={renderer} /> : <Skeleton width="200px" height="50px" opacity={0.3} />}
                    </SugarRushContainer>
                    <WinFeed />
                </FlexContainer>
                <BuyCrypto />
                <SectionHeader>Sweet Originals</SectionHeader>
                <GamesContainer>
                    <GameLink history={history} link="/crate-battles" image={battlesbanner} isNew />
                    <GameLink history={history} link="/crates" image={cratesbanner} />
                    <GameLink history={history} link="/dice" image={dicebanner} />
                    <GameLink history={history} link="/coinflip" image={coinflipbanner} />
                    <GameLink history={history} link="/crash" image={crashbanner} />
                    <GameLink history={history} link="/roulette" image={roulettebanner} />
                    <GameLink history={history} link="/blackjack" image={blackjackbanner} />
                </GamesContainer>
                <PopularSlots />
            </Container>
        </PageContainer>} />;
};
const mapStateToProps = state => ({
  activeTheme: state.theme.themeType
});
export default connect(mapStateToProps)(Main);
const GamesContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(13%, 1fr));
    margin-top: 1rem;
    margin-bottom: 2rem;

    @media (max-width: ${BREAKPOINTS.md}) {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
`;
const OriginalGame = styled.div`
    width: 100%;
    height: 100%;
    aspect-ratio: 0.7/1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    position: relative;
    border-radius: 10px;
    padding: 16px;
    transition: 0.2s;
    overflow: hidden;
    cursor: pointer;

    &:hover {
        transform: scale(1.05);
    }
`;
export const SlotLink = styled(OriginalGame)`
    aspect-ratio: 0.7 / 1;
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
`;
export const SectionHeader = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 2.2rem 0 1rem 0;
`;
const FlexContainer = styled.div`
    height: 233px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin: 2rem 0;
    gap: 1rem;

    @media (max-width: ${BREAKPOINTS.md}) {
        flex-direction: column;
        height: auto;
    }
`;
const NewGameBadge = styled.div`
    background: ${props => props.theme.colors.tertiary};
    border-radius: 5px;
    color: ${props => props.theme.colors._colorOnTeritary // if has special color, use it
? props.theme.colors._colorOnTeritary : props.theme.colors.primary};
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    padding: 4px 10px;
    top: 7px;
    right: 7px;
    text-transform: uppercase;
    z-index: 1;
`;
const Container = styled.div`
    color: ${props => props.theme.colors.secondary};
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.005em;
    margin-top: 15px;
`;
const BannerWrapper = styled.a`
    width: 100%;
    cursor: pointer;
`;
const SugarRushContainer = styled.div`
    width: 25%;
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
    background: #eb2584;
    padding: 1rem;
    background-image: url(${({
  isBlast
}) => isBlast ? blastHourlySugarRush : hourlysugarrush});
    background-size: cover;
    background-position: center;

    @media (max-width: ${BREAKPOINTS.md}) {
        width: 100%;
    }
`;
const SugarRushTitle = styled.div`
    color: rgba(239, 242, 245, 0.5);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
`;
const SugarRushDescription = styled.div`
    font-size: 20px;
    font-weight: 700;
    color: ${props => props.theme.colors.primary};
    color: ${({
  theme
}) => theme.colors.primary};
    white-space: nowrap;
    margin-bottom: 2rem;

    @media (max-width: ${BREAKPOINTS.md}) {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-bottom: 1rem;
    }
`;
const PrizeAmountContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;