import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
export const COLOURS = {
  primary: "primary",
  secondary: "secondary"
};
const StyledButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    text-transform: uppercase;
    padding: 12px;
    background: ${({
  theme,
  colour
}) => theme.button[colour]};
    border: none;
    outline: none;
    border-radius: 5px;
    color: ${({
  theme,
  colour
}) => theme.button["_colorOn_" + colour] || theme.colors.primary};
    letter-spacing: 0.1em;
    line-height: 1;
    font-size: 13px;
    font-weight: 700;
    box-shadow: ${({
  theme
}) => theme.shadow && `0px 0px 15px 0px ${theme.shadow.tertiary}`};

    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
        filter: brightness(1.1);
    }

    &:disabled: {
        cursor: not-allowed;
        opacity: 0.5;
    }

    ${({
  disabled
}) => disabled && `
        cursor: not-allowed;
        opacity: 0.4;
    `}
`;

// TODO: Use everywhere
// TODO: Effect on button click

const Button = ({
  children,
  onClick,
  disabled,
  style,
  colour = COLOURS.primary
}) => {
  return <StyledButton onClick={onClick} disabled={disabled} style={style} colour={colour}>
            {children}
        </StyledButton>;
};
Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object
};
export default Button;