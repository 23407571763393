import { combineReducers } from "redux";

// Import external reducers
import site from "./site";
import auth from "./auth";
import bets from "./bets";
import currency from "./currency";
import theme from "./theme";
import blocked from "./blocked";

export default combineReducers({
    site,
    auth,
    bets,
    currency,
    theme,
    blocked,
});
