import {
    SELECT_BET,
    DESELECT_BET,
    CLEAR_BETS,
    OPEN_BETS,
} from "../actions/types";

const initialState = {
    bets: [],
    openBets: [],
};

const bets = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SELECT_BET:
            return {
                ...state,
                bets: [...state.bets, payload],
            };
        case DESELECT_BET:
            return {
                ...state,
                bets: state.bets.filter(
                    (bet) =>
                        `${bet.eventId}-${bet.outcome.name}-${bet.outcome.point}-${bet.outcome.price}` !==
                        `${payload.eventId}-${payload.outcome.name}-${payload.outcome.point}-${payload.outcome.price}`,
                ),
            };
        case CLEAR_BETS:
            return {
                ...state,
                bets: [],
            };
        case OPEN_BETS:
            return {
                ...state,
                openBets: payload,
            };
        default:
            return state;
    }
};

export default bets;
