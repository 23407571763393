import React from "react";
import styled from "styled-components";

// TODO: Animate progress bar usign import * as Progress from '@radix-ui/react-progress';
const ProgressBar = ({
  progress,
  style
}) => {
  return <ProgressContainer style={style}>
            <Progress style={{
      width: `${progress}%`
    }} />
        </ProgressContainer>;
};
const ProgressContainer = styled.div`
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 8px;
    background-color: #141619;
    border-radius: 25rem;
`;
const Progress = styled.div`
    height: 100%;
    background-color: ${({
  theme
}) => theme.colors.green};
`;
export default ProgressBar;