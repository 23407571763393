import Button from "../Button";
export const JoinButton = ({
  children,
  disabled,
  onClick
}) => {
  return <Button disabled={disabled} onClick={onClick} style={{
    padding: "10px"
  }}>
            {children}
        </Button>;
};