import axios from "axios";
import store from "../store";
import { redirectToBannedCountryPage } from "../store/actions/blocked";

// Create axios client
export const API = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: axios.defaults.headers.common,
});

export const V2API = axios.create({
    baseURL: process.env.REACT_APP_V2_API_URL,
    headers: axios.defaults.headers.common,
});

V2API.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (!error.response) {
            return Promise.reject(
                new Error(
                    "Network error or server is unreachable. Please try again later.",
                ),
            );
        }

        // TODO: mv to useFetch after useFetch is implemented everywhere.
        const responseData = error.response.data;
        let errorMessage =
            (responseData && (responseData.message || responseData.error)) ||
            "An unknown error occurred.";

        if (errorMessage === "Country is blocked.") {
            store.dispatch(redirectToBannedCountryPage());

            return Promise.reject({ isCountryBlocked: true });
        }

        return Promise.reject(new Error(errorMessage));
    },
);

/**
 * API Methods
 */

// * Retrieve site schema
export const getSiteConfig = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.get("/site/config");
            resolve(response.data);
        } catch (error) {
            reject(error.message);
        }
    });
};

export const getExchangeRates = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.get("/data/exchange-rates");
            resolve(response.data);
        } catch (error) {
            reject(error.message);
        }
    });
};

// Retrieve user data using a auth token
export const getUser = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.get("/user/me");
            resolve(response.data);
        } catch (error) {
            reject(error.message);
        }
    });
};

export const getUserWallets = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.get("/user/wallets");
            resolve(response.data);
        } catch (error) {
            reject(error.message);
        }
    });
};

export const getCustodialUserWallets = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.get("/user/custodial-wallets");
            resolve(response.data);
        } catch (error) {
            reject(error.message);
        }
    });
};

export const createCustodialWallet = async (currencyId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.post(
                "/cashier/create-deposit-wallet",
                {
                    currency_id: currencyId,
                },
            );
            resolve(response.data);
        } catch (error) {
            reject(error.message);
        }
    });
};

export const getUserStatistics = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.get("/user/statistics");
            resolve(response.data);
        } catch (error) {
            reject(error.message);
        }
    });
};

export const registerAffiliate = async (code) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.post("/user/affiliates/register", {
                affiliate_code: code,
            });
            resolve(response.data);
        } catch (error) {
            reject(error.message);
        }
    });
};

export const getChatMessages = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.get("/chat/messages");
            resolve(response.data);
        } catch (error) {
            reject(error.message);
        }
    });
};

export const getActiveSugarRush = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.get("/chat/active-sugar-rush");
            resolve(response.data);
        } catch (error) {
            reject(error.message);
        }
    });
};

// Get user profile information
export const getUserTransactions = async (type, limit, offset) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.post("/user/transactions", {
                type,
                limit,
                offset,
            });
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const updateUsername = async (username) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.post("/user/update-username", {
                username,
            });
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

// Withdraw crypto to personal wallet
export const withdrawCrypto = async (
    currency,
    address,
    amount,
    idempotencyKey,
) => {
    return new Promise(async (resolve, reject) => {
        try {
            console.log("idempotency", idempotencyKey);
            const response = await V2API.post("/cashier/withdraw", {
                currency_id: currency,
                amount: amount,
                to_address: address,
                idempotency_key: idempotencyKey,
            });
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

// Get user affiliates information
export const getAffiliateData = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.get("/user/affiliates/data");
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const getAffiliatedUsers = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.get("/user/affiliates/users");
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

// Update user current affiliate code
export const updateUserAffiliateCode = async (code) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.post("/user/affiliates/update-code", {
                affiliate_code: code,
            });
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

// Claim avalailable affiliate balance
export const claimAffiliateEarnings = async (idempotencyKey) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.post("/user/affiliates/claim", {
                idempotency_key: idempotencyKey,
            });
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

// Get active coinflip games
export const getActiveCoinflipGames = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await API.get("/coinflip");
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

// Get active coinflip games
export const getActiveCratesBattles = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await API.get("/casebattle");
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};
export const getCratesBattlesHistory = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await API.get("/casebattle/history");
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const getCratesBattle = async (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await API.get(`/casebattle/${id}`);
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

// Get active coinflip games history
export const getCoinflipGamesHistory = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await API.get("/coinflip/history");
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

// Get private coinflip game
export const getCoinflipGame = async (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await API.get(`/coinflip/${id}`);
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const getCaseGame = async (id, gameId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await API.get(`/cases/${id}?gameId=${gameId}`);
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const getUnfinishedBlackjackGame = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await API.get(`/blackjack`);
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const getActiveRace = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.get("/race/active");
            resolve(response.data);
        } catch (error) {
            reject(error.message);
        }
    });
};

export const getRaceEntries = async (raceId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.get(`/race/${raceId}/entries`);
            resolve(response.data);
        } catch (error) {
            reject(error.message);
        }
    });
};

export const getUserVipData = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.get("/user/vip");
            resolve(response.data);
        } catch (error) {
            reject(error.message);
        }
    });
};

// Claim user rakeback balance
export const claimRakebackBalance = async (idempotencyKey) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.post("/user/rakeback/claim", {
                idempotency_key: idempotencyKey,
            });
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const getCratesSchema = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.get("/games/crates/data");
            resolve(response.data);
        } catch (error) {
            reject(error.message);
        }
    });
};

export const getCrates = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.get("/games/crates");
            resolve(response.data);
        } catch (error) {
            reject(error.message);
        }
    });
};

export const getCrateBySlug = async (slug) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.get(`/games/crates/${slug}`);
            resolve(response.data);
        } catch (error) {
            reject(error.message);
        }
    });
};

export const getSlotById = async (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.get(`/slots/${id}`);
            resolve(response.data);
        } catch (error) {
            reject(error.message);
        }
    });
};

export const initDemoSlotsGame = async (id, returnUrl, language) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.post(`/slots/${id}/init-demo`, {
                return_url: returnUrl,
                language,
            });
            resolve(response.data);
        } catch (error) {
            reject(error.message);
        }
    });
};

export const initSlotsGame = async (
    id,
    returnUrl,
    language,
    currency,
    lobbyData,
) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.post(`/slots/${id}/init`, {
                return_url: returnUrl,
                language,
                currency_id: currency,
                lobby_data: lobbyData,
            });
            resolve(response.data);
        } catch (error) {
            reject(error.message);
        }
    });
};

export const retrieveGameLobbies = async (id, currency) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.post(`/slots/${id}/lobbies`, {
                currency,
            });
            resolve(response.data);
        } catch (error) {
            reject(error.message);
        }
    });
};

// Get current roulette game and history
export const getRouletteSchema = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.get("/games/roulette/data");
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

// Get current crash game and history
export const getCrashSchema = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await API.get("/crash");
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const getDiceSchema = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await API.get("/dice");
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};
// Get user crash data
export const getUserCrashData = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await API.get("/crash/me");
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

// Get current jackpot game and history
export const getJackpotSchema = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await API.get("/jackpot");
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

// Get current jackpot game and history
export const getJackpotHistory = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await API.get("/jackpot/history");
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const getRecentWinsByFilter = async (filter) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.get(`/data/recent-wins/${filter}`);
            resolve(response.data);
        } catch (error) {
            reject(error.message);
        }
    });
};

export const getProviderGames = async (
    type,
    provider,
    limit,
    offset,
    search,
    sort,
) => {
    let isMobile = false;
    if (window.innerWidth <= 768) {
        isMobile = true;
    }

    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.post("/slots/games", {
                type,
                platform: isMobile ? "mobile" : "desktop",
                limit,
                offset,
                provider,
                search,
                sort,
            });
            resolve(response.data);
        } catch (error) {
            reject(error.message);
        }
    });
};

export const getGameProviders = async (gameType) => {
    let isMobile = false;
    if (window.innerWidth <= 768) {
        isMobile = true;
    }

    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.get(
                `/slots/providers?platform=${
                    isMobile ? "mobile" : "desktop"
                }&game_type=${gameType}`,
            );
            resolve(response.data);
        } catch (error) {
            reject(error.message);
        }
    });
};

export const getRegisteredAffiliate = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.get("/user/affiliated-by");
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const activateDepositBonus = async (code, idempotencyKey) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.post("/user/activate-deposit-bonus", {
                deposit_bonus_code: code,
                idempotency_key: idempotencyKey,
            });
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const sendTip = async (
    idempotencyKey,
    toUser,
    usdAmount,
    currencyId,
) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.post("/cashier/tip", {
                to_user: toUser,
                idempotency_key: idempotencyKey,
                amount_usd: usdAmount,
                currency_id: currencyId,
            });
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const bindStakingWallet = async (signature) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.post("/user/bind-staking-wallet", {
                signature,
            });
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const openCrate = async (slug, currency_id, amount, idempotencyKey) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.post(`/games/crates/${slug}/open`, {
                currencyId: currency_id,
                amount: amount,
                idempotencyKey,
            });
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const updateClientSeed = async (clientSeed, idempotencyKey) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.post(`/user/update-client-seed`, {
                client_seed: clientSeed,
                idempotencyKey,
            });
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const rotateServerSeed = async (idempotencyKey) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.post(`/user/rotate-server-seed`, {
                idempotencyKey,
            });
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

export const getSeeds = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await V2API.get(`/user/seeds`);
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};
