import React, { useState, forwardRef, useImperativeHandle } from "react";
import styled from "styled-components";

// Assets
import pin from "../../assets/cases/pin.svg";
import spin from "../../assets/cases/spin.mp3";
import landed from "../../assets/cases/landed.mp3";
import landedRare from "../../assets/cases/landed-rare.mp3";
const spinAudio = new Audio(spin);
const landedAudio = new Audio(landed);
const landedRareAudio = new Audio(landedRare);
const playSound = audioFile => {
  audioFile.play();
};
const CARD_WIDTH = 120;
export const _getRarityColor = price => {
  if (price < 10) {
    return "#7C829F";
  } else if (price < 50) {
    return "#7FB653";
  } else if (price < 100) {
    return "#7059FF";
  } else if (price < 500) {
    return "#ED4ADD";
  } else if (price < 1000) {
    return "#ED4ADD";
  } else {
    return "#F4A328";
  }
};
export const _generateWheelItems = items => {
  // Add relative amount of items to the wheel
  const wheelItems = [];
  items.forEach(item => {
    let relativeAmount = item.weight * 100;
    if (relativeAmount <= 10) {
      wheelItems.push(item);
    } else {
      let multiplier = Math.floor(relativeAmount / 10) || 1;
      for (let i = 0; i < multiplier; i++) {
        const newItem = i > 0 ? {
          ...item,
          item: {
            ...item.item,
            id: `${item.item.id}-${i}`
          }
        } : item;
        wheelItems.push(newItem);
      }
    }
  });
  return _shuffleItems(wheelItems);
};
export const _shuffleItems = items => {
  // Shuffle the items using Fisher-Yates algorithm
  for (let i = items.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [items[i], items[j]] = [items[j], items[i]];
  }
  return items;
};
let AnimationDuration = 5_000;
let AnimationDurationFast = 2_000;
export const Wheel = forwardRef(({
  scale = 1,
  margin = "none",
  items,
  setIsOpening,
  updateOpenings
}, ref) => {
  const [caseResult, setCaseResult] = useState(null);
  const [transitionTimingFunction, setTransitionTimingFunction] = useState("");
  const [transitionDuration, setTransitionDuration] = useState("");
  const [wheelTransform, setWheelTransform] = useState("");
  const [selectorOpacity, setSelectorOpacity] = useState("0.8");
  useImperativeHandle(ref, () => ({
    resetWheel() {
      setWheelTransform(`none`);
      setCaseResult(null);
      setSelectorOpacity("0.8");
    },
    spinWheel(drawnItem, fastOpening) {
      let duration = AnimationDuration;
      if (fastOpening) {
        duration = AnimationDurationFast;
      }
      const order = items.map(item => item.item.id);
      const position = order.indexOf(drawnItem.id);

      // determine position where to land
      const card = CARD_WIDTH + 15;
      const rowsToSkip = 2;
      const start = card + items.length * rowsToSkip * card; // initial card + cards to skip
      let landingPosition = start + position * card;

      // spoof the position where to land
      const randomize = Math.floor(Math.random() * CARD_WIDTH) - CARD_WIDTH / 2;
      landingPosition = landingPosition + randomize;

      // Spinning the wheel
      setTransitionTimingFunction(`cubic-bezier(0.25, 0.5)`);
      setTransitionDuration(`${duration / 1000}s`);
      setWheelTransform(`translate3d(-${landingPosition}px, 0px, 0px)`);
      playSound(spinAudio);

      // Centering the winning item
      setTimeout(() => {
        setTransitionTimingFunction(`ease-in-out`);
        setTransitionDuration("1s");
        setWheelTransform(`translate3d(-${landingPosition - randomize}px, 0px, 0px)`);
      }, duration + 1000);
      setTimeout(() => {
        setTransitionTimingFunction("");
        setTransitionDuration("");

        // Show result
        setSelectorOpacity("0");
        setCaseResult(drawnItem);
        updateOpenings(drawnItem);

        // Play sound
        if (drawnItem.priceUSD > 100) {
          playSound(landedRareAudio);
        } else {
          playSound(landedAudio);
        }

        // Allow opening again if functionality available
        if (setIsOpening) {
          setIsOpening(false);
        }
      }, duration + 2000);
    }
  }));
  return <AnimationContainer margin={margin} scale={scale}>
                <Selector src={pin} alt="Selector" style={{
      opacity: selectorOpacity
    }} />
                <WheelContainer style={{
      transitionTimingFunction,
      transitionDuration,
      transform: wheelTransform,
      marginLeft: CARD_WIDTH + 15 // ! Figure out why this is needed
    }}>
                    {[...Array(8)].map((_, i) => <RowContainer key={i}>
                            {items.map((item, i) => <CaseItem key={i} style={caseResult ? caseResult.id === item.item.id ? {
          transition: "all 400ms",
          transform: "scale(1.05)"
        } : {
          opacity: "0.4"
        } : {}}>
                                    <img style={{
            width: "100px",
            filter: `drop-shadow(0 .5rem 1rem ${_getRarityColor(item.item.priceUSD)})`
          }} src={item.item.imageUrl} alt="" />
                                    <div style={{
            color: "#fff",
            fontWeight: "500",
            display: caseResult?.id === item.item.id ? "block" : "none"
          }}>
                                        {item.item.name}
                                    </div>
                                    <CasePrice style={{
            display: caseResult?.id === item.item.id ? "block" : "none"
          }}>
                                        ${item.item.priceUSD}
                                    </CasePrice>
                                </CaseItem>)}
                        </RowContainer>)}
                </WheelContainer>
            </AnimationContainer>;
});
const AnimationContainer = styled.div`
    margin: ${({
  margin
}) => margin};
    width: 100%;
    height: ${({
  scale
}) => 175 * scale}px;
    border: 1px solid ${props => props.theme.border.primary};
    backdrop-filter: blur(7.5px);
    background: ${props => props.theme.background.primary};
    border-radius: 10px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    align-items: center;
    position: relative;

    &::before {
        background: linear-gradient(
            90deg,
            ${props => props.theme.background.primary} 0%,
            transparent 100%
        );
        content: "";
        width: 100px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 1;
    }

    &::after {
        background: linear-gradient(
            270deg,
            ${props => props.theme.background.primary} 0%,
            transparent 100%
        );
        content: "";
        width: 100px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
`;
const WheelContainer = styled.div`
    display: flex;
    height: 100%;
    gap: 15px;
`;
const RowContainer = styled.div`
    display: flex;
    padding: 15px 0;
    gap: 15px;
`;
const CaseItem = styled.div`
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: ${CARD_WIDTH}px;
    transition: text-shadow 0.5s;
    font-size: 12px;
    background-color: ${props => props.theme.background.secondary};
    border-radius: 8px;
`;
const Selector = styled.img`
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 2;
    transition: opacity 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;
const CasePrice = styled.div`
    color: ${props => props.theme.colors.green};
`;