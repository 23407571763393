import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { CURRENCIES } from "../constants/currencies";
import BREAKPOINTS from "../constants/breakpoints";
import useMediaQuery from "../hooks/useMediaQuery";
import useFetch from "../hooks/useFetch";
import { getSlotById, initDemoSlotsGame, initSlotsGame, retrieveGameLobbies } from "../services/api.service";
import { setInPlay } from "../store/actions/site";

// Material UI
import { Switch } from "@material-ui/core";

// Components
import PageContainer from "./PageContainer";
import Skeleton from "./Skeleton";
import PopularSlots from "./PopularSlots";
import Button, { COLOURS } from "./Button";

// Assets
import { ReactComponent as ExitIcon } from "../assets/v2/icons/exit.svg";
import { ReactComponent as FullScreenIcon } from "../assets/v2/icons/fullscreen.svg";
import { ReactComponent as TheatreIcon } from "../assets/v2/icons/theatre.svg";
const ProviderGamePage = ({
  type,
  id,
  isAuthenticated,
  currency
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(BREAKPOINTS.md);
  const [slot, setSlot] = useState(null);
  const gameIFrameRef = useRef(null);
  const [gameSessionUrl, setGameSessionUrl] = useState("");
  const [hasDemoMode, setHasDemoMode] = useState(false);
  const [isDemoMode, setIsDemoMode] = useState(!isAuthenticated);
  const [openMobileInNewTab, setOpenMobileInNewTab] = useState(false);
  const [conversionRate, setConversionRate] = useState(null);
  useEffect(() => {
    // ? Lobby Games are not available in demo mode
    if (slot && slot.has_lobby) {
      setHasDemoMode(false);
      return;
    }

    // ? Live Casino and Game Show games are not available in demo mode
    if (slot && slot.type === "live-casino" || slot && slot.type === "game-show") {
      setHasDemoMode(false);
      return;
    }
    setHasDemoMode(true);
    if (slot && slot.provider === "Hacksaw") {
      fetch("https://v6.exchangerate-api.com/v6/a615e78b1991bef75fe66f08/latest/USD").then(response => response.json()).then(data => {
        setConversionRate(data.conversion_rates.JPY);
      });
    } else {
      setConversionRate(null);
    }
  }, [slot]);
  const onClickBack = () => {
    history.push(`/${type}`);
  };
  const {
    isLoading: isLoadingGame
  } = useFetch(() => getSlotById(id), {
    onSuccess: data => {
      setSlot(data);
    },
    dependencies: [id]
  });
  const [isLoadingIframe, setIsLoadingIframe] = useState(true);
  const fetchGameUrl = async isDemo => {
    if (isDemo) {
      console.log("Initlializing Demo Game");
      const {
        data
      } = await initDemoSlotsGame(slot.id, document.location.href, "en");
      return data.redirect_url;
    } else {
      if (!isAuthenticated) {
        Promise.reject("User not authenticated");
      }
      let lobbyData = null;
      if (slot.has_lobby) {
        const {
          data
        } = await retrieveGameLobbies(slot.id, "USD");
        if (data.length === 0) {
          toast.error("No lobbies available");
          return;
        }
        let lobby = data[0];
        if (lobby) {
          lobbyData = lobby.lobbyData;
        }
      }
      const {
        data
      } = await initSlotsGame(slot.id, document.location.href, "en", currency.id, lobbyData);
      dispatch(setInPlay(true));
      return data.redirect_url;
    }
  };
  useEffect(() => {
    if (!slot) {
      return;
    }
    setOpenMobileInNewTab(slot.mobile_open_new_tab);

    // ? If the slot has a lobby, we don't want to load the game in demo mode
    if (slot.has_lobby && isDemoMode) {
      setIsDemoMode(false);
      return;
    }

    // ? Live Casino and Game Show games are not available in demo mode
    if (slot.type === "live-casino" && isDemoMode) {
      setIsDemoMode(false);
      return;
    }
    if (slot.type === "game-show" && isDemoMode) {
      setIsDemoMode(false);
      return;
    }
    setIsLoadingIframe(true);
    try {
      fetchGameUrl(isDemoMode).then(url => {
        setGameSessionUrl(url);
        setIsLoadingIframe(false);
      });
      return () => {
        dispatch(setInPlay(false));
      };
    } catch (error) {
      console.error(error);
      toast.error("Failed to load game");
    }
  }, [isDemoMode, slot, isAuthenticated, currency]);
  const handleFullScreen = () => {
    if (gameIFrameRef.current) {
      gameIFrameRef.current.requestFullscreen();
    }
  };
  const _getMobileGameHeader = () => {
    switch (type) {
      case "slots":
        return "Back to Slots";
      case "live-casino":
        return "Back to Live Casino";
      case "game-show":
        return "Back to Game Shows";
      default:
        return "Back to Slots";
    }
  };
  const handleMobileDemoPlay = () => {
    fetchGameUrl(true).then(url => {
      window.location.href = url;
    }).catch(error => {
      console.error(error);
      toast.error("Failed to load game");
    });
  };
  const handleMobilePlay = () => {
    fetchGameUrl(false).then(url => {
      window.location.href = url;
    }).catch(error => {
      console.error(error);
      toast.error("Failed to load game");
    });
  };
  return <PageContainer maxWidth="lg" header={<Header>
                    <ReturnToCrates onClick={onClickBack} data-tooltip-content="Return to Slots" data-tooltip-id="default" />
                    {isLoadingGame ? <Skeleton width="150px" height="40px" /> : isMobile && openMobileInNewTab ? <MobilePageHeader>
                            {_getMobileGameHeader()}
                        </MobilePageHeader> : <HeaderContent>
                            <SlotName>{slot.name}</SlotName>
                            <Provider>{slot.provider}</Provider>
                        </HeaderContent>}
                </Header>}>
            {isMobile && openMobileInNewTab ? <OpenInNewTabContainer>
                    <FlexContainer>
                        <SlotImage src={slot.card_image} />
                        <div>
                            <OpenInNewTabSlotName>
                                {slot.name}
                            </OpenInNewTabSlotName>
                            <Provider>{slot.provider}</Provider>
                        </div>
                    </FlexContainer>
                    <GameButtonsContainer>
                        <Button disabled={!isAuthenticated} style={{
          width: "calc(50% - 7.5px)"
        }} onClick={handleMobilePlay}>
                            Play
                        </Button>
                        <Button colour={COLOURS.secondary} style={{
          width: "calc(50% - 7.5px)"
        }} onClick={handleMobileDemoPlay}>
                            Play Demo
                        </Button>
                    </GameButtonsContainer>
                </OpenInNewTabContainer> : <GameContainer>
                    <GameWrapper>
                        {slot && slot.has_lobby && !isAuthenticated ? <PlacholderText>LOGIN TO PLAY</PlacholderText> : !isDemoMode && !isAuthenticated ? <PlacholderText>LOGIN TO PLAY</PlacholderText> : !isDemoMode && currency.id === CURRENCIES.SWEET.id ? <PlacholderText>
                                SWITCH TO A SUPPORTED CURRENCY
                            </PlacholderText> : isLoadingIframe ? <Skeleton width="100%" height="100% " /> : <iframe ref={gameIFrameRef} title={slot.name} src={gameSessionUrl} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true" />}
                    </GameWrapper>
                    <ActionBar>
                        <Actions>
                            {hasDemoMode && <>
                                    <CustomSwitch checked={isDemoMode} onChange={() => setIsDemoMode(!isDemoMode)} />
                                    <Label>Demo Mode</Label>
                                </>}
                        </Actions>
                        {conversionRate && <div>1 USD = {conversionRate} JYP</div>}
                        <Actions>
                            <FullScreenIcon data-tooltip-content="Fullscreen" data-tooltip-id="default" onClick={handleFullScreen} />
                        </Actions>
                    </ActionBar>
                </GameContainer>}
            <PopularSlots />
        </PageContainer>;
};
const Header = styled.div`
    padding-bottom: 10px;
    border-bottom: 1px solid ${({
  theme
}) => theme.border.primary};
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
`;
const ReturnToCrates = styled(ExitIcon)`
    color: ${({
  theme
}) => theme.colors.secondary};
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
        filter: brightness(1.1);
    }
`;
const SlotName = styled.div`
    color: ${({
  theme
}) => theme.colors.primary};
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
`;
const Provider = styled.div`
    color: ${({
  theme
}) => theme.colors.secondary};
    font-size: 14px;
`;
const HeaderContent = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    height: 100%;
`;
const GameContainer = styled.div`
    margin-top: 25px;
    margin-bottom: 35px;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    height: fit-content;
    background: ${({
  theme
}) => theme.background.primary};
`;
const ActionBar = styled.div`
    height: 40px;
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: ${({
  theme
}) => theme.colors.secondary};

    & svg {
        height: 16px;
        width: 16px;

        cursor: pointer;
        transition: 0.2s ease-in-out;
        &:hover {
            filter: brightness(1.1);
        }
    }
`;
const Actions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
`;
const GameWrapper = styled.div`
    aspect-ratio: 55 / 31;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: ${BREAKPOINTS.md}) {
        aspect-ratio: 46/82;
    }

    & iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
`;
const CustomSwitch = styled(Switch)({
  "& .MuiSwitch-switchBase": {
    color: ({
      theme
    }) => theme.colors.secondary,
    "&.Mui-checked": {
      color: "#03df58",
      "+ .MuiSwitch-track": {
        backgroundColor: "#03df58"
      }
    }
  }
});
const Label = styled.div`
    font-size: 14px;
    color: ${({
  theme
}) => theme.colors.secondary};
`;
const PlacholderText = styled.div`
    font-size: 28px;
    color: ${({
  theme
}) => theme.colors.secondary};
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.05em;
`;
const MobilePageHeader = styled.div`
    color: ${({
  theme
}) => theme.colors.secondary};
    font-size: 18px;
    text-transform: uppercase;
`;
const OpenInNewTabContainer = styled.div`
    background: ${({
  theme
}) => theme.background.primary};
    border-radius: 8px;
    padding: 15px;
    margin-top: 25px;
`;
const OpenInNewTabSlotName = styled(SlotName)`
    font-size: 16px;
`;
const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`;
const GameButtonsContainer = styled(FlexContainer)`
    margin-top: 25px;
    justify-content: space-between;
`;
const SlotImage = styled.img`
    width: 100px;
    height: auto;
    border-radius: 8px;
`;
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  currency: state.currency.currency
});
export default connect(mapStateToProps)(ProviderGamePage);