import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { toast } from "react-hot-toast";
import RecaptchaV3 from "react-google-recaptcha3";
import { chatSocket } from "../../services/websocket.service";

// Components
import Button from "../Button";
const Rain = ({
  rain
}) => {
  const [rainPlayerAmount, setRainPlayerAmount] = useState(0);
  useEffect(() => {
    setRainPlayerAmount(rain.players.length);
  }, [rain.players.length]);
  const onJoinRain = () => {
    const handleJoin = async () => {
      const reCaptcha = await RecaptchaV3.getToken({
        action: "join_rain"
      });
      chatSocket.emit("enter-rain", reCaptcha);
    };
    handleJoin();
  };
  useEffect(() => {
    // Handle rain joining error event
    const onError = message => {
      toast.error(message);
    };

    // Handle rain joining success event
    const onSuccess = message => {
      toast.success(message);
    };

    // Handle rain players changed event
    const rainPlayersChanged = newAmount => {
      setRainPlayerAmount(newAmount);
    };
    chatSocket.on("rain-join-error", onError);
    chatSocket.on("rain-join-success", onSuccess);
    chatSocket.on("rain-players-changed", rainPlayersChanged);
    return () => {
      chatSocket.off("rain-join-error", onError);
      chatSocket.off("rain-join-success", onSuccess);
      chatSocket.on("rain-players-changed", rainPlayersChanged);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <RainContainer>
            <Title>{parseFloat(rain.prize).toFixed(2)} Sugar Rush</Title>
            <Subtitle>
                {rainPlayerAmount} participants claimed their share
            </Subtitle>
            <Button onClick={onJoinRain} style={{
      fontSize: "10px",
      marginTop: "5px",
      padding: "8px"
    }}>
                Claim For Free
            </Button>
        </RainContainer>;
};
const slideIn = keyframes`
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0%);
    }
`;
const RainContainer = styled.div`
    margin: 10px 20px 0 20px;
    padding: 10px 13px;
    background: ${props => props.theme.background.tertiary};
    border-radius: 5px;
    border: 1px solid ${props => props.theme.border.primary};
    animation: ${slideIn} 0.1s ease-in-out;
`;
const Title = styled.div`
    font-weight: 700;
    color: ${props => props.theme.colors.tertiary};
`;
const Subtitle = styled.div`
    font-size: 12px;
    color: ${props => props.theme.colors.secondary};
`;
export default Rain;