import React from "react";
import styled from "styled-components";

// MUI Components
import { TextField } from "@material-ui/core";

// Components
import { StyledModal, CloseButton } from ".";
const Provably = ({
  open,
  handleClose,
  fields
}) => {
  return <StyledModal onClose={handleClose} open={open}>
            <Title>Provably Fair</Title>
            {fields.map(field => {
      return <StyledTextField label={field.label} value={field.value} inputProps={{
        readOnly: true
      }} variant="outlined" />;
    })}
            <CloseButton autoFocus onClick={handleClose} color="primary">
                CLOSE
            </CloseButton>
        </StyledModal>;
};
export default Provably;
const StyledTextField = styled(TextField)`
    width: 100%;
    margin-bottom: 20px !important;

    & label {
        color: ${props => props.theme.colors.secondary};
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.1em;
    }

    & label.Mui-focused {
        color: ${props => props.theme.colors.secondary};
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.1em;
    }

    & .MuiInput-underline:after {
        border: 1px solid ${props => props.theme.colors.secondary};
        border-radius: 6px;
    }

    & .MuiOutlinedInput-root {
        & fieldset {
            border: 1px solid ${props => props.theme.colors.secondary};
            border-radius: 6px;
        }
        &:hover fieldset {
            border: 1px solid ${props => props.theme.colors.secondary};
            border-radius: 6px;
        }
        &.Mui-focused fieldset {
            border: 1px solid ${props => props.theme.colors.secondary};
            border-radius: 6px;
        }
    }
`;
const Title = styled.div`
    color: ${({
  theme
}) => theme.colors.primary};
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 25px;

    && {
        font-size: 18px !important;
    }
`;