import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { toast } from "react-hot-toast";
import { ethers } from "ethers";
import { bindStakingWallet, getRegisteredAffiliate, registerAffiliate, updateUsername, rotateServerSeed, updateClientSeed as updateClientSeedApi, getSeeds as getSeedsApi } from "../services/api.service";
import { reloadUser } from "../store/actions/auth";
import { Switch, TextField, Box } from "@material-ui/core";

// Components
import PageContainer from "../components/PageContainer";
import CircularLoader from "../components/CircularLoader";
import Button from "../components/Button";
import { toggleShowInFiat } from "../store/actions/site";
import useFetch from "../hooks/useFetch";
import Skeleton from "../components/Skeleton";
const Settings = ({
  isLoading,
  isAuthenticated,
  user,
  showInFiat
}) => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [affiliateCode, setAffiliateCode] = useState("");
  const [affiliateDisabled, setAffiliateDisabled] = useState(true);
  const [isLoadingSeeds, setIsLoadingSeeds] = useState(true);
  const [revealedServerSeed, setRevealedServerSeed] = useState("");
  const [serverSeedHash, setServerSeedHash] = useState("");
  const [newClientSeed, setNewClientSeed] = useState("");
  const [clientSeed, setClientSeed] = useState("");
  const [nonce, setNonce] = useState(0);
  const updateClientSeed = async () => {
    try {
      setIsLoadingSeeds(true);
      const resp = await updateClientSeedApi(newClientSeed);
      setServerSeedHash(resp.data.server_seed_hash);
      setClientSeed(resp.data.client_seed);
      setRevealedServerSeed("");
      setNonce(resp.data.nonce);
      toast.success("Client seed updated successfully!");
      setIsLoadingSeeds(false);
    } catch (error) {
      setIsLoadingSeeds(false);
      console.error(error);
      toast.error("There was an error while updating client seed, please try again later!");
    }
  };
  const revealServerSeed = async () => {
    try {
      setIsLoadingSeeds(true);
      const resp = await rotateServerSeed();
      toast.success("Server seed revealed successfully!");
      setRevealedServerSeed(resp.data.server_seed);
      setServerSeedHash(resp.data.server_seed_hash);
      setNonce(resp.data.nonce);
    } catch (error) {
      setIsLoadingSeeds(false);
      console.error(error);
      toast.error("There was an error while revealing seeds, please try again later!");
    }
  };
  const getSeeds = async () => {
    try {
      const resp = await getSeedsApi();
      console.log("resp heree", resp.data);
      setServerSeedHash(resp.data.server_seed_hash);
      setClientSeed(resp.data.client_seed);
      setNonce(resp.data.nonce);
      setIsLoadingSeeds(false);
    } catch (error) {
      console.error(error);
      toast.error("There was an error while getting seeds, please try again later!");
    }
  };
  useEffect(() => {
    if (isAuthenticated) {
      getSeeds();
    }
  }, [isAuthenticated]);
  const {
    isLoading: isLoadingAffiliate
  } = useFetch(getRegisteredAffiliate, {
    onSuccess: data => {
      if (data.affiliated_by) {
        setAffiliateCode(data.affiliated_by);
      }
      if (new Date(user.created_at).getTime() + 86400000 < Date.now()) {
        return;
      }
      setAffiliateDisabled(data.affiliated_by !== null);
    }
  });

  // Input onChange event handler
  const onChangeUsername = e => {
    setUsername(e.target.value);
  };
  const onChangeAffiliateCode = e => {
    setAffiliateCode(e.target.value);
  };
  const onChangeClientSeed = e => {
    setNewClientSeed(e.target.value);
  };
  const handleUsernameChange = async () => {
    try {
      if (user.username === username) {
        toast.error("Username is the same as before!");
        return;
      }
      if (username.length < 3 || username.length > 12) {
        toast.error("Username must be between 3 and 16 characters!");
        return;
      }
      const resp = await updateUsername(username);
      if (resp.data.error) {
        toast.error(resp.data.error);
        return;
      }
      if (resp.data.success) {
        dispatch(reloadUser());
        toast.success("Username updated successfully!");
      }
    } catch (error) {
      console.error(error);
      toast.error("There was an error while loading your transactions data, please try again later!");
    }
  };
  const handleShowInFiatChange = () => {
    dispatch(toggleShowInFiat());
  };
  const handleAffiliateRegistration = async () => {
    const resp = await registerAffiliate(affiliateCode);
    if (resp.data.success) {
      toast.success("Affiliate code registered successfully!");
      dispatch(reloadUser());
    } else {
      if (resp.data.error) {
        toast.error(resp.data.error);
      } else {
        toast.error("Failed to register affiliate code, please try again later!");
      }
    }
  };
  useEffect(() => {
    if (isAuthenticated) {
      setUsername(user.username);
    }
  }, [isAuthenticated, user]);

  // If user is not logged in
  if (!isLoading && !isAuthenticated) {
    return <Redirect to="/" />;
  }
  console.log("user", user);
  const signMessage = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await window.ethereum.enable();
      const signer = provider.getSigner();
      const message = "Bind Staking Wallet to Sweet.gg Account";
      const signature = await signer.signMessage(message);
      await bindStakingWallet(signature);
      dispatch(reloadUser());
    } catch (error) {
      toast.error(error.message);
    }
  };
  return isLoading || !user ? <Loader>
            <CircularLoader />
        </Loader> : <PageContainer maxWidth="lg">
            <PageTitle>Settings</PageTitle>
            <Subtitle>User: {user.id}</Subtitle>
            <SettingsContainer>
                <CardTitle>Username</CardTitle>
                <InputWrapper style={{
        maxWidth: "300px"
      }}>
                    <TextField name="name" className="input" variant="outlined" inputProps={{
          maxLength: 16
        }} placeholder="#USERNAME" onChange={onChangeUsername} value={username} />
                    <Button onClick={handleUsernameChange}>CHANGE</Button>
                </InputWrapper>
            </SettingsContainer>
            <SettingsContainer style={{
      marginTop: "2rem"
    }}>
                <CardTitle>Preferences</CardTitle>
                <ToggleRow>
                    <ToggleLabel>
                        Show Crypto In Fiat Currency (USD)
                    </ToggleLabel>
                    <CustomSwitch checked={showInFiat} onChange={handleShowInFiatChange} />
                </ToggleRow>
            </SettingsContainer>
            <SettingsContainer style={{
      marginTop: "2rem"
    }}>
                <CardTitle>Affiliate</CardTitle>
                <Subtitle>
                    Support your favorite content creator by using their
                    affiliate code. Forgot to sign up under an affiliate link?
                    You can still register within the first 24 hours by entering
                    an affiliate code here.
                </Subtitle>
                {new Date(user.created_at).getTime() + 86400000 < Date.now() && <Subtitle style={{
        marginTop: "1rem",
        color: "#fff"
      }}>
                        You have exceeded the 24h time frame to enter an
                        affiliate code.
                    </Subtitle>}
                <InputWrapper style={{
        maxWidth: "300px",
        marginTop: "1rem"
      }}>
                    {isLoadingAffiliate ? <Skeleton width="300px" height="45px" /> : <>
                            <TextField name="name" className="input" variant="outlined" inputProps={{
            maxLength: 16
          }} placeholder="AFFILIATE CODE" onChange={onChangeAffiliateCode} value={affiliateCode} disabled={affiliateDisabled} />
                            <Button onClick={handleAffiliateRegistration} disabled={affiliateDisabled}>
                                SUBMIT
                            </Button>
                        </>}
                </InputWrapper>
            </SettingsContainer>
            <SettingsContainer>
                <CardTitle>Staking Wallet</CardTitle>
                <Subtitle>
                    Are you staking $SWEET?
                    <br />
                    Bind your wallet address to your account and skip the hassle
                    of wagering for higher levels.
                    <LearnMore>Learn More About Staking</LearnMore>
                </Subtitle>
                {user.staking_wallet && <Subtitle style={{
        margin: "1rem 0",
        color: "#fff"
      }}>
                        Staking Address:
                        <br />
                        {user.staking_wallet}
                    </Subtitle>}
                <Button disabled={user.staking_wallet !== ""} onClick={signMessage} style={{
        marginTop: "1rem"
      }}>
                    {user.staking_wallet ? "Wallet Address Bound" : "Bind Wallet"}
                </Button>
            </SettingsContainer>
            <SettingsContainer>
                <CardTitle>Provably Fair</CardTitle>
                <Subtitle>
                    Verify the fairness of our games by checking the server seed
                    hash and client seed.
                </Subtitle>
                <Box>
                    <Subtitle style={{
          marginTop: "1rem",
          color: "#fff"
        }}>
                        Client Seed: {clientSeed}
                    </Subtitle>
                    {revealedServerSeed ? <>
                            <Subtitle style={{
            marginTop: "1rem",
            color: "#fff"
          }}>
                                Revealed Server Seed: {revealedServerSeed}
                            </Subtitle>
                            <Subtitle style={{
            marginTop: "1rem",
            color: "#fff"
          }}>
                                New Server Seed Hash: {serverSeedHash}
                            </Subtitle>
                        </> : <Subtitle style={{
          marginTop: "1rem",
          color: "#fff"
        }}>
                            Server Seed Hash: {serverSeedHash}
                        </Subtitle>}
                    <Subtitle style={{
          marginTop: "1rem",
          color: "#fff"
        }}>
                        Nonce: {nonce}
                    </Subtitle>
                </Box>
                <Subtitle style={{
        marginTop: "1rem"
      }}>
                    Revealing the server seed will result in a new server seed
                    being generated. You can use the revealed server seed to
                    verify the result of prior games with the corresponding seed
                    pair.
                </Subtitle>
                <InputWrapper style={{
        maxWidth: "300px",
        marginTop: "1rem"
      }}>
                    {isLoadingSeeds ? <Skeleton width="300px" height="45px" /> : <>
                            <TextField name="name" className="input" variant="outlined" inputProps={{
            minLength: 12,
            maxLength: 25
          }} placeholder="CHANGE CLIENT SEED" onChange={onChangeClientSeed} value={newClientSeed} />
                            <Button onClick={updateClientSeed}>SUBMIT</Button>
                        </>}
                </InputWrapper>
                <Button style={{
        marginTop: "1rem"
      }} onClick={revealServerSeed}>
                    Reveal Server Seed
                </Button>
            </SettingsContainer>
        </PageContainer>;
};
Settings.propTypes = {
  isAuthenticated: PropTypes.bool,
  isLoading: PropTypes.bool,
  user: PropTypes.object
};
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading,
  user: state.auth.user,
  showInFiat: state.site.showInFiat
});
export default connect(mapStateToProps)(Settings);
const Loader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36rem;
`;
const PageTitle = styled.h1`
    color: ${({
  theme
}) => theme.colors.primary};
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.1em;
    margin: 0;
    margin-bottom: 5px;
`;
const CardTitle = styled.div`
    font-size: 17px;
    font-weight: 500;
    color: ${({
  theme
}) => theme.colors.primary};
    margin-bottom: 15px;
`;
const Subtitle = styled.div`
    color: ${({
  theme
}) => theme.colors.secondary};
    font-size: 14px;
`;
const SettingsContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: ${props => props.theme.background.primary};
    border: 1px solid ${props => props.theme.border.primary};
    backdrop-filter: blur(7.5px);
    border-radius: 10px;
    padding: 1.5rem;
    margin-top: 2rem;
`;
const InputWrapper = styled.div`
    position: relative;
    color: #fff;

    & > div > .MuiOutlinedInput-root {
        background: ${({
  theme
}) => theme.background.secondary};
        & > input {
            color: #fff;

            margin-right: 100px;
            font-size: 14px;
        }
    }

    & > div {
        width: 100%;
        & label {
            color: #ff9800;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.1em;
        }
        & label.Mui-focused {
            color: #ff9800;
        }
        & .MuiInput-underline:after {
            border: 1px solid ${props => props.theme.border.primary};
        }

        & .MuiOutlinedInput-root {
            & fieldset {
                border: 1px solid ${props => props.theme.border.primary};
            }
            &:hover fieldset {
                border: 1px solid ${props => props.theme.border.primary};
            }
            &.Mui-focused fieldset {
                border: 1px solid ${props => props.theme.border.primary};
            }
        }
    }

    & > button {
        position: absolute;
        right: 10px;
        top: 8.5px;
        width: 7rem;
    }
`;
const CustomSwitch = styled(Switch)({
  "& .MuiSwitch-switchBase": {
    color: ({
      theme
    }) => theme.colors.secondary,
    "&.Mui-checked": {
      color: "#03df58",
      "+ .MuiSwitch-track": {
        backgroundColor: "#03df58"
      }
    }
  }
});
const ToggleRow = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`;
const ToggleLabel = styled.div`
    font-size: 13px;
    font-weight: 500;
    color: ${({
  theme
}) => theme.colors.primary};
`;
const LearnMore = styled.div`
    color: #fff;
    margin-top: 15px;
    font-size: 14px;
    cursor: pointer;

    &:hover {
        opacity: 0.9;
    }
`;