import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { toast } from "react-hot-toast";
import PropTypes from "prop-types";
import { diceSocket } from "../services/websocket.service";

// MUI Components
import { TextField, Checkbox, FormControlLabel, FormGroup, Switch, InputAdornment } from "@material-ui/core";

// Components
import GameHeader from "../components/GameHeader";
import DiceSlider from "../components/dice/Slider";
import TokenInput from "../components/TokenInput";
import HistoryEntry from "../components/dice/HistoryEntry";
import GrowAnimation from "../components/GrowAnimation";
import PageContainer from "../components/PageContainer";
import Button from "../components/Button";
import coin from "../assets/coin.svg";
import error from "../assets/error.wav";
import BREAKPOINTS from "../constants/breakpoints";
const errorAudio = new Audio(error);
const playSound = audioFile => {
  audioFile.play();
};
const ContentContainer = styled.div`
    margin-top: 24px;
`;
const Dice = ({
  isAuthenticated,
  user,
  sweet
}) => {
  const [history, setHistory] = useState([]);
  const [joining, setJoining] = useState(false);
  const [autoBetEnabled, setAutoBetEnabled] = useState(false);
  const autoBetEnabledRef = useRef(false);
  const [betAmount, setBetAmount] = useState("0");
  const [lastBetAmount, setLastBetAmount] = useState("0");
  const [multiplier, setMultiplier] = useState(1.9);
  const [rollOverAmount, setRolloverAmount] = useState(50);
  const autoBetRollLimitRef = useRef(0);
  const [rollOver, setRollOver] = useState("under");
  const [onWinMultiplier, setOnWinMultiplier] = useState(2);
  const [onLoseMultiplier, setOnLoseMultiplier] = useState(2);
  const [winPercent, setWinPercent] = useState(50);
  const [onWinAction, setOnWinAction] = useState("baseBet");
  const baseBetRef = useRef(0);
  const maxBetRef = useRef(0);
  const rollAmountRef = useRef(0);
  const cancelAutoBetRef = useRef(false);
  const play = betAmount => {
    if (parseFloat(betAmount) <= 0) {
      toast.error("Bet amount must be greater than 0");
      return;
    }
    setJoining(true);

    // Emit new bet event
    diceSocket.emit("play", rollOver, parseFloat(rollOverAmount), parseFloat(betAmount));
    setLastBetAmount(betAmount);
  };

  // TextField onChange event handler
  const onBetChange = value => {
    setBetAmount(value);
  };

  // function calculateRollover(multiplierValue) {
  //     const multiplier = parseFloat(multiplierValue);

  //     if (multiplier >= 1 && multiplier <= 2) {
  //         return 1 + (multiplier - 1) * 49;
  //     } else if (multiplier > 2 && multiplier <= 100) {
  //         return 50 + (multiplier - 2) / 2;
  //     } else {
  //         // handle edge cases or errors
  //         return 1; // or a default value
  //     }
  // }

  // function calculateMultiplier(sliderValue) {
  //     if (sliderValue <= 50) {
  //         return 1 + (sliderValue - 1) * (1 / 49);
  //     } else {
  //         return 2 + (sliderValue - 50) * 2;
  //     }
  // }

  const HOUSE_EDGE_FACTOR = 0.975; // 2.5% house edge

  const onSliderChange = (_, value) => {
    setRolloverAmount(value);
    let winChance = rollOver === "over" ? 100 - value : value;
    setWinPercent(winChance);
    setMultiplier((100 * HOUSE_EDGE_FACTOR / winChance).toFixed(2));
  };
  const onChangeRollOverInput = e => {
    if (isNaN(e.target.value) || e.target.value === "") {
      setRolloverAmount("");
      setWinPercent(0);
      setMultiplier(0);
      return;
    }
    if (e.target.value > 97) {
      e.target.value = 97;
    }
    if (e.target.value < 3) {
      e.target.value = 3;
    }
    let rollover = parseFloat(e.target.value);
    setRolloverAmount(rollover);
    let winChance = rollOver === "over" ? 100 - rollover : rollover;
    setWinPercent(winChance.toFixed(2));
    setMultiplier((100 * HOUSE_EDGE_FACTOR / winChance).toFixed(2));
  };
  const onChangeRollOver = event => {
    const newRollOver = event.target.checked ? "over" : "under";

    // Calculate the new rollOverAmount based on the complementary value
    let newRollOverAmount = 100 - rollOverAmount;
    setRolloverAmount(newRollOverAmount); // Update the state with the new value
    setRollOver(newRollOver);
    let winChance = newRollOver === "over" ? 100 - newRollOverAmount : newRollOverAmount;
    winChance = Math.min(94, Math.max(2, winChance));
    setWinPercent(winChance.toFixed(2));
    setMultiplier((100 * HOUSE_EDGE_FACTOR / winChance).toFixed(2));
  };
  const onChangeOnWinMultiplyBy = e => {
    setOnWinAction("multiplyBy");
  };
  const onChangeOnWinBaseBet = e => {
    setOnWinAction("baseBet");
  };
  const [onLoseAction, setOnLoseAction] = useState("multiplyBy");
  const onChangeOnLoseMultiplyBy = e => {
    setOnLoseAction("multiplyBy");
  };
  const onChangeOnLoseBaseBet = e => {
    setOnLoseAction("baseBet");
  };

  // Error event handler
  const gameError = msg => {
    setJoining(false);
    toast.error(msg);
    playSound(errorAudio);
  };

  // Success event handler
  const gameResult = game => {
    console.log("game", game);
    setHistory(history => [...history, game]);
    if (!autoBetEnabledRef.current) {
      setJoining(false);
    } else {
      let betAmount = 0;
      if (game.won) {
        if (onWinAction === "multiplyBy") {
          betAmount = game.betAmount * parseFloat(onWinMultiplier);
        } else {
          betAmount = baseBetRef.current;
        }
      } else {
        if (onLoseAction === "multiplyBy") {
          betAmount = game.betAmount * parseFloat(onLoseMultiplier);
        } else {
          betAmount = baseBetRef.current;
        }
      }
      setBetAmount(betAmount);
      let maxBet = maxBetRef.current;
      if (parseFloat(maxBet) > 0 && parseFloat(betAmount) > parseFloat(maxBet)) {
        betAmount = maxBet;
        setBetAmount(maxBet);
      }
      let autoBetRollLimit = autoBetRollLimitRef.current;
      if (parseFloat(autoBetRollLimit) > 0 && rollAmountRef.current >= autoBetRollLimit) {
        setJoining(false);
        rollAmountRef.current = 0;
        return;
      }
      if (cancelAutoBetRef.current) {
        setJoining(false);
        cancelAutoBetRef.current = false;
        return;
      }
      rollAmountRef.current += 1;
      play(betAmount);
    }
  };
  useEffect(() => {
    diceSocket.on("game-error", gameError);
    diceSocket.on("game-result", gameResult);
    return () => {
      diceSocket.off("game-error", gameError);
      diceSocket.off("game-result", gameResult);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const calculateProfit = () => {
    return history.reduce((acc, game) => acc + (game.won ? parseFloat(game.betAmount) * parseFloat(game.multiplier) - parseFloat(game.betAmount) : -parseFloat(game.betAmount)), 0);
  };
  return <PageContainer maxWidth="lg" header={<GameHeader game="dice" />} style={{
    minHeight: "100%"
  }}>
            <GrowAnimation duration="620ms">
                <ContentContainer>
                    <BettingContainer>
                        <TopBettingRow>
                            <TokenInput value={betAmount} onChange={onBetChange} fiatPrice={sweet.usdPrice} />
                            <CashoutContainer data-tooltip-content="Cashout on Win" data-tooltip-id="default">
                                <p style={{
                color: "#03df58",
                fontWeight: 900,
                marginRight: "8px"
              }}>
                                    {(betAmount * multiplier).toFixed(2)}
                                </p>
                                <img src={coin} alt="Sweet Coin" />
                            </CashoutContainer>
                        </TopBettingRow>
                        <ButtonsRow>
                            <BetControllButton onClick={() => setBetAmount(lastBetAmount)}>
                                Last
                            </BetControllButton>
                            <BetControllButton onClick={() => setBetAmount(state => parseFloat(state) / 2 || 0)}>
                                1/2
                            </BetControllButton>
                            <BetControllButton onClick={() => setBetAmount(state => parseFloat(state) * 2 || 0)}>
                                2x
                            </BetControllButton>
                            <BetControllButton style={{
              marginRight: "15px"
            }} onClick={() => setBetAmount(user ? parseFloat(user.wallet) : 0)}>
                                Max
                            </BetControllButton>
                            <Button onClick={() => {
              if (autoBetEnabled && joining) {
                cancelAutoBetRef.current = true;
                return;
              }
              play(betAmount);
              baseBetRef.current = parseFloat(betAmount);
            }} disabled={!isAuthenticated || !autoBetEnabled && joining}>
                                {!autoBetEnabled ? <span>
                                        {joining ? "BETTING..." : "PLACE BET"}
                                    </span> : <span>
                                        {joining ? "CANCEL BETTING" : "START BETTING"}
                                    </span>}
                            </Button>
                        </ButtonsRow>
                    </BettingContainer>
                    <GameSettings>
                        <DiceSlider type={rollOver} onChange={onSliderChange} value={rollOverAmount} />
                        <MultiplierInfo>
                            <div style={{
              display: "flex",
              alignItems: "center",
              marginTop: "4%"
            }}>
                                <RollOverTypeText>Under</RollOverTypeText>
                                <StyledCustomFormControlLabel width="25%" control={<CustomSwitch checked={rollOver === "over"} onChange={onChangeRollOver} />} label="Over" labelPlacement="end" style={{
                marginLeft: 8,
                marginRight: 8
              }} />
                            </div>
                            <InfoBox>
                                <Label>
                                    Roll{" "}
                                    {rollOver === "over" ? "Over" : "Under"}{" "}
                                    Amount
                                </Label>
                                <StyledMultiplierInputBox variant="filled" width="100%" onChange={onChangeRollOverInput} value={rollOverAmount} />
                            </InfoBox>
                            <InfoBox>
                                <Label>Multiplier</Label>
                                <StyledMultiplierInputBox variant="filled" width="100%" value={multiplier} InputProps={{
                startAdornment: <InputIcon>
                                                <p style={{
                    color: "white"
                  }}>
                                                    x
                                                </p>
                                            </InputIcon>
              }} />
                            </InfoBox>
                            <InfoBox>
                                <Label>Win Chance</Label>
                                <StyledMultiplierInputBox variant="filled" width="100%" value={winPercent} InputProps={{
                endAdornment: <InputAdornment>
                                                <p style={{
                    color: "white"
                  }}>
                                                    %
                                                </p>
                                            </InputAdornment>
              }} />
                            </InfoBox>
                        </MultiplierInfo>
                    </GameSettings>
                    <PopoutMenu>
                        <PopoutDropdown>
                            <PopoutHeader>Auto Bet</PopoutHeader>
                            <StyledCustomFormControlLabel width="25%" control={<CustomSwitch checked={autoBetEnabled} onChange={() => {
              setAutoBetEnabled(!autoBetEnabled);
              autoBetEnabledRef.current = !autoBetEnabled;
            }} />} label="" labelPlacement="" style={{
              marginLeft: 8,
              marginRight: 8
            }} />
                        </PopoutDropdown>
                        {autoBetEnabled && <InnerPopoutMenu>
                                <PopoutInputContainer>
                                    <Label>On Win</Label>
                                    <FormGroup>
                                        <StyledFormControlLabel control={<Checkbox />} label="Return To Base Bet" labelPlacement="right" onChange={onChangeOnWinBaseBet} checked={onWinAction === "baseBet"} />
                                        <StyledFormControlLabel control={<Checkbox defaultChecked />} label="Multiply By" labelPlacement="right" onChange={onChangeOnWinMultiplyBy} checked={onWinAction === "multiplyBy"} />
                                    </FormGroup>
                                    <StyledMultiplierInputBox margin="6px 0 0 0" onChange={e => setOnWinMultiplier(e.target.value)} value={onWinMultiplier} InputProps={{
                startAdornment: <InputAdornment>
                                                    <p style={{
                    color: "white",
                    paddingLeft: "16px"
                  }}>
                                                        x
                                                    </p>
                                                </InputAdornment>
              }} />
                                </PopoutInputContainer>
                                <PopoutInputContainer>
                                    <Label>On Lose</Label>
                                    <FormGroup>
                                        <StyledFormControlLabel control={<Checkbox />} label="Return To Base Bet" labelPlacement="right" onChange={onChangeOnLoseBaseBet} checked={onLoseAction === "baseBet"} />
                                        <StyledFormControlLabel control={<Checkbox defaultChecked />} label="Multiply By" labelPlacement="right" onChange={onChangeOnLoseMultiplyBy} checked={onLoseAction === "multiplyBy"} />
                                    </FormGroup>
                                    <StyledMultiplierInputBox variant="filled" margin="6px 0 0 0" onChange={e => setOnLoseMultiplier(e.target.value)} value={onLoseMultiplier} InputProps={{
                startAdornment: <InputAdornment>
                                                    <p style={{
                    color: "white"
                  }}>
                                                        x
                                                    </p>
                                                </InputAdornment>
              }} />
                                </PopoutInputContainer>
                                <PopoutInputContainer>
                                    <Label>Max Bet</Label>
                                    <StyledMultiplierInputBox variant="filled" onChange={e => {
                maxBetRef.current = e.target.value;
              }} value={maxBetRef.current} InputProps={{
                endAdornment: <InputAdornment>
                                                    <img src={coin} alt="Sweet Coin" />
                                                </InputAdornment>
              }} />
                                    <Label>Roll Limit</Label>
                                    <StyledMultiplierInputBox variant="filled" onChange={e => {
                autoBetRollLimitRef.current = e.target.value;
              }} value={autoBetRollLimitRef.current} />
                                </PopoutInputContainer>
                            </InnerPopoutMenu>}
                    </PopoutMenu>
                    <TrackerLabel>Game History</TrackerLabel>
                    <TrackerContainer>
                        <div>
                            Profit:{" "}
                            <span style={{
              color: calculateProfit() === 0 ? "#fff" : calculateProfit() > 0 ? "#03df58" : "#BF3932"
            }}>
                                {calculateProfit().toFixed(2)}
                            </span>
                        </div>
                        <HistoryWrapper>
                            {history.map(game => <HistoryEntry key={game._id} game={game} />)}
                        </HistoryWrapper>
                    </TrackerContainer>
                </ContentContainer>
            </GrowAnimation>
        </PageContainer>;
};
Dice.propTypes = {
  user: PropTypes.object,
  sweet: PropTypes.object
};
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  sweet: state.site.sweet
});
export default connect(mapStateToProps)(Dice);
const BettingContainer = styled.div`
    margin-bottom: 24px;
    background: ${props => props.theme.background.primary};
    border-radius: 10px;
    border: 1px solid ${props => props.theme.border.primary};
    padding: 1rem;
`;
const TrackerLabel = styled.div`
    color: ${props => props.theme.colors.primary};
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 25px;
    margin-bottom: 10px;
`;
const TrackerContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-align: flex-start;
    justify-content: center;
    color: ${props => props.theme.colors.secondary};
    font-weight: 500;
    background: ${props => props.theme.background.primary};
    border: 1px solid ${props => props.theme.border.primary};
    border-radius: 8px;
    backdrop-filter: blur(7.5px);
    padding: 1rem;
`;
const TopBettingRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: row;
    height: 60%;
`;
const CashoutContainer = styled.div`
    display: flex;
    width: 30%;
    height: 100%;
    padding: 5px 10px;
    color: white;
    background: ${props => props.theme.background.secondary};
    border-radius: 10px;
    margin-left: 16px;
    justify-content: center;
    align-items: center;
`;
const PopoutInputContainer = styled.div`
    min-height: fit-content;
    min-width: 20%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 15px;
`;
const PopoutHeader = styled.p`
    color: ${props => props.theme.colors.secondary};
    font-weight: 500;
    font-size: 16px;
`;
const HistoryWrapper = styled.div`
    display: flex;
    max-width: 80%;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
    margin-left: auto;
`;
const MultiplierInfo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 16px;
    gap: 10px;

    @media (max-width: ${BREAKPOINTS.sm}) {
        flex-direction: column;
        gap: 0px;
    }
`;
const InfoBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 25%;

    @media (max-width: ${BREAKPOINTS.sm}) {
        width: 100%;
    }
`;
const RollOverTypeText = styled.div`
    color: ${props => props.theme.colors.secondary};
    font-size: 12px;
`;
const GameSettings = styled.div`
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
    flex-direction: column;
    max-height: 800px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid ${props => props.theme.border.primary};
    background: ${props => props.theme.background.primary};
    backdrop-filter: blur(7.5px);
    transition: 1s ease;
`;
const Label = styled.div`
    color: ${props => props.theme.colors.secondary};
    font-weight: 500;
    letter-spacing: 0.1em;
    font-size: 12px;
    margin: 5px 0;
`;
const InputIcon = styled(InputAdornment)`
    margin-top: 0 !important;
    color: ${props => props.theme.colors.tertiary};
    background: transparent !important;
`;
const PopoutMenu = styled.div`
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid ${props => props.theme.border.primary};
    background: ${props => props.theme.background.primary};
    margin-top: 24px;
`;
const PopoutDropdown = styled.div`
    display: flex;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
`;
const InnerPopoutMenu = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
const StyledFormControlLabel = styled(FormControlLabel)`
    .MuiSvgIcon-root {
        color: ${props => props.theme.colors.tertiary};
    }

    .MuiFormControlLabel-label {
        color: ${props => props.theme.colors.secondary};
        letter-spacing: 0.1em;
        font-size: 12px;
    }
`;
const CustomSwitch = styled(Switch)({
  "& .MuiSwitch-switchBase": {
    color: "rgb(191, 57, 50)",
    "&.Mui-checked": {
      color: "#03df58",
      "+ .MuiSwitch-track": {
        backgroundColor: "#03df58"
      }
    }
  },
  "& .MuiSwitch-track": {
    backgroundColor: "rgb(191, 57, 50)",
    opacity: 0.5
  }
});
const StyledCustomFormControlLabel = styled(FormControlLabel)({
  "& .MuiFormControlLabel-label": {
    color: props => props.theme.colors.secondary,
    fontSize: "12px"
  }
});
const StyledMultiplierInputBox = styled(TextField)(({
  theme,
  ...props
}) => ({
  width: props.width || "auto",
  height: props.height || "auto",
  minHeight: props.minHeight || "auto",
  margin: props.margin || "0",
  borderRadius: "10px",
  background: props.background || theme.background.secondary,
  "&:hover": {
    background: props.background || theme.background.secondary
  },
  "& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)": {
    marginTop: "0 !important"
  },
  "& :before": {
    display: "none"
  },
  "& :after": {
    display: "none"
  },
  "& div input": {
    height: "25px",
    color: "#fff",
    fontSize: "1em",
    fontWeight: 900,
    letterSpacing: ".1em",
    padding: "0.5rem 0.5rem",
    "&.MuiFilledInput-root.Mui-focused": {
      background: props.background || theme.background.secondary
    }
  },
  "& div": {
    background: props.background || theme.background.secondary,
    borderRadius: "10px",
    "&:hover": {
      background: props.background || theme.background.secondary,
      "&.MuiFilledInput-root.Mui-focused": {
        background: props.background || theme.background.secondary
      }
    },
    "&.MuiFilledInput-root.Mui-focused": {
      background: props.background || theme.background.secondary
    }
  }
}));
const BetControllButton = styled.div`
    height: fit-content;
    color: ${({
  theme
}) => theme.colors.secondary};
    background: ${({
  theme
}) => theme.background.secondary};
    border-radius: 5px;
    padding: 5px 12px;
    text-transform: uppercase;
    font-weight: 500;

    cursor: pointer;
    transition: 125ms ease;
    &:hover {
        filter: brightness(1.1);
    }
`;
const ButtonsRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 15px;
`;