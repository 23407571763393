import React, { useState, useEffect, createRef } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { getUnfinishedBlackjackGame } from "../services/api.service";

// Components
import Game from "../components/blackjack";
import GameHeader from "../components/GameHeader";
import PageContainer from "../components/PageContainer";
import BREAKPOINTS from "../constants/breakpoints";
const Blackjack = ({
  isAuthenticated
}) => {
  const blackJackRef = createRef();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchGame = async () => {
      try {
        const data = await getUnfinishedBlackjackGame();
        if (data) {
          blackJackRef.current.setData({
            gameId: data.id,
            playerHands: data.table.player,
            dealerHand: {
              ...data.table.dealer,
              cards: [...data.table.dealer.cards, {
                value: "dummy",
                suit: ""
              }]
            },
            inProgress: true
          });
        }
      } catch (err) {
        console.log(err);
        toast.error("Error fetching previous game data");
      } finally {
        setLoading(false);
      }
    };

    // Check if user has a game in progress
    if (isAuthenticated) {
      fetchGame();
    } else {
      setLoading(false);
    }
  }, [isAuthenticated]);
  return <PageContainer maxWidth="lg" header={<GameHeader game="blackjack" />}>
            <Content>
                <Game ref={blackJackRef} loading={loading} />
            </Content>
        </PageContainer>;
};

// TODO: cleaner way to do this?
const Content = styled.div`
    height: 75vh;
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${props => props.theme.background.primary};
    backdrop-filter: blur(7.5px);
    border-radius: 10px;
    border: 1px solid ${props => props.theme.border.primary};

    @media (max-width: ${BREAKPOINTS.sm}) {
        height: fit-content;
        padding-top: 2rem;
    }
`;
Blackjack.propTypes = {
  isAuthenticated: PropTypes.bool
};
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});
export default connect(mapStateToProps)(Blackjack);