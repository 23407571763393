import React, { useState } from "react";
import RecaptchaV3 from "react-google-recaptcha3";
import styled from "styled-components";
import { caseBattleSocket } from "../../services/websocket.service";

// Components
import { StyledModal, CloseButton } from ".";
import Button from "../Button";

// Assets
import coin from "../../assets/coin.svg";
const Title = styled.div`
    font-weight: 500;
    font-size: 18px !important;
    margin-bottom: 25px;
`;
const Label = styled.div`
    color: #75528d;
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-transform: uppercase;
`;
const PlayerAmountControls = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 10px;
    margin-bottom: 20px;
`;
const PlayerAmount = styled.div`
    width: 100px;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: ${({
  theme
}) => theme.background.secondary};
    color: ${({
  theme
}) => theme.colors.primary};

    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.1px;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid
        ${({
  theme,
  selected
}) => selected ? theme.colors.tertiary : theme.border.primary};
    transition: all 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
`;
const SelectableCrates = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 20px;
    max-height: 300px;
    overflow-y: auto;
`;
const Crate = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background: ${props => props.theme.background.secondary};
    border: 1px solid ${props => props.theme.border.primary};
    backdrop-filter: blur(7.5px);
    border-radius: 10px;
    width: 130px;
    height: fit-content;
`;
const SweetPrice = styled.div`
    color: ${({
  theme
}) => theme.colors.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    margin-left: 5px;
    border-left: 2px solid white;
`;
const AddToBattle = styled.button`
    background-color: ${({
  theme
}) => theme.colors.tertiary};
    box-shadow: none;
    border: none;
    outline: none;
    color: ${({
  theme
}) => theme.colors.primary};
    padding: 5px 10px;
    border-radius: 5px;

    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.1px;
    width: 100%;

    & .label {
        display: block;
    }

    & .price {
        display: none;
    }

    cursor: pointer;
    &:hover {
        & .label {
            display: none;
        }

        & .price {
            display: block;
        }

        opacity: 0.8;
    }
`;
const PlayerAmountText = styled.div`
    color: ${({
  theme
}) => theme.colors.secondary} !important;
    font-size: 11px !important;
`;
const TotalPriceContainer = styled.div`
    display: flex;
    align-items: center;
    color: ${({
  theme
}) => theme.colors.secondary} !important;
    font-weight: 500;
    margin-bottom: 10px;
`;
const CrateAmountInput = styled.input`
    width: 100%;
    background: ${({
  theme
}) => theme.background.tertiary};
    border: none;
    outline: none;
    color: ${({
  theme
}) => theme.colors.primary};
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
    font-weight: 500;
`;
const BATTLE_TYPES = {
  "1_VS_1": "1_VS_1",
  "1_VS_1_VS_1": "1_VS_1_VS_1",
  "2_VS_2": "2_VS_2"
};
const CreateBattle = ({
  history,
  open,
  handleClose,
  cases,
  sweet,
  creating,
  setCreating
}) => {
  const [battleType, setBattleType] = useState(BATTLE_TYPES["1_VS_1"]);
  const [teamSize, setTeamSize] = useState(1);
  const [teamAmount, setTeamAmount] = useState(2);
  const [selectedCases, setSelectedCases] = useState([]);
  const onCreate = async () => {
    setCreating(true);
    const reCaptcha = await RecaptchaV3.getToken({
      action: "create_crates_battle"
    });
    caseBattleSocket.emit("create-new-battle", selectedCases, teamAmount, teamSize, reCaptcha);
  };
  return <StyledModal onClose={handleClose} open={open}>
            <Title>CREATE NEW BATTLE</Title>
            <Label>PLAYER AMOUNT</Label>
            <PlayerAmountControls>
                <PlayerAmount selected={battleType === BATTLE_TYPES["1_VS_1"]} onClick={() => {
        setBattleType(BATTLE_TYPES["1_VS_1"]);
        setTeamSize(1);
        setTeamAmount(2);
      }}>
                    <div>1 vs 1</div>
                    <PlayerAmountText>2 Players</PlayerAmountText>
                </PlayerAmount>
                <PlayerAmount selected={battleType === BATTLE_TYPES["2_VS_2"]} onClick={() => {
        setBattleType(BATTLE_TYPES["2_VS_2"]);
        setTeamSize(2);
        setTeamAmount(2);
      }}>
                    <div>2 vs 2</div>
                    <PlayerAmountText>4 Players</PlayerAmountText>
                </PlayerAmount>
                <PlayerAmount selected={battleType === BATTLE_TYPES["1_VS_1_VS_1"]} onClick={() => {
        setBattleType(BATTLE_TYPES["1_VS_1_VS_1"]);
        setTeamSize(1);
        setTeamAmount(3);
      }}>
                    <div>1 vs 1 vs 1</div>
                    <PlayerAmountText>3 Players</PlayerAmountText>
                </PlayerAmount>
            </PlayerAmountControls>
            <Label>SELECTED CRATE(S)</Label>
            <SelectableCrates>
                {cases.map(crate => <Crate>
                        <img src={crate.imageUrl} alt={crate.name} onClick={() => history.push("/crates/" + crate._id)} style={{
          width: "auto",
          height: "70px ",
          cursor: "pointer",
          marginBottom: "8px"
        }} />
                        {selectedCases.includes(crate._id) ? <CrateAmountInput type="number" value={selectedCases.filter(id => id === crate._id).length} onChange={e => {
          const filtered = selectedCases.filter(id => id !== crate._id);
          setSelectedCases([...filtered, ...Array.from({
            length: parseInt(e.target.value)
          }, () => crate._id)]);
        }} /> : <AddToBattle onClick={() => {
          setSelectedCases([...selectedCases, crate._id]);
        }}>
                                <span className="label">ADD</span>
                                <span className="price">
                                    ${crate.fiatPrice}
                                </span>
                            </AddToBattle>}
                    </Crate>)}
            </SelectableCrates>
            <TotalPriceContainer>
                BATTLE PRICE:{" "}
                <div style={{
        color: "#fff",
        marginLeft: "10px"
      }}>
                    $
                    {selectedCases.reduce((acc, id) => acc + cases.find(crate => crate._id === id).fiatPrice, 0).toFixed(2)}
                </div>
                <SweetPrice>
                    {(selectedCases.reduce((acc, id) => acc + cases.find(crate => crate._id === id).fiatPrice, 0) / sweet.usdPrice).toFixed(2)}{" "}
                    <img src={coin} alt="Sweet Coin" style={{
          marginLeft: "5px"
        }} />
                </SweetPrice>
            </TotalPriceContainer>
            <Button onClick={onCreate}>
                {creating ? "Starting..." : "START NEW BATTLE"}
            </Button>
            <CloseButton autoFocus onClick={handleClose} color="primary">
                CLOSE
            </CloseButton>
        </StyledModal>;
};
export default CreateBattle;