import React from "react";
import styled from "styled-components";
import Emoji, { EMOJIS } from "./Emoji";
const EmojiDrawer = ({
  open,
  handleEmojiSelect
}) => {
  return <DrawerComponent open={open}>
            <Title>Emotes</Title>
            <EmojiList>
                {EMOJIS.map(emote => <CopyEmote onClick={() => handleEmojiSelect(emote.word)}>
                        <Emoji size="25px" src={emote.src} alt={emote.word} title={emote.word} />
                    </CopyEmote>)}
            </EmojiList>
        </DrawerComponent>;
};
const Title = styled.div`
    font-size: 12px;
    font-weight: 500;
    color: ${props => props.theme.colors.secondary};
    text-transform: uppercase;
    margin-bottom: 0.5rem;
`;
const DrawerComponent = styled.div`
    border-top: 1px solid ${props => props.theme.border.primary};
    position: fixed;
    padding: 0.5rem;
    height: 10rem;
    bottom: 70px;
    background: ${props => props.theme.background.primary};

    animation: ${props => props.open ? "slideIn 0.5s" : "slideOut 0.5s"};
    display: ${props => props.open ? "" : "none"};

    @keyframes slideIn {
        from {
            bottom: -100px;
        }
        to {
            bottom: 70px;
        }
    }
`;
const CopyEmote = styled.div`
    cursor: pointer;

    &:hover {
        transition: 125ms ease;
        transform: scale(1.1);
    }
`;
const EmojiList = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow-y: auto;
    gap: 5px;
    height: 7rem;
`;
export default EmojiDrawer;