import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import { DynamicContextProvider } from "@dynamic-labs/sdk-react-core";

// Wallet Connectors
import { EthereumWalletConnectors } from "@dynamic-labs/ethereum";
import { SolanaWalletConnectors } from "@dynamic-labs/solana";

import store from "./store/index";

// Components
import App from "./App";

import "./index.css";

ReactDOM.render(
    <Provider store={store}>
        <DynamicContextProvider
            settings={{
                environmentId: process.env.REACT_APP_DYNAMIC_ENVIRONMENT_ID,
                walletConnectors: [
                    EthereumWalletConnectors,
                    SolanaWalletConnectors,
                ],
                eventsCallbacks: {
                    onAuthSuccess: () => {
                        setTimeout(() => {
                            window.location.reload();
                        }, 1_000);
                    },
                },
            }}
        >
            <App />
        </DynamicContextProvider>
    </Provider>,
    document.getElementById("root"),
);
