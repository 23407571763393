import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import { blackjackSocket } from "../../services/websocket.service";

// Components
import TokenInput from "../TokenInput";
import CandyButton from "../../components/Button";
import BREAKPOINTS from "../../constants/breakpoints";
const Controls = ({
  isAuthenticated,
  inProgress,
  gameId,
  sweet,
  activeHandId,
  canSplit
}) => {
  const [betAmount, setBetAmount] = useState(0);
  const onChange = value => {
    setBetAmount(value);
  };
  const deal = () => {
    blackjackSocket.emit("create-new-game", parseFloat(betAmount));
  };
  const gameAction = action => {
    blackjackSocket.emit("game-action", gameId, activeHandId, action);
  };
  const double = () => {
    blackjackSocket.emit("double", gameId, activeHandId);
  };
  const split = () => {
    blackjackSocket.emit("split", gameId);
  };
  return <ControlsContainer>
            <TokenInput onChange={onChange} value={betAmount} fiatPrice={sweet.usdPrice} margin="0 0 30px 0" />
            <ButtonRow>
                <ActionButton background="linear-gradient(180deg, #A8F26E 0%, #428111 100%)" onClick={() => gameAction("hit")} disabled={!inProgress}>
                    HIT
                </ActionButton>
                <ActionButton background="linear-gradient(180deg, #FF7365 0%, #BF3932 100%)" onClick={() => gameAction("stand")} disabled={!inProgress}>
                    STAND
                </ActionButton>
            </ButtonRow>
            <ButtonRow>
                <ActionButton background="linear-gradient(180deg, #87A7FF 0%, #4D7AF6 100%)" onClick={split} disabled={!inProgress || !canSplit}>
                    SPLIT
                </ActionButton>
                <ActionButton background="linear-gradient(180deg, #87A7FF 0%, #4D7AF6 100%)" onClick={double} disabled={!inProgress}>
                    DOUBLE
                </ActionButton>
            </ButtonRow>
            <CandyButton disabled={inProgress || !isAuthenticated} onClick={deal} style={{
      width: "100%",
      marginTop: "30px"
    }}>
                DEAL NEW HAND
            </CandyButton>
        </ControlsContainer>;
};
const ControlsContainer = styled.div`
    height: 100%;
    width: 50%;
    max-width: 400px;
    background: ${props => props.theme.background._chatMessageInput};
    border-right: 1px solid ${props => props.theme.border.primary};
    backdrop-filter: blur(7.5px);
    border-radius: 10px 0 0 10px;
    padding: 15px;

    @media (max-width: ${BREAKPOINTS.md}) {
        width: 100%;
        max-width: 100%;
        border: none;
        border-top: 1px solid ${props => props.theme.border.primary};
        border-radius: 0 0 10px 10px;
    }
`;
const ButtonRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
`;
const ActionButton = styled.button`
    background: ${props => props.background};
    color: ${({
  theme
}) => theme.colors.primary};

    font-size: 14px;
    font-weight: 500;
    width: calc(50% - 5px);
    padding: 6px 16px;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    outline: none;
    border: none;

    cursor: pointer;
    &:disabled {
        color: rgba(0, 0, 0, 0.3);
        cursor: not-allowed;
        opacity: 0.5;
    }
`;
Controls.propTypes = {
  inProgress: PropTypes.bool.isRequired,
  gameId: PropTypes.string,
  sweet: PropTypes.object
};
const mapStateToProps = state => ({
  sweet: state.site.sweet,
  isAuthenticated: state.auth.isAuthenticated
});
export default connect(mapStateToProps)(Controls);