export const getWalletsByType = (wallets, type) => {
    if (!wallets) {
        return [];
    }

    const filteredWallets = [];
    for (const currencyWalletName in wallets) {
        const currencyWallet = wallets[currencyWalletName];
        for (const walletType in currencyWallet) {
            if (walletType === type) {
                filteredWallets.push(currencyWallet[walletType]);
            }
        }
    }

    return filteredWallets;
};
