import { UPDATE_THEME } from "../actions/types";
import {
    LIGHT_THEME,
    OG_THEME,
    MIDNIGHT_THEME,
    BLAST_THEME,
} from "../../constants/themes";

// _getThemeData returns the theme data based on the theme name
const _getThemeData = (theme) => {
    switch (theme) {
        case "light": // TODO: use enum
            return LIGHT_THEME;
        case "og":
            return OG_THEME;
        case "midnight":
            return MIDNIGHT_THEME;
        case "blast":
            return BLAST_THEME;
        default:
            return MIDNIGHT_THEME;
    }
};

// _getThemeFromLocalStorage returns the theme from local storage
const _getThemeFromLocalStorage = () => {
    const theme = localStorage.getItem("theme");
    return _getThemeData(theme);
};

const initialState = {
    theme: _getThemeFromLocalStorage(),
    themeType: localStorage.getItem("theme"),
};

// persist currency in window local storage
const persistTheme = (theme) => {
    localStorage.setItem("theme", theme);
};

const theme = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_THEME:
            persistTheme(payload);

            const themeData = _getThemeData(payload);

            return {
                ...state,
                theme: themeData,
                themeType: payload,
            };
        default:
            return state;
    }
};

export default theme;
