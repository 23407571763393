import styled, { useTheme } from "styled-components";
import { Grid } from "@material-ui/core";
import Price from "../../components/Price";
import { CURRENCIES } from "../../constants/currencies";

// Assets
import eventKeys from "../../assets/event-key.png";
const EVENT_BACKGROUND = "linear-gradient(180deg, rgba(247, 162, 51, 0.60) 0%, rgba(168, 94, 41, 0.60) 100%)";
const EVENT_PRICE_BOX = "black";
const CaseCard = ({
  crate,
  history
}) => {
  const theme = useTheme();
  const onClick = e => {
    e.stopPropagation();
    history.push({
      pathname: `/crates/${crate.slug}`,
      state: {
        create: true
      }
    });
  };
  return <Grid item xs={12} sm={6} md={4}>
            <StyledCard onClick={onClick} style={crate.isEventCrate ? {
      background: EVENT_BACKGROUND,
      border: "none"
    } : {}}>
                <img className="case-image" src={crate.imageUrl} alt={crate.name} />
                <div style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        marginTop: "8px",
        height: "20%"
      }}>
                    <CaseName>{crate.name}</CaseName>
                    <PriceBox style={crate.isEventCrate ? {
          background: EVENT_PRICE_BOX
        } : {}}>
                        {crate.isEventCrate ? <div style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            fontWeight: "500"
          }}>
                                1
                                <img src={eventKeys} alt="Event Key" style={{
              height: "16px",
              width: "auto"
            }} />
                            </div> : <Price amount={crate.priceUSD} currency={CURRENCIES.USD} textSize="14px" textColor={theme.colors._colorOnTeritary ? theme.colors._colorOnTeritary : theme.colors.primary} />}
                    </PriceBox>
                </div>
            </StyledCard>
        </Grid>;
};
export default CaseCard;
const StyledCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border: 1px solid ${props => props.theme.border.primary};
    background: ${props => props.theme.background.primary};
    backdrop-filter: blur(7.5px);
    color: white;
    border-radius: 10px;
    cursor: pointer;

    & .case-image {
        transition: all 0.2s ease-in-out;
        height: 180px;
        width: 180px;
        margin: 10px 0;
    }

    &:hover {
        & .case-image {
            transform: scale(1.1) rotate(2deg);
        }
    }
`;
const CaseName = styled.div`
    color: ${({
  theme
}) => theme.colors.primary};

    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-transform: uppercase;
`;
const PriceBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    background: ${({
  theme
}) => theme.colors.tertiary};
    border-radius: 5px;
    text-align: center;
`;