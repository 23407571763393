import React, { useState } from "react";
import styled from "styled-components";
import Button, { COLOURS } from "./Button";
import BuyCryptoModal from "./modals/BuyCrypto";

// Assets
import visa from "../assets/v2/payment-method-icons/visa.svg";
import mastercard from "../assets/v2/payment-method-icons/mastercard.svg";
import applePay from "../assets/v2/payment-method-icons/apple-pay.svg";
import googlePay from "../assets/v2/payment-method-icons/google-pay.svg";
import BREAKPOINTS from "../constants/breakpoints";
const BuyCrypto = () => {
  const [open, setOpen] = useState(false);
  return <>
            <BuyCryptoModal open={open} handleClose={() => setOpen(false)} />
            <BuyCryptoContainer>
                No Crypto? No problem.
                <SupportedPaymentMethods>
                    <PaymentMethod src={visa} alt="Visa" />
                    <PaymentMethod src={mastercard} alt="Mastercard" />
                    <PaymentMethod src={applePay} alt="Apple Pay" />
                    <PaymentMethod src={googlePay} alt="Google Pay" />
                </SupportedPaymentMethods>
                <Button colour={COLOURS.secondary} style={{
        padding: "1rem 1.5rem",
        fontWeight: 700
      }} onClick={() => setOpen(true)}>
                    Buy Crypto
                </Button>
            </BuyCryptoContainer>
        </>;
};
export default BuyCrypto;
const BuyCryptoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: ${props => props.theme.background.primary};
    color: ${props => props.theme.colors.primary};
    font-size: 1rem;
    font-weight: 500;
    border: 1px solid ${props => props.theme.border.primary};
    border-radius: 8px;

    @media (max-width: ${BREAKPOINTS.md}) {
        flex-wrap: wrap;
        gap: 1rem;
    }
`;
const SupportedPaymentMethods = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const PaymentMethod = styled.img`
    height: 25px;
    width: auto;

    @media (max-width: ${BREAKPOINTS.md}) {
        height: 18px;
    }
`;