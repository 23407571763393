import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-hot-toast";
import Countdown from "react-countdown";
import styled, { useTheme } from "styled-components";
import BREAKPOINTS from "../constants/breakpoints";
import useMediaQuery from "../hooks/useMediaQuery";
import { getActiveRace, getRaceEntries } from "../services/api.service";
import { getLevelBadge } from "../utils/vip";

// Components
import Price from "../components/Price";
import Skeleton from "../components/Skeleton";
import GrowAnimation from "../components/GrowAnimation";
import PageContainer from "../components/PageContainer";

// Assets
import raceBackground from "../assets/race-banner.webp";
import raceMobileBackground from "../assets/race-banner-mobile.webp";
import { CURRENCIES } from "../constants/currencies";
const PrizeBox = styled.div`
    background: url(${props => props.$image});
    min-height: 255px;
    height: fit-content;
    display: flex;
    background-size: cover;
    background-position: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${({
  theme
}) => theme.colors.primary};
    border-radius: 10px;
`;
const Title = styled.h1`
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.1em;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -50px;

    @media (min-width: ${BREAKPOINTS.lg}) {
        margin-top: 0;
    }
`;
const RaceName = styled.div`
    margin-left: 15px;
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    white-space: nowrap;
`;
const CountdownWrapper = styled.h1`
    margin-top: 20px;
    font-weight: 500;
    font-size: 20px;
`;
const NoRace = styled.div`
    height: 23rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 7rem;
    color: ${props => props.theme.colors.secondary};
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-transform: uppercase;
`;
const StyledTable = styled.table`
    border-spacing: 0 1rem;
    width: 100%;
`;
const TableWrapper = styled.div`
    margin-top: 2rem;
    background: ${({
  theme
}) => theme.background.primary};
    border-radius: 10px;
    padding: 0px 10px;
`;

// TODO: clean up the table styles
const StyledTableHead = styled.thead`
    tr {
        background: transparent;
    }

    td {
        padding-bottom: 4px;
        padding-top: 4px;
    }
`;
const StyledTableRow = styled.tr`
    margin-bottom: 10px;
    background: ${({
  theme
}) => theme.background.secondary};

    td {
        color: ${({
  theme
}) => theme.colors.primary};

        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.1em;
    }
`;
const StyledTableCell = styled.td`
    padding: 15px;
    color: ${({
  theme
}) => theme.colors.primary};

    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.05em;
    padding-left: 0;

    &:nth-child(1) {
        padding-left: 2rem;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border: none;

        @media (max-width: ${BREAKPOINTS.sm}) {
            padding-left: 5px;
            padding-right: 5px;
            font-size: 11px;
        }
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
        border: none;
        border-right: 0px;
        border-left: 0px;

        @media (max-width: ${BREAKPOINTS.sm}) {
            padding-left: 5px;
            padding-right: 5px;
            font-size: 11px;
        }
    }

    &:nth-child(4) {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-left: 0px;
    }
`;
const OwnEntryRow = styled(StyledTableRow)`
    outline: 1px solid ${({
  theme
}) => theme.colors.tertiary};
    border-radius: 10px;
`;
const Avatar = styled.img`
    width: auto;
    height: 16px;
    margin-right: 5px;
`;
const Race_1 = _compiledBlock(_props => _props.v0, {
  name: "Race_1",
  portals: ["v0"]
});
const Race = ({
  user
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(BREAKPOINTS.sm);
  const [loading, setLoading] = useState(true);
  const [activeRace, setActiveRace] = useState(null);
  const [raceEntries, setRaceEntries] = useState([]);
  const [ownEntry, setOwnEntry] = useState(null);

  // Renderer callback with condition
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed
  }) => {
    if (completed) {
      return <div>ENDED</div>;
    } else {
      function z(t) {
        return t < 10 ? `0${t}` : t;
      }
      return <span style={{
        display: "flex",
        gap: "15px"
      }}>
                    <span style={{
          padding: "12px 16px",
          width: "90px",
          textAlign: "center",
          background: theme.background.primary,
          borderRadius: "10px"
        }}>
                        {days}
                        <br />
                        <span style={{
            color: theme.colors.secondary,
            fontSize: "13px"
          }}>
                            DAYS
                        </span>
                    </span>{" "}
                    <span style={{
          padding: "12px 16px",
          width: "90px",
          textAlign: "center",
          background: theme.background.primary,
          borderRadius: "10px"
        }}>
                        {z(hours)}
                        <br />
                        <span style={{
            color: theme.colors.secondary,
            fontSize: "13px"
          }}>
                            HOURS
                        </span>
                    </span>{" "}
                    <span style={{
          padding: "12px 16px",
          width: "90px",
          textAlign: "center",
          background: theme.background.primary,
          borderRadius: "10px"
        }}>
                        {z(minutes)}
                        <br />
                        <span style={{
            color: theme.colors.secondary,
            fontSize: "13px"
          }}>
                            MIN
                        </span>
                    </span>{" "}
                    <span style={{
          padding: "12px 16px",
          width: "90px",
          textAlign: "center",
          background: theme.background.primary,
          borderRadius: "10px"
        }}>
                        {z(seconds)}
                        <br />
                        <span style={{
            color: theme.colors.secondary,
            fontSize: "13px"
          }}>
                            SECS
                        </span>
                    </span>
                </span>;
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const resp = await getActiveRace();
        setActiveRace(resp.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        toast.error("There was an error while loading race data, please try again later!");
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (activeRace) {
      const fetchRaceEntries = async () => {
        setLoading(true);
        try {
          const resp = await getRaceEntries(activeRace.id);
          setRaceEntries(resp.data.entries);
          setOwnEntry(resp.data.own);
          setLoading(false);
        } catch (error) {
          console.log(error);
          toast.error("There was an error while loading race data, please try again later!");
        }
      };
      fetchRaceEntries();
    }
  }, [activeRace]);
  return /*@million jsx-skip*/<Race_1 v0={<PageContainer maxWidth="lg">
            <GrowAnimation duration="620ms">
                <PrizeBox $image={isMobile ? raceMobileBackground : raceBackground}>
                    <Title>
                        {loading ? <Skeleton variant="rounded" height="30px" width="150px" opacity={0.2} /> : activeRace ? <Price amount={activeRace.prizeAmount} currency={CURRENCIES.USD} textSize="25px" textWeight="700" imageSize="24px" /> : <>NO ACTIVE RACE</>}
                        <RaceName>
                            {loading ? <Skeleton variant="rounded" height="30px" width="150px" opacity={0.2} /> : activeRace?.name}
                        </RaceName>
                    </Title>
                    <CountdownWrapper>
                        {loading ? <Skeleton variant="rounded" height="30px" width="170px" opacity={0.2} /> : activeRace && <Countdown date={new Date(activeRace.endTime)} renderer={renderer} />}
                    </CountdownWrapper>
                </PrizeBox>
                <TableWrapper>
                    {loading ? <Skeleton variant="rounded" height="600px" style={{
          marginTop: "2rem"
        }} /> : activeRace ? <StyledTable>
                            <StyledTableHead>
                                <StyledTableRow>
                                    <StyledTableCell>
                                        {isMobile ? "#" : "Rank"}
                                    </StyledTableCell>
                                    <StyledTableCell>User</StyledTableCell>
                                    <StyledTableCell>Wagered</StyledTableCell>
                                    <StyledTableCell>Prize</StyledTableCell>
                                </StyledTableRow>
                            </StyledTableHead>
                            <tbody>
                                {ownEntry && <OwnEntryRow>
                                        <StyledTableCell>
                                            {ownEntry.position}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Avatar alt="VIP Badge" src={getLevelBadge(ownEntry.vipRank)} data-tooltip-content={ownEntry.vipRank} data-tooltip-id="default" />
                                            {ownEntry.username}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Price amount={ownEntry.wagered} currency={CURRENCIES.USD} centered={false} />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Price amount={ownEntry.prize} currency={CURRENCIES.USD} textColor={theme.colors.green} centered={false} />
                                        </StyledTableCell>
                                    </OwnEntryRow>}
                                {raceEntries.map((entry, index) => {
              return <StyledTableRow key={index}>
                                            <StyledTableCell>
                                                {index + 1}
                                            </StyledTableCell>
                                            <StyledTableCell style={{
                  display: "flex",
                  alignItems: "center",
                  color: user?.username === entry.username ? theme.colors.tertiary : theme.colors.primary
                }}>
                                                <Avatar alt="VIP Badge" src={getLevelBadge(entry.vipRank)} data-tooltip-content={entry.vipRank} data-tooltip-id="default" />
                                                {entry.username}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Price amount={parseFloat(entry.wagered)} currency={CURRENCIES.USD} centered={false} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Price amount={entry.prize} currency={CURRENCIES.USD} textColor={theme.colors.green} centered={false} />
                                            </StyledTableCell>
                                        </StyledTableRow>;
            })}
                                {Array.from({
              length: 20 - raceEntries.length
            }).map((_, index) => <StyledTableRow key={index}>
                                        <StyledTableCell>
                                            {raceEntries.length + index + 1}
                                        </StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                    </StyledTableRow>)}
                            </tbody>
                        </StyledTable> : <NoRace>
                            There is currently no active race. <br />
                            check back later!
                        </NoRace>}
                </TableWrapper>
            </GrowAnimation>
        </PageContainer>} />;
};
Race.propTypes = {
  user: PropTypes.object
};
const mapStateToProps = state => ({
  user: state.auth.user
});
export default connect(mapStateToProps)(Race);