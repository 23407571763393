import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { coinflipSocket } from "../../services/websocket.service";
import PropTypes from "prop-types";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import makeBlockie from "ethereum-blockies-base64";
import RecaptchaV3 from "react-google-recaptcha3";

// MUI Components
import { IconButton } from "@material-ui/core";

// Components
import Animation from "./Animation";
import ProvablyModal from "../modals/ProvablyModal";
import { JoinButton } from "./JoinButton";

// Icons
import { AiFillInfoCircle } from "react-icons/ai";

// Assets
import redCoin from "../../assets/coinflip/red_coin.webp";
import blueCoin from "../../assets/coinflip/blue_coin.webp";
import coin from "../../assets/coin.svg";
import defaultAvatar from "../../assets/profile.svg";
import BREAKPOINTS from "../../constants/breakpoints";
const GameContainer = styled.div`
    height: 100%;
    width: 100%;
`;
const AnimationContainer = styled.div`
    background: ${props => props.theme.background.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid ${props => props.theme.background.primary};
    border-bottom: 0px;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    height: 40vh;
    margin-top: 28px;
    overflow: hidden;
    position: relative;
`;
const ProvablyContainer = styled.div`
    background: ${props => props.theme.background.primary};
    align-items: center;
    width: 100%;
    border-top: 0px;
    border: 2px solid ${props => props.theme.background.primary};
    & span {
        color: ${props => props.theme.colors.secondary};
    }
`;
const Value = styled.div`
    color: ${({
  theme
}) => theme.colors.primary};
    margin: 0 20px;
`;
const PlayersContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    justify-content: space-evenly;
    background: ${props => props.theme.background.secondary};
    padding-top: 20px;
    padding: 35px 20px;
    border-radius: 0px 0px 8px 8px;

    @media (max-width: ${BREAKPOINTS.md}) {
        flex-direction: column;
        align-items: center;
    }
`;
const WaitingForPlayer = styled.h1`
    color: ${({
  theme
}) => theme.colors.primary};
    font-weight: 500;
`;
const Loading = styled.p`
    &:after {
        content: " .";
        animation: dots 1s steps(5, end) infinite;
    }
`;
const BetResult = styled.div`
    position: absolute;
    top: 15px;
    left: 15px;
    font-weight: 500;
`;
const WinnerName = styled.div`
    color: #926bb0;
    font-size: 18px;
`;
const WinnerAmountWon = styled.div`
    color: #11ca52;
    font-size: 16px;
`;
const renderTime = ({
  remainingTime
}) => {
  return <Time>{remainingTime}</Time>;
};
const Time = styled.div`
    font-weight: 500;
    letter-spacing: ".1em";
    font-size: 60px;
    color: ${({
  theme
}) => theme.colors.primary};
`;
export const getPlayerAvatar = player => {
  if (player.avatar) {
    return player.avatar;
  }
  if (player.address) {
    return makeBlockie(player.address);
  }
  return "";
};
const Game = ({
  game
}) => {
  const [provablyModalVisible, setProvablyModalVisible] = useState(false);
  const [joining, setJoining] = useState(null);
  const [animationFinished, setAnimationFinished] = useState(false);
  const joinGame = async color => {
    setJoining(color);
    const reCaptcha = await RecaptchaV3.getToken({
      action: "join_coinflip_game"
    });
    coinflipSocket.emit("join-game", game._id, color, reCaptcha);
  };

  // Parse winning side into SideIndex
  const getWinner = side => ["red", "blue"].indexOf(side) + 1;
  useEffect(() => {
    // Handle join event
    const joinEvent = () => {
      setJoining(null);
    };
    coinflipSocket.on("game-join-error", joinEvent);
    coinflipSocket.on("game-join-success", joinEvent);
    return () => {
      coinflipSocket.off("game-join-error", joinEvent);
      coinflipSocket.off("game-join-success", joinEvent);
    };
  }, []);
  return <GameContainer>
            <ProvablyModal open={provablyModalVisible} handleClose={() => setProvablyModalVisible(state => !state)} fields={[{
      label: "Round ID",
      value: game._id
    }, {
      label: "Private Hash",
      value: game.privateHash
    }, {
      label: "Private Seed",
      value: game.privateSeed && game.status === 3 ? game.privateSeed : "Not Revealed"
    }, {
      label: "Public Seed",
      value: game.publicSeed && game.status === 3 ? game.publicSeed : "Not Revealed"
    }, {
      label: "Round Ticket",
      value: game.winningSide ? game.winningSide : "Not Picked"
    }]} />
            <AnimationContainer>
                {game.status === 2 && <CountdownCircleTimer isPlaying duration={5} strokeWidth={5} strokeLinecap={"square"} colors={["rgb(144, 73, 254)"]} trailColor={["#300C4B"]} onComplete={() => ({
        shouldRepeat: false
      })}>
                        {renderTime}
                    </CountdownCircleTimer>}
                {animationFinished && <BetResult>
                        <WinnerName>
                            Winner:{" "}
                            <span style={{
            color: "#fff"
          }}>
                                {game.players.find(player => player.color === game.winningSide).username}
                            </span>
                        </WinnerName>
                        <WinnerAmountWon>
                            {(game.betAmount * game.playerAmount).toFixed(2)}
                        </WinnerAmountWon>
                    </BetResult>}
                {game.status === 3 && <Animation winner={getWinner(game.winningSide)} setAnimationFinished={setAnimationFinished} />}
                {game.status === 1 && <WaitingForPlayer>
                        <Loading>Waiting for Player</Loading>
                    </WaitingForPlayer>}
            </AnimationContainer>
            <ProvablyContainer>
                <IconButton onClick={() => setProvablyModalVisible(state => !state)} color="primary" data-tooltip-content="View Provably Fair" data-tooltip-id="default">
                    <AiFillInfoCircle />
                </IconButton>
            </ProvablyContainer>
            <PlayersContainer>
                <PlayerSide side="red" coinImg={redCoin} game={game} joining={joining} joinGame={joinGame} animationFinished={animationFinished} />
                <Value>
                    <p style={{
          textAlign: "center"
        }}>VS</p>
                    <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
                        <h1 style={{
            textAlign: "center",
            margin: "0"
          }}>
                            {game.betAmount * game.playerAmount}
                        </h1>
                        <img src={coin} alt="Sweet" style={{
            marginLeft: "5px"
          }} />
                    </div>
                </Value>
                <PlayerSide side="blue" coinImg={blueCoin} game={game} joining={joining} joinGame={joinGame} animationFinished={animationFinished} />
            </PlayersContainer>
        </GameContainer>;
};
const Player = styled.div`
    display: flex;
    color: ${({
  theme
}) => theme.colors.primary};

    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.1em;
    align-items: center;
    position: relative;
    border-radius: 15px;
    background: ${props => props.theme.background.primary};
    padding: 20px;
    min-width: 300px;

    @media (max-width: ${BREAKPOINTS.md}) {
        padding: 15px;
    }
`;
const Avatar = styled.img`
    height: 40px;
    width: 40px;
    border-radius: 100%;
    margin-left: 1rem;
    background: #272b2f;
`;
const Username = styled.div`
    margin-left: 8px;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
`;
const UserLevel = styled.div`
    padding: 3px 6px 3px 6px;
    border-radius: 5px;
    margin-left: 10px;
    color: ${({
  theme
}) => theme.colors.primary};
`;
const PlayerSide = ({
  side,
  game,
  joining,
  joinGame,
  coinImg,
  animationFinished
}) => {
  // Variables
  let player = game.players.find(player => player.color === side);
  let color = "#2B83D1";
  if (side === "red") {
    color = "#CD4443";
  }
  let joinImg = side === "red" ? redCoin : blueCoin;
  return player ? <Player style={game.winningSide !== side && animationFinished ? {
    opacity: 0.2,
    transition: "all 0.2s ease",
    border: "1px solid " + color
  } : {
    opacity: 1,
    border: "1px solid " + color
  }}>
            <img src={coinImg} alt="Coin Side" style={{
      height: "2.3rem"
    }} />
            <Avatar src={getPlayerAvatar(player)} alt="Avatar" style={{
      border: "3px solid",
      borderColor: color
    }} />
            <UserLevel style={{
      background: player.level.levelColor
    }}>
                {player.level.name}
            </UserLevel>
            <Username>{player.username}</Username>
        </Player> : <Player style={{
    border: `1px solid ${color}`
  }}>
            <img src={joinImg} alt={side === "blue" ? "Blue Side" : "Red Side"} style={{
      height: "2.3rem"
    }} />
            <Avatar src={defaultAvatar} alt="Avatar" style={{
      border: `3px solid ${color}`
    }} />
            <JoinButton onClick={() => !joining ? joinGame(side) : null} disabled={joining ? true : false}>
                {joining === side ? "Joining..." : `Join for ${game.betAmount}`}
            </JoinButton>
        </Player>;
};
Game.propTypes = {
  game: PropTypes.object.isRequired
};
export default Game;